import Pusher from "pusher-js";
import * as PusherPushNotifications from "@pusher/push-notifications-web";

Pusher.logToConsole = true;
export const pusher = new Pusher("fbe480d217cc751b3ecf", {
  cluster: "us3",
  encrypted: true
});

export const beamsClient = !/^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  ? new PusherPushNotifications.Client({
      instanceId: "d25d37e8-d8d5-4678-8448-2bec838e13eb"
    })
  : null;
