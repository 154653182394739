import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Modal, message } from "antd";
import { useSelector } from "react-redux";
import { getImportST, updateImportServicetian } from "../services/Collections";

export default function ImportTitanService({
  open,
  handleClose,
  title,
  description,
  button,
  btnColor,
  isLoading
}) {
  const workSpaceData = useSelector((state) => state?.LoginSlice?.data?.workspace);

  const [type, setType] = useState([
    { name: "Enable", id: 1, isChecked: false },
    { name: "Disable", id: 0, isChecked: true }
  ]);

  const handleSelectedItems = (isSelected, item) => {
    let tempValues = [];
    if (item.name === "Enable") {
      tempValues.push({ name: "Enable", id: 1, isChecked: isSelected });
      tempValues.push({ name: "Disable", id: 2, isChecked: !isSelected });
    } else {
      tempValues.push({ name: "Enable", id: 1, isChecked: !isSelected });
      tempValues.push({ name: "Disable", id: 2, isChecked: isSelected });
    }
    setType(tempValues);
  };

  const getSelectedValuesOfImporntST = async () => {
    let requestPayload = `${workSpaceData?.tenant_id}/${workSpaceData?._id}`;
    // let requestPayload = "372955144/64a256ef4e71af824b163227";
    let res = await getImportST(requestPayload);
    if (res?.status === 200) {
      let tempValues = [];
      if (res?.data?.import_to_st == 1) {
        tempValues.push({ name: "Enable", id: 1, isChecked: true });
        tempValues.push({ name: "Disable", id: 2, isChecked: false });
      } else {
        tempValues.push({ name: "Enable", id: 1, isChecked: false });
        tempValues.push({ name: "Disable", id: 2, isChecked: true });
      }
      setType(tempValues);
    } else {
      message.error(
        res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
      );
    }
  };

  const handleSubmit = async () => {
    let requestPayload = {
      tenant_id: workSpaceData?.tenant_id,
      workspace_id: workSpaceData?._id,
      import_to_st: type?.[0]?.isChecked ? 1 : 0
    };
    let res = await updateImportServicetian(requestPayload);
    if (res?.status === 200) {
      message.info(
        res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
      );
      handleClose();
      //   let tempValues = [];
      //   if (res?.data?.import_to_st == 0) {
      //     tempValues.push({ name: "Enable", id: 1, isChecked: false });
      //     tempValues.push({ name: "Disable", id: 2, isChecked: true });
      //   } else {
      //     tempValues.push({ name: "Enable", id: 1, isChecked: true });
      //     tempValues.push({ name: "Disable", id: 2, isChecked: false });
      //   }
      //   setType(tempValues);
    } else {
      message.error(
        res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
      );
    }
  };

  useEffect(() => {
    getSelectedValuesOfImporntST();
  }, []);

  return (
    <div>
      <Modal open={open} centered width={448} footer={false} onCancel={handleClose}>
        <ImportTitanModalWrapper>
          <div className="title">
            <h2>{title}</h2>
            <p>{description}</p>
          </div>

          <div className="toggle">
            {type?.map((item, index) => (
              <section key={index}>
                <div className="custom-checkbox">
                  <input
                    type="checkbox"
                    onClick={(e) => handleSelectedItems(e.target.checked, item)}
                    checked={item.isChecked}
                  />
                  <svg viewBox="0 0 35.6 35.6">
                    <circle className="background" cx="17.8" cy="17.8" r="17.8"></circle>
                    <circle className="stroke" cx="17.8" cy="17.8" r="14.37"></circle>
                    <polyline
                      className="check"
                      points="11.78 18.12 15.55 22.23 25.17 12.87"></polyline>
                  </svg>
                </div>

                <span>{item?.name}</span>
              </section>
            ))}
          </div>

          <div className="btns">
            <button onClick={handleClose} className="cancel">
              Cancel
            </button>
            {isLoading ? (
              <span className="delete">Loading...</span>
            ) : (
              <button onClick={handleSubmit} style={{ background: btnColor }} className="delete">
                {button}
              </button>
            )}
          </div>
        </ImportTitanModalWrapper>
      </Modal>
    </div>
  );
}

ImportTitanService.propTypes = {
  open: PropTypes.boolean,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.string,
  btnColor: PropTypes.string,
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  setImportTitanModal: PropTypes.func
};

const ImportTitanModalWrapper = styled.div`
  width: 100%;
  margin: 36px 0 12px 0;

  .toggle {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 20px 0 0 0;
  }
  section {
    display: flex;
    align-items: center;
    span {
      margin: 0 5px;
    }
    .custom-checkbox {
      position: relative;
      display: inline-block;
      width: 25px;
      height: 25px;
      .background {
        fill: #55a3f2;
        transition: ease all 0.6s;
        -webkit-transition: ease all 0.6s;
      }
      .stroke {
        fill: none;
        stroke: #fff;
        stroke-miterlimit: 10;
        stroke-width: 2px;
        stroke-dashoffset: 100;
        stroke-dasharray: 100;
        transition: ease all 0.6s;
        -webkit-transition: ease all 0.6s;
      }
      .check {
        fill: none;
        stroke: #fff;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2px;
        stroke-dashoffset: 22;
        stroke-dasharray: 22;
        transition: ease all 0.6s;
        -webkit-transition: ease all 0.6s;
      }
      input[type="checkbox"] {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        margin: 0;
        opacity: 0;
        -appearance: none;
        -webkit-appearance: none;

        &:hover {
          cursor: pointer;
          & + svg {
            .background {
              fill: #cecece;
            }
            .check {
              stroke-dashoffset: 0;
            }
          }
        }

        &:checked {
          & + svg {
            .background {
              fill: #123295;
            }
            .stroke {
              stroke-dashoffset: 0;
            }
            .check {
              stroke-dashoffset: 0;
            }
          }
        }
      }
    }
  }

  .title {
    width: 100%;
    padding: 0 25px;

    h2 {
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      color: #101010;
    }

    p {
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #4b5563;
      margin-top: 8px;
    }
  }

  .btns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 24px;

    span {
      height: 44px;
      padding: 0 24px;
      border-radius: 100px;
      border-style: none;
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      cursor: pointer;
      color: #ffffff;
      background: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .cancel {
      width: 96px;
      height: 44px;
      background: #eaeaea;
      border-radius: 100px;
      border-style: none;
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      cursor: pointer;
      color: #101010;
    }
    .delete {
      height: 44px;
      padding: 0 24px;
      border-radius: 100px;
      border-style: none;
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      cursor: pointer;
      color: #ffffff;
    }
  }
`;
