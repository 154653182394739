/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import { ForgetWrapper, PasswordEyeWrapper } from "../Forget/ForgetStyle";
import { PublicButton } from "../../../components/PublicButton";
import { useNavigate } from "react-router";
import { Field, Form, Formik } from "formik";
// import InputField from "../../../validation/inputField";
import * as yup from "yup";
import { SignUpUser } from "../../../services/Collections";
import { message } from "antd";
import { Check, Dot, PasswordEye, PasswordEyeOpen } from "../../../utils/Icons/SvgIcons";
import { PasswordRequirement } from "../Forget/SetNewPassword";

export default function SignupWithEmail() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [password, setPassword] = useState(true);
  const [confirmpassword, setConfirmPassword] = useState(true);
  const [passed, setPassed] = useState({
    min8: false,
    lowercase: false,
    uppercase: false,
    special: false,
    numbers: false
    // consecutive: false
  });

  const initialValues = {
    email: "",
    password: "",
    confirmpassword: ""
  };

  const validatePassword = (password) => {
    if (password.length > 0) {
      if (password.length >= 8) {
        setPassed((prevState) => ({
          ...prevState,
          min8: true
        }));
      } else {
        setPassed((prevState) => ({
          ...prevState,
          min8: false
        }));
      }
      if (password.search(/[a-z]/) !== -1) {
        setPassed((prevState) => ({
          ...prevState,
          lowercase: true
        }));
      } else {
        setPassed((prevState) => ({
          ...prevState,
          lowercase: false
        }));
      }
      if (password.search(/[A-Z]/) !== -1) {
        setPassed((prevState) => ({
          ...prevState,
          uppercase: true
        }));
      } else {
        setPassed((prevState) => ({
          ...prevState,
          uppercase: false
        }));
      }
      if (password.search(/[0-9]/) !== -1) {
        setPassed((prevState) => ({
          ...prevState,
          numbers: true
        }));
      } else {
        setPassed((prevState) => ({
          ...prevState,
          numbers: false
        }));
      }
      if (password.search(/[^\w]/) !== -1) {
        setPassed((prevState) => ({
          ...prevState,
          special: true
        }));
      } else {
        setPassed((prevState) => ({
          ...prevState,
          special: false
        }));
      }
    } else {
      setPassed({
        min8: false,
        lowercase: false,
        uppercase: false,
        special: false,
        numbers: false
        // consecutive: false
      });
    }
  };

  const validationSchema = yup.object().shape({
    email: yup.string().email("Email must be a valid email").required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must have at least 8 characters")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol"),
    confirmpassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required")
  });

  const handleForm = async (payload) => {
    setLoading(true);
    let res = await SignUpUser(payload);
    if (res?.status === 200) {
      message.success(res?.message);
      navigate("/congratulation", { state: { email: payload?.email } });
    } else {
      message.error(
        res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
      );
    }
    setLoading(false);
  };

  // const FormikFieldValues = [
  //   {
  //     label: "Email",
  //     name: "email",
  //     type: "email",
  //     placeholder: "Enter your email address",
  //     component: InputField,
  //     eyeComponent: null
  //   },
  //   {
  //     label: "Password",
  //     name: "password",
  //     type: password ? "password" : "text",
  //     placeholder: "Enter your password",
  //     component: InputField,
  //     eyeComponent: (
  //       <PasswordEyeWrapper onClick={() => setPassword(!password)}>
  //         {password ? <PasswordEye /> : <PasswordEyeOpen />}
  //       </PasswordEyeWrapper>
  //     )
  //   },
  //   {
  //     label: "Confirm Password",
  //     name: "confirmpassword",
  //     type: confirmpassword ? "password" : "text",
  //     placeholder: "Enter Confirm password",
  //     component: InputField,
  //     eyeComponent: (
  //       <PasswordEyeWrapper onClick={() => setConfirmPassword(!confirmpassword)}>
  //         {confirmpassword ? <PasswordEye /> : <PasswordEyeOpen />}
  //       </PasswordEyeWrapper>
  //     )
  //   }
  // ];

  return (
    <ForgetWrapper>
      <section style={{ padding: "30px 0px 0px 0px", display: "grid", alignItems: "center" }}>
        {/* <img src={AppLogo} /> */}
        <div>
          <header>Sign up</header>
          <subheader>Sign up to start the communications with your mate</subheader>
          <div className="inner-Section">
            <Formik
              isInitialValid={false}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleForm}
              render={({ setFieldValue, errors, touched }) => (
                <Form>
                  <div style={{ position: "relative", display: "flex", flexDirection: "column" }}>
                    <label>Email</label>
                    <Field
                      name={"email"}
                      type={"email"}
                      placeholder={"Enter your email address"}
                      onChange={(e) => {
                        setFieldValue("email", e.target.value);
                      }}
                    />
                    {touched.email && errors.email && (
                      <div style={{ marginTop: "5px", fontSize: "12px", color: "red" }}>
                        {errors.email}
                      </div>
                    )}
                  </div>
                  <div style={{ position: "relative", display: "flex", flexDirection: "column" }}>
                    <label>Password</label>
                    <Field
                      name={"password"}
                      type={password ? "password" : "text"}
                      placeholder={"Enter your password"}
                      onChange={(e) => {
                        setFieldValue("password", e.target.value);
                        validatePassword(e.target.value);
                        // isIdentical(e.target.value);
                      }}
                    />
                    {touched.password && errors.password && (
                      <div style={{ marginTop: "5px", fontSize: "12px", color: "red" }}>
                        {errors.password}
                      </div>
                    )}
                    <PasswordEyeWrapper onClick={() => setPassword(!password)}>
                      {password ? <PasswordEye /> : <PasswordEyeOpen />}
                    </PasswordEyeWrapper>
                  </div>
                  <div style={{ position: "relative", display: "flex", flexDirection: "column" }}>
                    <label>Confirm Password</label>
                    <Field
                      name={"confirmpassword"}
                      type={confirmpassword ? "password" : "text"}
                      placeholder={"Enter Confirm password"}
                      onChange={(e) => {
                        setFieldValue("confirmpassword", e.target.value);
                      }}
                    />
                    {touched.confirmpassword && errors.confirmpassword && (
                      <div style={{ marginTop: "5px", fontSize: "12px", color: "red" }}>
                        {errors.confirmpassword}
                      </div>
                    )}
                    <PasswordEyeWrapper onClick={() => setConfirmPassword(!confirmpassword)}>
                      {confirmpassword ? <PasswordEye /> : <PasswordEyeOpen />}
                    </PasswordEyeWrapper>
                  </div>

                  <PasswordRequirement>
                    <p>Password requirements :</p>
                    <div>
                      <ul className="password-validations">
                        <li className={passed?.min8 && "active"}>
                          {" "}
                          {passed?.min8 ? <Check /> : <Dot />} Min. 8 characters
                        </li>
                        <li className={passed?.lowercase && "active"}>
                          {" "}
                          {passed?.lowercase ? <Check /> : <Dot />} Include lowercase characters
                        </li>
                        <li className={passed?.uppercase && "active"}>
                          {" "}
                          {passed?.uppercase ? <Check /> : <Dot />} Include uppercase characters
                        </li>
                        <li className={passed?.special && "active"}>
                          {" "}
                          {passed?.special ? <Check /> : <Dot />} Include special characters
                        </li>

                        <li className={passed?.numbers && "active"}>
                          {" "}
                          {passed?.numbers ? <Check /> : <Dot />} Include numbers
                        </li>
                      </ul>
                    </div>
                  </PasswordRequirement>

                  <div className="btn-wrapper" style={{ marginBottom: "15px" }}>
                    <PublicButton textcard={"Continue"} isLoading={loading} />
                  </div>
                </Form>
              )}
            />
          </div>
          <footer>
            Already have an account? <span onClick={() => navigate("/")}>Sign In</span>
          </footer>
        </div>
      </section>
    </ForgetWrapper>
  );
}
