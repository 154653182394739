import { Modal } from "antd";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { DeleteGroupModalIcon, LeaveGroupModalIcon } from "../utils/Icons/SvgIcons";

export const LeaveGroupModal = ({ open, handleClose, isLoading, handleSubmit, type }) => {
  return (
    <Modal open={open} centered width={500} footer={false} onCancel={handleClose}>
      <DeleteModalWrapper>
        <div className="title">
          {type == "delete" ? <DeleteGroupModalIcon /> : <LeaveGroupModalIcon />}
          <div>
            {type == "delete" ? (
              <>
                <h2>Are you sure you want to delete the group?</h2>
                <p>
                  Deleting the group will permanently remove all <br /> conversations and shared
                  content associated with it.
                </p>
              </>
            ) : (
              <>
                <h2>Are you sure you want to leave group?</h2>
                <p>
                  This action will remove you from the {"group's"} communication and shared
                  resources.
                </p>
              </>
            )}
          </div>
        </div>
        <div className="btns">
          <button onClick={handleClose} className="cancel">
            Cancel
          </button>
          {isLoading ? (
            <span style={{ background: type == "delete" && "#D91717" }} className="delete">
              Loading...
            </span>
          ) : type == "delete" ? (
            <button
              style={{ background: "#D91717" }}
              onClick={handleSubmit ? handleSubmit : handleClose}
              className="delete">
              Delete group
            </button>
          ) : (
            <button onClick={handleSubmit ? handleSubmit : handleClose} className="delete">
              Leave group
            </button>
          )}
        </div>
      </DeleteModalWrapper>
    </Modal>
  );
};
LeaveGroupModal.propTypes = {
  open: PropTypes.boolean,
  handleClose: PropTypes.func,
  isLoading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  type: PropTypes.string
};

const DeleteModalWrapper = styled.div`
  width: 100%;
  margin: 36px 0 12px 0;

  .title {
    width: 100%;
    padding: 0 25px;
    display: grid;
    gap: 10px;
    place-items: center;

    h2 {
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      color: #101010;
    }

    p {
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #4b5563;
      margin-top: 8px;
    }
  }

  .btns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 24px;

    span {
      height: 44px;
      padding: 0 24px;
      border-radius: 100px;
      border-style: none;
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      cursor: pointer;
      color: #ffffff;
      background: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .cancel {
      width: 96px;
      height: 44px;
      background: #eaeaea;
      border-radius: 4px;
      border-style: none;
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      cursor: pointer;
      color: #101010;
    }
    .delete {
      height: 44px;
      padding: 0 24px;
      border-radius: 4px;
      background: #3d97f2;
      border-style: none;
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      cursor: pointer;
      color: #ffffff;
    }
  }
`;
