// import NoActiveWorkspace from "../components/NoActiveWorkspace";
import NoJobs from "../components/NoJobs";
import { BusinessIndex } from "../module/business/BusinessIndex";
import { JobsManagement } from "../module/jobManagement/JobsManagement";
import { JobsIndex } from "../module/jobs/JobsIndex";
// import ChatMessenger from "../module/Messenger/ChatMessenger";
import RegionsIndex from "../module/regions/RegionsIndex";
import { UserManagement } from "../module/userManagement/UserManagement";
import { WorkspaceIndex } from "../module/workspaceManagement/WorkspaceIndex";

export const PrivatePaths = [
  {
    path: "workspace",
    component: <WorkspaceIndex />,
    subTabs: [
      {
        path: "regions",
        component: <RegionsIndex />
      },
      {
        path: "business",
        component: <BusinessIndex />
      },
      {
        path: "jobs",
        component: <JobsIndex />
      }
      // {
      //   path: "titan-job",
      //   component: <NoActiveWorkspace />
      // }
    ]
  },
  // {
  //   path: "NoActiveWorkspace",
  //   component: <NoActiveWorkspace />
  // },
  {
    path: "no-jobs",
    component: <NoJobs />
  },

  {
    path: "user-management",
    component: <UserManagement />
  },
  {
    path: "jobs",
    component: <JobsManagement />
  }
  // {
  //   path: "chats",
  //   component: <ChatMessenger />
  // }
];
