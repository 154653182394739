/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import { ForgetWrapper } from "../Forget/ForgetStyle";
import { PublicButton } from "../../../components/PublicButton";
import { useNavigate } from "react-router";
import { Field, Form, Formik } from "formik";
import InputField from "../../../validation/inputField";
import * as yup from "yup";
import { ForgetPasswordApi } from "../../../services/Collections";
import { message } from "antd";

export default function ForgetPassword() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const initialValues = {
    email: ""
  };

  const validationSchema = yup.object().shape({
    email: yup.string().email("Email must be a valid email").required("Email is required")
  });

  const handleForm = async (payload) => {
    setLoading(true);
    let res = await ForgetPasswordApi(payload);
    if (res?.status === 200) {
      message.success(res?.message);
      navigate(-1);
    } else {
      message.error(
        res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
      );
    }
    setLoading(false);
  };

  const FormikFieldValues = [
    {
      label: "Email",
      name: "email",
      type: "email",
      placeholder: "Enter your email address",
      component: InputField,
      eyeComponent: null
    }
  ];

  return (
    <ForgetWrapper>
      <section style={{ padding: "30px 0px 0px 0px", display: "grid", alignItems: "center" }}>
        {/* <img src={AppLogo} /> */}
        <div>
          <header>Forgot Password ?</header>
          <subheader>No worries, we’ll send you reset instructions</subheader>
          <div className="inner-Section">
            <Formik
              isInitialValid={false}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleForm}
              render={() => (
                <Form>
                  {FormikFieldValues &&
                    FormikFieldValues?.map((field) => (
                      <div
                        key={field?.name}
                        style={{ position: "relative", display: "flex", flexDirection: "column" }}>
                        <label>{field?.label}</label>
                        <Field
                          name={field?.name}
                          type={field?.type}
                          placeholder={field?.placeholder}
                          component={field?.component}
                        />
                        {field?.eyeComponent}
                      </div>
                    ))}

                  <div className="btn-wrapper" style={{ marginBottom: "15px" }}>
                    <PublicButton textcard={"Send link to the email"} isLoading={loading} />
                  </div>
                </Form>
              )}
            />
          </div>
          <footer>
            <span onClick={() => navigate(-1)} style={{ fontSize: "15px", textDecoration: "none" }}>
              Back
            </span>
          </footer>
        </div>
      </section>
    </ForgetWrapper>
  );
}
