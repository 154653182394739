import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import CustomCard from "../../components/CustomCard";
import { EditModal } from "../../components/EditModal";
import {
  getBusinessListing,
  getRegionListing,
  updateBusinessRegion
} from "../../services/Collections";
import { ModalWrapper } from "../regions/RegionsIndex";
import { ModalLoader } from "../../components/ModalLoader";
import { Table, message } from "antd";
import { useSelector } from "react-redux";
import CxcHeader from "../../components/CxcHeader";
import ResponsiveHeader from "../../components/ResponsiveHeader";
// import { NoData } from "../jobs/JobsIndex";
import { NameWrapper } from "../userManagement/UserManagement";
import { PaginationBox } from "../../components/Pagination";

export const BusinessIndex = () => {
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [businessUnit, setBusinessUnit] = useState([]);
  const [loading, setLoading] = useState(false);
  const [regionData, setRegionData] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const connectedWorkspace = useSelector((state) => state?.LoginSlice?.data);
  const [searchData, setSearchData] = useState([]);
  const [searchWord, setSearchWord] = useState("");
  const [pagesize, setPageSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  let columns = [
    {
      title: "NAME",
      dataIndex: "name",
      render: (val) => (
        <NameWrapper>
          <p>{val ? val : "NA"}</p>
        </NameWrapper>
      )
    },
    {
      title: "REGION MANUAL",
      dataIndex: "region",
      render: (val) => (
        <NameWrapper>
          <p>{val ? val : "NA"}</p>
        </NameWrapper>
      )
    },
    {
      title: "ACTION",
      dataIndex: "action",
      render: (_, cardData) => (
        <NameWrapper>
          <div
            onClick={() => {
              setEditModal(true);
              setEditData(cardData);
            }}>
            <u>EDIT</u>
          </div>
        </NameWrapper>
      )
    }
  ];

  const getTableData = (searchData, listing) => {
    if (searchData?.length > 0 || searchWord) {
      return searchData;
    } else {
      return listing;
    }
  };

  // const businessUnit = [
  //   {
  //     label1: "NAME",
  //     value1: "Air Pros, South East Florida",
  //     label2: "REGION MANUAL",
  //     value2: "NA"
  //   },
  //   {
  //     label1: "NAME",
  //     value1: "Air Pros, South East Florida",
  //     label2: "REGION MANUAL",
  //     value2: "NA"
  //   },
  //   {
  //     label1: "NAME",
  //     value1: "Air Pros, South East Florida",
  //     label2: "REGION MANUAL",
  //     value2: "NA"
  //   },
  //   {
  //     label1: "NAME",
  //     value1: "Air Pros, South East Florida",
  //     label2: "REGION MANUAL",
  //     value2: "NA"
  //   },
  //   {
  //     label1: "NAME",
  //     value1: "Air Pros, South East Florida",
  //     label2: "REGION MANUAL",
  //     value2: "NA"
  //   },
  //   {
  //     label1: "NAME",
  //     value1: "Air Pros, South East Florida",
  //     label2: "REGION MANUAL",
  //     value2: "NA"
  //   },
  //   {
  //     label1: "NAME",
  //     value1: "Air Pros, South East Florida",
  //     label2: "REGION MANUAL",
  //     value2: "NA"
  //   },
  // {
  //   label1: "NAME",
  //   value1: "Air Pros, South East Florida",
  //   label2: "REGION MANUAL",
  //   value2: "NA"
  // }
  // ];

  const handleBusinessListing = async () => {
    setLoading(true);
    let params = new URLSearchParams();

    let dbname =
      "yapi_" + connectedWorkspace?.workspace?._id + "_" + connectedWorkspace?.workspace?.tenant_id;
    params.append("db", dbname);
    params.append("page", currentPage);
    params.append("page_size", pagesize);

    let res = await getBusinessListing(params.toString());
    if (res?.status == 200 || res?.message == "OK") {
      setTotalCount(res?.total);

      let arr = [];
      Array.isArray(res?.data) &&
        res?.data?.length > 0 &&
        res?.data?.map((ele) => {
          let obj = {
            name: ele?.name,
            region: ele?.region_manual ? ele?.region_manual : "NA",
            ...ele
          };
          arr.push(obj);
        });

      setLoading(false);
      setBusinessUnit(arr);
    } else {
      setLoading(false);
      message.error(res?.response?.data?.message || res?.message || "Something went wrong");
    }
  };

  const handleUpdateRegions = async (value) => {
    setModalLoading(true);
    let params = new URLSearchParams();
    let dbname =
      "yapi_" + connectedWorkspace?.workspace?._id + "_" + connectedWorkspace?.workspace?.tenant_id;
    params.append("db", dbname);

    let obj = [
      {
        id: value?.id,
        region_manual: value?.label
      }
    ];
    let res = await updateBusinessRegion(params.toString(), obj);
    if (res?.status == 200 || res?.message == "OK") {
      setEditModal(false);
      setModalLoading(false);
      handleBusinessListing();
      handleRegionListing();
    } else {
      setModalLoading(false);
      message.error(res?.response?.data?.message || res?.message || "Something went wrong");
    }
  };

  const handleRegionListing = async () => {
    setLoading(true);
    let params = new URLSearchParams();
    let dbname =
      "yapi_" + connectedWorkspace?.workspace?._id + "_" + connectedWorkspace?.workspace?.tenant_id;
    params.append("db", dbname);
    let res = await getRegionListing(params.toString());
    if (res?.status == 200 || res?.message == "OK") {
      setLoading(false);
      let arr = [];
      Array.isArray(res?.data) &&
        res?.data?.length > 0 &&
        res?.data?.map((ele) => {
          let obj = {
            label: ele?.region,
            value: ele?.id
          };
          arr.push(obj);
        });
      setRegionData(arr);
    } else {
      setLoading(false);

      // message.error(res?.message || "Something went wrong");
    }
  };

  const handleSearch = (word) => {
    setSearchWord(word);
    if (word) {
      let updated = businessUnit?.filter((el) =>
        el?.name?.toLowerCase()?.includes(word.toLowerCase())
      );

      setSearchData(updated);
    } else {
      setSearchData([]);
    }
  };

  const onChange = (page, limit) => {
    setPageSize(limit);
    setCurrentPage(page);
  };

  useEffect(() => {
    handleRegionListing();
  }, []);

  useEffect(() => {
    handleBusinessListing();
  }, [currentPage]);
  return (
    <>
      {editModal && (
        <EditModal
          open={editModal}
          handleClose={() => setEditModal(false)}
          data={editData}
          type="business"
          options={regionData}
          handleUpdateRegions={handleUpdateRegions}
          loading={modalLoading}
        />
      )}
      <div>
        <CxcHeader headerName={"Business Units"} isFilter={false} />
        <ResponsiveHeader />
        <TitleWrapper>
          <h4></h4>
          <SearchWrapper>
            <label>Search:</label>{" "}
            <input
              placeholder="Search..."
              disabled={loading}
              type="text"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </SearchWrapper>
        </TitleWrapper>
        {loading ? (
          <ModalWrapper>
            <ModalLoader size={40} color={"#101010"} />
          </ModalWrapper>
        ) : (
          <>
            {/* {searchData.length > 0 || searchWord ? (
              <>
                {searchData?.length > 0 ? (
                  <ContentWrapper>
                    {searchData?.map((ele, index) => (
                      <CustomCard
                        key={index}
                        cardData={ele}
                        open={() => setEditModal(true)}
                        setEditData={setEditData}
                      />
                    ))}
                  </ContentWrapper>
                ) : (
                  <NoData>
                    <h1>NO DATA FOUND</h1>
                  </NoData>
                )}
              </>
            ) : ( */}
            <TableWrapper>
              <Table
                className="gap-table"
                columns={columns}
                dataSource={getTableData(searchData, businessUnit)}
                pagination={false}
              />
            </TableWrapper>
            {/* )} */}
          </>
        )}

        <PaginationBox
          limit={pagesize}
          current={currentPage}
          total={totalCount}
          onChange={onChange}
          showSizeChanger={false}
        />
        {/* {businessUnit?.map((ele, index) => (
                  <Table   />
                ))} */}
      </div>
    </>
  );
};

const TitleWrapper = styled.div`
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-family: Lato, sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 30px;
    line-height: 20px !important;
    color: #101010 !important;
    margin: 10px 0 !important;
  }
`;

export const ContentWrapper = styled.div`
display:grid;
grid-template-columns: repeat(3,1fr);
padding: 16px !important;
gap:16px;
padding: 0 0 86px 0;
margin-bottom:70px;

@media(max-width: 800px){
  grid-template-columns: repeat(2,1fr);
}
@media(max-width: 500px){
  grid-template-columns: repeat(1,1fr);
}
  .cardWrapper {
    cursor: pointer;
    background: #fff;.
    box-shadow: 0px 2px 12px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    padding:15px;
    .audioPlayDiv {
      display: flex;
      align-items: center;
      gap: 8px;
      h1 {
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
      }
      button {
        display: flex;
        align-items: center;
        padding: 4px 12px;
        background: #f3edff;
        border-radius: 40px;
        border: none;
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height, or 133% */

        display: flex;
        align-items: center;

        color: #8045fe;
      }
      .playArrowDiv {
        width: 40px;
        height: 40px;
       display: flex;
       justify-content: center;
       align-items: center;
        border-radius: 50%;
        background: #edf2f8ed;
      }
    }
    .cardInfo{
      display:flex;
      justify-content:space-between;
      margin-top:20px;
      
        ul{
          list-style-type:none;
          .forGray{
            color :#656E79;
            font-family: Lato, sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #656E79;
            padding-top: 8px;
          }
          .forGray1{
            color :#656E79;
            font-family: Lato, sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #656E79;
            padding-top: 8px;
            margin-bottom:20px;

            img{
              width: 20px;
              height: 20px;
              border-radius: 50%;

            }
          }
            li{
                font-family: Lato, sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                color: #101010;
                display:flex;
                align-items:center;
                gap:6px;
                line-height: 20px;
            }
        }
    }
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  input {
    height: 33px;
    padding: 0 5px;
    width: 100%;
    filter: drop-shadow(rgba(16, 24, 40, 0.06) 0px 2px 12px);
    background: rgb(255, 255, 255);
    border-radius: 8px;
    border-style: none;
    padding: 14px 16px;
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgb(75, 85, 99);
    border: 1px solid #cecece;
    :focus {
      outline: none;
    }
  }
  label {
    font-family: Lato, sans-serif;
    font-size: 15px;
  }
`;

{
  /* <CustomCard
  key={index}
  cardData={ele}
  open={() => setEditModal(true)}
  setEditData={setEditData}
/> */
}
export const TableWrapper = styled.div`
  width: 100%;
  padding: 0 15px;
  margin-bottom: 63px !important;
  overflow-x: scroll;
`;
