/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ChatList from "./ChatList";
import PropTypes from "prop-types";
import { JobsIcon, SingleDownArrow, SingleUpArrow } from "../utils/Icons/SvgIcons";
import { useDispatch, useSelector } from "react-redux";
import { getJobInfo, searchJobListing, viewJobMember } from "../services/Collections";
import { message } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useNavigate } from "react-router-dom";
import { ChatId, JobsData } from "../module/Messenger/ChatListIdSlice";
import { addMemberEvent, removeMemberEvent, unReadMessageEvent } from "../Pusher/PusherSlice";

export default function MessengerSidenav({
  setSubMenu,
  extractGroup,
  setExtractGroup,
  navigateRoutes,
  calcHeight,
  setTeamExtractGroup,
  setGroupExtractGroup
}) {
  const location = useLocation()?.pathname;
  const activeJobId = useSelector((state) => state?.ChatId?.job_Id?.id);
  const [activeJob, setActiveJob] = useState(null);
  const connectedWorkspace = useSelector((state) => state?.LoginSlice?.data);
  const [page, setPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const jobListData = useSelector((state) => state?.ChatId?.jobs_Data);
  let userid = useSelector((state) => state?.LoginSlice?.data?._id);
  let AllJobIds = useSelector((state) => state?.LoginSlice?.data?.jobIds);
  const [searchJobData, setSearchJobData] = useState([]);
  const navigate = useNavigate();
  const removemember = useSelector((state) => state?.PusherSlice?.removeMemberEvent);
  const addmember = useSelector((state) => state?.PusherSlice?.addMemberEvent);
  const messageUnRead = useSelector((state) => state?.PusherSlice?.unReadMessageEvent);

  const handleExtractionofGroup = () => {
    setExtractGroup(!extractGroup);
  };

  const searchJobsListing = async () => {
    setPage(1);
    let params = new URLSearchParams();
    params.append("workspace_id", connectedWorkspace?.workspace?._id);
    params.append("user_id", connectedWorkspace?._id);
    search && params.append("search", search);

    let res = await searchJobListing(params.toString());
    if (res?.status == 200 || res?.message == "OK") {
      let data = res?.data;
      setHasMoreData(data?.length !== 0);

      if (search) {
        setSearchJobData(data);
      } else {
        dispatch(JobsData(data));
      }
    } else {
      message.error(res?.response?.data?.message || res?.message || "Something went wrong");
    }
  };
  
  const handleJobsListing = async (pageNo) => {
    let currentpage;
    if (
      jobListData?.length > 0 &&
      jobListData?.[0]?.workspace_id != connectedWorkspace?.workspace?._id
    ) {
      currentpage = 1;
    } else {
      currentpage = pageNo ?? page;
    }
    let params = new URLSearchParams();
    params.append("workspace_id", connectedWorkspace?.workspace?._id);
    params.append("user_id", connectedWorkspace?._id);
    params.append("page", currentpage);

    let res = await searchJobListing(params.toString());
    if (res?.status == 200 || res?.message == "OK") {
      let data = res?.data;
      let datalength = currentpage == 1 ? 0 : jobListData?.length;
      setHasMoreData(data?.workspaceusersCount > datalength + data?.length);
      if (currentpage === 1) {
        dispatch(JobsData(data));
      } else {
        dispatch(JobsData([...jobListData, ...data]));
      }
      if (data?.workspaceusersCount > datalength + data?.length) {
        setPage(currentpage + 1);
      }
    }
  };

  const handleRoutes = () => {
    if (activeJobId) {
      navigateRoutes("chats");
    }
  };

  const matchJobsId = async (data) => {
    const idFind = jobListData?.some((val) => val?._id === data?.job_id);
    if (idFind) {
      const param = new URLSearchParams();
      param.append("job_id", data?.job_id);
      param.append("user_id", userid);
      param.append("workspace_id", connectedWorkspace?.workspace?._id);

      const res = await viewJobMember(param.toString());

      dispatch(unReadMessageEvent({}));

      if (res?.status === 200) {
        if (res?.data?.members[0]?.unreadMessageCount == 0) {
          return;
        } else {
          dispatch(JobsData(res?.data));
        }
      }
    } else {
      handleJobsListing();
    }
  };
  
  const handleJobInfoData = async (jobid, type, id) => {
    // setLoading(true);
    let params = new URLSearchParams();
    params.append("job_id", jobid);
    params.append("user_id", connectedWorkspace?._id);
    params.append("workspace_id", connectedWorkspace?.workspace?._id);
    let res = await getJobInfo(params?.toString());
    if (res?.status === 200) {
      if (type == "add") {
        if (Object.keys(res?.data)?.length != 0) {
          dispatch(addMemberEvent({}));
          handleJobsListing(1);
        }
      } else {
        if (Object.keys(res?.data)?.length == 0) {
          if (location?.includes("chats") && jobid == id) {
            navigate("/private-layout/chats");  
            dispatch(ChatId(null));
          }
          dispatch(removeMemberEvent({}));
          handleJobsListing(1);
        }
      }
    } 
  };

  useEffect(() => {
    if (removemember?.type == 1) {
      setPage(1);
      handleJobInfoData(removemember?._id, "remove", activeJobId);
      setActiveJob(null);
    }
    if (addmember?.type == 1) {
      setPage(1);
      handleJobInfoData(addmember?._id, "add", activeJobId);
    }
    if (messageUnRead?.type == 1) {
      let findUser = messageUnRead?.memberIds?.some((el) => el == connectedWorkspace?._id);
      if (messageUnRead?.memberIds?.length > 0 && findUser) {
        if (messageUnRead?.job_id) {
          matchJobsId(messageUnRead);
          setPage(1);
        }
      }
    }
  }, [removemember, addmember,messageUnRead]);

  useEffect(() => {
    if (search) {
      searchJobsListing();
    } else {
      handleJobsListing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, connectedWorkspace?.workspace_id]);

  useEffect(() => {
    let locationPathId = location.slice(location.lastIndexOf("/") + 1);
    if (location?.includes("chats")) {
      let data = jobListData.find((ele) => ele?._id == locationPathId);
      if (data) {
        localStorage.setItem("dummy", data?._id);
        dispatch(
          ChatId({
            id: data?._id,
            jobname: data?.name,
            image_url: data?.image_url,
            createdAt: data?.createdAt
          })
        );
      } else if (activeJobId) {
        navigate(`/private-layout/chats/${activeJobId}`);
      } else {
        navigate("/private-layout/chats");
      }
    }
    if (activeJobId) {
      setActiveJob(activeJobId);
    }
  }, [activeJobId]);

  return (
    <MessengerSidenavWrapper calcHeight={calcHeight}>
      <GroupChat isOpen={extractGroup}>
        <div className="headerwrapper">
          <div style={{ margin: 0 }} className="all-tabs">
            <div
              style={{ marginTop: "5px", marginBottom: "2px" }}
              onClick={() => {
                setTeamExtractGroup(false);
                setGroupExtractGroup(false);
                if (jobListData?.length > 0) {
                  handleRoutes();
                  setSubMenu(false);
                  handleExtractionofGroup();
                } else {
                  navigateRoutes("no-jobs");
                }
              }}
              className={location.includes("chats") && "active"}>
              <span className="spanJobWrap">
                <span style={{ display: "flex" }}>
                  <i className="jobsIcon">
                    <JobsIcon />
                  </i>
                  <p>Jobs</p>
                </span>

                <i className="arrows-icons">
                  {extractGroup ? <SingleUpArrow /> : <SingleDownArrow />}
                </i>
              </span>
            </div>
          </div>

          {extractGroup && (
            <>
              <div id="scrollableDiv" className="infinityScroll">
                <InfiniteScroll
                  dataLength={jobListData?.length}
                  next={handleJobsListing}
                  hasMore={hasMoreData}
                  loader={<p className="seenAll">Loading...</p>}
                  scrollableTarget="scrollableDiv"
                  className="infinity">
                  {search
                    ? searchJobData?.length > 0 &&
                      searchJobData?.map((val, index) => {
                        return (
                          <ChatList
                            key={index}
                            jobId={val._id}
                            chatData={val}
                            activeJob={activeJob}
                            setActiveJob={setActiveJob}
                            type={1}
                          />
                        );
                      })
                    : jobListData?.map((val, index) => {
                        return (
                          <ChatList
                            key={index}
                            jobId={val._id}
                            chatData={val}
                            activeJob={activeJob}
                            setActiveJob={setActiveJob}
                            type={1}
                            search={false}
                          />
                        );
                      })}
                </InfiniteScroll>
              </div>
            </>
          )}
        </div>
      </GroupChat>
    </MessengerSidenavWrapper>
  );
}

MessengerSidenav.propTypes = {
  setSubMenu: PropTypes.func,
  navigateRoutes: PropTypes.func,
  setExtractGroup: PropTypes.func,
  extractGroup: PropTypes.bool,
  calcHeight: PropTypes.number,
  setTeamExtractGroup: PropTypes.func,
  setGroupExtractGroup: PropTypes.func
};

const GroupChat = styled.div`
  width: 100%;
  /* height: ${({ isOpen }) => (isOpen ? "95%" : "48px")}; */
  overflow: hidden;

  .groupchat-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: #3d97f2;
      font-size: 19px;
    }
  }
`;

const MessengerSidenavWrapper = styled.div`
  width: 100%;
  height: auto;
  /* padding: 20px 0 0 0; */
  position: relative;
  overflow: hidden;
  opacity: 1;

  .headerwrapper {
    display: flex;
    gap: 5px;
    padding: 2px 0;
    justify-content: center;
    flex-direction: column;

    .arrows-icons {
      svg {
        font-size: 18px;
        color: #3d97f2;
        cursor: pointer;
      }
      cursor: pointer;
    }
    header {
      color: #3d3d3d;
      font-family: Lato, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      letter-spacing: 0.12px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
    }
    .header {
      display: flex;
      justify-content: space-between;
      padding: 12px;
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
  }

  .seenAll {
    font-family: Lato, sans-serif;
    color: #8f8f8f;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin: 10px 0;
    padding: 0 44px;
  }

  .infinityScroll {
    max-height: ${({ calcHeight }) => `${calcHeight}px`};
    overflow-y: auto;
    padding: 0 0 0 40px;
    ::-webkit-scrollbar {
      width: 2px;
    }
    ::-webkit-scrollbar-thumb {
      background: rgb(232 237 243 / 38%);
      border-radius: 10px;
    }
  }
  /* .infinity{
    height: auto;
    overflow: auto;
    ::-webkit-scrollbar {
      height: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background: #f5f5f5;
      border-radius: 10px;
    }
  } */
  .jobsIcon {
    svg path {
      fill: rgb(255, 255, 255);
    }
  }
  .spanJobWrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .inputDiv {
    position: relative;
    display: flex;
    justify-content: center;
  }
  .searchInput {
    width: 90%;
    /* background: #343434; */
    background: transparent;
    border: 1px solid transparent;
    padding: 10px 10px 10px 22px;
    border-radius: 500px;
    outline: none;
    color: white;
    font-family: Lato, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  .searchIconWrap {
    position: absolute;
    left: 22px;
    top: 54%;
    transform: translate(-50%, -50%);

    svg {
      width: 15px;
      path {
        stroke: white;
      }
    }
  }
`;
