/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ChatList from "./ChatList";
import PropTypes from "prop-types";
import {
  GroupIcon,
  InfoIcon,
  NavPlus,
  SingleDownArrow,
  SingleUpArrow
} from "../utils/Icons/SvgIcons";
import { useDispatch, useSelector } from "react-redux";
import { getGroupListing, viewJobMember } from "../services/Collections";
import { message, Popover } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useNavigate } from "react-router-dom";
import { pusher } from "../Pusher/PusherConfig";
import { JobsData } from "../module/Messenger/ChatListIdSlice";
import { CreateGroupModal } from "../modals/CreateGroupModal";
import { AddPeopleWithEmail } from "../modals/AddPeopleWithEmail";
import { PendingRequestModal } from "../modals/PendingRequests";
import { AddMembers } from "../module/group/AddMembers";
import {
  groupSelectedChat,
  groupsData,
  groupRecentChatHistory
} from "../module/group/GroupChatSlice";
import { CreateStyled } from "./TeamSideNav";
import {
  addMemberEvent,
  deleteGroupEvent,
  removeMemberEvent,
  unReadMessageEvent
} from "../Pusher/PusherSlice";

export default function GroupSideNav({
  setSubMenu,
  extractGroup,
  setExtractGroup,
  navigateRoutes,
  calcHeight,
  setJobExtractGroup,
  setTeamExtractGroup
}) {
  const location = useLocation()?.pathname;
  const activeJobId = useSelector(
    (state) => state?.GroupChat?.selectedChat?.id ?? state?.GroupChat?.selectedChat?._id
  );
  const selectedGroupChat = useSelector((state) => state?.GroupChat?.selectedChat);
  const [activeJob, setActiveJob] = useState(null);
  const connectedWorkspace = useSelector((state) => state?.LoginSlice?.data);
  const [page, setPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  const jobListData = useSelector((state) => state?.GroupChat?.groups_Data);
  const [searchJobData, setSearchJobData] = useState([]);
  const currentLoginSlice = useSelector((state) => state?.LoginSlice?.data);
  const navigate = useNavigate();
  const [addGroup, setAddGroup] = useState(false);
  const [addPeoplesToGroup, setAddPeoplesToGroup] = useState(false);
  const [addPeoplesWithEmailGroup, setAddPeoplesWithEmaiGroup] = useState(false);
  const [pendingModal, setPendingModal] = useState(false);
  const [groupid, setGroupId] = useState(null);
  const [open, setOpen] = useState(false);
  const removemember = useSelector((state) => state?.PusherSlice?.removeMemberEvent);
  const addmember = useSelector((state) => state?.PusherSlice?.addMemberEvent);
  const deleteGroup = useSelector((state) => state?.PusherSlice?.deleteGroupEvent);
  const messageUnRead = useSelector((state) => state?.PusherSlice?.unReadMessageEvent);

  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleExtractionofGroup = () => {
    setExtractGroup(!extractGroup);
  };

  const searchJobsListing = async () => {
    setPage(1);
    let params = new URLSearchParams();
    params.append("workspace_id", connectedWorkspace?.workspace?._id);
    params.append("user_id", connectedWorkspace?._id);
    search && params.append("search", search);

    let res = await getGroupListing(params.toString());
    if (res?.status == 200 || res?.message == "OK") {
      let data = res?.data;
      setHasMoreData(data.length !== 0);
      if (search) {
        setSearchJobData(data);
      } else {
        dispatch(JobsData(data));
      }
    } else {
      message.error(res?.response?.data?.message || res?.message || "Something went wrong");
    }
  };

  const handleJobsListing = async (eventPage) => {
    let currentpage;
    if (
      jobListData?.length > 0 &&
      jobListData?.[0]?.workspace_id != connectedWorkspace?.workspace?._id
    ) {
      currentpage = 1;
    } else {
      currentpage = eventPage ?? page;
    }
    let params = new URLSearchParams();
    params.append("workspace_id", connectedWorkspace?.workspace?._id);
    params.append("user_id", connectedWorkspace?._id);
    params.append("page", currentpage);

    let res = await getGroupListing(params.toString());
    if (res?.status == 200 || res?.message == "OK") {
      dispatch(removeMemberEvent({}));
      dispatch(addMemberEvent({}));
      let data = res?.data;

      if (activeJobId && !data?.some((ele) => ele?._id == activeJobId)) {
        dispatch(groupSelectedChat(null));
        dispatch(groupRecentChatHistory([]));
      }
      let datalength = currentpage == 1 ? 0 : jobListData?.length;
      setHasMoreData(data?.workspaceusersCount > datalength + data?.length);
      if (currentpage === 1) {
        dispatch(groupsData(data));
      } else {
        dispatch(groupsData([...jobListData, ...data]));
      }
      if (data?.workspaceusersCount > datalength + data?.length) {
        setPage(currentpage + 1);
      }
    }
  };

  const handleRoutes = () => {
    if (activeJobId) {
      navigateRoutes("group");
    }
  };

  const matchJobsId = async (data) => {
    const idFind = jobListData?.some((val) => val?._id === data?.job_id);
    if (idFind) {
      const param = new URLSearchParams();
      param.append("job_id", data?.job_id);
      param.append("user_id", currentLoginSlice?._id);
      param.append("workspace_id", currentLoginSlice?.workspace?._id);
      param.append("type", 3);

      const res = await viewJobMember(param.toString());

      dispatch(unReadMessageEvent({}));

      if (res?.status === 200) {
        let countData =
          res?.data?.members?.length > 0 &&
          res?.data?.members?.find((el) => el?.user_id == currentLoginSlice?._id);
        if (countData?.unreadMessageCount == 0) {
          return;
        } else {
          let tempArray = [];
          let tempArray2 = [];

          for (let i = 0; i < jobListData?.length; i++) {
            if (jobListData?.[i]?._id !== res?.data?._id) {
              tempArray.push(jobListData[i]);
            }
          }
          tempArray2 = [res?.data, ...tempArray];
          dispatch(groupsData(res?.data));
          tempArray2 = [];
        }
      }
    } else {
      handleJobsListing();
    }
  };

  const handleDeleteGroup = (data, id) => {
    let dataID = data?.job_id || data?._id;
    if (dataID == id) {
      const groupDataArr = jobListData?.filter((ele) => ele?._id !== id);
      dispatch(groupsData(groupDataArr));
      navigate("/private-layout/group");
      dispatch(groupRecentChatHistory([]));
      dispatch(groupSelectedChat(null));
    }
    handleJobsListing();
    dispatch(deleteGroupEvent({}));
  };

  const handleCreateGroup = () => {
    const channel = pusher.subscribe(`workspace_${connectedWorkspace?.workspace?._id}`);
    if (channel) {
      channel.bind("create-group", function (data) {
        setPage(1);
        if (data?.memberIds?.some((el) => el?.user_id == connectedWorkspace?._id)) {
          handleJobsListing();
        }
      });
    }
    return () => {
      pusher.unsubscribe(`workspace_${connectedWorkspace?.workspace?._id}`);
      channel.unbind("create-group");
    };
  };

  useEffect(() => {
    if (removemember?.type == 3) {
      setPage(1);
      handleJobsListing(1);
      setActiveJob(null);
    }
    if (addmember?.type == 3) {
      setPage(1);
      handleJobsListing(1);
    }
    if (deleteGroup?.job_id || deleteGroup?._id) {
      handleDeleteGroup(deleteGroup, selectedGroupChat?.id);
    }
    if (messageUnRead?.type == 3) {
      let findUser = messageUnRead?.memberIds?.some((el) => el == connectedWorkspace?._id);
      if (messageUnRead?.memberIds?.length > 0 && findUser) {
        if (messageUnRead?.job_id) {
          matchJobsId(messageUnRead);
          setPage(1);
        }
      }
    }
  }, [removemember, addmember, deleteGroup, messageUnRead]);

  useEffect(() => {
    handleCreateGroup();
  }, [connectedWorkspace]);

  useEffect(() => {
    if (search) {
      searchJobsListing();
    } else {
      handleJobsListing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, connectedWorkspace?.workspace_id, groupid]);

  useEffect(() => {
    let locationPathId = location.slice(location.lastIndexOf("/") + 1);
    if (location?.includes("group")) {
      let data = jobListData.find((ele) => ele?._id == locationPathId);
      if (data) {
        localStorage.setItem("dummy", data?._id);
        dispatch(
          groupSelectedChat({
            id: data?._id,
            jobname: data?.name,
            image_url: data?.image_url,
            createdAt: data?.createdAt
          })
        );
      } else if (activeJobId) {
        navigate(`/private-layout/group/${activeJobId}`);
      } else {
        navigate("/private-layout/group");
      }
    }
    if (activeJobId) {
      setActiveJob(activeJobId);
    }
  }, [activeJobId]);

  return (
    <MessengerSidenavWrapper calcHeight={calcHeight}>
      {addGroup && (
        <CreateGroupModal
          open={addGroup}
          handleClose={() => setAddGroup(false)}
          setAddPeoplesToGroup={setAddPeoplesToGroup}
        />
      )}

      {addPeoplesToGroup && (
        <AddMembers open={addPeoplesToGroup} handleModal={() => setAddPeoplesToGroup(false)} />
      )}

      {addPeoplesWithEmailGroup && (
        <AddPeopleWithEmail
          open={addPeoplesWithEmailGroup}
          handleClose={() => setAddPeoplesWithEmaiGroup(false)}
          setPendingModal={setPendingModal}
        />
      )}

      {pendingModal && (
        <PendingRequestModal open={pendingModal} handleClose={() => setPendingModal(false)} />
      )}

      <GroupChat isOpen={extractGroup}>
        <div className="headerwrapper">
          <div style={{ margin: 0 }} className="all-tabs">
            <div
              style={{ margin: "0px 0" }}
              className={
                (location?.includes("group") || location?.includes("no-groups")) && "active"
              }>
              <span
                className="spanJobWrap"
                onClick={() => {
                  setSearch("");
                  if (jobListData?.length > 0) {
                    handleRoutes();
                    setSubMenu(false);
                    setJobExtractGroup(false);
                    setTeamExtractGroup(false);
                    handleExtractionofGroup();
                  } else {
                    navigateRoutes("no-groups");
                    setTeamExtractGroup(false);
                    setExtractGroup(!extractGroup);
                  }
                }}>
                <span style={{ display: "flex", width: "100%" }}>
                  <i className="jobsIcon">
                    <GroupIcon />
                  </i>
                  <p>Group Chat</p>
                </span>

                <i className="arrows-icons">
                  {extractGroup ? (
                    connectedWorkspace?.user_type?.toLowerCase() == "admin" ||
                    connectedWorkspace?.user_type?.toLowerCase() == "owner" ? (
                      <Popover
                        prefixCls="addPopOverClass"
                        content={
                          <CreateStyled onClick={hide}>
                            <div>
                              <h4>
                                <InfoIcon width="15px" height="15px" /> Click + to create new groups
                              </h4>
                            </div>
                          </CreateStyled>
                        }
                        trigger="hover"
                        open={open}
                        onOpenChange={handleOpenChange}>
                        <p
                          className="plusIcon"
                          onClick={(event) => {
                            event.stopPropagation();
                            setAddGroup(true);
                          }}>
                          <NavPlus />
                        </p>
                      </Popover>
                    ) : (
                      <SingleUpArrow />
                    )
                  ) : (
                    <SingleDownArrow />
                  )}
                </i>
              </span>
            </div>
          </div>

          {extractGroup && (
            <>
              <div id="scrollableDiv" className="infinityScroll">
                <InfiniteScroll
                  dataLength={jobListData?.length}
                  next={handleJobsListing}
                  hasMore={hasMoreData}
                  // loader={<p className="seenAll">Loading...</p>}
                  scrollableTarget="scrollableDiv"
                  className="infinity">
                  {search
                    ? searchJobData?.length > 0 &&
                      searchJobData?.map((val, index) => {
                        return (
                          <ChatList
                            key={index}
                            jobId={val._id}
                            chatData={val}
                            activeJob={activeJob}
                            setActiveJob={setActiveJob}
                            type={3}
                            searchChat={false}
                            search={false}
                          />
                        );
                      })
                    : jobListData?.map((val, index) => {
                        return (
                          <ChatList
                            key={index}
                            jobId={val._id}
                            chatData={val}
                            activeJob={activeJob}
                            setActiveJob={setActiveJob}
                            type={3}
                            setGroupId={setGroupId}
                          />
                        );
                      })}
                </InfiniteScroll>
              </div>
            </>
          )}
        </div>
      </GroupChat>
    </MessengerSidenavWrapper>
  );
}

GroupSideNav.propTypes = {
  setSubMenu: PropTypes.func,
  navigateRoutes: PropTypes.func,
  setExtractGroup: PropTypes.func,
  extractGroup: PropTypes.bool,
  calcHeight: PropTypes.number,
  setJobExtractGroup: PropTypes.func,
  setTeamExtractGroup: PropTypes.func
};

const GroupChat = styled.div`
  width: 100%;
  /* height: ${({ isOpen }) => (isOpen ? "95%" : "48px")}; */
  overflow: hidden;

  .groupchat-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: #3d97f2;
      font-size: 19px;
    }
  }
`;

const MessengerSidenavWrapper = styled.div`
  width: 100%;
  height: auto;
  /* padding: 20px 0 0 0; */
  position: relative;
  overflow: hidden;
  opacity: 1;

  .active {
    background: #343434;
    border-radius: 500px;
  }

  .create-group {
    color: black;
    cursor: pointer;
    border-radius: 7px;

    h4 {
      font-weight: 700;
      font-family: Lato, sans-serif;
      font-size: 15px;
      font-style: normal;
    }
  }
  /* .create-group {
    padding: 10px 20px;
    margin-left: 30px;
    width: 80%;
    color: #fff;
    font-family: Lato, sans-serif;
    cursor: pointer;
    background: rgb(251 242 242 / 17%);
    border-radius: 7px;
  } */

  .headerwrapper {
    display: flex;
    gap: 5px;
    padding: 2px 0;
    justify-content: center;
    flex-direction: column;

    .arrows-icons {
      svg {
        font-size: 18px;
        color: #3d97f2;
        cursor: pointer;
      }
      cursor: pointer;
    }
    header {
      color: #3d3d3d;
      font-family: Lato, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      letter-spacing: 0.12px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
    }
    .header {
      display: flex;
      justify-content: space-between;
      padding: 12px;
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
  }

  .seenAll {
    font-family: Lato, sans-serif;
    color: #8f8f8f;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin: 10px 0;
    padding: 0 44px;
  }

  .infinityScroll {
    max-height: ${({ calcHeight }) => `${calcHeight}px`};
    overflow-y: auto;
    padding: 0 0 0 40px;
    ::-webkit-scrollbar {
      width: 2px;
    }
    ::-webkit-scrollbar-thumb {
      background: rgb(232 237 243 / 38%);
      border-radius: 10px;
    }
  }
  /* .infinity{
    height: auto;
    overflow: auto;
    ::-webkit-scrollbar {
      height: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background: #f5f5f5;
      border-radius: 10px;
    }
  } */
  .jobsIcon {
    svg path {
      fill: rgb(255, 255, 255);
    }
  }
  .spanJobWrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .inputDiv {
    position: relative;
    display: flex;
    justify-content: center;
  }
  .searchInput {
    width: 90%;
    /* background: #343434; */
    background: transparent;
    border: 1px solid transparent;
    padding: 10px 10px 10px 22px;
    border-radius: 500px;
    outline: none;
    color: white;
    font-family: Lato, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  .searchIconWrap {
    position: absolute;
    left: 22px;
    top: 54%;
    transform: translate(-50%, -50%);

    svg {
      width: 15px;
      path {
        stroke: white;
      }
    }
  }
  .plusIcon {
    width: fit-content;
    height: 31px;
    font-size: 20px;
    color: inherit;
    padding: 0 6px;
    /* margin-right: 12px; */
    svg {
      width: 17px !important;
      height: 17px !important;
    }
  }
`;
