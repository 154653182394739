import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedChat: null,
  recentChat: [],
  unReadCount: [],
  groups_Data: [],
  newGroup: null
};

const GroupChatSlice = createSlice({
  name: "GroupChatSlice",
  initialState: initialState,
  reducers: {
    groupSelectedChat: (state, action) => {
      if (action.payload === null) {
        state.selectedChat = null;
      } else {
        state.selectedChat = action.payload;
      }
    },
    groupRecentChatHistory: (state, action) => {
      if (action.payload) {
        state.recentChat = action?.payload;
      }
    },
    groupReceviedNewMessage: (state, action) => {
      if (action.payload) {
        let newArr = [];
        // let updatedArr = [];
        for (let i = 0; i < state.recentChat.length; i++) {
          if (
            !Array.isArray(state.recentChat[i]) &&
            state.recentChat[i]?.local_id == action?.payload?.local_id
          ) {
            newArr.push(action?.payload);
          } else {
            newArr?.push(state.recentChat[i]);
          }
        }

        state.recentChat = newArr;

        let checkingDulicateMessge = state.recentChat?.some(
          (el) => el?._id === action.payload?._id
        );
        if (!checkingDulicateMessge) state.recentChat.unshift(action.payload);
      }
    },
    unReadCountMessage: (state, action) => {
      state.unReadCount = action.payload;
    },
    groupsData: (state, action) => {
      if (Array?.isArray(action?.payload)) {
        state.groups_Data = action?.payload;
      } else {
        let tempArray = [];
        for (let i = 0; i < state.groups_Data?.length; i++) {
          if (state.groups_Data?.[i]?._id !== action?.payload?._id) {
            tempArray.push(state.groups_Data[i]);
          }
        }
        state.groups_Data = [action?.payload, ...tempArray];
      }
    },
    updateNewGroup: (state, action) => {
      state.newGroup = action?.payload;
    },
    groupInitialStateReset: (state, action) => {
      if (action.payload) {
        state.selectedChat = null;
        state.recentChat = [];
        state.unReadCount = [];
        state.groups_Data = [];
        state.newGroup = null;
      }
    }
  }
});

export const {
  groupSelectedChat,
  groupRecentChatHistory,
  groupReceviedNewMessage,
  unReadCountMessage,
  groupsData,
  updateNewGroup,
  groupInitialStateReset
} = GroupChatSlice.actions;

export default GroupChatSlice.reducer;
