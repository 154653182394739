/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { WhiteAppLogo } from "../utils/Images/Images";
import {
  Dashboard,
  ProfileExit,
  Search,
  SubMenuDiamond,
  Team,
  ThreeLines
} from "../utils/Icons/SvgIcons";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { OpenSideNav, SignInStep, UpdateSteps } from "../app/Auth/signIn/SignInSlice";
import { DeleteModal } from "./DeleteModal";
import PropTypes from "prop-types";
import { ConnectCrmModal } from "./ConnectCrmModal";
import ImportTitanService from "./ImportTitanService";
import MessengerSidenav from "./MessengerSidenav";
import {
  ChatId,
  chatInitialStateReset,
  JobsData,
  receviedNewMessage
} from "../module/Messenger/ChatListIdSlice";
import {
  CurrentWorkspace,
  WorkSpaceData,
  workSpaceInitialStateReset
} from "../app/Workspace/WorkSpaceSlice";
import TeamSideNav from "./TeamSideNav";
import GroupSideNav from "./GroupSideNav";
import { beamsClient, pusher } from "../Pusher/PusherConfig";
import { getAllUsersListing, getWorkspaceDetails, searchGlobal } from "../services/Collections";
import { Skeleton, message } from "antd";
import { debounce } from "../utils/common/commonParamter";
import ChatList from "./ChatList";
import { ModalLoader } from "./ModalLoader";
import { groupInitialStateReset } from "../module/group/GroupChatSlice";
import { individualInitialStateReset } from "../module/Messenger/IndividualChatSlice";
import SettingSideNav from "./SettingSideNav";
import SearchModal from "../modals/SearchModal";
import { useMemo } from "react";

export default function Sidenav({ notFound }) {
  const location = useLocation()?.pathname;
  const navigate = useNavigate();
  const sidebar = useSelector((state) => state?.LoginSlice?.sideNav);
  const userDetails = useSelector((state) => state?.LoginSlice?.data);
  // const jobListData = useSelector((state) => state?.ChatId?.jobs_Data);
  const [subMenu, setSubMenu] = useState(false);
  const [logoutModal, setLogout] = useState(false);
  const [connectCRMModal, setConnectCrmModal] = useState(false);
  const [importTitanModal, setImportTitanModal] = useState(false);
  // const CurrentlyActiveWorkspace = useSelector((state) => state?.WorkSpaceSlice?.currentWorkspace);
  const [extractGroup, setExtractGroup] = useState(false);
  const connectedWorkspace = useSelector((state) => state?.LoginSlice?.data);
  const [teamExtractGroup, setTeamExtractGroup] = useState(
    location?.includes("individual") ? true : false
  );
  const [groupExtractGroup, setGroupExtractGroup] = useState(
    location?.includes("group") ? true : false
  );
  const [setting, setSetting] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [search, setSearch] = useState("");
  const [globalSearchData, setGlobalSearchData] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [activeSearchBtn, setActiveSearchBtn] = useState(1);
  const selectedGroupChat = useSelector((state) => state?.GroupChat?.selectedChat);
  const selectedTeamChat = useSelector((state) => state?.IndividualChat?.selectedChat);
  const selectedJobChat = useSelector((state) => state?.ChatId?.job_Id);

  const handlesetOpenSearch = () => {
    setOpenSearch(!openSearch);
    setActiveSearchBtn(1);
    setSearch("");
    setGlobalSearchData([]);
  };

  const navigateRoutes = (params) => {
    let selectedId =
      params == "chats"
        ? selectedJobChat?.id
        : params == "group"
        ? selectedGroupChat?.id
        : params == "individual"
        ? selectedTeamChat?._id
        : null;
    let finalParam = selectedId ? `${params}/${selectedId}` : params;
    setImportTitanModal(false);
    setLogout(false);
    setConnectCrmModal(false);
    if (!notFound) {
      params === "chats" ? navigate("/private-layout/" + finalParam) : navigate(finalParam);
    } else {
      navigate("/private-layout/" + finalParam);
    }
  };
  const handleLogout = () => {
    beamsClient
      .start()
      .then(() => beamsClient.removeDeviceInterest(`user_${userDetails?._id}`))
      .then(() => beamsClient.clearDeviceInterests())
      .then(() => console.log("Successfully registered and subscribed!"))
      .catch(console.error);

    dispatch(SignInStep(null));
    dispatch(UpdateSteps(0));
    navigate("/");
    dispatch(ChatId(null));
    receviedNewMessage([]);
    dispatch(WorkSpaceData([]));
    dispatch(JobsData([]));
    dispatch(CurrentWorkspace(null));
    dispatch(groupInitialStateReset(true));
    dispatch(individualInitialStateReset(true));
    dispatch(chatInitialStateReset(true));
    dispatch(workSpaceInitialStateReset(true));
  };

  const handleWorkspaceInfo = async (id) => {
    let res = await getWorkspaceDetails(id);
    if (res?.status == 200) {
      let dispatchPayload = {
        ...connectedWorkspace,
        ...res?.data
      };
      if (res?.data?._id) {
        dispatch(SignInStep(dispatchPayload));
      }
    } else {
      message.error(
        res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
      );
    }
  };

  const handleGlobalSearch = async (search, activeSearchBtn, activeWorkspace) => {
    setSearchLoading(true);
    setSearch(search);
    const connectedUser = activeWorkspace?.workspace?.users?.find(
      (ele) => ele?.user_id == activeWorkspace?.user_id
    );
    if (activeSearchBtn != 2) {
      if (search) {
        let params = new URLSearchParams();
        params.append("search", search);
        params.append("workspace_id", activeWorkspace?.workspace_id);
        params.append("user_id", connectedUser?._id);
        params.append("type", activeSearchBtn);
        let res = await searchGlobal(params?.toString());
        if (res?.status == 200) {
          setGlobalSearchData(res?.data);
          setSearchLoading(false);
        } else {
          setSearchLoading(false);
          message.error(
            res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
          );
        }
      } else {
        // setSearchLoading(false);
        // setGlobalSearchData([]);
        setSearch("");
        let params = new URLSearchParams();
        // params.append("search", search);
        params.append("workspace_id", activeWorkspace?.workspace_id);
        params.append("user_id", connectedUser?._id);
        params.append("type", activeSearchBtn);
        let res = await searchGlobal(params?.toString());
        if (res?.status == 200) {
          setGlobalSearchData(res?.data);
          setSearchLoading(false);
        } else {
          setSearchLoading(false);
          message.error(
            res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
          );
        }
      }
    } else {
      if (search) {
        let params = new URLSearchParams();
        params.append("workspace_id", activeWorkspace?.workspace_id);
        if (search) params.append("search", search);
        let res = await getAllUsersListing(params.toString());
        if (res?.status == 200) {
          let arr = [];
          Array.isArray(res?.data) &&
            res?.data?.length > 0 &&
            res?.data?.map((el) => {
              if (el?._id != activeWorkspace?._id) {
                arr.push(el);
              }
            });
          setGlobalSearchData(arr);
          setSearchLoading(false);
        } else {
          setSearchLoading(false);
        }
      } else {
        setSearch("");
        let params = new URLSearchParams();
        params.append("workspace_id", activeWorkspace?.workspace_id);
        // if (search) params.append("search", search);
        let res = await getAllUsersListing(params.toString());
        if (res?.status == 200) {
          let arr = [];
          Array.isArray(res?.data) &&
            res?.data?.length > 0 &&
            res?.data?.map((el) => {
              if (el?._id != activeWorkspace?._id) {
                arr.push(el);
              }
            });
          setGlobalSearchData(arr);
          setSearchLoading(false);
        } else {
          setSearchLoading(false);
        }
      }
    }
  };

  const optimizedFn = useCallback(debounce(handleGlobalSearch), [activeSearchBtn]);

  const handleModalsClose = () => {
    setImportTitanModal(false);
    setLogout(false);
    setConnectCrmModal(false);
  };

  const handleImportTitan = () => {
    // navigateRoutes("workspace/titan-job");
    setImportTitanModal(!importTitanModal);
  };

  const handleClose = () => {
    setImportTitanModal(false);
    // navigate(-1);
  };

  const handleTabsSwitch = (current, activeSearchBtn) => {
    if (current != activeSearchBtn) {
      optimizedFn(search, activeSearchBtn, connectedWorkspace);
    }
  };

  const [calcHeight, setCalcHeight] = useState(60);

  const calculateHeightForActiveTab = () => {
    var sectionHeight = document?.getElementById("tabsections")?.clientHeight;
    if (
      userDetails?.user_type?.toLowerCase() == "owner" ||
      userDetails?.user_type?.toLowerCase() == "admin"
    ) {
      if (userDetails?.workspace?.tenant_id != null) {
        sectionHeight = sectionHeight - 48;
        setCalcHeight(sectionHeight);
      }
      sectionHeight = sectionHeight - 158;
      setCalcHeight(sectionHeight);
    }
    sectionHeight = sectionHeight - 140;
    setCalcHeight(sectionHeight);
  };

  useMemo(() => {
    if (userDetails) {
      calculateHeightForActiveTab();
    }
  }, [userDetails]);

  const updateAddNewMemberInWorkspace = () => {
    const channel = pusher.subscribe(`workspace_${connectedWorkspace?.workspace?._id}`);
    if (channel) {
      channel.bind("new-workspace-member", function (data) {
        handleWorkspaceInfo(data?.workspace_id);
      });
    }
    return () => {
      if (channel) {
        pusher.unsubscribe(`workspace_${connectedWorkspace?.workspace?._id}`);
        channel.unbind("new-workspace-member");
      }
    };
  };

  useEffect(() => {
    updateAddNewMemberInWorkspace();
  }, [connectedWorkspace]);

  useEffect(() => {
    handleWorkspaceInfo(connectedWorkspace?.workspace?._id);
  }, []);

  useEffect(() => {
    setExtractGroup(location?.includes("chats") ? true : false);
    setGroupExtractGroup(location?.includes("group") ? true : false);
    setTeamExtractGroup(location?.includes("individual") ? true : false);
  }, [location]);

  const dispatch = useDispatch();
  return (
    <SidenavWrapper subMenu={subMenu}>
      {logoutModal && (
        <DeleteModal
          handleClose={() => setLogout(false)}
          open={logoutModal}
          title="Are you sure you want to Log out ?"
          description="All your process and data will be saved."
          button="Log out"
          btnColor="#E93124"
          handleSubmit={() => handleLogout()}
        />
      )}
      {connectCRMModal && (
        <ConnectCrmModal
          open={connectCRMModal}
          handleClose={() => setConnectCrmModal(false)}
          connectModalData={userDetails?.workspace}
          closeStatus={false}
          newConnection={true}
        />
      )}

      {importTitanModal && (
        <ImportTitanService
          open={importTitanModal}
          handleClose={handleClose}
          setImportTitanModal={setImportTitanModal}
          title={"Import to Service Titan Job"}
          description={`Workspace - ${userDetails?.workspace?.workspace_name}`}
          button={"Save"}
          btnColor={"#FF0000"}
        />
      )}
      {openSearch && (
        <SearchModal
          openSearch={openSearch}
          handlesetOpenSearch={handlesetOpenSearch}
          optimizedFn={optimizedFn}
          setSearchLoading={setSearchLoading}
          setActiveSearchBtn={setActiveSearchBtn}
          activeSearchBtn={activeSearchBtn}
          searchLoading={searchLoading}
          globalSearchData={globalSearchData}
          handleTabsSwitch={handleTabsSwitch}
          handleGlobalSearch={handleGlobalSearch}
        />
      )}

      <header>
        <img src={WhiteAppLogo} />
        {sidebar && (
          <div onClick={() => dispatch(OpenSideNav(false))} className="Three-lines">
            <ThreeLines className="ThreeLinesWhite" />
          </div>
        )}
      </header>

      <section>
        <div>
          <div className="search-input" onClick={() => setOpenSearch(!openSearch)}>
            {/* <input
              type="search"
              placeholder="Search"
              disabled
              onFocus={() => setOpenSearch(!openSearch)}
            /> */}
            <i className="searchIconWrap">
              <Search />
            </i>
            Search
          </div>
          <div style={{ height: "100%" }} id="tabsections">
            <MessengerSidenav
              setSubMenu={setSubMenu}
              extractGroup={extractGroup}
              setExtractGroup={setExtractGroup}
              navigateRoutes={navigateRoutes}
              calcHeight={"250" || calcHeight}
              setTeamExtractGroup={setTeamExtractGroup}
              setGroupExtractGroup={setGroupExtractGroup}
            />

            <GroupSideNav
              setSubMenu={setSubMenu}
              extractGroup={groupExtractGroup}
              setExtractGroup={setGroupExtractGroup}
              setJobExtractGroup={setExtractGroup}
              setTeamExtractGroup={setTeamExtractGroup}
              navigateRoutes={navigateRoutes}
              calcHeight={"250" || calcHeight}
            />
            <TeamSideNav
              setSubMenu={setSubMenu}
              extractGroup={teamExtractGroup}
              setExtractGroup={setTeamExtractGroup}
              setJobExtractGroup={setExtractGroup}
              setGroupExtractGroup={setGroupExtractGroup}
              navigateRoutes={navigateRoutes}
              calcHeight={"250" || calcHeight}
            />
            {userDetails?.user_type?.toLowerCase() == "owner" ||
            userDetails?.user_type?.toLowerCase() == "admin" ? (
              userDetails?.workspace?.tenant_id != null && (
                <div className="all-tabs">
                  <div
                    className={location?.includes("workspace") && "active"}
                    onClick={() => {
                      handleModalsClose();
                      if (userDetails?.workspace?.tenant_id !== null) {
                        setSubMenu(!subMenu);
                        setExtractGroup(false);
                      } else {
                        setExtractGroup(false);
                        setConnectCrmModal(true);
                      }
                    }}>
                    <Dashboard />
                    <p>Workspace</p>
                  </div>
                  <div className="sub-menu">
                    {subMenu && (
                      <div className="menu">
                        <div
                          className={location?.includes("regions") && "Subactive"}
                          onClick={() => navigateRoutes("workspace/regions")}>
                          <SubMenuDiamond />
                          <p>Regions</p>
                        </div>
                        <div
                          className={location?.includes("business") && "Subactive"}
                          onClick={() => navigateRoutes("workspace/business")}>
                          <SubMenuDiamond />
                          <p>Business</p>
                        </div>
                        <div
                          className={location?.includes("workspace/jobs") && "Subactive"}
                          onClick={() => navigateRoutes("workspace/jobs")}>
                          <SubMenuDiamond />
                          <p>Job Type</p>
                        </div>

                        <div
                          className={location?.includes("titan-job") && "Subactive"}
                          onClick={handleImportTitan}>
                          <SubMenuDiamond />
                          <p>Import to ST Job</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )
            ) : (
              <>
                <div></div>
              </>
            )}
            {(userDetails?.user_type?.toLowerCase() == "owner" ||
              userDetails?.user_type?.toLowerCase() == "admin") && (
              <div className="all-tabs">
                <div
                  style={{ margin: "5px 0" }}
                  onClick={() => {
                    navigateRoutes("user-management");
                    setSubMenu(false);
                    setExtractGroup(false);
                  }}
                  className={location?.includes("user-management") && "active"}>
                  <Team />
                  <p>Users</p>
                </div>
              </div>
            )}

            {(userDetails?.user_type?.toLowerCase() == "owner" ||
              userDetails?.user_type?.toLowerCase() == "admin") && (
              <SettingSideNav
                extractGroup={setting}
                setSetting={setSetting}
                calcHeight={"250" || calcHeight}
              />
            )}
          </div>
        </div>

        <div className="sidebar-footer">
          <div
            onClick={() => {
              setLogout(true);
            }}>
            <ProfileExit />
            <p>Logout</p>
          </div>
        </div>
      </section>
    </SidenavWrapper>
  );
}

Sidenav.propTypes = {
  notFound: PropTypes.bool
};

const SidenavWrapper = styled.div`
  width: 100%;
  background: linear-gradient(172deg, #5a1f5c, rgb(48, 23, 52));
  padding: 32px 16px;
  transition: all 0.3s;
  position: relative;

  .search-content {
    width: 100%;
    height: 100%;
    max-height: 70vh;
    overflow: auto;
    ::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }

    ::-webkit-scrollbar-track {
      ${"" /* box-shadow: inset 0 0 5px #fff; */}
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #cecece;
      border-radius: 10px;
    }
  }

  .search-input {
    background: #541e56;
    padding: 0 10px;
    position: relative;
    display: flex;
    /* justify-content: center; */
    margin: 12px 16px;
    background: #fff;
    border: 1px solid transparent;
    height: 40px;
    /* width: 100%; */
    border-radius: 5px;
    padding: 0px 10px;
    padding-left: 20px;
    color: gray;
    border: 1px solid rgb(151 151 151 / 53%);
    align-items: center;
    gap: 10px;
    cursor: text;

    /* input {
      background: #fff;
      border: 1px solid transparent;
      height: 40px;
      width: 100%;
      border-radius: 5px;
      padding: 0px 10px;
      padding-left: 28px;
      color: #000;
      border: 1px solid rgb(151 151 151 / 53%);
      :focus {
        border: 1px solid #cecece;
      }
    } */
    .searchIconWrap {
      /* position: absolute; */
      /* left: 25px; */
      /* top: 54%; */
      /* transform: translate(-50%, -50%); */
      margin-top: 5px;

      svg {
        width: 15px;
        path {
          /* stroke: white; */
        }
      }
    }
  }

  header {
    img {
      width: 120px;
      margin: 0 26px;
    }
  }
  .sub-menu {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    padding: 0 !important;
  }

  .menu {
    width: 100%;
    display: flex;
    flex-direction: column !important;
    margin: 0 !important;
    padding: 0 !important;
    padding: 5px 20px !important;

    div {
      width: 100%;
      padding: 7px 10px !important;
    }
    p {
      white-space: nowrap;
    }
    ${"" /* margin-top: 10px; */}
  }

  .Three-lines {
    position: absolute;
    top: 15px;
    right: 18px;
    cursor: pointer;
  }

  section {
    width: 100%;
    height: calc(100vh - 78px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .all-tabs {
      width: 100%;
      margin: 6px 0;
      color: #ffffff;
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;

      .active {
        background: #331737;
        border-radius: 500px;
      }
      .Subactive {
        border-left: 4px solid #fff;
        ${"" /* background: linear-gradient(212deg, #ffffff96, #ffffff14); */}
        background: #343434;
        border-radius: 15px 0;
        ${"" /* box-shadow: 0 0 5px; */}
      }

      div {
        display: flex;
        align-items: center;
        justify-content: start;
        margin: 8px 0;
        cursor: pointer;

        padding: 12px 16px;

        svg {
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }
      }
    }
    .sidebar-footer {
      width: 100%;
      margin: 32px 0;
      color: #ffffff;
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;

      @media (max-width: 800px) {
        display: none;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: start;
        margin: 8px 0;
        cursor: pointer;
        // background: #343434;
        border-radius: 500px;
        padding: 12px 16px;

        svg {
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }
      }
    }
  }
`;
