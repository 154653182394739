/* eslint-disable react/no-unescaped-entities */
import { Modal } from "antd";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { PendingRequestGroupBg } from "../utils/Images/Images";
import { EmailSvg, PendingIconSVG } from "../utils/Icons/SvgIcons";

export const PendingRequestModal = ({ open, handleClose }) => {
  return (
    <div>
      <Modal
        prefixCls="group-modal"
        open={open}
        onCancel={handleClose}
        width={976}
        footer={false}
        centered>
        <CreateGroupModalBox>
          <div className="header-banner">
            <img src={PendingRequestGroupBg} />
            {/* <div className="text">
              <h3>Create Group</h3>
              <p>
                Groups serve as the hub for your team's communication, allowing you to add <br />{" "}
                numerous members for seamless and effective interaction.
              </p>
            </div> */}
          </div>

          <div className="fields">
            <div className="field-sections">
              <h1>You have invited one person</h1>

              <div className="content-field">
                <div className="icon-label">
                  <div className="icon">
                    <EmailSvg />
                  </div>
                  <div className="label-text">
                    <h3>sulaman.abir@gmail.com</h3>
                    <p>Can post & Invite</p>
                  </div>
                </div>
                <div className="pending-btns">
                  <PendingIconSVG />
                  Pending
                </div>
              </div>
            </div>
          </div>

          <div className="submit-btns">
            <button className="next">Done</button>
          </div>
        </CreateGroupModalBox>
      </Modal>
    </div>
  );
};

PendingRequestModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  setAddPeoplesToGroup: PropTypes.func
};

const CreateGroupModalBox = styled.div`
  width: 100%;
  min-height: 350px;
  padding: 32px;
  max-height: 95vh;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 3px;
    height: 2px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
  }
  ::-webkit-scrollbar-thumb {
    background: #cecece;
    border-radius: 10px;
  }

  .header-banner {
    width: 100%;
    background: #3d97f2;
    border-radius: 4px;
    position: relative;
    padding: 0 10px;
    z-index: 1;

    img {
      width: 100%;
      height: 100%;
      z-index: 2;
    }
    .text {
      position: absolute;
      z-index: 3;
      top: 35px;
      width: 100%;
      text-align: center;

      h3 {
        color: #fff;
        text-align: center;
        font-family: Lato, sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 132%; /* 42.24px */
        letter-spacing: 0.32px;
      }
      p {
        margin-top: 4px;
        color: #fff;
        text-align: center;
        font-family: Lato, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
      }
    }
  }

  .fields {
    width: 100%;
    max-width: 492px;
    margin: 32px auto;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .field-sections {
      width: 100%;

      .content-field {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;

        .pending-btns {
          border-radius: 4px;
          background: rgba(255, 191, 0, 0.1);
          color: #ffbf00;
          font-family: Lato, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 16.8px */
          letter-spacing: 0.14px;
          padding: 3.5px 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .label-text {
          h3 {
            color: #3d3d3d;
            font-family: Lato, sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 19.2px;
          }
          p {
            margin-top: 4px;
            color: #8f8f8f;
            font-family: Lato, sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 14.4px;
            letter-spacing: 0.12px;
            text-transform: uppercase;
          }
        }

        .icon-label {
          display: flex;
          align-items: center;
          gap: 16px;

          .icon {
            padding: 16px;
            border-radius: 4px;
            background: #f5f5f5;
          }
        }
      }

      label {
        color: #3d3d3d;
        font-family: Lato, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.12px;
        text-transform: uppercase;

        span {
          color: #b8b8b8;
          font-family: Lato, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 0.12px;
          text-transform: uppercase;
        }
      }

      .image-size {
        margin-top: 8px;
        color: #b8b8b8;
        font-family: Lato, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      input {
        border-radius: 4px;
        border: 1px solid #f5f5f5;
        background: #fff;
        width: 100%;
        margin-top: 8px;
        padding: 17px 16px;
        font-family: Lato, sans-serif;
        color: #8f8f8f;

        ::placeholder {
          color: #8f8f8f;
          font-family: Lato, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .private-group {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 17px 16px;

        p {
          color: #3d3d3d;
          font-family: Lato, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 132%;
        }
      }

      h1 {
        color: #3d3d3d;
        font-family: Lato, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    .image-box {
      display: flex;
      gap: 16px;
      align-items: center;
      margin-top: 4px;

      .upload-image {
        border-radius: 4px;
        background: #ecf5fe;
        padding: 16px 32px;
        color: #3d97f2;
        font-family: Lato, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.14px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
      }
    }

    .image-preview {
      width: 56px;
      height: 56px;

      .empty-box {
        width: 100%;
        height: 100%;
        background: #f5f5f5;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .submit-btns {
    display: flex;
    gap: 10px;
    width: 100%;
    max-width: 492px;
    margin: auto;

    .cancel {
      border-radius: 4px;
      background: #f5f5f5;
      width: 100%;
      height: 56px;
      border-style: none;
      color: #3d3d3d;
      text-align: center;
      font-family: Lato, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 124%; /* 19.84px */
      letter-spacing: 0.16px;
      text-transform: uppercase;
      cursor: pointer;
    }
    .next {
      border-radius: 4px;
      background: #3d97f2;
      border-style: none;
      width: 100%;
      height: 56px;
      color: #fff;
      text-align: center;
      font-family: Lato, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 124%;
      letter-spacing: 0.16px;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
`;
