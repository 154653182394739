import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { SendIcon } from "../utils/Icons/SvgIcons";
import { useDispatch, useSelector } from "react-redux";
import { sendMessage } from "../services/Collections";
import PropTypes from "prop-types";
import { recentChatHistory } from "../module/Messenger/ChatListIdSlice";
import { individualRecentChatHistory } from "../module/Messenger/IndividualChatSlice";
import { groupRecentChatHistory } from "../module/group/GroupChatSlice";
import { useLocation } from "react-router-dom";

export default function MessageSender({ type, scrollToBottom }) {
  const currentLoginSlice = useSelector((state) => state?.LoginSlice?.data);
  const selectedJob = useSelector((state) => state?.ChatId?.job_Id);
  const selectedchat = useSelector((state) => state?.IndividualChat?.selectedChat);
  const selectGroupchat = useSelector((state) => state?.GroupChat?.selectedChat);
  const JobRecentChat = useSelector((state) => state?.ChatId?.recentChat);
  const individualChat = useSelector((state) => state?.IndividualChat?.recentChat);
  const groupChat = useSelector((state) => state?.GroupChat?.recentChat);
  const [input, setInput] = useState("");
  const [senderColor, setSenderColor] = useState(false);
  const inputFocus = useRef(null);
  const [btnClicked, setBtnClicked] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation().pathname;

  const getName = (selectedchat) => {
    let getName =
      selectedchat?.userData?.length > 0 &&
      selectedchat?.userData?.find((el) => el?._id != currentLoginSlice?._id);

    return getName?.name;
  };

  const openChat = location?.includes("group")
    ? selectGroupchat?.jobname
    : location?.includes("individual")
    ? getName(selectedchat)
    : selectedJob?.jobname;

  const handleInputText = () => {
    let inputValue = input.trim();
    if (inputFocus.current?.style) {
      if (inputValue == "") {
        inputFocus.current.style.height = "38px";
      } else {
        inputFocus.current.style.height = "0px";
        const scrollHeight = inputFocus.current.scrollHeight;
        inputFocus.current.style.height = 2 + scrollHeight + "px";
      }
    }
  };

  const handleDispatchAction = (payload) => {
    setInput("");
    let obj = {
      ...payload,
      name: currentLoginSlice?.name,
      participants: [
        {
          user_id: currentLoginSlice?._id,
          readStatus: true
        }
      ]
    };
    if (type == 1) {
      let arr = [...JobRecentChat];
      arr.unshift(obj);
      dispatch(recentChatHistory(arr));
    } else {
      if (type == 2) {
        let arr = [...individualChat];
        arr.unshift(obj);
        dispatch(individualRecentChatHistory(arr));
      } else {
        let arr = [...groupChat];
        arr.unshift(obj);
        dispatch(groupRecentChatHistory(arr));
      }
    }
    setTimeout(() => {
      scrollToBottom();
    }, 500);
  };

  const sendingMessage = async () => {
    if (input.trim()) {
      setSenderColor(true);
      // setInput("");

      let payload = {
        type: type,
        workspace_id: currentLoginSlice?.workspace?._id,
        channelName: `job_${
          type == 3 ? selectGroupchat?.id : type == 2 ? selectedchat?._id : selectedJob?.id
        }`,
        user_id: currentLoginSlice?._id,
        message_type: "text",
        message: input.trim(),
        sentAt: new Date(Date.now()).toISOString(),
        local_id: new Date() * 10,
        thumbnail: "",
        duration: "",
        media_name: "",
        media_size: ""
      };
      handleDispatchAction({ ...payload, type: "text" });

      let res = await sendMessage(payload);
      if (res?.status === 200) {
        setBtnClicked(false);
        setSenderColor(false);
      } else {
        setBtnClicked(false);
        setSenderColor(false);
      }
    }
  };

  // const sendMessageOnEnter = () => {
  //   const listener = (event) => {
  //     if (event.code === "Enter" || event.code === "NumpadEnter") {
  //       event.preventDefault();
  //       sendingMessage();
  //     }
  //   };
  //   document.addEventListener("keydown", listener);
  //   return () => {
  //     document.removeEventListener("keydown", listener);
  //   };
  // };

  useEffect(() => {
    inputFocus?.current?.focus();
  }, [senderColor]);

  useEffect(() => {
    setInput("");
    inputFocus?.current?.focus();
  }, [selectedchat, selectedJob, selectGroupchat]);

  useEffect(() => {
    handleInputText();
  }, [input]);

  return (
    <MessageSenderWrapper>
      <div className="wrapper">
        <textarea
          className="input"
          onChange={(e) => setInput(e.target.value)}
          placeholder={`Message ${openChat}`}
          ref={inputFocus}
          rows={1}
          value={input}
          onKeyDown={(event) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
              input && !event.shiftKey && sendingMessage();
              if (event?.keyCode == 13 && !event?.shiftKey) {
                event.preventDefault();
                return false;
              }
            }
          }}
        />
        {input && !btnClicked ? (
          <i
            className="iconWrapper"
            onClick={() => {
              setBtnClicked(true);
              sendingMessage();
            }}>
            <SendIcon color={senderColor ? "gray" : "#3D97F2"} />
          </i>
        ) : (
          <i className="iconWrapper">
            <SendIcon color={"gray"} />
          </i>
        )}
      </div>
    </MessageSenderWrapper>
  );
}

MessageSender.propTypes = {
  type: PropTypes.any,
  scrollToBottom: PropTypes.func
};

const MessageSenderWrapper = styled.div`
  position: absolute;
  right: 20px;
  bottom: 16px;
  left: 20px;
  // height: 116px;
  min-height: 57px;
  max-height: 100%;
  // border-radius: 8px;
  /* background: #fff; */
  display: flex;
  align-items: end;
  // border: 1px solid #979797;
  transition: border 0.16s, box-shadow 0.16s;

  .wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .iconWrapper {
    position: absolute;
    bottom: 8px;
    right: 10px;
    transform: transform(-50%, -50%);
    cursor: pointer;
  }

  .input {
    width: 99%;
    height: 100%;
    border-radius: 8px;
    background: #fff;
    border: 1px solid #fff;
    min-height: 40px;
    max-height: 183px;
    /* overflow-y: scroll; */
    padding: 9px 72px 7px 16px;
    border: 1px solid #979797;
    /* transition: border 0.16s, box-shadow 0.16s; */
    /* transition: border-color 150ms ease; */
    font-family: Lato, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.46668;
    color: black;
    resize: none;

    &:focus {
      /* transform: scale(1.01); */
      /* transition: all 0.3s ease 0s; */
      outline: none;
      border: 1px solid #979797;
      box-shadow: 0px 0px 5px 0px #c7c5c5;
    }

    ::-webkit-scrollbar {
      width: 4px;
    }
    ::-webkit-scrollbar-track {
      background: #fafafa;
    }
    ::-webkit-scrollbar-thumb {
      background: #e0e0e0;
      border-radius: 4px;
    }
  }
  .iconWrapper {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .send {
    color: #3d97f2;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.14px;
    text-transform: uppercase;
  }
`;
