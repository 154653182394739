import { getAllWorkspaceListing } from "../../services/Collections";

export const handleWorkspaceListing = async () => {
  let res = await getAllWorkspaceListing();

  if (res.status === 200) {
    // console.log(res);
    return res?.data;

    // setWorkspaceCRM(res?.data);
  }
};
