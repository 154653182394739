import AWS from "aws-sdk";

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET_NAME;
// const REGION = process.env.REACT_APP_S3_REGION;
const ACCESS_KEY = process.env.REACT_APP_S3_ACCESS_KEY;
const SECRET_ACCESS_KEY = process.env.REACT_APP_S3_SECRET_ACCESS_KEY;

export const uploadFileToS3 = async (file, bucketPath, setFileUploader) => {
  AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY
  });
  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET }
  });

  const params = {
    Bucket: S3_BUCKET,
    Key: bucketPath, // file.name,
    Body: file
  };
  var upload = s3
    .putObject(params)
    .on("httpUploadProgress", (evt) => {
      console.log("Uploading" + parseInt((evt.loaded * 100) / evt.total) + "%");
    })
    .promise();

  await upload.then((err, data) => {
    console.log("AWSs3", err, data);

    setFileUploader(true);

    // alert("File uploaded successfully.");
  });
};

// export const deleteS3Object = async (bucketPath, handleDelete = () => {}) => {
//   // return new Promise((resolve, reject) => {
//   try {
//     let s3bucket = new AWS.S3({
//       accessKeyId: ACCESS_KEY,
//       secretAccessKey: SECRET_ACCESS_KEY,
//       Bucket: S3_BUCKET
//     });
//     var params = { Bucket: S3_BUCKET, Key: bucketPath };
//     // console.log('params delete', params);
//     s3bucket.deleteObject(params, function (err) {
//       // console.log('++delete response', data, err);
//       // an error occurred
//       if (err) console.log("image delete failed");
//       else handleDelete();
//       //resolve(data); // successful response
//       // console.log(typeof handleDelete, 'type');
//     });
//   } catch (e) {
//     message.error("Something went wrong-----");
//   }
//   // });
// };
