import React, { useState } from "react";
import { Modal, Select } from "antd";
import PropTypes from "prop-types";
import { DownArrow } from "../utils/Icons/SvgIcons";
import styled from "styled-components";

export const EditModal = ({
  open,
  handleClose,
  data,
  type,
  options,
  handleUpdateRegions,
  loading
}) => {
  const [value, setValue] = useState(null);
  const [JobsWebhook, setJobsWebhook] = useState(data?.value2 == "YES" ? 1 : 0);

  const handleSubmit = async () => {
    if (type == "business") {
      let obj = {
        ...value,
        ...data
      };
      handleUpdateRegions(obj);
    } else {
      let obj = {
        webhookStatus: JobsWebhook,
        ...data
      };
      handleUpdateRegions(obj);
    }
  };

  return (
    <div>
      <Modal open={open} centered width={474} footer={false} onCancel={handleClose}>
        <SelectTradeWrapper>
          <>
            <div className="title">
              <h3>{data?.name}</h3>
            </div>

            <div className="content">
              {type == "business" ? (
                <div className="input-control">
                  <label>REGION MANUAL</label>
                  <div className="input-section">
                    <Select
                      style={{
                        width: "100%"
                      }}
                      defaultValue={data?.region && data?.region !== "NA" ? data?.region : null}
                      placeholder="Please select"
                      suffixIcon={null}
                      onChange={(_, e) => setValue(e)}
                      tokenSeparators={[","]}
                      options={options}
                    />
                    <div className="img-icon">
                      <DownArrow />
                    </div>
                  </div>
                </div>
              ) : (
                <JobTypeWrapper>
                  <input
                    defaultChecked={data?.webhook == "YES"}
                    type="checkbox"
                    onChange={(e) => setJobsWebhook(e.target.checked ? 1 : 0)}
                  />
                  <h3>Enable YAPI Webhook</h3>
                </JobTypeWrapper>
              )}
            </div>

            <div className="btn-wrapper">
              {loading ? (
                <button>Loading...</button>
              ) : (
                <button onClick={handleSubmit}>Save Changes</button>
              )}
            </div>
          </>
        </SelectTradeWrapper>
      </Modal>
    </div>
  );
};

EditModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  data: PropTypes.any,
  type: PropTypes.string,
  options: PropTypes.array,
  handleUpdateRegions: PropTypes.any,
  loading: PropTypes.bool
};
const SelectTradeWrapper = styled.div`
  width: 100%;
  padding-bottom: 10px;

  .loader-box {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    width: 100%;

    h3 {
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: #101010;
    }
    p {
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #4b5563;
      padding-top: 8px;
    }
  }

  .content {
    width: 100%;
    margin-top: 24px;
    .ant-select-selection-placeholder {
      color: #000000 !important;
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      padding: 5px 16px;
    }
    .input-control {
      width: 100%;
      display: flex;
      flex-direction: column;

      label {
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #101010;
      }

      input {
        height: 52px;
        background: #f5f6f7;
        border-radius: 500px;
        border-style: none;
        padding-left: 16px;
        margin: 8px 0 16px 0;
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #000000;

        &::placeholder {
          color: #000000;
          font-family: Lato, sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }

        &:focus {
          outline: none;
        }
      }

      .input-section {
        position: relative;
        width: 100%;
        margin: 2px 0 16px 0;

        .ant-select-selection-item {
          padding-left: 11px;
        }
      }

      .img-icon {
        position: absolute;
        right: 20px;
        top: 25px;
        cursor: pointer;
      }
    }
  }

  .btn-wrapper {
    width: 100%;
    margin-top: 8px;

    button {
      width: 100%;
      height: 56px;
      background: #101010;
      border-radius: 500px;
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      border-style: none;
      cursor: pointer;
    }
  }
`;

const JobTypeWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  margin-bottom: 30px;

  input {
    width: 17px;
    accent-color: #101010;
  }

  h3 {
    font-style: normal;
    font-weight: 700;
    font-family: Lato, sans-serif;
  }
`;
