/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Sidenav from "../../components/Sidenav";
import { useDispatch, useSelector } from "react-redux";
import ClickAwayListener from "react-click-away-listener";
import {
  OpenSideNav,
  SigninInitialStateReset,
  SignInStep
} from "../../app/Auth/signIn/SignInSlice";
import {
  getAllWorkspaceListing,
  searchJobListing,
  updateActiveUser,
  ViewWorkspaceUsers
} from "../../services/Collections";
import { message } from "antd";
import {
  CurrentWorkspace,
  WorkSpaceData,
  workSpaceInitialStateReset
} from "../../app/Workspace/WorkSpaceSlice";
import DummyComponent from "../../components/DummyComponent";
import { navigateByRoles } from "../../utils/common/commonParamter";
import {
  chatInitialStateReset,
  JobsData,
  splashLoading
} from "../../module/Messenger/ChatListIdSlice";
import { NothingSelected } from "../../utils/Icons/SvgIcons";
import { groupInitialStateReset } from "../../module/group/GroupChatSlice";
import { individualInitialStateReset } from "../../module/Messenger/IndividualChatSlice";
import { beamsClient, pusher } from "../../Pusher/PusherConfig";

export const PrivateLayout = () => {
  const navigate = useNavigate();
  const currentLoginSlice = useSelector((state) => state?.LoginSlice?.data);
  const sidebar = useSelector((state) => state?.LoginSlice?.sideNav);
  const currentAllWorkSpaces = useSelector((state) => state?.WorkSpaceSlice?.workSpaceData);
  const location = useLocation();
  const userDetails = useSelector((state) => state?.LoginSlice?.data);

  const dispatch = useDispatch();
  const handleClickAway = () => {
    dispatch(OpenSideNav(false));
  };

  const handleJobsListing = async (workspaceid) => {
    // setLoading(true);
    let params = new URLSearchParams();
    params.append("workspace_id", workspaceid);
    params.append("user_id", currentLoginSlice?._id);
    params.append("page", 1);

    let res = await searchJobListing(params.toString());
    if (res?.status == 200 || res?.message == "OK") {
      dispatch(JobsData(res?.data));
    } else {
      // setLoading(false);
      message.error(res?.response?.data?.message || res?.message || "Something went wrong");
    }
  };

  const handleUpdateActiveUser = async () => {
    let requestPayload = {
      workspace_id: currentLoginSlice?.workspace?._id,
      user_id: currentLoginSlice?._id
    };
    let res = await updateActiveUser(requestPayload);
    if (res?.status == 200) {
      // console.log(res, "resssssssssssssssssssssssssssss");
    }
  };

  const updateLoginSlice = (defaultPayload) => {
    // if (currentLoginSlice?.user_type !== defaultPayload?.user_type) {
    let object = {
      workspace_id: defaultPayload?._id,
      workspace: defaultPayload,
      user_type: defaultPayload?.user_type
    };
    dispatch(SignInStep({ ...currentLoginSlice, ...object, ...defaultPayload?.workSpaceUsers }));
    handleJobsListing(defaultPayload?._id);
    if (navigateByRoles(defaultPayload)) {
      navigate("/private-layout");
    } else {
      if (defaultPayload?.tenant_id && defaultPayload?.tenant_id != null) {
        navigate("/private-layout/workspace/regions");
      } else {
        navigate("/private-layout/user-management");
      }
    }
  };

  const handleWorkspaceListing = async () => {
    let res = await getAllWorkspaceListing();
    if (res.status === 200) {
      let connectedWorkSpace = res?.data?.find((ele) => ele?.default_workspace);

      if (connectedWorkSpace && connectedWorkSpace?._id !== currentLoginSlice?.workspace?._id) {
        dispatch(groupInitialStateReset(true));
        dispatch(individualInitialStateReset(true));
        dispatch(chatInitialStateReset(true));
        dispatch(WorkSpaceData(res?.data));
        handleViewWorkspaceUsers(res?.data, connectedWorkSpace?._id);
      } else {
        if (currentAllWorkSpaces?.length === 0) {
          dispatch(WorkSpaceData(res?.data));
          handleViewWorkspaceUsers(res?.data, null);
        } else {
          dispatch(WorkSpaceData(res?.data));
        }
      }
    } else {
      message.error(
        res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
      );
    }
  };

  const handleViewWorkspaceUsers = async (payloadData, workspace_id) => {
    dispatch(splashLoading(true));
    const param = new URLSearchParams();
    param.append("workspace_id", workspace_id ?? currentLoginSlice?.workspace?._id);
    const res = await ViewWorkspaceUsers(param.toString());
    if (res?.status === 200) {
      Array.isArray(payloadData) &&
        payloadData?.length > 0 &&
        payloadData?.map((ele) => ele?.default_workspace && dispatch(CurrentWorkspace(ele)));
      payloadData?.map(
        (ele) =>
          ele?.default_workspace &&
          updateLoginSlice({ ...ele, users: res?.data?.[0].workSpaceUsers })
      );
    }
    setTimeout(() => {
      dispatch(splashLoading(false));
    }, 1000);
  };

  const handleLogout = () => {
    beamsClient
      .start()
      .then(() => beamsClient.removeDeviceInterest(`user_${userDetails?._id}`))
      .then(() => beamsClient.clearDeviceInterests())
      .then(() => console.log("Successfully registered and subscribed!"))
      .catch(console.error);

    message.error(
      `${currentLoginSlice?.workspace?.workspace_name} workspace is no longer available`
    );
    dispatch(SigninInitialStateReset(true));
    dispatch(groupInitialStateReset(true));
    dispatch(individualInitialStateReset(true));
    dispatch(chatInitialStateReset(true));
    dispatch(workSpaceInitialStateReset(true));
    navigate("/");
  };

  const deleteWorkspace = (id) => {
    const channel = pusher.subscribe(`workspace_${id}`);
    channel.bind("delete-workspace", function (data) {
      if (id == data?.workspace_id) {
        handleLogout();
      }
    });

    return () => {
      pusher.unsubscribe(`workspace_${id}`);
      channel.unbind("delete-workspace");
    };
  };

  useEffect(() => {
    deleteWorkspace(currentLoginSlice?.workspace?._id);
  }, [currentLoginSlice?.workspace?._id]);

  useEffect(() => {
    handleWorkspaceListing();
    handleUpdateActiveUser();
  }, []);

  return (
    <PrivateLayoutWrapper sidebar={sidebar}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <section className="sidebar-section">
            <Sidenav />
          </section>
          <DummyComponent />
        </div>
      </ClickAwayListener>
      <section className="outlet-section">
        <div className="outlet-section-inner">
          {location.pathname === "/private-layout" ? (
            <div className="nothing">
              <NothingSelected />
            </div>
          ) : (
            <Outlet />
          )}
        </div>
      </section>
    </PrivateLayoutWrapper>
  );
};

const PrivateLayoutWrapper = styled.div`
  width: 100%;
  display: flex;
  background: #5a1f5c;
  // background: linear-gradient(172deg, #5a1f5c, rgb(48, 23, 52));
  transition: all 0.3s;

  .sidebar-section {
    width: 320px;
    height: 100vh;
    /* background: #101010; */
    background: #37183b;
    position: fixed;
    z-index: 9;
    @media (max-height: 800px) {
      overflow-y: scroll;
    }

    ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #fff;
    }
    ::-webkit-scrollbar-thumb {
      background: #101010;
      border-radius: 10px;
    }

    // @media (max-width: 1440px) {
    //   width: 200px;
    // }
    @media (max-width: 992px) {
      display: ${({ sidebar }) => !sidebar && "none"};
    }
  }
  .outlet-section {
    width: calc(100% - 320px);
    margin-left: 320px;
    margin-top: 16px;
    background: #5a1f5c;
    height: calc(100vh - 16px);
    // @media (max-height: 850px) {
    //   height: 100vh;
    // }

    .outlet-section-inner {
      position: relative;
      width: 100%;
      height: 100%;
      background: #fafafa;
      border-top-left-radius: 20px;
      overflow-y: scroll;
      @media (max-width: 992px) {
        border-top-left-radius: 0;
      }
    }

    // @media (max-width: 1440px) {
    //   margin-left: 200px;
    //   width: calc(100% - 200px);
    // }

    @media (max-width: 992px) {
      width: 100%;
      margin: 0;
    }
  }
  .nothing {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
