export const activeWorkSpace = (payload, managerDB) => {
  if (managerDB) {
    return managerDB;
  } else {
    let updatedValues = payload?.find((workspace) => workspace?.currentWorkSpaceStatus);
    return "yapi_" + updatedValues?._id + "_" + updatedValues?.tenantId;
  }
};

export const navigateByRoles = (defaultPayload) => {
  if (defaultPayload?.user_type?.toLowerCase() !== "owner") {
    if (defaultPayload?.user_type?.toLowerCase() !== "admin") {
      return true;
    }
    return false;
  }
  return false;
};

export const debounce = (func, timeOut = 400) => {
  let timer;
  return (...args) => {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, timeOut);
  };
};

export const GenerateAvatarName = (payload) => {
  let name = payload?.toString();

  let first = name?.split(" ")?.[0]?.slice(0, 1);
  let second = name?.split(" ")?.[1]?.slice(0, 1);
  if (first && second) {
    // return first + second;
    return first ;
  } else if (first) {
    return first;
  } else if (second) {
    return second;
  }
};