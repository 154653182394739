import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { JobInfoModal } from "../module/jobManagement/JobInfo";
import { useLocation } from "react-router-dom";
import { ModalLoader } from "./ModalLoader";

export default function ChatInboxEmpty() {
  const [infoData, setInfoData] = useState(null);
  const [openInfo, setOpenInfo] = useState(false);
  const currentLoginSlice = useSelector((state) => state?.LoginSlice?.data);
  const _id = useSelector((state) => state?.ChatId?.job_Id?.id);
  const selectedGroupChat = useSelector((state) => state?.GroupChat?.selectedChat);
  // console.log("selectedJob", _id);
  const location = useLocation();
  const [dummyLoading, setDummyLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setDummyLoading(false), 1000);
  }, []);

  if (dummyLoading) {
    return (
      <div className="modalWrapper">
        <ModalLoader size={28} />
      </div>
    );
  }

  return (
    <ChatInboxEmptyWrapper>
      <StartedTime>
        <span></span>
        <p>
          {/* {moment().format("MMMM DD, YYYY")} */}
          {moment().calendar(null, {
            lastDay: "[Yesterday]",
            sameDay: "[Today]",
            nextDay: "[Tomorrow]",
            lastWeek: "dddd, MMMM Do",
            nextWeek: "dddd, MMMM Do",
            sameElse: "dddd, MMMM Do"
          })}
        </p>
        <span></span>
      </StartedTime>
      <div className="emptyContent">
        <p className="heading">
          {location?.pathname?.includes("group")
            ? "You have created a Group"
            : location?.pathname?.includes("individual")
            ? "Hey ! Let's Chat with the mate"
            : "You are assigned to a Job"}
        </p>
        {location?.pathname?.includes("individual") ? (
          <div style={{ margin: "20px 0 50px" }}>
            <p className="head2" style={{ textAlign: "center" }}>
              Send a message or sms to begin the conversation
            </p>
          </div>
        ) : (
          <div style={{ margin: "36px 0 50px 0" }}>
            <p className="head2">
              {location?.pathname?.includes("group") ? "Groups can have:" : "Job can have:"}
            </p>
            <ul>
              <li>Persistant chat history.</li>
              <li>Public links</li>
              <li>Admin, Managers with different rights</li>
            </ul>
          </div>
        )}
        {!location?.pathname?.includes("individual") && (
          <button
            className="viewbtn"
            onClick={() => {
              setOpenInfo(true);
              setInfoData({
                _id: location?.pathname?.includes("group")
                  ? selectedGroupChat?.id ?? selectedGroupChat?._id
                  : _id
              });
            }}>
            {location?.pathname?.includes("group") ? "View group info" : "view job info"}
          </button>
        )}
      </div>
      {openInfo && (
        <JobInfoModal
          open={openInfo}
          handleModal={() => setOpenInfo(false)}
          data={infoData}
          userID={currentLoginSlice?._id}
        />
      )}
    </ChatInboxEmptyWrapper>
  );
}

const StartedTime = styled.div`
  width: 100%;
  display: flex;
  span {
    display: block;
    width: 100%;
    height: 100%;
    border: 1px solid #ebebeb;
  }

  p {
    color: #3d3d3d;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    white-space: nowrap;
    padding: 0 10px;
  }
`;

const ChatInboxEmptyWrapper = styled.div`
  width: 100%;
  height: auto;

  .emptyContent {
    width: 398px;
    height: fit-content;
    padding: 32px 42px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }
  .heading {
    color: #3d97f2;
    font-family: Lato, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-align: center;
  }
  ul {
    /* margin: 36px 0 50px 0; */
  }
  .head2 {
    color: #8f8f8f;
    font-family: Lato, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin: 8px;
    margin-left: -9px !important;
  }
  li {
    color: #8f8f8f;
    font-family: Lato, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin: 8px;
  }
  .viewbtn {
    color: #fff;
    text-align: center;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.14px;
    text-transform: uppercase;
    border-radius: 4px;
    background: #3d97f2;
    padding: 18px 32px;
    border: 1px solid #3d97f2;
    cursor: pointer;
  }
`;
