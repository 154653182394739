import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

export const WorkspaceIndex = () => {
  return (
    <WorkspaceWrapper>
      <Outlet />
    </WorkspaceWrapper>
  );
};

const WorkspaceWrapper = styled.div`
  width: 100%;
  height: 100vh;
`;
