/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
import styled from "styled-components";
import PropTypes from "prop-types";
import { DeleteIcon, DoubleTick, Download, WatchTimeIcon } from "../utils/Icons/SvgIcons";
import { GenerateAvatarName } from "../utils/common/commonParamter";
import moment from "moment";
import { Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DeleteMessage } from "../services/Collections";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { recentChatHistory } from "../module/Messenger/ChatListIdSlice";
import { individualRecentChatHistory } from "../module/Messenger/IndividualChatSlice";
import { groupRecentChatHistory } from "../module/group/GroupChatSlice";
import ImageModal from "../modals/ImageModal";
import { PdfIcon, TxtIcon, TextIcon, newDoc, xlsIcon } from "../utils/Images/Images";
import { DeleteModal } from "../components/DeleteModal";
import AudioPlayer from "./AudioPlayer";
import VideoPlayer from "./VideoPlayer";

const MessageText = ({
  tempArray,
  idx,
  id,
  position,
  title,
  text,
  profile,
  time,
  isRead,
  sentAt,
  isNext,
  type,
  mediaArray,
  userId
}) => {
  const selectedJob = useSelector((state) => state?.ChatId?.job_Id);
  const chat = useSelector((state) => state?.ChatId?.recentChat);
  const individualChat = useSelector((state) => state?.IndividualChat?.recentChat);
  const groupChat = useSelector((state) => state?.GroupChat?.recentChat);
  const location = useLocation();
  let pathname = location?.pathname;
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImgIndex, setSelectedImgIndex] = useState();
  const mediaFileImages = {
    pdf: PdfIcon,
    txt: TextIcon,
    docx: TxtIcon,
    xls: xlsIcon,
    xlsx: xlsIcon
  };
  const currentUser = useSelector((state) => state?.LoginSlice?.data);
  const cardCondition = type == "file" || mediaArray?.length > 2;

  const [deleteM, setDeleteM] = useState(null);

  const removeDeleteMessageFromStore = (id, chat) => {
    let tempArray = [];
    for (let i = 0; i < chat.length; i++) {
      if (Array.isArray(chat[i])) {
        let tempImgArr = [];
        for (let j = 0; j < chat[i].length; j++) {
          if (chat[i][j]?._id !== id) {
            tempImgArr.push(chat[i][j]);
          }
        }
        if (tempImgArr?.length == 1) {
          tempArray.push(tempImgArr[0]);
        } else {
          tempImgArr?.length > 0 && tempArray.push(tempImgArr);
        }
      } else {
        if (chat[i]?._id !== id) {
          tempArray.push(chat[i]);
        }
      }
    }
    return tempArray;
  };

  const DeleteTextMessage = async (id) => {
    if (pathname?.includes("group")) {
      let result = removeDeleteMessageFromStore(id, groupChat);
      dispatch(groupRecentChatHistory(result));
    } else {
      if (pathname?.includes("individual")) {
        let result = removeDeleteMessageFromStore(id, individualChat);
        dispatch(individualRecentChatHistory(result));
      } else {
        let result = removeDeleteMessageFromStore(id, chat);
        dispatch(recentChatHistory(result));
      }
    }
    setDeleteM(false);
    let payload = {
      job_id: selectedJob?.id,
      message_id: id,
      workspace_id: currentUser?.workspace?._id
    };
    await DeleteMessage(payload);
  };

  const handleDownload = async (imageUrl) => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;

        const filename = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);

        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
      });
  };

  const TextMessage = ({ data }) => {
    return <div className="dangerouslyHTML" dangerouslySetInnerHTML={{ __html: data }} />;
  };

  return (
    <MessageTextStyle nextMsg={isNext?.user_id !== userId}>
      {tempArray?.includes(idx) && (
        <StartedTime>
          <span></span>
          <p>
            {moment(new Date(sentAt)).calendar(null, {
              lastDay: "[Yesterday]",
              sameDay: "[Today]",
              nextDay: "[Tomorrow]",
              lastWeek: "dddd, MMMM Do",
              nextWeek: "dddd, MMMM Do",
              sameElse: "dddd, MMMM Do"
            })}
          </p>
          <span></span>
        </StartedTime>
      )}
      <div className="positionDiv">
        <div className="wrap">
          <div className="userImg">
            {tempArray?.includes(idx) || isNext?.user_id !== userId ? (
              profile ? (
                <img src={profile} alt="" />
              ) : (
                <p style={{ textTransform: "capitalize", fontSize: "19px" }}>
                  {GenerateAvatarName(title)}
                </p>
              )
            ) : (
              <span className="graytext">{moment(time)?.format("HH:mm")}</span>
            )}
          </div>

          {type == "text" && (
            <>
              <div className="textMessage">
                {(tempArray?.includes(idx) || isNext?.user_id !== userId) && (
                  <div className="userDetails">
                    <span className="userName">{title}</span>
                    <span className="graytextone">{moment(time)?.format("HH:mm")}</span>
                  </div>
                )}

                {text?.includes("<!DOCTYPE html") ||
                text?.includes("&nbsp") ||
                text?.includes("<br>") ||
                text?.includes("span") ||
                text?.includes("style") ||
                text?.includes("&amp;") ? (
                  <>
                    <TextMessage data={text} />
                  </>
                ) : text?.includes("<") ? (
                  <>
                    <TextMessage data={text} />
                  </>
                ) : (
                  <>
                    <p style={{ whiteSpace: "pre-wrap" }}>{text}</p>
                  </>
                )}
              </div>
              {position == "right" && (
                <div className="actions">
                  {position != "right" ? null : id ? (
                    <i className="icons">
                      <DoubleTick isRead={isRead ? "rgb(61, 151, 242)" : "rgb(159, 168, 180)"} />
                    </i>
                  ) : (
                    <i className="icons">
                      <WatchTimeIcon />
                    </i>
                  )}
                  {position == "right" && id && (
                    <>
                      <span className="span"></span>
                      <i onClick={() => setDeleteM(id)}>
                        <DeleteIcon />
                      </i>
                    </>
                  )}
                </div>
              )}
            </>
          )}

          {(type == "image" || type == "file") && mediaArray?.length > 0 && (
            <div className="textMessage" key={id}>
              {(tempArray?.includes(idx) || isNext?.user_id !== userId) && (
                <div className="userDetails" style={{ marginBottom: "6px" }}>
                  <span className="userName">{title}</span>
                  <span className="graytextone">{moment(time).format("HH:mm")}</span>
                </div>
              )}
              <ImageContainer fileType={type == "image"}>
                {mediaArray.map((ele, i) => {
                  let str = ele?.message.toString();
                  let last = ele?.message.toString().lastIndexOf("/") + 1;
                  let imgname = str.slice(last, str?.length);
                  let typeOfFile = imgname.lastIndexOf(".") + 1;
                  let imgtype = imgname.slice(typeOfFile, imgname?.length);
                  return (
                    <div
                      className={cardCondition ? "multiimg" : "imgwrapper"}
                      key={i}
                      style={{
                        border:
                          imgtype == mediaFileImages[imgtype]
                            ? "1px solid rgb(191, 189, 189)"
                            : cardCondition
                            ? "1px solid rgb(191, 189, 189)"
                            : "none"
                      }}>
                      <div className={cardCondition && "imgbox"}>
                        {type == "image" ? (
                          <img
                            src={ele?.message}
                            alt="img"
                            onClick={() => {
                              setIsModalOpen(!isModalOpen);
                              setSelectedImgIndex(i);
                            }}
                          />
                        ) : (
                          <img
                            src={mediaFileImages[imgtype] || newDoc}
                            alt="file"
                            style={{ cursor: "default" }}
                          />
                        )}
                      </div>

                      {cardCondition && (
                        <div className="imgdetail">
                          <Tooltip title={imgname}>
                            <div className="imgtitle">{imgname}</div>
                          </Tooltip>
                          <div className="imgtype">{imgtype}</div>
                        </div>
                      )}

                      <div className="onhover">
                        <i
                          className="imgIcons"
                          onClick={() => {
                            handleDownload(ele?.message);
                            setSelectedImgIndex(i);
                          }}>
                          <Download />
                        </i>
                        {position == "right" && id && (
                          <>
                            <span className="span"></span>
                            <i onClick={() => setDeleteM(ele?._id)}>
                              <DeleteIcon />
                            </i>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </ImageContainer>
            </div>
          )}

          {(type == "audio" || type == "video") && (
            <>
              <div className="textMessage">
                {(tempArray?.includes(idx) || isNext?.user_id !== userId) && (
                  <div className="userDetails">
                    <span className="userName">{title}</span>
                    <span className="graytextone">{moment(time)?.format("HH:mm")}</span>
                  </div>
                )}
                {type == "audio" ? (
                  <AudioPlayer tracks={text} id={id} />
                ) : (
                  <VideoPlayer videoMessage={text} id={id} handleDownload={handleDownload}/>
                )}
              </div>
              {position == "right" && (
                <div className="actions">
                  {position != "right" ? null : id ? (
                    <i className="icons">
                      <DoubleTick isRead={isRead ? "rgb(61, 151, 242)" : "rgb(159, 168, 180)"} />
                    </i>
                  ) : (
                    <i className="icons">
                      <WatchTimeIcon />
                    </i>
                  )}
                  {position == "right" && id && (
                    <>
                      <span className="span"></span>
                      <i onClick={() => setDeleteM(id)}>
                        <DeleteIcon />
                      </i>
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {isModalOpen && (
        <ImageModal
          array={mediaArray?.length != 0 ? mediaArray : [{ message: text, createdAt: sentAt }]}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          selectedImgIndex={mediaArray?.length != 0 ? selectedImgIndex : 0}
          profile={profile}
          title={title}
          type={type}
        />
      )}
      {deleteM && (
        <DeleteModal
          handleClose={() => setDeleteM(false)}
          open={deleteM}
          title="Delete Message"
          description="Are you sure you want to delete this message?"
          button="Delete"
          btnColor="#5a1f5c"
          handleSubmit={() => DeleteTextMessage(deleteM)}
        />
      )}
    </MessageTextStyle>
  );
};

export default MessageText;

MessageText.propTypes = {
  position: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  profile: PropTypes.string,
  time: PropTypes.string,
  isRead: PropTypes.bool,
  isNext: PropTypes.object,
  mediaArray: PropTypes.array,
  userId: PropTypes.string
};

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  gap: 10px;
  width: 100%;
  justify-content: start;
  /* justify-content: ${({ position }) => (position ? "end" : "start")}; */

  .imgwrapper {
    max-width: 200px;
    max-height: 320px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    :hover .onhover {
      display: flex;
    }
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: ${({ fileType }) => (fileType ? "cover" : "contain")};
    cursor: zoom-in;
  }

  .multiimg {
    position: relative;
    /* width: 30%; */
    width: 280px;
    min-height: 70px;
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 0 10px;
    /* border: ${({ fileType }) =>
      fileType ? "1px solid #bfbdbd" : "2px solid rgb(229 37 42)"}; */
    border-radius: 15px;
    background: #fafafa;
    // cursor: zoom-in;

    :hover .onhover {
      display: flex;
    }
    @media (max-width: 561px) {
      width: 100%;
    }
  }
  .multifile {
    display: flex;
    width: 100%;
    gap: 15px;
  }
  .multiimgButton {
    flex-direction: column;
    padding: 10px;
  }
  .FileViwer {
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: center;
  }
  .onhover {
    position: absolute;
    z-index: 99;
    background: white;
    top: 10px;
    right: 10px;
    width: fit-content;
    padding: 5px;
    border: 1px solid #bfbdbd;
    border-radius: 5px;
    display: none;
    gap: 10px;
    cursor: pointer;

    .span {
      border: 1px solid #b1b4b8;
      height: 15px;
    }
  }
  .imgbox {
    width: 50px;
    height: 50px;
  }
  .imgtitle {
    text-transform: capitalize;
    font-family: "Lato", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    color: black !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .imgdetail {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 560px) {
      width: auto;
      white-space: wrap;
      overflow: unset;
      text-overflow: unset;
    }
  }
  .imgtype {
    text-transform: capitalize;
    font-family: "Lato", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    color: #1d1c1d !important;
  }
  .imgIcons {
    display: flex;
  }
`;

const StartedTime = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  span {
    display: block;
    width: 100%;
    height: 100%;
    border: 1px solid #ebebeb;
  }

  p {
    color: #3d3d3d;
    font-family: "Lato", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    white-space: nowrap;
    padding: 0 10px;
  }
`;

const MessageTextStyle = styled.div`
  margin: ${({ nextMsg }) => (nextMsg ? "10px 0 2px" : "2px 0")};
  width: initial;
  font-family: "Lato", sans-serif;
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-items: start;

  .positionDiv {
    width: 100%;
    padding: 0 20px;
    position: relative;

    :hover {
      background: #e5e5e594;
    }
  }

  .userDetails {
    display: flex;
    gap: 8px;
    justify-content: start;
    align-items: center;

    .userName {
      text-transform: capitalize;
      font-family: "Lato", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 900;
      border-radius: 0px 8px 8px;
      color: #1d1c1d;
    }
  }

  .wrap {
    display: flex;
    gap: 5px;
    justify-content: start;
    padding: 3px 0;
  }
  .userImg {
    width: 36px;
    max-height: 36px;
    min-height: 20px;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    margin: 5px 0;
    p {
      width: 36px;
      height: 36px;
      object-fit: cover;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(9, 179, 243, 0.14);
      color: rgb(0, 173, 255);
      font-family: "Lato", sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
    }

    img {
      border-radius: 6px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .textMessage {
    display: inline-block;
    word-wrap: break-word !important;
    width: 95%;
    border: 1px solid transparent;
    background: transparent;
    align-items: center;
    border-radius: 0px 8px 8px 8px;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    span,
    lable,
    div,
    .dangerouslyHTML {
      font-family: "Lato", sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      border-radius: 0px 8px 8px 8px;
      color: #1d1c1de6;
      text-decoration: none;
    }
    .graytextone {
      color: gray;
      font-family: "Lato", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
      letter-spacing: 0.12px;
      text-transform: uppercase;
      display: block;
    }
  }
  :hover .graytext {
    display: block;
  }
  .graytext {
    color: gray;
    font-family: "Lato", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: 0.12px;
    text-transform: uppercase;
    display: none;
  }
  .dot {
    border: 2px solid #e6e6e6;
    border-radius: 50%;
    width: 4px;
    height: 4px;
  }
  :hover .actions {
    display: flex;
  }
  .actions {
    display: none;
    position: absolute;
    right: 10px;
    top: -20px;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 5px;
    background: white;
    cursor: pointer;
    z-index: 2;
  }
  .actions :hover {
    display: flex;
  }
  .icons {
    display: flex;
    align-items: center;
    width: 16px;
    height: 16px;
  }
  .span {
    border: 1px solid #b1b4b8;
    height: 15px;
    margin: 0 5px;
  }
`;
