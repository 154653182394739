/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import { ForgetWrapper } from "../app/Auth/Forget/ForgetStyle";
// import { AppLogo } from "../utils/Images/Images";
import { PublicButton } from "./PublicButton";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Otp from "./Otp";
import { message } from "antd";
import { ResendOtp, verifyOtp } from "../services/Collections";
import { useDispatch } from "react-redux";
import { SignInStep } from "../app/Auth/signIn/SignInSlice";
import { splashLoading } from "../module/Messenger/ChatListIdSlice";

export const Congratulations = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { email } = state || {};
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState("01:30");

  const inputStyle = {
    width: "100%",
    height: "52px",
    margin: "0 0px",
    borderRadius: "10px",
    borderStyle: "none",
    background: "rgb(61 151 242 / 20%)",
    display: "flex",
    alignItems: "center",
    padding: 0
  };
  const handleSubmit = () => {
    navigate(-1);
  };

  const handleForm = async () => {
    setLoading(true);
    let req = {
      email: email
    };
    let res = await ResendOtp(req);
    if (res?.status === 200) {
      message.success(res?.message);
      setTime("01:30");
    } else {
      message.error(
        res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
      );
    }
    setLoading(false);
  };

  const isValidOtp = async () => {
    dispatch(splashLoading(false));
    if (time == "00:00" || localStorage?.getItem("expiredOtp") == otp) {
      message.error("OTP expired! Click to resend.");
      localStorage?.setItem("expiredOtp", otp);
      return;
    }
    if (otp && otp.length == 6) {
      setLoading(true);
      let payload = { email: email, otp: otp };
      let res = await verifyOtp(payload);
      if (res?.status === 200) {
        const result = { ...res?.data, token: res?.token };
        dispatch(SignInStep(result));
        localStorage?.removeItem("expiredOtp");
        if (res?.data?.workspace?.user_type) {
          navigate("/private-layout/workspace/regions");
          message.success(res?.message);
        } else {
          navigate("/accept-invitation");
        }
      } else {
        message.error(
          res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
        );
      }
      setLoading(false);
    } else {
      message.error("Please enter valid otp");
    }
  };

  useEffect(() => {
    let newTime = time.split(":");
    setTimeout(() => {
      if (time != "00:00") {
        if (newTime[1] <= 1) {
          if (newTime[0] == "01") {
            setTime("00:60");
          } else {
            setTime("00:00");
          }
        } else {
          if (newTime[1] > 10) {
            setTime(`${newTime[0]}:${newTime[1] - 1}`);
          } else {
            setTime(`${newTime[0]}:0${newTime[1] - 1}`);
          }
        }
      }
    }, 1000);
  }, [time]);

  return (
    <ForgetWrapper>
      <section style={{ padding: "30px 0px 0px 0px", display: "grid", alignItems: "center" }}>
        {/* <img src={AppLogo} /> */}
        <div style={{ textAlign: "center" }}>
          <header>Check your email for a code</header>
          <subheader>
            We've sent a 6-character code to{" "}
            <span style={{ color: "#3d3d3d" }}>{state?.email}</span>. The code expires shortly.
            Please enter it soon.
          </subheader>

          <div className="inner-Section">
            <VerifyOtp>
              <Otp otp={otp} setOtp={setOtp} inputStyle={inputStyle} />
            </VerifyOtp>
            <SendOtpWrapper color={"00:00" == time && "#2c53a8"}>
              <span
                onClick={() => {
                  "00:00" == time && handleForm();
                }}>
                Resend
              </span>{" "}
              {time}
            </SendOtpWrapper>
            <div className="btn-wrapper">
              <ButtonWrapper>
                <PublicButton
                  textcard={"Back"}
                  handleAction={handleSubmit}
                  color={"#3D3D3D"}
                  background={"#F5F5F5"}
                />
                <PublicButton
                  textcard={"Continue"}
                  handleAction={isValidOtp}
                  color={"#FFFFFF"}
                  background={"#3D97F2"}
                  isLoading={loading}
                />
              </ButtonWrapper>
            </div>
          </div>
        </div>
      </section>
    </ForgetWrapper>
  );
};

const VerifyOtp = styled.div`
  width: 100%;
  margin: 30px 0 !important;
`;

const ButtonWrapper = styled.div`
  display: flex;
  div {
    margin: 5px;
  }
`;

const SendOtpWrapper = styled.div`
  text-align: end;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-size: 14px;
  color: rgb(61, 61, 61);
  span {
    font-size: 16px;
    color: ${({ color }) => color || "rgb(160 155 155)"};
    cursor: pointer;
  }
`;
