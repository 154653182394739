/* eslint-disable no-irregular-whitespace */
import React from "react";
import styled from "styled-components";
import { NewWorkSpace } from "../../utils/Images/Images";
import { PublicButton } from "../../components/PublicButton";
import { useNavigate } from "react-router-dom";
// import InvitationComponentSec from "./InvitationComponentSec";
// import { getWorkSpaceInvitation } from "../../services/Collections";
// import { message } from "antd";
// import InvitationComponent from "./InvitationComponent";

export default function AcceptInvitation() {
  const navigate = useNavigate();
  // const [workSpaceInvitaitons, setWorkSpaceInvitations] = useState([]);

  // const getAllInvitation = async () => {
  //   const res = await getWorkSpaceInvitation();
  //   if (res?.status === 200) {
  //     setWorkSpaceInvitations(res?.data);
  //   } else {
  //     message.error(
  //       res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
  //     );
  //   }
  // };

  // useEffect(() => {
  //   getAllInvitation();
  // }, []);

  const handleSubmit = () => {
    navigate("/steps");
  };
  return (
    <AcceptInvitationWrapper>
      <section>
        <div className="left-div">
          <div className="image-container">
            {" "}
            <img src={NewWorkSpace} />
          </div>
          <h1>Create Your Yapi Workspace</h1>
          <h4>
            Yapi team is - a place where they can talk and work together. To create a new team click
            on the button
          </h4>

          <PublicButton
            textcard={"NEXT"}
            handleAction={handleSubmit}
            color={"#FFFFFF"}
            background={"#3D97F2"}
          />
        </div>

        {/* <div>
          {workSpaceInvitaitons.length ? <InvitationComponent /> : <InvitationComponentSec />}
        </div> */}
      </section>
    </AcceptInvitationWrapper>
  );
}

const AcceptInvitationWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  section {
    width: 50%;
    background: #fff;
    display: flex;
    padding: 20px 24px;
    margin-top: 100px;

    .left-div {
      position: relative;
      // ::before {
      //   position: absolute;
      //   content: "";
      //   width: 2px;
      //   height: 100%;
      //   background: #e6e6e6;
      //   right: 0;
      // }
    }

    > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 40px;

      .image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 225px;
          height: 222px;
        }
      }

      h1 {
        color: #3d3d3d;
        font-family: Lato, sans-serif;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 136%;
        padding: 16px 0;
        // text-align: start;
        text-align: center;
      }

      h4 {
        color: #8f8f8f;
        font-family: Lato, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        padding: 0 0 50px 0;
        text-align: center;
      }
    }
  }

  @media only screen and (max-width: 1400px) {
    section > div h1 {
      font-size: 30px;
    }
    section > div h4 {
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 834px) {
    section {
      width: auto;
      display: grid;
      gap: 34px;
      margin-top: 0px;
    }
    section > div h1 {
      // text-align: center;
      font-size: 24px;
      padding: 8px 0;
    }
    section > div h4 {
      // text-align: center;
      padding: 0px 0px 16px;
    }
  }
`;
