import axios from "axios";
import { store } from "../app/store/store";

// const EndPoint = "http://ec2-3-146-206-105.us-east-2.compute.amazonaws.com:3001/"; //staging
// const EndPoint = "http://192.168.1.87:3000/";
// const EndPoint = "http://192.168.1.88:3000/";
// const EndPoint = process.env.REACT_APP_BASEURL;
const EndPoint = "https://api.yapi.io/";

const Api = axios.create({
  timeout: 1000000,
  baseURL: EndPoint
});

Api.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
Api.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
Api.interceptors.request.use(
  (config) => {
    if (store.getState()?.LoginSlice?.data?.token) {
      const token = `Bearer ${store.getState()?.LoginSlice?.data?.token}`;
      config.headers = {
        Authorization: token
      };
    } else {
      // const lang = store.getState().languageDirection.language;
    }
    return config;
  },
  (error) =>
    // Do something with request error
    Promise.reject(error)
);

// Add a response interceptor
Api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default Api;
