import React from "react";
import styled from "styled-components";
import { AlignLeftOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

export default function MessengerHeader({ setChatsidenavOpen, chatsidenavOpen }) {
  return (
    <MessengerHeaderWrapper>
      <i onClick={() => setChatsidenavOpen(!chatsidenavOpen)}>
        <AlignLeftOutlined />
      </i>
      {chatsidenavOpen && <p>yapi messenger</p>}
    </MessengerHeaderWrapper>
  );
}

MessengerHeader.propTypes = {
  setChatsidenavOpen: PropTypes.func,
  chatsidenavOpen: PropTypes.bool
};

const MessengerHeaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  background: #ffffff;
  gap: 20px;
  position: relative;
  z-index: 99;

  i {
    cursor: pointer;
  }

  p {
    color: #000000;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Lato, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
  }
`;
