/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
// import CustomCard from "../../components/CustomCard";
import { searchJobListing } from "../../services/Collections";
import { ModalWrapper } from "../regions/RegionsIndex";
import { ModalLoader } from "../../components/ModalLoader";
import { Table, message } from "antd";
import CxcHeader from "../../components/CxcHeader";
import ResponsiveHeader from "../../components/ResponsiveHeader";
import { SearchWrapper, TableWrapper } from "../business/BusinessIndex";
import { NameWrapper } from "../userManagement/UserManagement";
// import { PaginationBox } from "../../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { JobInfoModal } from "./JobInfo";
import { PaginationBox } from "../../components/Pagination";
import { debounce } from "../../utils/common/commonParamter";
import { WechatOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ChatId } from "../Messenger/ChatListIdSlice";

export const JobsManagement = () => {
  //   const [editModal, setEditModal] = useState(false);
  const [infoData, setInfoData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);

  const connectedWorkspace = useSelector((state) => state?.LoginSlice?.data);

  const [search, setSearch] = useState("");
  const [openInfo, setOpenInfo] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pagesize, setPageSize] = useState(30);
  //   const [pagesize, setPageSize] = useState(50);
  //   const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let columns = [
    {
      title: "Sr.no",
      dataIndex: "index"
    },
    {
      title: "NAME",
      dataIndex: "name",
      render: (val) => (
        <NameWrapper>
          <p>{val ? val : "NA"}</p>
        </NameWrapper>
      )
    },
    // {
    //   title: "YAPI WEBHOOK",
    //   dataIndex: "webhook",
    //   render: (val) => (
    //     <NameWrapper>
    //       <p>{val ? val : "NA"}</p>
    //     </NameWrapper>
    //   )
    // },
    {
      title: "ACTION",
      dataIndex: "action",
      render: (_, cardData) => (
        <NameWrapper>
          <div
            onClick={() => {
              setOpenInfo(true);
              setInfoData(cardData);
            }}>
            <u>INFO</u>
          </div>
          <div>
            <i
              onClick={() => {
                navigate("/private-layout/chats");
                dispatch(
                  ChatId({
                    id: cardData?._id,
                    jobname: cardData?.name,
                    image_url: cardData?.image_url,
                    createdAt: cardData?.createdAt
                  })
                );
              }}>
              <WechatOutlined className="chatIcon" />
            </i>
          </div>
        </NameWrapper>
      )
    }
  ];

  // const getTableData = (searchData, listing) => {
  //   if (searchData?.length > 0) {
  //     return searchData;
  //   } else {
  //     return listing;
  //   }
  // };

  const handleJobsListing = async (pageNumber) => {
    setLoading(true);
    let params = new URLSearchParams();
    // let dbname =
    //   "yapi_" + connectedWorkspace?.workspace?._id + "_" + connectedWorkspace?.workspace?.tenant_id;
    // params.append("db", dbname);
    params.append("workspace_id", connectedWorkspace?.workspace?._id);
    params.append("user_id", connectedWorkspace?._id);
    params.append("page", pageNumber);

    let res = await searchJobListing(params.toString());
    if (res?.status == 200 || res?.message == "OK") {
      //   setTotalCount(res?.total);
      let arr = [];

      Array.isArray(res?.data) &&
        res?.data?.length > 0 &&
        res?.data?.map((ele, idx) => {
          let obj = {
            index: (pageNumber - 1) * 30 + (idx + 1) + ".",
            name: ele?.name,

            ...ele
          };

          arr.push(obj);
        });
      setLoading(false);
      setTotalCount(res?.workspaceusersCount);

      setJobs(arr);
    } else {
      setLoading(false);
      message.error(res?.response?.data?.message || res?.message || "Something went wrong");
    }
  };

  const handleJobsSearch = async (search, pageNumber) => {
    setLoading(true);

    let params = new URLSearchParams();
    params.append("workspace_id", connectedWorkspace?.workspace?._id);
    params.append("user_id", connectedWorkspace?._id);
    params.append("page", pageNumber);
    search && params.append("search", search);
    let res = await searchJobListing(params.toString());
    if (res?.status == 200) {
      let arr = [];

      Array.isArray(res?.data) &&
        res?.data?.length > 0 &&
        res?.data?.map((ele, idx) => {
          let obj = {
            index: (pageNumber - 1) * 30 + (idx + 1) + ".",
            name: ele?.name,

            ...ele
          };

          arr.push(obj);
        });
      setLoading(false);
      setTotalCount(res?.workspaceusersCount);
      setJobs(arr);
    } else {
      setLoading(false);
      message.error(
        res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
      );
    }
  };

  const optimizedFn = useCallback(debounce(handleJobsSearch), []);

  // const handleSearch = (word) => {
  //   setSearchWord(word);
  //   if (word) {
  //     let updated = jobs?.filter((el) => el?.name?.toLowerCase()?.includes(word.toLowerCase()));
  //     console.log(updated, "updateeee");
  //     setSearchData(updated);
  //   } else {
  //     setSearchData([]);
  //   }
  // };

  const onChange = (page) => {
    setPageNumber(page);

    setPageSize(30);
  };

  useEffect(() => {
    // optimizedFn();
  }, []);

  useEffect(() => {
    if (search) {
      optimizedFn(search, pageNumber);
    } else {
      handleJobsListing(pageNumber);
    }
  }, [pageNumber, search]);

  return (
    <div>
      {/* {editModal && (
        <EditModal
          open={editModal}
          handleClose={() => setEditModal(false)}
          data={editData}
          type="job"
          handleUpdateRegions={handleUpdateWebhook}
          loading={modalLoading}
        />
      )} */}
      {openInfo && (
        <JobInfoModal
          open={openInfo}
          handleModal={() => setOpenInfo(false)}
          data={infoData}
          userID={connectedWorkspace?._id}
        />
      )}
      <CxcHeader headerName={"Jobs"} isFilter={false} />
      <ResponsiveHeader />
      <TitleWrapper>
        <h4></h4>
        <SearchWrapper>
          <label>Search:</label>{" "}
          <input
            placeholder="Search..."
            type="text"
            onChange={(e) => {
              setSearch(e.target.value);
              setPageNumber(1);
            }}
          />
        </SearchWrapper>
      </TitleWrapper>

      {loading ? (
        <ModalWrapper>
          <ModalLoader size={40} color={"#101010"} />
        </ModalWrapper>
      ) : (
        <>
          {/* {searchData.length > 0 || searchWord ? (
            <>
              {searchData?.length > 0 ? (
                <ContentWrapper>
                  {searchData?.map((ele, index) => (
                    <CustomCard
                      key={index}
                      cardData={ele}
                      open={() => setEditModal(true)}
                      setEditData={setEditData}
                    />
                  ))}
                </ContentWrapper>
              ) : (
                <NoData>
                  <h1>NO DATA FOUND</h1>
                </NoData>
              )}
            </>
          ) : (
            <ContentWrapper>
              {jobs?.map((ele, index) => (
                <CustomCard
                  key={index}
                  cardData={ele}
                  open={() => setEditModal(true)}
                  setEditData={setEditData}
                />
              ))}
            </ContentWrapper>
          )} */}
          <TableWrapper>
            <Table className="gap-table" columns={columns} dataSource={jobs} pagination={false} />
          </TableWrapper>
        </>
      )}

      <PaginationBox
        limit={pagesize}
        current={pageNumber}
        total={totalCount}
        onChange={onChange}
        showSizeChanger={false}
      />

      {/* <PaginationBox
        limit={pagesize}
        current={currentPage}
        total={totalCount}
        onChange={onChange}
        showSizeChanger={false}
      /> */}
    </div>
  );
};

const TitleWrapper = styled.div`
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-family: Lato, sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 30px;
    line-height: 20px !important;
    color: #101010 !important;
    margin: 10px 0 !important;
  }
`;

export const ContentWrapper = styled.div`
display:grid;
grid-template-columns: repeat(3,1fr);
padding: 16px !important;
gap:16px;
padding: 0 0 86px 0;
margin-bottom:70px;

@media(max-width: 800px){
  grid-template-columns: repeat(2,1fr);
}
@media(max-width: 500px){
  grid-template-columns: repeat(1,1fr);
}
  .cardWrapper {
    cursor: pointer;
    background: #fff;.
    box-shadow: 0px 2px 12px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    padding:15px;
    .audioPlayDiv {
      display: flex;
      align-items: center;
      gap: 8px;
      h1 {
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
      }
      button {
        display: flex;
        align-items: center;
        padding: 4px 12px;
        background: #f3edff;
        border-radius: 40px;
        border: none;
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height, or 133% */

        display: flex;
        align-items: center;

        color: #8045fe;
      }
      .playArrowDiv {
        width: 40px;
        height: 40px;
       display: flex;
       justify-content: center;
       align-items: center;
        border-radius: 50%;
        background: #edf2f8ed;
      }
    }
    .cardInfo{
      display:flex;
      justify-content:space-between;
      margin-top:20px;
      
        ul{
          list-style-type:none;
          .forGray{
            color :#656E79;
            font-family: Lato, sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #656E79;
            padding-top: 8px;
          }
          .forGray1{
            color :#656E79;
            font-family: Lato, sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #656E79;
            padding-top: 8px;
            margin-bottom:20px;

            img{
              width: 20px;
              height: 20px;
              border-radius: 50%;

            }
          }
            li{
                font-family: Lato, sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                color: #101010;
                display:flex;
                align-items:center;
                gap:6px;
                line-height: 20px;
            }
        }
    }
  }
`;

export const NoData = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: #101010;
  }
`;
