import React, { useState } from "react";
import { Answers, Heading, Stepsection } from "./Stepsstyle";
import { useDispatch, useSelector } from "react-redux";
import { createWorkSpace } from "../../services/Collections";
import { message } from "antd";
import { PublicButton } from "../../components/PublicButton";
import PropTypes from "prop-types";
import { WorkSpaceData, WorkSpaceDataSignup } from "../Workspace/WorkSpaceSlice";
export default function Step2({ setstage }) {
  const { workSpaceName } = useSelector((e) => e.WorkSpaceSlice);
  const [loading, setLoading] = useState(false);
  const [taskName, setTaskName] = useState("");
  const dispatch = useDispatch();

  const handleWorkingOn = (e) => {
    setTaskName(e.target.value);
  };

  const handleNext = async () => {
    if (taskName === "") message.error("Please enter your Working on");
    else {
      setLoading(true);
      const payload = { workspace_name: workSpaceName, working_on: taskName };
      let res = await createWorkSpace(payload);
      if (res?.status === 200) {
        dispatch(WorkSpaceData([{ ...res?.data?.workspace, ...res?.data }]));
        dispatch(WorkSpaceDataSignup(res?.data));
        message.success(res?.message);
        setstage(2);
        setLoading(false);
      } else {
        message.error(
          res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
        );
        setLoading(false);
      }
    }
  };
  const handleback = () => {
    setstage(0);
  };

  return (
    <Stepsection>
      <Heading>
        <p className="stepstage">STEP 2 OF 3</p>
        <p className="heading"> Whats your team working on right now?</p>
        <p className="lighttext">This could be anything: a project, campaign, event or the deal.</p>
      </Heading>
      <Answers paddingTop={"83px !important"}>
        <div className="field">
          <div className="team">
            <p className="company"> What are you working on?</p>
            <p className="number">{taskName?.length}/50</p>
          </div>
          <div>
            <input
              className="input-field"
              style={{ margin: "8px 0px 16px" }}
              placeholder="Working on..."
              onChange={handleWorkingOn}
              maxLength={50}
            />
          </div>
          <div className="btns">
            <PublicButton
              handleAction={handleback}
              textcard="Back"
              color="black"
              background="#F5F5F5"
            />
            <PublicButton
              handleAction={handleNext}
              textcard="Next"
              color="white"
              background="#3D97F2"
              isLoading={loading}
            />
          </div>
        </div>
      </Answers>
    </Stepsection>
  );
}
Step2.propTypes = {
  setstage: PropTypes.func
};
