import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedChat: null,
  recentChat: [],
  unReadCount: [],
  chats_Data: [],
};

const IndividualChatSlice = createSlice({
  name: "individualChat",
  initialState: initialState,
  reducers: {
    selectedChat: (state, action) => {
      if (action.payload === null) {
        state.selectedChat = null;
      } else {
        state.selectedChat = action.payload;
      }
    },
    individualRecentChatHistory: (state, action) => {
      if (action.payload) {
        state.recentChat = action.payload;
      }
    },
    individualReceviedNewMessage: (state, action) => {
      if (action.payload) {
        let newArr = [];
        for (let i = 0; i < state.recentChat.length; i++) {
          if (state.recentChat[i]?.local_id == action?.payload?.local_id) {
            newArr.push(action?.payload);
          } else {
            newArr?.push(state.recentChat[i]);
          }
        }
        state.recentChat = newArr;

        let checkingDulicateMessge = state.recentChat?.some(
          (el) => el?._id === action.payload?._id
        );
        if (!checkingDulicateMessge) state.recentChat.unshift(action.payload);
      }
    },
    unReadCountMessage: (state, action) => {
      state.unReadCount = action.payload;
    },
    chatsData: (state, action) => {
      // state.chats_Data = action?.payload;
      if (Array?.isArray(action?.payload)) {
        state.chats_Data = action?.payload;
      } else {
        let tempArray = [];
        for (let i = 0; i < state.chats_Data?.length; i++) {
          if (state.chats_Data?.[i]?._id !== action?.payload?._id) {
            tempArray.push(state.chats_Data[i]);
          }
        }
        state.chats_Data = [action?.payload, ...tempArray];
      }
    },
    individualInitialStateReset: (state, action) => {
      if (action.payload) {
        state.selectedChat = null;
        state.recentChat = [];
        state.unReadCount = [];
        state.chats_Data = [];
      }
    }
  }
});

export const {
  selectedChat,
  individualRecentChatHistory,
  individualReceviedNewMessage,
  unReadCountMessage,
  chatsData,
  individualInitialStateReset
} = IndividualChatSlice.actions;

export default IndividualChatSlice.reducer;
