import { Modal, Select, message } from "antd";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import InputField from "../../validation/inputField";
import { DownArrow } from "../../utils/Icons/SvgIcons";
import { addRegions } from "../../services/Collections";
import { useSelector } from "react-redux";

export default function EditRegions({ open, handleModal, afterAddAction, options, editDetails }) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [loading, setLoading] = useState(false);
  const connectedWorkspace = useSelector((state) => state?.LoginSlice?.data);

  const createInitialObject = (value, id) => {
    if (value && id) {
      return {
        label: value,
        value: id,
        user_id: id
      };
    } else {
      return "";
    }
  };

  const removeSelectedManager = () => {
    let arr = [];
    let finalArray = [];
    let obj = createInitialObject(editDetails?.manager, editDetails?.manager_user_id);
    let obj2 = createInitialObject(editDetails?.manager2, editDetails?.manager_user_id_2);
    let obj3 = createInitialObject(editDetails?.manager3, editDetails?.manager_user_id_3);
    let obj4 = createInitialObject(editDetails?.manager4, editDetails?.manager_user_id_4);
    arr.push(obj);
    arr.push(obj2);
    arr.push(obj3);
    arr.push(obj4);

    // el?.user_id !== obj?.user_id
    // arr?.filter((ele) => el?.user_id !== ele?.user_id)

    for (let index = 0; index < options.length; index++) {
      let flag = 0;
      for (let j = 0; j < arr.length; j++) {
        if (options[index]?.user_id === arr[j].user_id) {
          flag++;
        }
      }
      flag === 0 && finalArray.push(options[index]);
    }
    return finalArray;
  };

  const removeSelectedDispatcher = () => {
    let arr = [];
    let finalArray = [];
    let obj = createInitialObject(editDetails?.dispatcher, editDetails?.dispatcher_user_id);
    let obj2 = createInitialObject(editDetails?.dispatcher2, editDetails?.dispatcher_user_id_2);
    let obj3 = createInitialObject(editDetails?.dispatcher3, editDetails?.dispatcher_user_id_3);
    let obj4 = createInitialObject(editDetails?.dispatcher4, editDetails?.dispatcher_user_id_4);

    arr.push(obj);
    arr.push(obj2);
    arr.push(obj3);
    arr.push(obj4);

    // el?.user_id !== obj?.user_id
    // arr?.filter((ele) => el?.user_id !== ele?.user_id)

    for (let index = 0; index < options.length; index++) {
      let flag = 0;
      for (let j = 0; j < arr.length; j++) {
        if (options[index]?.user_id === arr[j].user_id) {
          flag++;
        }
      }
      flag === 0 && finalArray.push(options[index]);
    }

    return finalArray;
  };

  const initialValues = useMemo(() => {
    if (options) {
      return {
        region: editDetails?.region,
        manager: createInitialObject(editDetails?.manager, editDetails?.manager_user_id),
        manager2: createInitialObject(editDetails?.manager2, editDetails?.manager_user_id_2),
        manager3: createInitialObject(editDetails?.manager3, editDetails?.manager_user_id_3),
        manager4: createInitialObject(editDetails?.manager4, editDetails?.manager_user_id_4),
        dispatcher: createInitialObject(editDetails?.dispatcher, editDetails?.dispatcher_user_id),
        dispatcher2: createInitialObject(
          editDetails?.dispatcher2,
          editDetails?.dispatcher_user_id_2
        ),
        dispatcher3: createInitialObject(
          editDetails?.dispatcher3,
          editDetails?.dispatcher_user_id_3
        ),
        dispatcher4: createInitialObject(
          editDetails?.dispatcher4,
          editDetails?.dispatcher_user_id_4
        ),
        allmanager: removeSelectedManager(),
        alldispatcher: removeSelectedDispatcher()
      };
    } else {
      return {
        region: "",
        manager: "",
        manager2: "",
        manager3: "",
        manager4: "",
        dispatcher: "",
        dispatcher2: "",
        dispatcher3: "",
        dispatcher4: "",
        allmanager: [],
        alldispatcher: []
      };
    }
  }, []);

  const validationSchema = yup.object().shape({
    region: yup.string().required("Region is required")
    // manager:yup.string().when(['manager1','manager2'])
    // email: yup.string().email("Email must be a valid email").required("Email is required"),
    // password: yup.string().required("Password is required")
  });

  const handleForm = async (val) => {
    setLoading(true);

    let params = new URLSearchParams();
    let dbname =
      "yapi_" + connectedWorkspace?.workspace?._id + "_" + connectedWorkspace?.workspace?.tenant_id;
    params.append("db", dbname);
    let requestPayload = [
      {
        id: editDetails?.id,
        region: val?.region,
        manager_user_id: val?.manager?._id,
        manager_user_name: val?.manager?.label,
        manager_user_id_2: val?.manager2?._id,
        manager_user_name_2: val?.manager2?.label,
        manager_user_id_3: val?.manager3?._id,
        manager_user_name_3: val?.manager3?.label,
        manager_user_id_4: val?.manager4?._id,
        manager_user_name_4: val?.manager4?.label,
        dispatcher_user_id: val?.dispatcher?._id,
        dispatcher_user_name: val?.dispatcher?.label,
        dispatcher_user_id_2: val?.dispatcher2?._id,
        dispatcher_user_name_2: val?.dispatcher2?.label,
        dispatcher_user_id_3: val?.dispatcher3?._id,
        dispatcher_user_name_3: val?.dispatcher3?.label,
        dispatcher_user_id_4: val?.dispatcher4?._id,
        dispatcher_user_name_4: val?.dispatcher4?.label,
        active: 1
      }
    ];
    let res = await addRegions(params.toString(), requestPayload);
    if (res?.status == 200 || res?.message == "OK") {
      handleModal();
      message.success(res?.message);
      setLoading(false);

      afterAddAction();
    } else {
      setLoading(false);
      message.error(res?.message || "Something went wrong");
    }
  };

  const FormikFieldValues = [
    {
      label: "Region",
      name: "region",
      type: "text",
      placeholder: "Enter Region",
      component: InputField,
      eyeComponent: null
    }
  ];

  const addingCurrentValue = (
    currentSelectedValue,
    initialValues,
    name,
    setFieldValue,
    updatedOn
  ) => {
    if (initialValues[name]) {
      let prev = initialValues[name];
      setFieldValue(name, currentSelectedValue);
      let updatedValues = initialValues[updatedOn]?.filter(
        (el) => el?.user_id !== currentSelectedValue?.user_id
      );
      updatedValues.push(prev);
      setFieldValue(updatedOn, updatedValues);
    } else {
      setFieldValue(name, currentSelectedValue);
      let updatedValues = initialValues[updatedOn]?.filter(
        (el) => el?.user_id !== currentSelectedValue?.user_id
      );
      setFieldValue(updatedOn, updatedValues);
    }
  };

  return (
    <Modal
      className="add-region-modal"
      open={open}
      centered
      width={474}
      footer={false}
      onCancel={handleModal}>
      <ConnectModalWrapper>
        <div className="title">
          <h3>Edit Region</h3>
        </div>

        <Formik
          isInitialValid={false}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleForm}
          render={({ setFieldValue, values }) => (
            <Form>
              <div className="content">
                {FormikFieldValues &&
                  FormikFieldValues?.map((field) => (
                    <InputWrapper key={field.name}>
                      <label>{field?.label}</label>
                      <Field
                        name={field?.name}
                        type={field?.type}
                        placeholder={field?.placeholder}
                        component={field?.component}
                      />
                    </InputWrapper>
                  ))}

                <SelectTradeWrapper>
                  <InputWrapper>
                    <label style={{ margin: 0 }}>Manager</label>
                    <div className="select-wrapper">
                      <Select
                        style={{
                          width: "100%"
                        }}
                        defaultValue={values.manager == "---" ? null : values.manager}
                        placeholder="Select Manager"
                        allowClear={false}
                        showSearch={true}
                        suffixIcon={null}
                        onSelect={(_, currentSelectedValue) => {
                          addingCurrentValue(
                            currentSelectedValue,
                            values,
                            "manager",
                            setFieldValue,
                            "allmanager"
                          );
                        }
                        }
                        tokenSeparators={[","]}
                        options={values?.allmanager}
                      />
                      <div className="img-icon">
                        <DownArrow />
                      </div>
                    </div>
                  </InputWrapper>
                  <InputWrapper>
                    <label style={{ margin: 0 }}>Manager 2</label>
                    <div className="select-wrapper">
                      <Select
                        style={{
                          width: "100%"
                        }}
                        defaultValue={values.manager2 == "---" ? null : values.manager2}
                        placeholder="Select Manager 2"
                        allowClear={false}
                        showSearch={true}
                        suffixIcon={null}
                        // onSelect={(_, currentSelectedValue) =>
                        //   setFieldValue("manager2", currentSelectedValue)
                        // }
                        onSelect={(_, currentSelectedValue) =>
                          addingCurrentValue(
                            currentSelectedValue,
                            values,
                            "manager2",
                            setFieldValue,
                            "allmanager"
                          )
                        }
                        tokenSeparators={[","]}
                        options={values?.allmanager}
                      />
                      <div className="img-icon">
                        <DownArrow />
                      </div>
                    </div>
                  </InputWrapper>
                  <InputWrapper>
                    <label style={{ margin: 0 }}>Manager 3</label>
                    <div className="select-wrapper">
                      <Select
                        style={{
                          width: "100%"
                        }}
                        defaultValue={values.manager3 == "---" ? null : values.manager3}
                        placeholder="Select Manager 3"
                        allowClear={false}
                        showSearch={true}
                        suffixIcon={null}
                        // onSelect={(_, currentSelectedValue) =>
                        //   setFieldValue("manager3", currentSelectedValue)
                        // }
                        onSelect={(_, currentSelectedValue) =>
                          addingCurrentValue(
                            currentSelectedValue,
                            values,
                            "manager3",
                            setFieldValue,
                            "allmanager"
                          )
                        }
                        tokenSeparators={[","]}
                        options={values?.allmanager}
                      />
                      <div className="img-icon">
                        <DownArrow />
                      </div>
                    </div>
                  </InputWrapper>
                  <InputWrapper>
                    <label style={{ margin: 0 }}>Manager 4</label>
                    <div className="select-wrapper">
                      <Select
                        style={{
                          width: "100%"
                        }}
                        defaultValue={values.manager4 == "---" ? null : values.manager4}
                        placeholder="Select Manager 4"
                        allowClear={false}
                        showSearch={true}
                        suffixIcon={null}
                        // onSelect={(_, currentSelectedValue) =>
                        //   setFieldValue("manager4", currentSelectedValue)
                        // }
                        onSelect={(_, currentSelectedValue) =>
                          addingCurrentValue(
                            currentSelectedValue,
                            values,
                            "manager4",
                            setFieldValue,
                            "allmanager"
                          )
                        }
                        tokenSeparators={[","]}
                        options={values?.allmanager}
                      />
                      <div className="img-icon">
                        <DownArrow />
                      </div>
                    </div>
                  </InputWrapper>
                  <InputWrapper>
                    <label style={{ margin: 0 }}>Dispatcher</label>
                    <div className="select-wrapper">
                      <Select
                        style={{
                          width: "100%"
                        }}
                        placeholder="Select Dispatcher"
                        allowClear={false}
                        showSearch={true}
                        suffixIcon={null}
                        defaultValue={values.dispatcher == "---" ? null : values.dispatcher}
                        // onSelect={(_, currentSelectedValue) =>
                        //   setFieldValue("dispatcher", currentSelectedValue)
                        // }
                        onSelect={(_, currentSelectedValue) =>
                          addingCurrentValue(
                            currentSelectedValue,
                            values,
                            "dispatcher",
                            setFieldValue,
                            "alldispatcher"
                          )
                        }
                        tokenSeparators={[","]}
                        options={values?.alldispatcher}
                      />
                      <div className="img-icon">
                        <DownArrow />
                      </div>
                    </div>
                  </InputWrapper>
                  <InputWrapper>
                    <label style={{ margin: 0 }}>Dispatcher 2</label>
                    <div className="select-wrapper">
                      <Select
                        style={{
                          width: "100%"
                        }}
                        placeholder="Select Dispatcher 2"
                        allowClear={false}
                        showSearch={true}
                        suffixIcon={null}
                        defaultValue={values.dispatcher2 == "---" ? null : values.dispatcher2}
                        // onSelect={(_, currentSelectedValue) =>
                        //   setFieldValue("dispatcher2", currentSelectedValue)
                        // }
                        onSelect={(_, currentSelectedValue) =>
                          addingCurrentValue(
                            currentSelectedValue,
                            values,
                            "dispatcher2",
                            setFieldValue,
                            "alldispatcher"
                          )
                        }
                        tokenSeparators={[","]}
                        options={values?.alldispatcher}
                      />
                      <div className="img-icon">
                        <DownArrow />
                      </div>
                    </div>
                  </InputWrapper>
                  <InputWrapper>
                    <label style={{ margin: 0 }}>Dispatcher 3</label>
                    <div className="select-wrapper">
                      <Select
                        style={{
                          width: "100%"
                        }}
                        placeholder="Select Dispatcher 3"
                        allowClear={false}
                        showSearch={true}
                        suffixIcon={null}
                        defaultValue={values.dispatcher3 == "---" ? null : values.dispatcher3}
                        // onSelect={(_, currentSelectedValue) =>
                        //   setFieldValue("dispatcher3", currentSelectedValue)
                        // }
                        onSelect={(_, currentSelectedValue) =>
                          addingCurrentValue(
                            currentSelectedValue,
                            values,
                            "dispatcher3",
                            setFieldValue,
                            "alldispatcher"
                          )
                        }
                        tokenSeparators={[","]}
                        options={values?.alldispatcher}
                      />
                      <div className="img-icon">
                        <DownArrow />
                      </div>
                    </div>
                  </InputWrapper>
                  <InputWrapper>
                    <label style={{ margin: 0 }}>Dispatcher 4</label>
                    <div className="select-wrapper">
                      <Select
                        style={{
                          width: "100%"
                        }}
                        placeholder="Select Dispatcher 4"
                        defaultValue={values.dispatcher4 == "---" ? null : values.dispatcher4}
                        allowClear={false}
                        showSearch={true}
                        suffixIcon={null}
                        // onSelect={(_, currentSelectedValue) =>
                        //   setFieldValue("dispatcher4", currentSelectedValue)
                        // }
                        onSelect={(_, currentSelectedValue) =>
                          addingCurrentValue(
                            currentSelectedValue,
                            values,
                            "dispatcher4",
                            setFieldValue,
                            "alldispatcher"
                          )
                        }
                        tokenSeparators={[","]}
                        options={values?.alldispatcher}
                      />
                      <div className="img-icon">
                        <DownArrow />
                      </div>
                    </div>
                  </InputWrapper>
                </SelectTradeWrapper>
              </div>

              <div className="btn-wrapper">
                {loading ? <span>Loading...</span> : <button type="submit">Submit</button>}
              </div>
            </Form>
          )}></Formik>
      </ConnectModalWrapper>
    </Modal>
  );
}

EditRegions.propTypes = {
  open: PropTypes.bool,
  handleModal: PropTypes.func,
  options: PropTypes.array,
  afterAddAction: PropTypes.func,
  editDetails: PropTypes.any
};

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 8px 0;

  label {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #101010;
    margin-bottom: 8px;
  }

  input {
    height: 52px;
    background: #f5f6f7;
    border-radius: 500px;
    border-style: none;
    padding-left: 16px;
    margin: 8px 0 16px 0;
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #000000;

    :focus {
      outline: none;
    }
  }
`;

export const PasswordEyeWrapper = styled.div`
  position: absolute;
  right: 14px;
  top: 59px;
  cursor: pointer;
`;

const ConnectModalWrapper = styled.div`
  width: 100%;
  padding-bottom: 10px;
  padding: 20px;
  overflow-y: scroll;
  max-height: 90vh;
  ::-webkit-scrollbar {
    width: 5px;
    height: 2px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
  }
  ::-webkit-scrollbar-thumb {
    background: #cecece;
    border-radius: 10px;
  }

  .title {
    width: 100%;

    h3 {
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: #101010;
    }
  }

  .content {
    width: 100%;
    margin: 24px 0;
  }

  .btn-wrapper {
    width: 100%;
    margin-top: 8px;

    button,
    span {
      width: 100%;
      height: 56px;
      background: #101010;
      border-radius: 500px;
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      border-style: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
  }
`;

const SelectTradeWrapper = styled.div`
  width: 100%;
  padding-bottom: 10px;

  .select-wrapper {
    display: flex;
    position: relative;
  }

  .img-icon {
    position: absolute;
    right: 20px;
    top: 25px;
    cursor: pointer;
  }

  .loader-box {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    width: 100%;

    h3 {
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: #101010;
    }
    p {
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #4b5563;
      padding-top: 8px;
    }
  }

  .content {
    width: 100%;
    margin-top: 24px;
    .ant-select-selection-placeholder {
      color: #000000 !important;
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      padding: 5px 16px;
    }
    .input-control {
      width: 100%;
      display: flex;
      flex-direction: column;

      label {
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #101010;
      }

      input {
        height: 52px;
        background: #f5f6f7;
        border-radius: 500px;
        border-style: none;
        padding-left: 16px;
        margin: 8px 0 16px 0;
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #000000;

        &::placeholder {
          color: #000000;
          font-family: Lato, sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }

        &:focus {
          outline: none;
        }
      }

      .input-section {
        position: relative;
        width: 100%;
        margin: 8px 0 16px 0;

        .ant-select-selection-item {
          padding-left: 11px;
        }
      }
    }
  }

  .btn-wrapper {
    width: 100%;
    margin-top: 8px;

    button,
    span {
      width: 100%;
      height: 56px;
      background: #101010;
      border-radius: 500px;
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      border-style: none;
      cursor: pointer;
    }
  }
`;
