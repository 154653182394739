import { Modal, Select, message } from "antd";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import InputField from "../../validation/inputField";
import { DownArrow } from "../../utils/Icons/SvgIcons";
import ErrorMessage from "../../utils/ErrorMessage";
import {
  addWorkspaceMembers,
  checkMemberExisting,
  editWorkspaceMembers
} from "../../services/Collections";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// import { addRegions } from "../../services/Collections";
// import { useSelector } from "react-redux";

export default function AddMembers({ open, handleModal, listingApi, editModal, editData }) {
  const [loading, setLoading] = useState(false);
  const userDetails = useSelector((state) => state?.LoginSlice?.data);

  const options = [
    {
      label: "Admin",
      value: "admin"
    },
    {
      label: "Employee",
      value: "employee"
    },
    {
      label: "Technician",
      value: "technician"
    }
  ];

  // const initialValues = {
  //   name: "",
  //   email: "",
  //   phone: "",
  //   role: ""
  // };
  // const digitsOnly = (value) => /^\d+$/.test(value);

  const initialValues = useMemo(() => {
    if (editData) {
      return {
        name: editData?.name,
        email: editData?.email,
        phone: editData?.phone,
        role: editData?.user_type
      };
    } else {
      return {
        name: "",
        email: "",
        phone: "",
        role: ""
      };
    }
  }, []);

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().email("Email must be a valid email").required("Email is required")
    // phone: yup
    //   .string()
    //   .optional()
    //   .nullable()
    //   .matches(/^\d{7,}$/, "Phone number must have at least 7 digits"),
    // role: yup.string().required("Role is required")
  });

  const FormikFieldValues = [
    {
      label: "Name",
      name: "name",
      type: "text",
      placeholder: "Enter name",
      component: InputField,
      eyeComponent: null,
      isDisabled: false
    },
    {
      label: "Email Address",
      name: "email",
      type: "text",
      placeholder: "Enter Email Address",
      component: InputField,
      eyeComponent: null,
      isDisabled: true
    }
    // {
    //   label: "Phone",
    //   name: "phone",
    //   type: "number",
    //   placeholder: "Enter Phone Number",
    //   component: InputField,
    //   eyeComponent: null,
    //   isDisabled: true
    // }
  ];

  // let dbname = "yapi_" + connectedWorkspace?.workspace?._id + "_" + connectedWorkspace?.workspace?.tenant_id;
  // params.append("db", dbname);

  const handleForm = async (values) => {
    setLoading(true);
    if (!editModal) {
      let requestPayload = {
        workspace_id: userDetails?.workspace_id,
        email: values?.email
      };
      let res = await checkMemberExisting(requestPayload);
      if (res?.status == 200) {
        let requestPayload = {
          from_crm: false,
          workspace_id: userDetails?.workspace_id,
          invitaions: [
            {
              user_name: values?.name,
              email: values?.email,
              phone: values?.phone,
              user_type: values?.role,
              user_active: true
            }
          ]
        };

        handleAddMember(requestPayload);
      } else {
        setLoading(false);
        message.error(
          res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
        );
      }
    } else {
      let requestPayload = {
        workspace_id: editData?.workspace_id,
        user_id: editData?._id,
        name: values?.name,
        user_type: values?.role
      };
      let res = await editWorkspaceMembers(requestPayload);
      if (res?.status === 200) {
        message.success("Updated successfully");
        handleModal();
        listingApi();
        setLoading(false);
        window.location.reload();
      } else {
        setLoading(false);
        message.error(res?.response?.data?.message || res?.message || "Something went wrong");
      }
    }
  };

  const handleAddMember = async (requestPayload) => {
    let res = await addWorkspaceMembers(requestPayload);
    if (res?.status === 200) {
      message.success("User was successfully invited" || res?.message);
      handleModal();
      listingApi();
      setLoading(false);
    } else {
      message.error(
        res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
      );
      setLoading(false);
    }
  };

  return (
    <Modal
      className="add-region-modal"
      open={open}
      centered
      width={474}
      footer={false}
      onCancel={handleModal}>
      <ConnectModalWrapper>
        <div className="title">
          <h3>{!editModal ? "Add" : "Edit"} Member</h3>
        </div>

        <Formik
          isInitialValid={false}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleForm}
          render={({ setFieldValue, errors }) => (
            <Form>
              <div className="content">
                {FormikFieldValues &&
                  FormikFieldValues?.map((field) => (
                    <InputWrapper key={field.name}>
                      <label>
                        {field?.label}{" "}
                        {field?.label != "Phone" && <span style={{ color: "red" }}>*</span>}{" "}
                      </label>
                      <Field
                        style={field?.isDisabled && editModal ? { cursor: "not-allowed" } : {}}
                        name={field?.name}
                        type={field?.type}
                        placeholder={field?.placeholder}
                        component={field?.component}
                        disabled={field?.isDisabled && editModal}
                      />
                    </InputWrapper>
                  ))}

                <InputWrapper>
                  <label style={{ marginBottom: "3px" }}>Phone</label>

                  <PhoneInput
                    disableCountryCode={true}
                    disableCountryGuess={true}
                    disableInitialCountryGuess={true}
                    disableDropdown={true}
                    placeholder="Enter Phone Number"
                    disabled={editModal}
                    onChange={(phone) => setFieldValue("phone", phone)}
                  />
                  {errors?.phone && <ErrorMessage message={errors?.phone} />}
                </InputWrapper>

                <SelectTradeWrapper>
                  <InputWrapper>
                    <label style={{ margin: 0 }}>
                      Role <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="select-wrapper">
                      <Select
                        style={{
                          width: "100%"
                        }}
                        // disabled={editModal}
                        placeholder="Select Role"
                        defaultValue={editData?.user_type}
                        suffixIcon={null}
                        tokenSeparators={[","]}
                        onChange={(e) => setFieldValue("role", e)}
                        options={options}
                      />
                      <div className="img-icon">
                        <DownArrow />
                      </div>
                    </div>
                  </InputWrapper>
                  {errors?.role && <ErrorMessage message={errors?.role} />}
                </SelectTradeWrapper>
              </div>

              <div className="btn-wrapper">
                {!editModal ? (
                  <>{loading ? <span>Loading...</span> : <button type="submit">Submit</button>}</>
                ) : (
                  <>{loading ? <span>Loading...</span> : <button type="submit">Update</button>}</>
                )}
              </div>
            </Form>
          )}></Formik>
      </ConnectModalWrapper>
    </Modal>
  );
}

AddMembers.propTypes = {
  open: PropTypes.bool,
  handleModal: PropTypes.func,
  listingApi: PropTypes.func,
  editModal: PropTypes.bool,
  editData: PropTypes.object
};

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 8px 0;

  label {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #101010;
    margin-bottom: 8px;
  }

  input {
    height: 52px;
    background: #f5f6f7;
    border-radius: 500px;
    border-style: none;
    padding-left: 16px;
    margin: 8px 0 16px 0;
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #000000;

    :focus {
      outline: none;
    }
  }
`;

export const PasswordEyeWrapper = styled.div`
  position: absolute;
  right: 14px;
  top: 59px;
  cursor: pointer;
`;

const ConnectModalWrapper = styled.div`
  width: 100%;
  padding-bottom: 10px;
  padding: 20px;
  overflow-y: scroll;
  max-height: 95vh;

  ::-webkit-scrollbar {
    width: 5px;
    height: 2px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
  }
  ::-webkit-scrollbar-thumb {
    background: #cecece;
    border-radius: 10px;
  }

  .title {
    width: 100%;

    h3 {
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: #101010;
    }
  }

  .content {
    width: 100%;
    margin: 24px 0;
  }

  .btn-wrapper {
    width: 100%;
    margin-top: 8px;

    button,
    span {
      width: 100%;
      height: 56px;
      background: #101010;
      border-radius: 500px;
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      border-style: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
  }
`;

const SelectTradeWrapper = styled.div`
  width: 100%;
  padding-bottom: 10px;

  .select-wrapper {
    display: flex;
    position: relative;
  }

  .img-icon {
    position: absolute;
    right: 20px;
    top: 25px;
    cursor: pointer;
  }

  .loader-box {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    width: 100%;

    h3 {
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: #101010;
    }
    p {
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #4b5563;
      padding-top: 8px;
    }
  }

  .content {
    width: 100%;
    margin-top: 24px;
    .ant-select-selection-placeholder {
      color: #000000 !important;
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      padding: 5px 16px;
    }
    .input-control {
      width: 100%;
      display: flex;
      flex-direction: column;

      label {
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #101010;
      }

      input {
        height: 52px;
        background: #f5f6f7;
        border-radius: 500px;
        border-style: none;
        padding-left: 16px;
        margin: 8px 0 16px 0;
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #000000;

        &::placeholder {
          color: #000000;
          font-family: Lato, sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }

        &:focus {
          outline: none;
        }
      }

      .input-section {
        position: relative;
        width: 100%;
        margin: 8px 0 16px 0;

        .ant-select-selection-item {
          padding-left: 11px;
        }
      }
    }
  }

  .btn-wrapper {
    width: 100%;
    margin-top: 8px;

    button {
      width: 100%;
      height: 56px;
      background: #101010;
      border-radius: 500px;
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      border-style: none;
      cursor: pointer;
    }
  }
`;
