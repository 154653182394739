import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  removeMemberEvent: {},
  addMemberEvent: {},
  deleteGroupEvent:{},
  unReadMessageEvent:{},
};

const PusherSlice = createSlice({
  name: "PusherSlice",
  initialState: initialState,
  reducers: {
    removeMemberEvent: (state, actions) => {
      if (actions.payload) {
        state.removeMemberEvent = actions?.payload;
      }
    },
    addMemberEvent: (state, actions) => {
      if (actions.payload) {
        state.addMemberEvent = actions?.payload;
      }
    },
    deleteGroupEvent: (state, actions) => {
      if (actions.payload) {
        state.deleteGroupEvent = actions?.payload;
      }
    },
    unReadMessageEvent: (state, actions) => {
      if (actions.payload) {
        state.unReadMessageEvent = actions?.payload;
      }
    },
  }
});

export const { removeMemberEvent, addMemberEvent, deleteGroupEvent,unReadMessageEvent } = PusherSlice.actions;

export default PusherSlice.reducer;
