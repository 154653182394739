import { Modal, message } from "antd";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import InputField from "../validation/inputField";
import { PublicButton } from "./PublicButton";
import styled from "styled-components";
import { createNewWorkspace } from "../services/Collections";
import { useDispatch } from "react-redux";
import { handleWorkspaceListing } from "../utils/common/WorkspaceListing";
import { CurrentWorkspace, WorkSpaceData } from "../app/Workspace/WorkSpaceSlice";
// import { useNavigate } from "react-router-dom";
// import { SignInStep } from "../app/Auth/signIn/SignInSlice";

export const CreateWorkSpaceModal = ({ open, handleClose, setConnectCRM, setConnectModalData }) => {
  const [loading, setLoading] = useState(false);
  // const userDetails = useSelector((state) => state?.LoginSlice?.data);
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const initialValues = {
    name: "",
    workingon: ""
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required("Workspace name is required"),
    workingon: yup.string().required("Working on is required")
  });

  const handleConnect = async (values) => {
    // console.log(values, "values");
    setLoading(true);
    let requestPayload = {
      workspace_name: values?.name,
      working_on: values?.workingon
    };
    let res = await createNewWorkspace(requestPayload);
    if (res?.status === 200) {
      // console.log(res, "ressssssssssss------>>>>>");

      // let obj = { ...userDetails, ...res?.data };

      // dispatch(SignInStep(obj));

      // window.location.reload();
      // navigate("/private-layout/user-management");
      setConnectModalData({
        ...res?.data?.workspace,
        workspaceId: res?.workspaceId,
        workSpaceName: values?.name
      });
      let listing = await handleWorkspaceListing();
      dispatch(WorkSpaceData(listing));
      Array.isArray(listing) &&
        listing?.length > 0 &&
        listing?.map((ele) => ele?.default_workspace && dispatch(CurrentWorkspace(ele)));
      //   window.location.reload();
      setLoading(false);
      setConnectCRM(true);
      message.success(res?.message);
      handleClose();
    } else {
      message.error(
        res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
      );
      setLoading(false);
    }
  };

  const FormikFieldValues = [
    // {
    //   label: "Select Workspace",
    //   name: "workspace",
    //   type: "text",
    //   placeholder: "Select Workspace",
    //   component: InputField
    // },
    {
      label: "Workspace name",
      name: "name",
      type: "text",
      placeholder: "Enter workspace name",
      component: InputField
    },

    {
      label: "Working on",
      name: "workingon",
      type: "text",
      placeholder: "Enter working on",
      component: InputField
    }
  ];

  return (
    <Modal
      className="Crm-modal"
      open={open}
      centered
      width={548}
      footer={false}
      onCancel={handleClose}>
      <ModalWrapper>
        <ConnectServiceTitanWrapper>
          <section>
            <div>
              {/* <header>Add new CRM</header> */}
              <subheader>Create New Workspace</subheader>
              <div className="inner-Section">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleConnect}
                  render={() => (
                    <Form>
                      {/* <label>Select Workspace</label>
                          <Select
                            style={{
                              width: "100%",
                              margin: 0
                            }}
                            className="workspace-select"
                            placeholder="Please company name"
                            value={values?.manager && values?.manager}
                            // suffixIcon={null}
                            onChange={(_, data) => setFieldValue("workspace", data)}
                            //   onChange={handleChange}
                            tokenSeparators={[","]}
                            options={options}
                          /> */}
                      {FormikFieldValues &&
                        FormikFieldValues?.map((field) => (
                          <div key={field?.name} className="gapRow">
                            <label>{field?.label}</label>
                            <Field
                              name={field?.name}
                              type={field?.type}
                              placeholder={field?.placeholder}
                              component={field?.component}
                            />
                          </div>
                        ))}

                      <div className="btn-wrapper">
                        <PublicButton textcard={"Next"} isLoading={loading} />
                      </div>
                    </Form>
                  )}></Formik>
              </div>
            </div>
          </section>
        </ConnectServiceTitanWrapper>
      </ModalWrapper>
    </Modal>
  );
};
CreateWorkSpaceModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  connectModalData: PropTypes.object,
  setConnectCRM: PropTypes.func,
  setConnectModalData: PropTypes.func
};

const ModalWrapper = styled.div`
  width: 100%;
  padding: 0 5px;
`;

const ConnectServiceTitanWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin-right: 5px;
  justify-content: center;
  overflow-y: scroll;
  overflow-x: hidden;

  @media (max-height: 850px) {
    height: 65vh;
  }
  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
  }
  ::-webkit-scrollbar-thumb {
    background: #cecece;
    border-radius: 10px;
  }

  section {
    width: 100%;
    height: 100%;
    padding: 30px 0;

    img {
      width: 98px;
      height: 48px;
      margin-bottom: 84px;
    }

    .stepsWrapper {
      display: flex;
      gap: 15px;
      align-items: center;

      .img-box {
        img {
          width: 48px;
          height: 48px;
          margin-bottom: 0px;
        }
      }

      .step-name {
        h4 {
          font-family: Lato, sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #101010;
        }

        p {
          font-family: Lato, sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #4b5563;
        }
      }
    }
    .three-line-box {
      display: flex;
      gap: 8px;
      padding: 14px 0;

      .black {
        width: 160px;
        border-bottom: 3px solid #000000;
      }
      .gray {
        width: 160px;
        border-bottom: 3px solid #d9e0e7;
      }
    }

    header {
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 40px;
      color: #101010;
      margin: 12px 0 8px 0px;
    }

    subheader {
      font-family: Lato, sans-serif;
      font-weight: 700;
      font-size: 26px;
      line-height: 24px;
      color: #101010;
    }

    .inner-Section {
      display: flex;
      flex-direction: column;
      margin: 30px 0px 0 0;

      .gapRow {
        margin-top: 16px;
      }
      .info-wrapper {
        margin: 12px 0 34px 0;
      }

      .two-in-one {
        display: flex;
        gap: 16px;
        div {
          label {
            font-family: Lato, sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #101010;
          }
          input {
            margin-top: 4px;
          }
        }
      }
      label {
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: #101010;
        padding: 5px 0;
      }

      input {
        width: 100%;
        height: 52px;
        filter: drop-shadow(0px 2px 12px rgba(16, 24, 40, 0.06));
        background: #ffffff;
        border-radius: 8px;
        border-style: none;
        padding: 14px 16px;
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #4b5563;
        ${"" /* margin-bottom: 16px; */}

        :focus {
          outline: none;
        }
      }
      .btn-wrapper {
        margin: 32px 0px 30px 0px;
      }
    }
  }
`;
