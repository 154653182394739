import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getAllUsersListing } from "../../services/Collections";
import { ModalWrapper } from "../regions/RegionsIndex";
import { ModalLoader } from "../../components/ModalLoader";
import { Table, Tooltip, message } from "antd";
import { useSelector } from "react-redux";
import CxcHeader from "../../components/CxcHeader";
import ResponsiveHeader from "../../components/ResponsiveHeader";
// import { NoData } from "../jobs/JobsIndex";
import { PaginationBox } from "../../components/Pagination";
import AddMembers from "./AddMembers";
import { NoData } from "../jobs/JobsIndex";

export const UserManagement = () => {
  const [loading, setLoading] = useState(true);
  const [editModal, setEditModal] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const userDetails = useSelector((state) => state?.LoginSlice?.data);
  const [pagesize, setPageSize] = useState(21);
  const [currentPage, setCurrentPage] = useState(1);
  const [addModal, setAddModal] = useState(false);
  const [editData, setEditData] = useState();
  const [totalCount, setTotalCount] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const searchRef = useRef();

  const GenerateAvatarName = (payload) => {
    let name = payload?.toString();

    let first = name?.split(" ")?.[0]?.slice(0, 1);
    let second = name?.split(" ")?.[1]?.slice(0, 1);
    if (first && second) {
      // return first + second;
      return first;
    } else if (first) {
      return first;
    } else if (second) {
      return second;
    }
  };

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      render: (val, data) => (
        <NameWrapper>
          <div className="avatarWrapper">
            <div className="avatar-name">
              <div className="avatar" style={{ background: "#137ae53b" }}>
                {/* {GenerateAvatarName(crm?.workSpaceName)} */}
                {data?.profile_url ? (
                  <img src={data?.profile_url} alt="img" />
                ) : (
                  GenerateAvatarName(val)
                )}
              </div>
            </div>
            <div>
              <p>{val ? val : "NA"}</p>
            </div>
          </div>
        </NameWrapper>
      )
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      render: (val) => (
        <NameWrapper className="tooltip">
          <Tooltip style={{ width: "500px", whiteSpace: "nowrap" }} placement="topLeft" title={val}>
            <p
              style={{
                cursor: "context-menu",
                width: "260px",
                maxWidth: "220px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap"
              }}>
              {val ? val : "NA"}
            </p>
          </Tooltip>
        </NameWrapper>
      )
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phone",
      render: (val) => (
        <NameWrapper>
          <p>{(val && val != " ") ? val : "NA"}</p>
        </NameWrapper>
      )
    },
    {
      title: "CRM",
      dataIndex: "crm",
      render: (val) => (
        <NameWrapper>
          <p>{val}</p>
        </NameWrapper>
      )
    },
    {
      title: "ROLE",
      dataIndex: "role",
      render: (val) => (
        <NameWrapper>
          <p style={{ textTransform: "capitalize" }}>{val ? val : "NA"}</p>
        </NameWrapper>
      )
    },
    {
      title: "ACTION",
      dataIndex: "action",
      render: (_, cardData) => (
        <NameWrapper>
          {cardData?.role != "owner" &&
            (userDetails?.user_type?.toLowerCase() == "owner" ||
              userDetails?.user_type?.toLowerCase() == "admin") ? (
            <div
              onClick={() => {
                setEditModal(true);
                setEditData(cardData);
              }}>
              <u>EDIT</u>
            </div>
          ) : (
            <div style={{ cursor: "not-allowed" }}>
              <u disabled style={{ cursor: "not-allowed" }}>
                EDIT
              </u>
            </div>
          )}
        </NameWrapper>
      )
    }
  ];

  const filteredDataForTable = (data) => {
    if (Array.isArray(data) && data?.length > 0) {
      let FilteredArray = data?.filter((el) => el?.user_id != userDetails?.user_id);
      let arr = [];
      FilteredArray?.map((ele) => {
        let obj = {
          name: ele?.name,
          email: ele?.email,
          phone: ele?.phone,
          crm: ele?.crm_user_id ? "Service titan" : "Yapi",
          role: ele?.user_type,
          ...ele
        };
        arr.push(obj);
      });
      setUsersData(arr);
    } else {
      setUsersData([]);
    }
  };

  const handleUserListing = async (search) => {
    if (search && search != "") {
      // setLoading(true);
      setSearchLoading(true);
      let params = new URLSearchParams();
      params.append("workspace_id", userDetails?.workspace_id);
      if (search) params.append("search", search);
      let res = await getAllUsersListing(params.toString());
      if (res?.status === 200) {
        setLoading(false);
        filteredDataForTable(res?.data);
        // setUsersData(res?.data);
        setSearchLoading(false);
        setTotalCount(res?.workspaceusersCount);
      } else {
        setLoading(false);
        setSearchLoading(false);
        message.error(res?.response?.data?.message || res?.message || "Something went wrong");
      }
    } else {
      setLoading(true);
      searchRef.current.value = "";
      let params = new URLSearchParams();
      params.append("workspace_id", userDetails?.workspace_id);
      params.append("limit", 21);
      params.append("page", currentPage);
      let res = await getAllUsersListing(params.toString());
      if (res?.status === 200) {
        setLoading(false);
        // filteredDataForTable(res?.data);
        filteredDataForTable(res?.data);
        setTotalCount(res?.workspaceusersCount);
      } else {
        setLoading(false);
        message.error(res?.response?.data?.message || res?.message || "Something went wrong");
      }
    }
  };

  const onChange = (page, limit) => {
    setPageSize(limit);
    setCurrentPage(page);
  };

  useEffect(() => {
    handleUserListing();
  }, [currentPage, userDetails?.workspace?._id]);
  return (
    <>
      {addModal && (
        <AddMembers
          open={addModal}
          handleModal={() => setAddModal(false)}
          listingApi={handleUserListing}
          editModal={false}
        />
      )}

      {editModal && (
        <AddMembers
          open={editModal}
          handleModal={() => setEditModal(false)}
          listingApi={handleUserListing}
          editModal={true}
          editData={editData}
        />
      )}
      <div>
        <CxcHeader headerName={"User Management"} isFilter={false} />
        <ResponsiveHeader />
        <TitleWrapper>
          <h4></h4>
          <SearchWrapper>
            <label>Search:</label>{" "}
            <input
              ref={searchRef}
              placeholder="Search..."
              disabled={loading}
              type="text"
              onChange={(e) => handleUserListing(e.target.value)}
            />
            <button onClick={() => setAddModal(true)} style={{ whiteSpace: "nowrap" }}>
              Add user
            </button>
          </SearchWrapper>
        </TitleWrapper>
        {loading || searchLoading ? (
          <ModalWrapper>
            <ModalLoader size={40} color={"#101010"} />
          </ModalWrapper>
        ) : (
          <>
            {usersData?.length > 0 ? (
              <TableWrapper>
                <Table
                  className="gap-table"
                  columns={columns}
                  dataSource={usersData}
                  pagination={false}
                />
              </TableWrapper>
            ) : (
              <NoData>
                <h1>NO DATA FOUND</h1>
              </NoData>
            )}
          </>
        )}
      </div>
      <PaginationBox
        limit={pagesize}
        current={currentPage}
        total={totalCount}
        onChange={onChange}
        showSizeChanger={false}
      />
    </>
  );
};

const TitleWrapper = styled.div`
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-family: Lato, sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 30px;
    line-height: 20px !important;
    color: #101010 !important;
    margin: 10px 0 !important;
  }

  button {
    border-style: none;
    background: #71bbef30;
    color: #00adff;
    padding: 10px 10px;
    border-radius: 7px;
    font-family: Lato, sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    cursor: pointer;
  }
`;

export const ContentWrapper = styled.div`
display:grid;
grid-template-columns: repeat(3,1fr);
padding: 16px !important;
gap:16px;
padding: 0 0 86px 0;
margin-bottom:70px;

@media(max-width: 800px){
  grid-template-columns: repeat(2,1fr);
}
@media(max-width: 500px){
  grid-template-columns: repeat(1,1fr);
}
  .cardWrapper {
    cursor: pointer;
    background: #fff;.
    box-shadow: 0px 2px 12px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    padding:15px;
    .audioPlayDiv {
      display: flex;
      align-items: center;
      gap: 8px;
      h1 {
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
      }
      button {
        display: flex;
        align-items: center;
        padding: 4px 12px;
        background: #f3edff;
        border-radius: 40px;
        border: none;
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height, or 133% */

        display: flex;
        align-items: center;

        color: #8045fe;
      }
      .playArrowDiv {
        width: 40px;
        height: 40px;
       display: flex;
       justify-content: center;
       align-items: center;
        border-radius: 50%;
        background: #edf2f8ed;
      }
    }
    .cardInfo{
      display:flex;
      justify-content:space-between;
      margin-top:20px;
      
        ul{
          list-style-type:none;
          .forGray{
            color :#656E79;
            font-family: Lato, sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #656E79;
            padding-top: 8px;
          }
          .forGray1{
            color :#656E79;
            font-family: Lato, sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #656E79;
            padding-top: 8px;
            margin-bottom:20px;

            img{
              width: 20px;
              height: 20px;
              border-radius: 50%;
            }
          }
            li{
                font-family: Lato, sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                color: #101010;
                display:flex;
                align-items:center;
                gap:6px;
                line-height: 20px;
            }
        }
    }
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  input {
    height: 33px;
    padding: 0 5px;
    width: 100%;
    filter: drop-shadow(rgba(16, 24, 40, 0.06) 0px 2px 12px);
    background: rgb(255, 255, 255);
    border-radius: 8px;
    border-style: none;
    padding: 14px 16px;
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgb(75, 85, 99);
    border: 1px solid #cecece;
    :focus {
      outline: none;
    }
  }
  label {
    font-family: Lato, sans-serif;
    font-size: 15px;
  }
`;

const TableWrapper = styled.div`
  width: 100%;
  padding: 0 15px;
  margin-bottom: 63px;
  overflow-x: scroll;
`;
export const NameWrapper = styled.div`
  max-width: 400px;
  display: flex;
  gap: 6px;
  .avatarWrapper {
    display: flex;
    gap: 10px;
    align-items: center;

    .avatar-name {
      display: flex;
      align-items: center;
      gap: 10px;

      .avatar {
        width: 36px;
        height: 36px;
        border-radius: 6px;
        background: #cecece;
        color: #101010;
        font-family: Lato, sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  p {
    font-size: 14px;
    font-family: Lato, sans-serif;
  }

  u {
    color: #407bff;
    font-family: Lato, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 136%;
    text-transform: uppercase;
    padding: 10px;
    cursor: pointer;
  }
  .chatIcon {
    svg {
      font-size: 18px;
      color: #477dff;
      cursor: pointer;
    }
  }
`;
