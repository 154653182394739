/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Answers, ButtonWrapper, Heading, Stepsection } from "./Stepsstyle";
import { PublicButton } from "../../components/PublicButton";
import InviteCRM from "../Integration/InviteCRM";
import { InviteWorkSpace } from "../../services/Collections";
import { message, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ADDMEMBERS, CrossRedIcon, DownArrow } from "../../utils/Icons/SvgIcons";
import { useNavigate } from "react-router-dom";
import { Field, FieldArray, Form, Formik } from "formik";
import * as yup from "yup";
import InputField from "../../validation/inputField";
import { SignInStep } from "../Auth/signIn/SignInSlice";
import ErrorMessage from "../../utils/ErrorMessage";
import styled from "styled-components";

export default function Step3() {
  const [loader, setloader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const workspaceDetails = useSelector((state) => state?.WorkSpaceSlice?.workSpaceData?.[0]);
  const workspaceDetailsSignup = useSelector((state) => state?.WorkSpaceSlice?.workSpaceDataSignup);
  const userDetails = useSelector((state) => state?.LoginSlice?.data);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const options = [
    {
      label: "Admin",
      value: "admin"
    },
    {
      label: "Employee",
      value: "employee"
    },
    {
      label: "Technician",
      value: "technician"
    }
  ];

  let schema = yup.object().shape({
    invitaions: yup.array().of(
      yup.object().shape({
        email: yup.string().email("Invaild format").required("Please enter email")
      })
    )
  });

  const handleSubmit = async (values) => {
    let arr = [];
    values?.invitaions?.forEach((element) => {
      if (!arr?.includes(element?.email)) {
        arr?.push(element?.email);
      }
    });

    if (arr?.length !== values?.invitaions?.length) {
      message.error("Don't use the same email more than once");
      return;
    }

    setloader(true);
    let req = {
      workspace_id: workspaceDetailsSignup?.workspace_id,
      invitaions: values?.invitaions
    };
    const res = await InviteWorkSpace(req);
    if (res?.status == 200) {
      setloader(false);
      skipStep();
    } else {
      setloader(false);
      message.error(res?.response?.data?.message || "something went wrong");
    }
  };

  const skipStep = () => {
    dispatch(
      SignInStep({
        ...userDetails,
        user_type: workspaceDetailsSignup?.user_type,
        workspace: workspaceDetailsSignup?.workspace,
        workspace_id: workspaceDetailsSignup?.workspace_id,
        email: workspaceDetailsSignup?.email,
        user_name: workspaceDetailsSignup?.user_name,
        user_id: workspaceDetailsSignup?.user_id,
        name: workspaceDetailsSignup?.user_name
      })
    );
  };

  return (
    <Stepsection>
      {isModalOpen && <InviteCRM open={isModalOpen} handleModal={() => setIsModalOpen(false)} />}
      <Heading>
        <p className="stepstage">STEP 3 OF 3</p>
        <p className="heading"> Add your team mates</p>
        <p className="lighttext">
          To give yapi a spin, add a few coworkers that you talk to regularly.
        </p>
      </Heading>
      <Answers FieldWidth={"500px"}>
        <div className="field">
          <Formik
            initialValues={{
              invitaions: [
                {
                  email: "",
                  user_type: "employee"
                }
              ]
            }}
            validationSchema={schema}
            onSubmit={handleSubmit}
            render={({ values, setFieldValue, errors }) => (
              <Form>
                <div className="team">
                  <p className="company"> Member’s Email</p>
                </div>

                <FieldArray name="invitaions">
                  {({ push, remove }) => (
                    <div>
                      {values?.invitaions?.length > 0 &&
                        values?.invitaions?.map((el, index) => (
                          <div className="row" key={index} style={{ position: "relative" }}>
                            <div style={{ margin: "20px 0 0 0" }}>
                              <SectionStyle>
                                <div style={{ width: "100%", position: "relative" }}>
                                  <Field
                                    className="input-field"
                                    style={{ margin: "8px 0px 16px" }}
                                    name={`invitaions.${index}.email`}
                                    type={"text"}
                                    placeholder={"emailaddress@example.com"}
                                    component={InputField}
                                  />
                                </div>
                                <SelectTradeWrapper>
                                  <div>
                                    <div className="select-wrapper">
                                      <Select
                                        style={{
                                          width: "100%",
                                          height: "55px !important"
                                        }}
                                        className="step3select"
                                        placeholder="Select Role"
                                        suffixIcon={null}
                                        tokenSeparators={[","]}
                                        onChange={(e) =>
                                          setFieldValue(`invitaions.${index}.user_type`, e)
                                        }
                                        options={options}
                                      />
                                      <div className="img-icon">
                                        <DownArrow />
                                      </div>
                                    </div>
                                  </div>
                                  {errors?.role && <ErrorMessage message={errors?.role} />}
                                </SelectTradeWrapper>
                                {index != 0 && (
                                  <div
                                    className="removeEle"
                                    type="button"
                                    onClick={() => remove(index)}>
                                    <CrossRedIcon />
                                  </div>
                                )}
                              </SectionStyle>
                            </div>
                          </div>
                        ))}

                      <div
                        className="add"
                        onClick={() =>
                          push({
                            email: "",
                            user_type: "employee"
                          })
                        }>
                        <ADDMEMBERS />
                        <p>Add member</p>
                      </div>

                      <ButtonWrapper>
                        <PublicButton
                          textcard="NEXT"
                          color="white"
                          background=" #3D97F2"
                          // handleAction={handlesubmit}
                          isLoading={loader}
                        />
                      </ButtonWrapper>
                    </div>
                  )}
                </FieldArray>
              </Form>
            )}
          />

          <div className="skip">
            <div>
              <span onClick={skipStep}>skip this step</span>
            </div>
          </div>
        </div>
      </Answers>
    </Stepsection>
  );
}

const SectionStyle = styled.section`
  display: flex;
  gap: 10px;
  position: relative;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 10px 0;
  }
`;

const SelectTradeWrapper = styled.div`
  width: 200px;
  padding-bottom: 10px;

  @media (max-width: 600px) {
    width: 100%;
  }

  .select-wrapper {
    display: flex;
    position: relative;
  }

  .img-icon {
    position: absolute;
    right: 20px;
    top: 18px;
    cursor: pointer;
  }
`;
