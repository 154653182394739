/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import styled from "styled-components";
import ChatInbox from "../../components/ChatInbox";
import ResponsiveHeader from "../../components/ResponsiveHeader";

export default function GroupChatMessenger() {
  // const [chatsidenavOpen, setChatsidenavOpen] = useState(true);
  return (
    <>
      <ChatMessengerWrapper>
        <ChatMessengerPlayground>
          <ResponsiveHeader />
          <ChatInbox type="individual" />
        </ChatMessengerPlayground>
      </ChatMessengerWrapper>
    </>
  );
}

const ChatMessengerPlayground = styled.div`
  width: 100%;
  height: calc(100vh - 16px);
  background: #fafafa;
  
`;
const ChatMessengerSidenav = styled.div`
  width: ${({ isOpen }) => (isOpen ? "320px" : "60px")};
  height: calc(100vh - 68px);
`;
const ChatMessengerHeader = styled.div`
  width: 100%;
  width: ${({ isOpen }) => (isOpen ? "320px" : "60px")};
  height: 52px;
`;
const ChatMessengerWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 16px);
  background: #ffffff;
  display: flex;
`;
