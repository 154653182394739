import React from "react";
import { Modal } from "antd";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useState } from "react";
import { ModalImagArrow } from "../utils/Icons/SvgIcons";
import { GenerateAvatarName } from "../utils/common/commonParamter";
import TimeAgo from "timeago-react";

const ImageModal = ({ array, isModalOpen, setIsModalOpen, selectedImgIndex, profile, title }) => {
  const [img, setImg] = useState(selectedImgIndex);

  const handleNavigate = (val) => {
    const len = array?.length - 1;
    if (val == "pre") {
      if (img > 0) {
        setImg(img - 1);
      }
    } else {
      if (img < len) {
        setImg(img + 1);
      }
    }
  };

  const getImgName = (ele) => {
    let str = ele?.message.toString();
    let last = ele?.message.toString().lastIndexOf("/") + 1;
    let imgname = str?.slice(last, str?.length);
    return imgname;
  };
  return (
    <Modal
      open={isModalOpen}
      onCancel={() => setIsModalOpen(!isModalOpen)}
      centered={true}
      footer={null}
      title={null}
      width={"95%"}
      prefixCls="Image-modal">
      <ImageModalStyle className="imgContainer" bgimg={array[img].message}>
        <div className="backgroundimg"></div>
        <header>
          <div className="userImg">
            {profile ? (
              <img src={profile} alt="" />
            ) : (
              <p style={{ textTransform: "capitalize", fontSize: "19px" }}>
                {GenerateAvatarName(title)}
              </p>
            )}
          </div>
          <div>
            <div className="titlename">{title}</div>
            <div style={{ display: "flex", gap: "5px", color: "white" }}>
              <span>
                <TimeAgo datetime={array[img]?.createdAt} />
              </span>
              <span>-</span>
              <span>{getImgName(array[img])}</span>
            </div>
          </div>
        </header>
        {array?.length > 1 && (
          <div className="navigateIcons">
            <button
              onClick={() => handleNavigate("pre")}
              style={{ visibility: img == 0 ? "hidden" : "unset" }}>
              <ModalImagArrow />
            </button>
            <button
              onClick={() => handleNavigate("next")}
              style={{
                rotate: "180deg",
                visibility: img == array?.length - 1 ? "hidden" : "unset"
              }}>
              <ModalImagArrow />
            </button>
          </div>
        )}
        <img src={array[img]?.message} alt="img" className="images" />
      </ImageModalStyle>
    </Modal>
  );
};
export default ImageModal;

ImageModal.propTypes = {
  isModalOpen: PropTypes.bool,
  array: PropTypes.array,
  selectedImgIndex: PropTypes.number,
  setIsModalOpen: PropTypes.func,
  profile: PropTypes.string,
  title: PropTypes.string
};

const ImageModalStyle = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;

  .backgroundimg {
    background-image: url(${({ bgimg }) => bgimg});
    background-size: cover;
    background-position: center;
    filter: blur(6px);
    margin: 0;
    position: absolute;
    inset: 0;
  }

  .images {
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 1;
  }
  :hover .navigateIcons button {
    display: flex;
  }

  .navigateIcons {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
    z-index: 2;

    button {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: transparent;
      border: 1px solid transparent;
      overflow: hidden;
      display: none;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      box-shadow: 0 0 5px black;
      border: 1px solid gray;
      color: gray;
      background: white;
    }
  }

  //header
  header {
    position: absolute;
    width: 100%;
    top: 0px;
    overflow: hidden;
    display: none;
    gap: 10px;
    z-index: 3;
    background: linear-gradient(180deg, #100f0fc9, transparent);
    padding: 10px;
  }
  :hover header {
    display: flex;
  }
  .userImg {
    width: 44px;
    max-height: 44px;
    border-radius: 4px;
    p {
      width: 44px;
      height: 44px;
      object-fit: cover;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(9, 179, 243, 0.14);
      color: rgb(0, 173, 255);
      font-family: Lato, sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
    }

    img {
      border-radius: 6px;
      width: 100%;
      height: 100%;
    }
  }
  .titlename {
    text-transform: capitalize;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    border-radius: 0px 8px 8px;
    // color: #1d1c1d;
    color: white;
  }
`;
