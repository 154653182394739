/* eslint-disable no-unused-vars */
import React from "react";
import styled from "styled-components";
import ChatInbox from "../../components/ChatInbox";
import ResponsiveHeader from "../../components/ResponsiveHeader";

export default function ChatMessenger() {
  return (
    <>
      <ChatMessengerWrapper>
        <ChatMessengerPlayground>
          <ResponsiveHeader />
          <ChatInbox />
        </ChatMessengerPlayground>
      </ChatMessengerWrapper>
    </>
  );
}

const ChatMessengerPlayground = styled.div`
  width: 100%;
  height: calc(100vh - 16px);
  background: #fafafa;
`;

const ChatMessengerWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 16px);
  background: #ffffff;
  display: flex;
`;
