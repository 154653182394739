/* eslint-disable no-unused-vars */
import React from "react";
import styled from "styled-components";
// import { JobsProfileIcon } from "../utils/Icons/SvgIcons";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { ChatId, JobsData, recentChatHistory } from "../module/Messenger/ChatListIdSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip, message } from "antd";
import { oneToOneChatInititate, updateSeenStatusMessage } from "../services/Collections";
import {
  chatsData,
  selectedChat,
  individualRecentChatHistory
} from "../module/Messenger/IndividualChatSlice";
import {
  groupSelectedChat,
  groupsData as groupsListing,
  groupRecentChatHistory
} from "../module/group/GroupChatSlice";
import { GroupNameIcon, JobNameIcon, TeamNameIcon } from "../utils/Icons/SvgIcons";

const ChatList = ({
  jobId,
  chatData,
  activeJob,
  setActiveJob,
  type,
  searchChat,
  setGroupId,
  search,
  globalSearch,
  handleModal,
  setChatInitiate
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user_Id = useSelector((state) => state?.LoginSlice?.data?._id);
  // const currentLoginSlice = useSelector((state) => state?.LoginSlice?.data);
  // const unReadCount = useSelector((state) => state?.ChatId?.unReadCount);
  let jobData = useSelector((state) => state?.ChatId?.jobs_Data);
  let groupsData = useSelector((state) => state?.GroupChat?.groups_Data);
  const selectedId = useSelector((state) => state?.ChatId?.job_Id?.id);
  const chats_Data = useSelector((state) => state?.IndividualChat?.chats_Data);

  const currentLoginSlice = useSelector((state) => state?.LoginSlice?.data);
  const location = useLocation();

  const selectedJob = useSelector((state) => state?.ChatId?.job_Id);
  const selectedchat = useSelector((state) => state?.IndividualChat?.selectedChat);
  const selectedGroupchat = useSelector((state) => state?.GroupChat?.selectedChat);
  const currentActiveChat = location?.pathname?.includes("group")
    ? selectedGroupchat?.id
    : location?.pathname?.includes("individual")
    ? selectedchat?._id
    : selectedJob?.id;

  const handleChatInitiate = async (id) => {
    setChatInitiate(true);
    let payload = {
      workspace_id: currentLoginSlice?.workspace?._id,
      user1_id: currentLoginSlice?._id,
      user2_id: id
    };
    let res = await oneToOneChatInititate(payload);
    if (res?.status === 200) {
      navigate(`individual/${res?.data?._id}`);
      localStorage.setItem("dummy", res?.data?._id);
      setTimeout(() => {
        dispatch(selectedChat(res?.data));
        handleModal();
        setChatInitiate(false);
      }, 500);
    } else {
      setChatInitiate(false);
      message.error(res?.response?.data?.message);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleIndividualChat = async (id) => {
    let payload = {
      workspace_id: currentLoginSlice?.workspace?._id,
      user1_id: currentLoginSlice?._id,
      user2_id: id
    };
    let res = await oneToOneChatInititate(payload);
    if (res?.status === 200) {
      // console.log(res?.data, "responseeeeeeee");
      dispatch(selectedChat(res?.data));
      navigate("/private-layout/individual");
      localStorage.setItem("dummy", res?.data?._id);
    } else {
      message.error(res?.response?.data?.message);
    }
  };

  // console.log(location?.pathname?.includes("chats"), "locationlocation");

  // const getName = (selectedchat) => {
  //   let getName =
  //     selectedchat?.userData?.length > 0 &&
  //     selectedchat?.userData?.find((el) => el?._id != currentLoginSlice?._id);

  //   return getName?.name;
  // };

  const handleNavigate = (jobData, id) => {
    if (type == 3) {
      globalSearch && handleModal();
      navigate(`group/${id}`);
      if (jobData && jobData?.length > 0) {
        let object = null;
        for (let i = 0; i < jobData.length; i++) {
          if (jobData[i]?._id === jobId) {
            // console.log(jobData[i], "unReadCount[i]?._id");
            let tempArray = [{ ...jobData[i]?.members?.[0], unreadMessageCount: 0 }];
            object = { ...jobData[i], members: tempArray };
            // console.log(object, "objectttttt");
          }
        }

        const NewData = jobData.map((ele) => {
          if (ele?._id === object?._id) {
            return object;
          } else {
            return ele;
          }
        });

        // console.log(NewData, "newDttaaaaaa");

        dispatch(groupsListing(NewData));
      }
    } else {
      if (type == 2) {
        if (globalSearch) {
          jobId != currentLoginSlice?._id && handleChatInitiate(jobId);
        } else {
          // dispatch(individualRecentChatHistory([]));
          dispatch(selectedChat(chatData));
          navigate(`individual/${id}`);
          if (chats_Data && chats_Data?.length > 0) {
            let object = null;
            let tempArray = [];
            for (let i = 0; i < chats_Data.length; i++) {
              if (chats_Data[i]?._id === jobId) {
                if (chats_Data[i]?.members?.[0]?.user_id === currentLoginSlice?._id) {
                  tempArray.push({ ...chats_Data[i]?.members?.[0], unreadMessageCount: 0 });
                  tempArray.push({ ...chats_Data[i]?.members?.[1] });
                } else {
                  tempArray.push({ ...chats_Data[i]?.members?.[0] });
                  tempArray.push({ ...chats_Data[i]?.members?.[1], unreadMessageCount: 0 });
                }

                object = { ...chats_Data[i], members: tempArray };
              }
            }

            const NewData = chats_Data.map((ele) => {
              if (ele?._id === object?._id) {
                return object;
              } else {
                return ele;
              }
            });

            dispatch(chatsData(NewData));
          }
        }
      } else {
        globalSearch && handleModal();
        navigate(`chats/${id}`);
        if (jobData && jobData?.length > 0) {
          let object = null;
          for (let i = 0; i < jobData.length; i++) {
            if (jobData[i]?._id === jobId) {
              // console.log(jobData[i], "unReadCount[i]?._id");
              let tempArray = [{ ...jobData[i]?.members?.[0], unreadMessageCount: 0 }];
              object = { ...jobData[i], members: tempArray };
              // console.log(object, "objectttttt");
            }
          }

          const NewData = jobData.map((ele) => {
            if (ele?._id === object?._id) {
              return object;
            } else {
              return ele;
            }
          });

          // console.log(NewData, "newDttaaaaaa");

          dispatch(JobsData(NewData));
        }
      }
    }
  };

  // console.log(jobData, "NewData");

  const handleSeenMessage = async (id) => {
    const req = {
      user_id: user_Id,
      channelName: `job_${id}`,
      type: type == 2 ? 2 : 1,
      workspace_id: currentLoginSlice?.workspace?._id
    };

    await updateSeenStatusMessage(req);
  };

  const getName = (selectedchat) => {
    if (globalSearch) {
      return chatData?.user_name;
    } else {
      let getName =
        selectedchat?.userData?.length > 0 &&
        selectedchat?.userData?.find((el) => el?._id != currentLoginSlice?._id);

      return getName?.name;
    }
  };

  const getActiveClass = () => {
    if (location?.pathname?.includes("individual")) {
      return activeJob === jobId && location?.pathname?.includes("individual");
    } else {
      return (
        activeJob === jobId &&
        (location?.pathname?.includes("chats") || location?.pathname?.includes("group"))
      );
    }
  };

  const getCountData = (data) => {
    let countData = data?.length > 0 && data?.find((el) => el?.user_id == currentLoginSlice?._id);

    return countData;
  };

  // console.log(chatData, "chatDatachatData");

  return (
    <Tooltip
      placement="top"
      title={type == 2 ? getName(chatData) : chatData?.name}
      color={"#3d97f2"}
      zIndex={999999999}>
      <ChatListStyle
        active={getActiveClass()}
        isWindows={navigator?.userAgent?.includes("Windows")}
        globalSearch={globalSearch}
        onClick={() => {
          setGroupId && setGroupId("");
          if (!searchChat) {
            type == 1
              ? dispatch(
                  ChatId({
                    id: chatData?._id,
                    jobname: chatData?.name,
                    image_url: chatData?.image_url,
                    createdAt: chatData?.createdAt
                  }),
                  dispatch(recentChatHistory([]))
                )
              : type == 3
              ? (dispatch(
                  groupSelectedChat({
                    id: chatData?._id,
                    jobname: chatData?.name,
                    image_url: chatData?.image_url,
                    createdAt: chatData?.createdAt
                  })
                ),
                dispatch(groupRecentChatHistory([])))
              : type == 2 && dispatch(individualRecentChatHistory([]));
            localStorage.setItem("dummy", chatData?._id);
            setActiveJob && setActiveJob(jobId);
            handleNavigate(type == 3 ? groupsData : jobData, jobId);
            handleSeenMessage(chatData?._id);
          } else {
            handleIndividualChat(chatData?.userData?.[0]?._id);
          }
        }}>
        <div className="mainWrapper">
          <div className="groupDetails">
            <div className="groupName">
              {type == 3 ? <GroupNameIcon /> : type == 1 ? <JobNameIcon /> : <TeamNameIcon />}{" "}
              {type == 2 ? getName(chatData) : chatData?.name}
            </div>
          </div>
        </div>
        {currentActiveChat != chatData?._id &&
          (type == 2 || type == 3
            ? getCountData(chatData?.members)?.unreadMessageCount > 0 && (
                <div className="unreadmessage">
                  <p>
                    {getCountData(chatData?.members)?.unreadMessageCount > 10
                      ? "10+"
                      : getCountData(chatData?.members)?.unreadMessageCount}
                  </p>
                </div>
              )
            : chatData?.members?.[0]?.unreadMessageCount > 0 && (
                <div className="unreadmessage">
                  <p>
                    {chatData?.members?.[0]?.unreadMessageCount > 10
                      ? "10+"
                      : chatData?.members?.[0]?.unreadMessageCount}
                  </p>
                </div>
              ))}
      </ChatListStyle>
    </Tooltip>
  );
};

export default ChatList;

ChatList.propTypes = {
  jobId: PropTypes.number,
  chatData: PropTypes.all,
  activeJob: PropTypes.any,
  setActiveJob: PropTypes.func,
  type: PropTypes.any,
  searchChat: PropTypes.any,
  setGroupId: PropTypes.any,
  search: PropTypes.bool,
  globalSearch: PropTypes.bool,
  handleModal: PropTypes.func,
  setChatInitiate: PropTypes.func
};

const ChatListStyle = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 10px;
  /* margin: 12px 15px; */
  margin: 0 0 10px 0;
  font-family: Lato, sans-serif;
  font-style: normal;
  border-left: ${({ active }) => (active ? "2px solid #3D97F2" : " 2px solid transparent")};
  display: flex;
  align-items: center;
  position: relative;

  .mainWrapper {
    display: flex;
    gap: 10px;
    margin: 5px 0;
  }

  .groupImg {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    svg path {
      fill: ${({ active, globalSearch }) =>
        active ? "#3D97F2" : globalSearch ? "#00000" : "#ffffff"};
    }
  }
  .groupDetails {
    display: flex;
    align-items: center;
  }
  .groupName {
    font-family: Lato, sans-serif;
    color: ${({ active, globalSearch }) =>
      active ? "#3D97F2" : globalSearch ? "#00000" : "#ffffff"};
    /* font-size: 18px; */
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-overflow: ellipsis;
    width: 220px;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &:hover {
    border-left: 2px solid #3d97f2;
    cursor: pointer;

    .groupName {
      color: #3d97f2;
    }

    .groupImg {
      svg path {
        fill: #3d97f2;
      }
    }
  }
  .unreadmessage {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #3d97f2;
    border-radius: 50%;
    display: grid;
    place-items: center;
    font-family: Lato, sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;

    p {
      color: white;
      // width: ${({ isWindows }) => (isWindows ? "20px" : "18px")};
      // height: ${({ isWindows }) => (isWindows ? "17px" : "16.5px")};
      // padding: ${({ isWindows }) => !isWindows && "2px 0 0 0"};
    }
  }
`;
