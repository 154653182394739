import React from "react";
import styled from "styled-components";
import MessageText from "./MessageText";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";

export default function Inbox({ chat, MessageHistory, page, hasMore, scrollRef, messageLoader, parentScrollingBox }) {
  const currentLoginSlice = useSelector((state) => state?.LoginSlice?.data);
  const [tempArray, setTempArray] = useState([]);
  const diffDateMessage = (chat) => {
    let a = [];
    let prev;
    let next;
    for (let i = 0; i < chat.length; i++) {
      if (Array.isArray(chat[i])) {
        prev = moment(new Date(chat[i][0]?.sentAt)).format("YYYY-MM-DD");
      } else {
        prev = moment(new Date(chat[i]?.sentAt)).format("YYYY-MM-DD");
      }
      if (Array.isArray(chat[i + 1])) {
        next = moment(new Date(chat[i + 1][0]?.sentAt)).format("YYYY-MM-DD");
      } else {
        next = moment(new Date(chat[i + 1]?.sentAt)).format("YYYY-MM-DD");
      }

      if (prev > next) {
        a.push(i);
        // break;
      }
      if (i == chat.length - 1) {
        a.push(i);
      }
    }

    setTempArray(a);
  };

  const checkArray = (ele) => {
    let val;
    if (Array.isArray(ele)) {
      val = ele[0]?.user_id === currentLoginSlice?._id ? "right" : "left";
      return val;
    } else {
      val = ele?.user_id === currentLoginSlice?._id ? "right" : "left";
      return val;
    }
  };

  useEffect(() => {
    diffDateMessage(chat);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat]);

  return (
    <InboxStyle>
      <div
        className="inner-inbox"
        style={{
          // overflow: "auto",
          display: "flex",
          flexDirection: "column-reverse"
          // paddingRight: "10px"
        }}
        onScroll={parentScrollingBox}
        id="box">
        <div
          ref={scrollRef}
          id="scrollId"
          style={{ marginTop: "15px" }}
        />
        <InfiniteScroll
          dataLength={chat.length}
          next={() => MessageHistory(page)}
          style={{ display: "flex", flexDirection: "column-reverse" }}
          inverse={true}
          hasMore={hasMore}
          loader={messageLoader && <p className="seenAll">Loading...</p>}
          scrollableTarget="box">
          {chat?.map((val, index) => {
            return (
              <>
                <MessageText
                  key={index}
                  id={Array.isArray(val) ? val[0]?._id : val?._id}
                  position={checkArray(val)}
                  type={Array.isArray(val) ? val[0]?.type : val?.type}
                  time={Array.isArray(val) ? val[0]?.sentAt : val?.sentAt}
                  title={Array.isArray(val) ? val[0]?.name : val?.name}
                  text={Array.isArray(val) ? val[0]?.message : val?.message}
                  profile={Array.isArray(val) ? val[0]?.profile_url : val?.profile_url}
                  isRead={Array.isArray(val) ? val[0]?.isRead : val?.isRead}
                  sentAt={Array.isArray(val) ? val[0]?.sentAt : val?.sentAt}
                  userId={Array.isArray(val) ? val[0]?.user_id : val?.user_id}
                  idx={index}
                  tempArray={tempArray}
                  isNext={Array.isArray(chat[index + 1]) ? chat[index + 1][0] : chat[index + 1]}
                  mediaArray={Array.isArray(val) ? val : [val]}
                />
              </>
            );
          })}
        </InfiniteScroll>
      </div>
    </InboxStyle>
  );
}

Inbox.propTypes = {
  chat: PropTypes.array,
  MessageHistory: PropTypes.func,
  page: PropTypes.number,
  hasMore: PropTypes.bool,
  scrollRef: PropTypes.any,
  messageLoader: PropTypes.bool,
  parentScrollingBox: PropTypes.func,
};

const InboxStyle = styled.div`
  width: 100%;
  height: calc(100vh - 148px);
  display: flex;
  flex-direction: column;
  justify-content: end;
  .inner-inbox {
    width: 100%;
    height: 800px;
    overflow: scroll;
    margin-bottom: 40px;
    /* padding-bottom: 90px; */

    ::-webkit-scrollbar {
      width: 4px;
    }
    ::-webkit-scrollbar-track {
      background: #fafafa;
    }
    ::-webkit-scrollbar-thumb {
      background: #e0e0e0;
      border-radius: 4px;
    }
  }
  .seenAll {
    text-align: center;
    color: #8f8f8f;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .MultiImageContainer {
    display: flex;
  }
`;
