import React from "react";
import styled from "styled-components";
import { BlackYapiLogo, YapiPrivateBG } from "../utils/Images/Images";
import flyingLoading from "../lotties/flyingLoading.json";
import Lottie from "react-lottie";

export default function LoaderSplash() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: flyingLoading
  };

  return (
    <LoaderSplashWrapper bg={YapiPrivateBG}>
      <img src={BlackYapiLogo} alt="logo" />
      <InnerWrapper>
        <Lottie className="lottie"  options={defaultOptions} height={400} width={400} />
        <h1>Launching Workspace</h1>
      </InnerWrapper>
    </LoaderSplashWrapper>
  );
}

const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-top:102px;
  h1 {
    font-size: 42px;
    line-height: 1.2143;
    font-weight: 900;
    color: #1d1c1d;
    font-family: "Lato", sans-serif;
  }

`;

const LoaderSplashWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
  position: absolute;
  inset: 0;
  z-index: 9999;

  img {
    width: 124px;
    margin-top:20px;
  }
`;
