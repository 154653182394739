/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import { Modal, Switch, message } from "antd";
import React, { useEffect, useId, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { CreateGroupBg } from "../utils/Images/Images";
import { ImagePreview, UploadImageIcon } from "../utils/Icons/SvgIcons";
import { createGroupChat, getAwsDetails } from "../services/Collections";
import { uploadFileToS3 } from "../aws/AwsConfig";
import { useDispatch, useSelector } from "react-redux";
import { updateNewGroup } from "../module/group/GroupChatSlice";

export const CreateGroupModal = ({ open, handleClose, setAddPeoplesToGroup }) => {
  const [preview, setPreview] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [loading, setLoading] = useState(false);
  const userDetails = useSelector((state) => state?.LoginSlice?.data);
  const [groupName, setGroupName] = useState(null);
  const [error, setError] = useState("");
  const [description, setDescription] = useState(null);
  const dispatch = useDispatch();
  const [fileUploaded, setFileUploader] = useState(false);

  const handleProfile = (files) => {
    if (files?.size > 5e6) {
      message.error("5.0mb size jpg, png, jpeg format image accepted");
      return;
    }
    if (files && window.URL) {
      setPreview(URL.createObjectURL(files));
      setProfilePicture(files);
    }
  };

  const handleSubmit = async () => {
    if (groupName) {
      setLoading(true);
      // if (profilePicture != null) {
      let fileExtension = "jpeg";
      let bucketPath = "Workspace/" + userDetails._id + `/Groups/${groupName}.` + fileExtension;
      profilePicture != null && (await uploadFileToS3(profilePicture, bucketPath, setFileUploader));
      // }
      let requestPayload = {
        workspace_id: userDetails?.workspace_id,
        group_name: groupName,
        description: description,
        image: bucketPath,
        image_url: process.env.REACT_APP_IMAGE_BASE_URL + bucketPath
      };
      if (profilePicture == null) {
        delete requestPayload?.image_url;
      }
      if (!description) {
        delete requestPayload?.description;
      }

      let res = await createGroupChat(requestPayload);
      if (res?.status == 200) {
        dispatch(updateNewGroup(res?.data));
        handleClose();
        setAddPeoplesToGroup(true);

        setLoading(false);
      } else {
        setLoading(false);
        message.error(
          res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
        );
      }
    } else {
      setError("Name is required");
    }
  };

  // useEffect(() => {
  //   handleSubmit();
  // }, []);

  return (
    <div>
      <Modal
        prefixCls="group-modal"
        open={open}
        onCancel={handleClose}
        width={976}
        footer={false}
        centered>
        <CreateGroupModalBox>
          <div className="header-banner">
            <img src={CreateGroupBg} />
            <div className="text">
              <h3>Create Group</h3>
              <p>
                Groups serve as the hub for your team's communication, allowing you to add <br />{" "}
                numerous members for seamless and effective interaction.
              </p>
            </div>
          </div>

          <div className="fields">
            <div className="field-sections">
              <label>
                Group Image <span>(Optional)</span>
              </label>

              <div className="image-box">
                <div className="image-preview">
                  <div className="empty-box">
                    {preview ? <img src={preview} alt="" /> : <ImagePreview />}
                  </div>
                </div>
                <label htmlFor="profile">
                  <div className="upload-image">
                    <UploadImageIcon />
                    Upload
                    <input
                      style={{ display: "none" }}
                      id="profile"
                      type="file"
                      accept="image/png, image/jpg, image/jpeg"
                      onChange={(e) => handleProfile(e?.target?.files[0], e)}
                    />
                  </div>
                </label>
              </div>

              <p className="image-size">5.0mb size jpg, png, jpeg format image accepted</p>
            </div>
            <div className="field-sections">
              <label>
                Group Name <span style={{ color: "#D32D4A" }}>*</span>
              </label>
              <input
                type="text"
                placeholder="Enter a group name"
                onChange={(e) => {
                  setGroupName(e?.target?.value);
                  setError("");
                }}
              />
              <span style={{ color: "#D32D4A" }}>{error}</span>
            </div>
            <div className="field-sections">
              <label>
                Group Description <span>(Optional)</span>
              </label>
              <input
                type="text"
                placeholder="Enter a description"
                onChange={(e) => setDescription(e?.target?.value)}
              />
            </div>
            {/* <div className="field-sections">
              <label>Make Private</label>
              <div className="private-group">
                <p>Private can only be viewed or joined by invitation.</p>

                <Switch onClick={(e) => console.log(e)} />
              </div>
            </div> */}
          </div>

          <div className="submit-btns">
            <button className="cancel" onClick={() => handleClose()}>
              Cancel
            </button>
            {loading ? (
              <button className="next">Loading...</button>
            ) : (
              <button
                className="next"
                onClick={() => {
                  handleSubmit();
                }}>
                Next
              </button>
            )}
          </div>
        </CreateGroupModalBox>
      </Modal>
    </div>
  );
};

CreateGroupModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  setAddPeoplesToGroup: PropTypes.func
};

const CreateGroupModalBox = styled.div`
  width: 100%;
  min-height: 350px;
  padding: 32px;
  max-height: 95vh;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 3px;
    height: 2px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
  }
  ::-webkit-scrollbar-thumb {
    background: #cecece;
    border-radius: 10px;
  }

  .header-banner {
    width: 100%;
    background: #3d97f2;
    border-radius: 4px;
    position: relative;
    padding: 10px;
    z-index: 1;
    height: 179px;

    img {
      width: 100%;
      height: 100%;
      z-index: 2;
    }
    .text {
      position: absolute;
      z-index: 3;
      top: 35px;
      width: 100%;
      text-align: center;

      h3 {
        color: #fff;
        text-align: center;
        font-family: Lato, sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 132%; /* 42.24px */
        letter-spacing: 0.32px;
      }
      p {
        margin-top: 4px;
        color: #fff;
        text-align: center;
        font-family: Lato, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
      }
    }
  }

  .fields {
    width: 100%;
    max-width: 492px;
    margin: 32px auto;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .field-sections {
      width: 100%;

      label {
        color: #3d3d3d;
        font-family: Lato, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.12px;
        text-transform: uppercase;

        span {
          color: #b8b8b8;
          font-family: Lato, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 0.12px;
          text-transform: uppercase;
        }
      }

      .image-size {
        margin-top: 8px;
        color: #b8b8b8;
        font-family: Lato, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      input {
        border-radius: 4px;
        border: 1px solid #f5f5f5;
        background: #fff;
        width: 100%;
        margin-top: 8px;
        padding: 17px 16px;
        font-family: Lato, sans-serif;
        // color: #8f8f8f;
        color: #000;

        ::placeholder {
          color: #8f8f8f;
          font-family: Lato, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .private-group {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 17px 16px;

        p {
          color: #3d3d3d;
          font-family: Lato, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 132%;
        }
      }
    }

    .image-box {
      display: flex;
      gap: 16px;
      align-items: center;
      margin-top: 4px;

      .upload-image {
        border-radius: 4px;
        background: #ecf5fe;
        padding: 16px 32px;
        color: #3d97f2;
        font-family: Lato, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.14px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
      }
    }

    .image-preview {
      width: 56px;
      height: 56px;

      .empty-box {
        width: 100%;
        height: 100%;
        background: #f5f5f5;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
        }
      }
    }
  }
  .submit-btns {
    display: flex;
    gap: 10px;
    width: 100%;
    max-width: 492px;
    margin: auto;

    .cancel {
      border-radius: 4px;
      background: #f5f5f5;
      width: 100%;
      height: 56px;
      border-style: none;
      color: #3d3d3d;
      text-align: center;
      font-family: Lato, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 124%; /* 19.84px */
      letter-spacing: 0.16px;
      text-transform: uppercase;
      cursor: pointer;
    }
    .next {
      border-radius: 4px;
      background: #3d97f2;
      border-style: none;
      width: 100%;
      height: 56px;
      color: #fff;
      text-align: center;
      font-family: Lato, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 124%;
      letter-spacing: 0.16px;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
`;
