/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import AllRoutes from "./routes/routes";
import "./style/global.css";
import "./fonts/style.css";
import { beamsClient } from "./Pusher/PusherConfig";
import { useDispatch, useSelector } from "react-redux";
import LoaderSplash from "./components/LoaderSplash";
import styled from "styled-components";
import { pusher } from "./Pusher/PusherConfig";
import { SignInStep, UpdateSteps } from "./app/Auth/signIn/SignInSlice";
import {
  ChatId,
  JobsData,
  chatInitialStateReset,
  receviedNewMessage
} from "./module/Messenger/ChatListIdSlice";
import {
  CurrentWorkspace,
  WorkSpaceData,
  workSpaceInitialStateReset
} from "./app/Workspace/WorkSpaceSlice";
import { groupInitialStateReset } from "./module/group/GroupChatSlice";
import { individualInitialStateReset } from "./module/Messenger/IndividualChatSlice";
import {
  addMemberEvent,
  deleteGroupEvent,
  removeMemberEvent,
  unReadMessageEvent
} from "./Pusher/PusherSlice";

function App() {
  let userid = useSelector((state) => state?.LoginSlice?.data);
  let splashLoader = useSelector((state) => state?.ChatId?.splashLoader);
  const dispatch = useDispatch();

  beamsClient
    ?.start()
    ?.then(() => beamsClient.addDeviceInterest(`user_${userid?._id}`))
    ?.then(() => console.log("Successfully registered and subscribed!"))
    ?.catch(console.error);

  const WorkspaceChange = () => {
    const channel = pusher.subscribe(`user_${userid?._id}`);
    if (channel) {
      channel.bind("workspace-switched", function (data) {
        if (userid?._id == data?.user_id) {
          dispatch(SignInStep(null));
          dispatch(UpdateSteps(0));
          dispatch(ChatId(null));
          dispatch(receviedNewMessage([]));
          dispatch(WorkSpaceData([]));
          dispatch(JobsData([]));
          dispatch(CurrentWorkspace(null));
          dispatch(groupInitialStateReset(true));
          dispatch(individualInitialStateReset(true));
          dispatch(chatInitialStateReset(true));
          dispatch(workSpaceInitialStateReset(true));
        }
      });
    }
    return () => {
      if (channel) {
        pusher.unsubscribe(`user_${userid?._id}`);
        channel.unbind("workspace-switched");
      }
    };
  };

  useEffect(() => {
    WorkspaceChange();
  }, [userid]);

  useEffect(() => {
    const channel = pusher.subscribe(`workspace_${userid?.workspace?._id}`);

    channel.bind("remove-member", function (data) {
      if (data?._id) {
        dispatch(removeMemberEvent(data));
      }
    });

    channel.bind("add-member", function (data) {
      if (data?._id) {
        dispatch(addMemberEvent(data));
      }
    });

    channel.bind("delete-group", function (data) {
      if (data?.job_id || data?._id) {
        dispatch(deleteGroupEvent(data));
      }
    });

    channel.bind("unread-message", function (data) {
      if (data?.job_id) {
        dispatch(unReadMessageEvent(data));
      }
    });

    return () => {
      pusher.unsubscribe(`workspace_${userid?.workspace?._id}`);
      channel.unbind("remove-member");
      channel.unbind("add-member");
      channel.unbind("delete-group");
      channel.unbind("unread-message");
    };
  }, [userid?.workspace?._id]);

  return (
    <AppWrapper>
      {userid?.workspace && splashLoader && <LoaderSplash />}
      <AllRoutes />
    </AppWrapper>
  );
}

export default App;

const AppWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: #fff;
  position: relative;
  z-index: 9;
`;
