import { createSlice } from "@reduxjs/toolkit";

export const WorkSpaceSlice = createSlice({
  name: "WorkSpaceSlice",
  initialState: {
    workSpaceName: null,
    teamWorkOn: null,
    workSpaceData: [],
    currentWorkspace: null,
    workSpaceDataSignup: []
  },
  reducers: {
    WorkSpaceName: (state, actions) => {
      if (actions) {
        state.workSpaceName = actions.payload;
      }
    },
    WorkSpaceData: (state, actions) => {
      if (actions) {
        state.workSpaceData = actions.payload;
      }
    },
    CurrentWorkspace: (state, actions) => {
      // console.log(actions.payload, "actions.payload");
      if (actions) {
        state.currentWorkspace = actions.payload;
      }
    },
    // ,
    // WorkSpaceTask: (state, actions) => {
    //   if (actions) {
    //     state.teamWorkOn = actions.payload;
    //   }
    // }
    workSpaceInitialStateReset: (state, actions) => {
      if (actions) {
        state.workSpaceName = null;
        state.teamWorkOn = null;
        state.workSpaceData = [];
        state.currentWorkspace = null;
        state.workSpaceDataSignup = [];
      }
    },
    WorkSpaceDataSignup: (state, actions) => {
      if (actions) {
        state.workSpaceDataSignup = actions.payload;
      }
    }
  }
});

export const {
  WorkSpaceName,
  WorkSpaceData,
  CurrentWorkspace,
  workSpaceInitialStateReset,
  WorkSpaceDataSignup
} = WorkSpaceSlice.actions;
export default WorkSpaceSlice.reducer;
