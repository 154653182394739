import React from "react";
import styled from "styled-components";
import { WhiteAppLogo } from "../utils/Images/Images";

export const PrivacyPolicy = () => {
  return (
    <ContentWrapper>
      <div className="header-nav">
        <div className="header-logo">
          <img src={WhiteAppLogo} alt="Yapi logo" />
        </div>
      </div>
      <div className="content">
        <div className="title">
          <h2>
            <b>Privacy Policy</b>
          </h2>
          <p>
            Effective Date: <b>September 4th, 2023</b>
          </p>
        </div>
        <div className="description">
          <p>
            Yapi.io (“<b>Yapi</b>,” “<b>we</b>,” “<b>our</b>,” or “<b>us</b>”) and our affiliates,
            provides this privacy policy to describe how we collect, use, and share the information
            of individuals who visit our website at <a href="https://yapi.io">https://yapi.io/</a>,
            mobile app, as well as other digital properties that link to this privacy policy (the
            “Site”). We also describe how we collect, use, and share information about our client’s
            end users that use our services on the Services Privacy Policy.
          </p>

          <p>
            <b>
              <u>Site Privacy Policy</u>
            </b>
          </p>
          <p>
            When using our Site, you may voluntarily provide information about yourself, including
            your name, contact information, business name and contact information, log-in
            information, payment information, and any other information you choose to provide. For
            example, we collect this information when you join our email list, create an account,
            leave a review, contact us or use chat functions on our Site, or interact with us for
            any other purpose. We may also collect your mailing and billing information. We may also
            collect or receive information from third parties and other publicly available
            information about you. We may combine all information we collect and receive together
            and aggregate or de-identify that information.
          </p>
          <p>
            We (and other entities) automatically collect information relating to your interactions
            with us and our Site, including browser type, IP address, pages visited, content
            entered, and other activities on the Site, device type, and time and date of visit, and
            other information we collect through the use of cookies and similar technology. See the
            “Digital Advertising & Analytics” section of this privacy policy to learn more about the
            use of this information and the choices available to you.
          </p>
          <p>We may use information about you for any lawful purpose, including the following:</p>

          <li>To fulfill your orders;</li>
          <li>To respond to your inquiries, including questions about order status</li>
          <li>To operate, personalize, and improve the Site, including for analytics purposes;</li>
          <li>
            To send updates, promotions, and marketing materials that may be of interest to you
            about our products and the products of third parties;
          </li>
          <li>To comply with the law and to maintain the security of our Site; or</li>
          <li>
            With your consent, or as otherwise disclosed at the time information is collected.
          </li>

          <p>
            We may share the information we collect with other parties, including the following:
          </p>
          <li>
            With third-party service providers who process information on our behalf, such as
            payment processors, data hosting services, and email service providers;
          </li>
          <li>With any affiliates or joint venture partners that we may have in the future;</li>
          <li>
            For advertising and marketing services on our behalf and on for delivering messages for
            other products and services that may be of interest to you;
          </li>
          <li>For market research and product development;</li>
          <li>
            As part of a corporate sale, merger, or acquisition, or other transfer of all or part of
            our assets, including as part of a bankruptcy proceeding;
          </li>
          <li>
            Pursuant to a subpoena, court order, governmental inquiry, or other legal process or as
            otherwise required by law, to protect our rights or the rights of third parties, or to
            protect the safety or security of any person or entity;
          </li>
          <li>
            With your consent or as otherwise disclosed at the time of data collection or sharing.
          </li>
          <p>
            We may share information that has been de-identified or aggregated without limitation.
          </p>
          <p>
            The Site may offer interactive features, such as the ability to leave reviews, that you
            can use to communicate with other Site visitors or to submit and post your own content.
            If you disclose information in one of these forums, this information can be viewed,
            collected, and used by others.
          </p>
          <p>
            <b>
              <u>Information Collection Tools</u>
            </b>
          </p>
          <p>
            We and our partners may use a variety of tools that collect information about how you
            interact with our Site, including what you do on the Site, how you arrived at the Site,
            and information you enter into the Site. Those tools may include cookies, clear GIFs,
            web beacons, and other similar technology. Additionally, we may use session replay
            services provided by a third party partner that may collect real-time session
            information generated in the course of using our websites, such as mouse movements,
            clicks, page visits, text entered, how long you spent on a page, and other details of
            your actions on our websites.
          </p>

          <p>
            <b>
              <u>Digital Advertising & Analytics</u>
            </b>
          </p>
          <p>
            We may partner with ad networks and other ad serving providers (“
            <b>Advertising Providers</b>”) that serve ads on behalf of us and others on
            non-affiliated platforms. Some of those ads may be personalized, meaning that they are
            intended to be relevant to you based on information Advertising Providers collect about
            your use of the Site and other sites or apps over time, including information about
            relationships among different browsers and devices. This type of advertising is known as
            interest-based advertising.
          </p>
          <p>
            may visit the DAA WebChoices tool at{" "}
            <a href="https://optout.aboutads.info/?c=2&lang=EN">www.aboutads.info/choices</a> to
            learn more about this type of advertising and how to opt out of this advertising on
            websites by companies participating in the DAA self-regulatory program. You may also
            make choices regarding this type of advertising on mobile apps by downloading the
            appropriate version of the DAA’s AppChoices tool at{" "}
            <a href="https://youradchoices.com/appchoices">https://youradchoices.com/appchoices</a>.
          </p>
          <p>
            If you delete your cookies, reset a mobile ad identifier, or use a different browser or
            mobile device, you may need to renew your opt-out choices exercised through the DAA
            tools. Note that electing to opt out will not stop advertising from appearing in your
            browser or applications. It may make the ads you see less relevant to your interests.
          </p>
          <p>
            We may also work with third parties that collect data about your use of the Site and
            other sites or apps over time for non-advertising purposes. Yapi uses Google Analytics
            and other third-party services to improve the performance of the Site and for analytics
            and marketing purposes.
          </p>
          <p>
            Additionally, your browser may offer tools to limit the use of cookies or to delete
            cookies; however, if you use these tools, our Site may not function as intended.
          </p>
          <p>
            <b>
              <u>Third-Party Links and Tools</u>
            </b>
          </p>
          <p>
            The Site may provide links to third-party websites or apps. We do not control the
            privacy practices of those websites or apps, and they are not covered by this privacy
            policy. You should review the privacy policies of other websites or apps that you use to
            learn about their data practices.
          </p>
          <p>
            The Site also includes integrated social media tools or “plug-ins,” such as social
            networking tools offered by third parties. If you use these tools to share personal
            information or you otherwise interact with these features on the Site, those companies
            may collect information about you and may use and share such information in accordance
            with your account settings, including by sharing such information with the general
            public.
          </p>
          <p>
            Your interactions with third-party companies and your use of their features are governed
            by the privacy policies of the companies that provide those features. We encourage you
            to carefully read the privacy policies of any accounts you create and use.
          </p>
          <p>
            <b>
              <u>Updating Your Information</u>
            </b>
          </p>
          <p>
            <b>
              <u>Delete Account</u>
            </b>
          </p>
          <p>
            To delete your profile please click on the top right user name or user photo using the app, and then click on the top right View Profile option.
          </p>
          <p>
            You will then see the Deactivate your account or Delete Account link at the very bottom. </p>

          {/* <p>
            <b>
              <u>CxC Service Privacy Policy</u>
            </b>
          </p>
          <p>
            This privacy policy explains how we and our affiliates collect, use, and share
            information to provide our services to clients.
          </p>
          <p>
            We may collect information from our client’s employees, contractors, and end users when
            clients provide it to us or integrate our tools into their call systems and workflow
            processes that allow us to access that information. This information may include names,
            postal addresses, phone numbers, email addresses, transaction and order history, payment
            information, voice recordings, text messages and chats, and transcripts of those
            recordings. We may also receive information from third parties about such individuals,
            including information related to demographics, psychographics, financial & property
            data. We may combine any of this information together and also aggregate and de-identify
            information about you.
          </p>
          <p>
            We use the information we collect, along with analytical and other tools, for our
            services for the following purposes:
          </p>
          <li>
            To transcribe in-person, telephone, and text-based interactions with client end users
            and employees/contractors;
          </li>
          <li>To create notes and highlights from those interactions;</li>
          <li>
            To create suggestions for action items and end user interactions for
            employees/contractors;
          </li>
          <li>To recommend service approaches and solutions for end user concerns;</li>
          <li>
            To infer interests and sentiment values for improved customer service and marketing;
          </li>
          <li>To assess potential financing options;</li>
          <li>
            To operate, customize, and improve our products and services, including for analytics;
          </li>
          <li>
            To send updates, promotions, and marketing materials that may be of interest to our
            clients and other individuals about our products and the products of third parties;
          </li>
          <li>To comply with the law and to maintain the security of our services; or</li>
          <li>
            With the consent of client end user or employee/contractor, or as otherwise disclosed at
            the time information is collected.
          </li>
          <p>We may share information we collect and generate with other parties, including:</p>
          <li>
            With third-party service providers who process information on our behalf, such as
            payment processors, data hosting services, transcription services, messaging services,
            and email service providers;
          </li>
          <li>
            With our clients and their technicians and employees/contractors to provide our
            services;
          </li>
          <li>With any affiliates or joint venture partners that we may have in the future;</li>
          <li>
            For advertising and marketing services on our behalf and on for delivering messages for
            other products and services that may be of interest to you;
          </li>
          <li>For market research and product development;</li>
          <li>
            As part of a corporate sale, merger, or acquisition, or other transfer of all or part of
            our assets, including as part of a bankruptcy proceeding;
          </li>
          <li>
            Pursuant to a subpoena, court order, governmental inquiry, or other legal process or as
            otherwise required by law, to protect our rights or the rights of third parties, or to
            protect the safety or security of any person or entity;
          </li>
          <li>
            With the consent of client end user or employee/contractor or as otherwise disclosed at
            the time of data collection or sharing. We may share information that has been
            de-identified or aggregated without limitation.
          </li>
          <p>
            We use a variety of tools provided by us, or service providers, and third-party partners
            to collect and analyze information for our services. These tools may include machine
            learning and automated decision-making tools that help us assess various data points to
            provide services to our clients, customize services and recommendations, and derive new
            insights to customize our and others’ marketing.
          </p> */}
          <p>
            <b>
              <u>Changes to Our Privacy Policy</u>
            </b>
          </p>
          <p>
            If our information practices change, we will post these changes on this page. We
            encourage you to visit this page periodically to learn of any updates.
          </p>
          <p>
            <b>
              <u>Updating Your Information</u>
            </b>
          </p>
          <p>
            <b>
              <u>Contact</u>
            </b>
          </p>
          <p>
            If you have questions, comments, or concerns about these privacy policies, please
            contact us at:
          </p>
          <p>privacy@yapi.io</p>
        </div>
      </div>
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background: #ffffff;

  .header-nav {
    width: 100%;
    padding: 10px;
    background: url("https://yapi.io/wp-content/uploads/2023/04/background.png");
    ${"" /* background-color: #101010; */}
  }
  .header-logo {
    padding-left: 10px;
    img {
      width: 120px;
    }
  }
  .content {
    width: 90%;
    margin: auto;
    margin-top: 30px;

    .title {
      h2 {
        color: #333333;
        font-size: 40px;
        font-family: Lato, sans-serif;
        font-style: normal;
      }
      p {
        margin: 10px 0;
        font-family: Lato, sans-serif;
        font-size: 16px;
      }
    }
    .description {
      p {
        margin: 18px 0;
        font-family: Lato, sans-serif;
        font-size: 16px;
        line-height: 24px;
      }
      li {
        font-family: Lato, sans-serif;
        font-size: 16px;
        line-height: 24px;
        padding-left: 10px;
      }
      a {
        color: #cf337f;
      }
    }
  }
`;
