/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import styled from "styled-components";
import MessengerHeader from "../../components/MessengerHeader";
import MessengerSidenav from "../../components/MessengerSidenav";
import ChatInbox from "../../components/ChatInbox";
import CxcHeader from "../../components/CxcHeader";
import ResponsiveHeader from "../../components/ResponsiveHeader";

export default function IndividualChatMessenger() {
  // const [chatsidenavOpen, setChatsidenavOpen] = useState(true);
  return (
    <>
      <ChatMessengerWrapper>
        {/* <div>
        <ChatMessengerHeader isOpen={chatsidenavOpen}>
          <MessengerHeader
            setChatsidenavOpen={setChatsidenavOpen}
            chatsidenavOpen={chatsidenavOpen}
          />
        </ChatMessengerHeader>
        <ChatMessengerSidenav isOpen={chatsidenavOpen}>
          <MessengerSidenav chatsidenavOpen={chatsidenavOpen} />
        </ChatMessengerSidenav>
      </div> */}

        <ChatMessengerPlayground>
          <ResponsiveHeader />
          <ChatInbox type="individual" />
        </ChatMessengerPlayground>
      </ChatMessengerWrapper>
    </>
  );
}

const ChatMessengerPlayground = styled.div`
  width: 100%;
  height: calc(100vh - 16px);
  background: #fafafa;
  // background: red;
  // border-top-left-radius: 20px;
  // padding:20px 0 0 0;
`;
const ChatMessengerSidenav = styled.div`
  width: ${({ isOpen }) => (isOpen ? "320px" : "60px")};
  height: calc(100vh - 68px);
`;
const ChatMessengerHeader = styled.div`
  width: 100%;
  width: ${({ isOpen }) => (isOpen ? "320px" : "60px")};
  height: 52px;
`;
const ChatMessengerWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 16px);
  background: #ffffff;
  display: flex;
  // @media (max-height: 850px) {
  //   height: 100vh;
  // }
`;
