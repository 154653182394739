import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";

import createSagaMiddleware from "redux-saga";
import SignInSlice from "../Auth/signIn/SignInSlice";
import storage from "redux-persist/lib/storage";
import rootSaga from "../Auth/signIn/LoginSaga";
import WorkSpaceSlice from "../Workspace/WorkSpaceSlice";
import ChatId from "../../module/Messenger/ChatListIdSlice";
import IndividualChatSlice from "../../module/Messenger/IndividualChatSlice";
import GroupChatSlice from "../../module/group/GroupChatSlice";
import PusherSlice from "../../Pusher/PusherSlice";

const reducers = combineReducers({
  LoginSlice: SignInSlice,
  WorkSpaceSlice: WorkSpaceSlice,
  ChatId: ChatId,
  IndividualChat: IndividualChatSlice,
  GroupChat: GroupChatSlice,
  PusherSlice: PusherSlice
});

const persistConfig = {
  key: "yapi4.1",
  storage
};

const saga = createSagaMiddleware();

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(saga)
});
saga.run(rootSaga);

export const persistor = persistStore(store);
