import React from "react";
import { BrowserRouter as Router, Navigate, Outlet, Route, Routes } from "react-router-dom";
import PropTypes from "prop-types";
import { Paths } from "./Paths";
import { PrivateLayout } from "../layouts/privateLayouts/PrivateLayout";
import { PrivatePaths } from "./PrivatePaths";
import { PublicLayout } from "../layouts/publicLayouts/PublicLayout";
import { useSelector } from "react-redux";
import NoActiveWorkspace from "../components/NoActiveWorkspace";
import { UserManagement } from "../module/userManagement/UserManagement";
import { PrivacyPolicy } from "../components/PrivacyPolicy";
import ChatMessenger from "../module/Messenger/ChatMessenger";
import NoJobs from "../components/NoJobs";
import IndividualChatMessenger from "../module/Messenger/IndividualChatMessenger";
import GroupChatMessenger from "../module/group/GroupChatMessenger";
import SetNewPassword from "../app/Auth/Forget/SetNewPassword";
import SetupPassword from "../app/Auth/Forget/SetupPassword";

export function PublicRoute({ isAuthenticated, selectedJob, no_Of_Jobs }) {
  if (isAuthenticated?.user_type) {
    if (
      isAuthenticated?.user_type?.toLowerCase() === "owner" ||
      isAuthenticated?.user_type?.toLowerCase() == "admin"
    ) {
      if (isAuthenticated?.workspace?.tenant_id && isAuthenticated?.workspace?.tenant_id != null) {
        return <Navigate to="/private-layout/workspace/regions" replace />;
      } else {
        return <Navigate to="/private-layout/user-management" replace />;
      }
    } else {
      if (no_Of_Jobs?.length > 0) {
        if (selectedJob) {
          return <Navigate to="/private-layout/chats" />;
        } else {
          return <Navigate to="/private-layout" />;
        }
      } else {
        return <Navigate to="/private-layout/no-jobs" />;
      }
    }
  }
  return <Outlet />;
}

// This function is used for Authentication when user's credentials will be invalid.
function PrivateRoute({ isAuthenticated }) {
  if (!isAuthenticated?.user_type) return <Navigate to="/" />;
  return <Outlet />;
}

export default function AllRoutes() {
  const userDetails = useSelector((state) => state?.LoginSlice?.data);
  const selectedJob = useSelector((state) => state?.ChatId?.job_Id);
  const no_Of_Jobs = useSelector((state) => state?.ChatId?.jobs_Data);
  // const token = 3;

  return (
    <Router>
      <Routes>
        <Route path={"/privacy-policy"} element={<PrivacyPolicy />} />
        <Route path={"/set-new-password"} element={<SetNewPassword />} />
        <Route path={"/setup-password"} element={<SetupPassword />} />
        <Route
          element={
            <PublicRoute
              isAuthenticated={userDetails}
              selectedJob={selectedJob}
              no_Of_Jobs={no_Of_Jobs}
            />
          }>
          <Route path="/" element={<PublicLayout />}>
            {Paths.map((routes, index) => (
              <Route key={index} path={routes.path} element={routes.component} />
            ))}
          </Route>
        </Route>

        <Route element={<PrivateRoute isAuthenticated={userDetails} />}>
          <Route path="/private-layout" element={<PrivateLayout />}>
            {no_Of_Jobs?.length > 0 && selectedJob && (
              <Route path="/private-layout/chats/:id" element={<ChatMessenger />} />
            )}
            <Route path="/private-layout/individual/:id" element={<IndividualChatMessenger />} />
            <Route path="/private-layout/group/:id" element={<GroupChatMessenger />} />
            <Route path="/private-layout/no-jobs" element={<NoJobs />} />
            <Route path="/private-layout/no-chat" element={<NoJobs />} />
            <Route path="/private-layout/no-groups" element={<NoJobs />} />
            {PrivatePaths.map((routes, index) =>
              userDetails?.user_type?.toLowerCase() == "owner" ||
              userDetails?.user_type?.toLowerCase() == "admin" ? (
                userDetails?.workspace?.tenant_id != null ? (
                  <Route key={index} path={`${routes.path}`} element={routes.component}>
                    {Array.isArray(routes?.subTabs) &&
                      routes?.subTabs?.length > 0 &&
                      routes?.subTabs?.map((subRoutes, index) => (
                        <Route
                          key={index}
                          path={`${subRoutes.path}`}
                          element={subRoutes.component}
                        />
                      ))}
                  </Route>
                ) : (
                  <Route key={index} path="user-management" element={<UserManagement />} />
                )
              ) : (
                <Route key={index} path="no-jobs" element={<NoJobs isNotRouteMatched={false} />} />
              )
            )}
            <Route path="*" element={<NoActiveWorkspace isNotRouteMatched={false} />} />
          </Route>
        </Route>

        <Route path="*" element={<NoActiveWorkspace isNotRouteMatched={true} />} />
      </Routes>
    </Router>
  );
}

PublicRoute.propTypes = {
  isAuthenticated: PropTypes.object,
  selectedJob: PropTypes.object,
  no_Of_Jobs: PropTypes.any
};

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.object
};
