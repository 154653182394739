/* eslint-disable react/no-unescaped-entities */
import { Modal } from "antd";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { CreateGroupBg } from "../utils/Images/Images";

export const AddPeopleWithEmail = ({ open, handleClose, setPendingModal }) => {
  return (
    <div>
      <Modal
        prefixCls="group-modal"
        open={open}
        onCancel={handleClose}
        width={976}
        footer={false}
        centered>
        <CreateGroupModalBox>
          <div className="header-banner">
            <img src={CreateGroupBg} />
            <div className="text">
              <h3>Add People</h3>
              <p>
                Enhance your group's collaboration by easily inviting and adding individuals to join{" "}
                <br />
                your team.
              </p>
            </div>
          </div>

          <div className="fields">
            <div className="field-sections">
              <label>EMail Address</label>
              <input type="text" placeholder="Search member by email" />
            </div>
            {/* <div className="field-sections" style={{ textAlign: "center" }}>
              <label>
                <span>or</span>
              </label>
            </div> */}

            {/* <div className="field-sections">
              <div className="private-group">
                <EmailIconSVG />
                <p>Send Email Invitations</p>
              </div>
            </div> */}
          </div>

          <div className="submit-btns">
            <button className="cancel" onClick={() => handleClose()}>
              Cancel
            </button>
            <button
              className="next"
              onClick={() => {
                handleClose();
                setPendingModal(true);
              }}>
              Next
            </button>
          </div>
        </CreateGroupModalBox>
      </Modal>
    </div>
  );
};

AddPeopleWithEmail.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  setPendingModal: PropTypes.func
};

const CreateGroupModalBox = styled.div`
  width: 100%;
  min-height: 350px;
  padding: 32px;
  max-height: 95vh;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 3px;
    height: 2px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
  }
  ::-webkit-scrollbar-thumb {
    background: #cecece;
    border-radius: 10px;
  }

  .header-banner {
    width: 100%;
    background: #3d97f2;
    border-radius: 4px;
    position: relative;
    padding: 10px;
    z-index: 1;

    img {
      width: 100%;
      height: 100%;
      z-index: 2;
    }
    .text {
      position: absolute;
      z-index: 3;
      top: 35px;
      width: 100%;
      text-align: center;

      h3 {
        color: #fff;
        text-align: center;
        font-family: Lato, sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 132%; /* 42.24px */
        letter-spacing: 0.32px;
      }
      p {
        margin-top: 4px;
        color: #fff;
        text-align: center;
        font-family: Lato, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
      }
    }
  }

  .fields {
    width: 100%;
    max-width: 492px;
    margin: 62px auto;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .field-sections {
      width: 100%;

      label {
        color: #3d3d3d;
        font-family: Lato, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.12px;
        text-transform: uppercase;

        span {
          color: #b8b8b8;
          font-family: Lato, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 0.12px;
          text-transform: uppercase;
        }
      }

      .image-size {
        margin-top: 8px;
        color: #b8b8b8;
        font-family: Lato, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      input {
        border-radius: 4px;
        border: 1px solid #f5f5f5;
        background: #fff;
        width: 100%;
        margin-top: 8px;
        padding: 17px 16px;
        font-family: Lato, sans-serif;
        color: #8f8f8f;

        ::placeholder {
          color: #8f8f8f;
          font-family: Lato, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .private-group {
        width: 100%;
        display: flex;
        gap: 8px;
        align-items: center;
        border-radius: 4px;
        background: #ecf5fe;
        padding: 17px 16px;
        cursor: pointer;

        p {
          color: #3d97f2;
          font-family: Lato, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .image-box {
      display: flex;
      gap: 16px;
      align-items: center;
      margin-top: 4px;

      .upload-image {
        border-radius: 4px;
        background: #ecf5fe;
        padding: 16px 32px;
        color: #3d97f2;
        font-family: Lato, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.14px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
      }
    }

    .image-preview {
      width: 56px;
      height: 56px;

      .empty-box {
        width: 100%;
        height: 100%;
        background: #f5f5f5;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .submit-btns {
    display: flex;
    gap: 10px;
    width: 100%;
    max-width: 492px;
    margin: auto;

    .cancel {
      border-radius: 4px;
      background: #f5f5f5;
      width: 100%;
      height: 56px;
      border-style: none;
      color: #3d3d3d;
      text-align: center;
      font-family: Lato, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 124%; /* 19.84px */
      letter-spacing: 0.16px;
      text-transform: uppercase;
      cursor: pointer;
    }
    .next {
      border-radius: 4px;
      background: #3d97f2;
      border-style: none;
      width: 100%;
      height: 56px;
      color: #fff;
      text-align: center;
      font-family: Lato, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 124%;
      letter-spacing: 0.16px;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
`;
