import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { SignInStep, UpdateSteps } from "../signIn/SignInSlice";
import { AppLogo, PublicAppImg } from "../../../utils/Images/Images";
import { Form, Formik } from "formik";
import { Check, Dot, PasswordEye, PasswordEyeOpen } from "../../../utils/Icons/SvgIcons";
import { message } from "antd";
import { resetPassword } from "../../../services/Collections";
import { PublicButton } from "../../../components/PublicButton";
import { PasswordRequirement } from "./SetNewPassword";
import { PublicLayoutWrapper } from "../../../layouts/publicLayouts/PublicLayout";
import { beamsClient } from "../../../Pusher/PusherConfig";
import { groupInitialStateReset } from "../../../module/group/GroupChatSlice";
import { individualInitialStateReset } from "../../../module/Messenger/IndividualChatSlice";
import { chatInitialStateReset, splashLoading } from "../../../module/Messenger/ChatListIdSlice";
import { workSpaceInitialStateReset } from "../../Workspace/WorkSpaceSlice";
import axios from "axios";
import Bowser from "bowser";

export default function SetupPassword() {
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [loading, setLoading] = useState(false);
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [waitForEmptyRedux, setWaitForEmptyRedux] = useState(true);
  const [inputVal, setInputVal] = useState({
    newPassword: "",
    confirmPassword: ""
  });
  const dispatch = useDispatch();
  const [passed, setPassed] = useState({
    min8: false,
    lowercase: false,
    uppercase: false,
    special: false,
    numbers: false,
    consecutive: false
  });
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state?.LoginSlice?.data);
  const location = useLocation();

  const initialValues = {
    newPassword: null,
    confirmPassword: null
  };

  function isIdentical(str) {
    if (str) {
      let pattern = new RegExp("\\b([a-zA-Z0-9])\\1\\1+\\b");
      if (str == null) {
        return false;
      }
      if (pattern.test(str) == true) {
        // return false;
        setPassed((prevState) => ({
          ...prevState,
          consecutive: false
        }));
      } else {
        setPassed((prevState) => ({
          ...prevState,
          consecutive: true
        }));
        // return true;
      }
    } else {
      setPassed({
        min8: false,
        lowercase: false,
        uppercase: false,
        special: false,
        numbers: false,
        consecutive: false
      });
    }
  }

  const validatePassword = (password) => {
    if (password.length > 0) {
      if (password.length >= 6) {
        setPassed((prevState) => ({
          ...prevState,
          min8: true
        }));
      } else {
        setPassed((prevState) => ({
          ...prevState,
          min8: false
        }));
      }
      if (password.search(/[a-z]/) !== -1) {
        setPassed((prevState) => ({
          ...prevState,
          lowercase: true
        }));
      } else {
        setPassed((prevState) => ({
          ...prevState,
          lowercase: false
        }));
      }
      if (password.search(/[A-Z]/) !== -1) {
        setPassed((prevState) => ({
          ...prevState,
          uppercase: true
        }));
      } else {
        setPassed((prevState) => ({
          ...prevState,
          uppercase: false
        }));
      }
      if (password.search(/[0-9]/) !== -1) {
        setPassed((prevState) => ({
          ...prevState,
          numbers: true
        }));
      } else {
        setPassed((prevState) => ({
          ...prevState,
          numbers: false
        }));
      }
      if (password.search(/[^\w]/) !== -1) {
        setPassed((prevState) => ({
          ...prevState,
          special: true
        }));
      } else {
        setPassed((prevState) => ({
          ...prevState,
          special: false
        }));
      }
    } else {
      setPassed({
        min8: false,
        lowercase: false,
        uppercase: false,
        special: false,
        numbers: false,
        consecutive: false
      });
    }
  };

  const handleSubmit = () => {
    if (!inputVal.newPassword) {
      message.error("Password is required");
    } else {
      if (Object?.values(passed).filter((val) => val === false)?.length > 0) {
        message.error("Please Enter valid password");
      } else {
        if (inputVal.confirmPassword) {
          if (inputVal.newPassword == inputVal.confirmPassword) {
            handleSetNewPassword(inputVal?.confirmPassword);
          } else {
            message.error("Confirm password does not match");
          }
        } else {
          message.warning("Confirm password is required");
        }
      }
    }
  };

  const handleSetNewPassword = async (password) => {
    const deviceType = Bowser.parse(window.navigator.userAgent);
    setLoading(true);
    let obj = {
      newPassword: password
    };
    // let object = {
    //   header: "Congratulations!",
    //   subheader: "Your password has been changed successfully."
    // };
    let res = await resetPassword(obj);
    if (res?.status === 200) {
      setLoading(false);
      if (deviceType?.platform?.type == "desktop") {
        navigate("/");
      } else {
        handleBranchIo();
      }
      message.success(
        res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
      );
      //   success(messageApi, res?.message);
    } else {
      setLoading(false);
      message.error(
        res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
      );
      //  let errorMessage =
      //    res?.response?.data?.message || res?.message || res?.error || "Something went wrong";
      //  error(messageApi, errorMessage);
    }
  };

  const handleLogout = () => {
    beamsClient
      .start()
      .then(() => beamsClient.removeDeviceInterest(`user_${userDetails?._id}`))
      .then(() => beamsClient.clearDeviceInterests())
      .then(() => console.log("Successfully registered and subscribed!"))
      .catch(console.error);

    dispatch(SignInStep(null));
    dispatch(UpdateSteps(0));
    dispatch(groupInitialStateReset(true));
    dispatch(individualInitialStateReset(true));
    dispatch(chatInitialStateReset(true));
    dispatch(workSpaceInitialStateReset(true));
    setWaitForEmptyRedux(false);
  };

  const handleBranchIo = async () => {
    // const key = process.env.REACT_APP_BRANCH_IO_LIVE_KEY;
    const key = "key_live_gtlhU4AZ1sepdc4Y1fzebjoavyl66W1E";
    await axios
      .post(
        "https://api.branch.io/v1/url",
        {
          branch_key: key
        },
        {
          headers: {
            accept: "application/json",
            "content-type": "application/json"
          }
        }
      )
      .then(function (response) {
        if (response?.data?.url) {
          // let a = document.createElement("a");
          // a.setAttribute("href", response?.data?.url);
          // a.setAttribute("target", "_blank");
          // a.setAttribute("rel", "noreferrer");
          // a.click();
          // a.removeChild();
          window.location.href = response.data.url;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    dispatch(splashLoading(false));
    if (!waitForEmptyRedux) {
      let url = window.location;
      let token = url?.search.split("=");
      let obj = {
        token: token?.[1]
      };
      dispatch(SignInStep(obj));
    }
  }, [waitForEmptyRedux]);

  useEffect(() => {
    if (userDetails?._id) {
      handleLogout();
    } else {
      setWaitForEmptyRedux(false);
    }
  }, []);

  if (waitForEmptyRedux) {
    return <div>Loading...</div>;
  }

  return (
    <PublicLayoutWrapper isBackgroundApply={location?.pathname?.includes("/setup-password")}>
      <div className="Outlet-box">
        <ForgetWrapper>
          <section>
            <img src={AppLogo} />
            <div>
              <header>Setup Password</header>
              {/* <subheader style={{ fontWeight: "400", lineHeight: "150%" }}>
            New password must be different than previous password{" "}
          </subheader> */}
              <div className="inner-Section">
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  render={({ setFieldValue }) => (
                    <Form>
                      <div
                        style={{ position: "relative", display: "flex", flexDirection: "column" }}>
                        <label>New Password</label>
                        <input
                          type={newPasswordType}
                          placeholder="Enter new password"
                          name="newPassword"
                          onChange={(e) => {
                            setFieldValue("newPassword", e.target.value);
                            validatePassword(e.target.value);
                            isIdentical(e.target.value);
                            setInputVal((prevState) => ({
                              ...prevState,
                              newPassword: e.target.value
                            }));
                          }}
                        />

                        <PasswordEyeWrapper
                          onClick={() =>
                            newPasswordType == "password"
                              ? setNewPasswordType("text")
                              : setNewPasswordType("password")
                          }>
                          {newPasswordType == "password" ? <PasswordEye /> : <PasswordEyeOpen />}
                        </PasswordEyeWrapper>
                      </div>

                      <div
                        style={{ position: "relative", display: "flex", flexDirection: "column" }}>
                        <label>Confirm Password</label>

                        <input
                          type={confirmPasswordType}
                          placeholder="Repeat new password"
                          name="confirmPassword"
                          onChange={(e) => {
                            setFieldValue("confirmPassword", e.target.value);
                            setInputVal((prevState) => ({
                              ...prevState,
                              confirmPassword: e.target.value
                            }));
                          }}
                        />

                        <PasswordEyeWrapper
                          onClick={() =>
                            confirmPasswordType == "password"
                              ? setConfirmPasswordType("text")
                              : setConfirmPasswordType("password")
                          }>
                          {confirmPasswordType == "password" ? (
                            <PasswordEye />
                          ) : (
                            <PasswordEyeOpen />
                          )}
                        </PasswordEyeWrapper>
                      </div>

                      <PasswordRequirement>
                        <p>Password requirements :</p>
                        <div>
                          <ul className="password-validations">
                            <li className={passed?.min8 && "active"}>
                              {" "}
                              {passed?.min8 ? <Check /> : <Dot />} Min. 8 characters
                            </li>
                            <li className={passed?.lowercase && "active"}>
                              {" "}
                              {passed?.lowercase ? <Check /> : <Dot />} Include lowercase characters
                            </li>
                            <li className={passed?.uppercase && "active"}>
                              {" "}
                              {passed?.uppercase ? <Check /> : <Dot />} Include uppercase characters
                            </li>
                            <li className={passed?.special && "active"}>
                              {" "}
                              {passed?.special ? <Check /> : <Dot />} Include special characters
                            </li>

                            <li className={passed?.numbers && "active"}>
                              {" "}
                              {passed?.numbers ? <Check /> : <Dot />} Include numbers
                            </li>
                          </ul>

                          {/* <ul className="password-validations">
                            <li className={passed?.consecutive && "active"}>
                              {" "}
                              {passed?.consecutive ? <Check /> : <Dot />} Don’t use identical
                              characters
                            </li>
                            <li className={passed?.consecutive && "active"}>
                              {passed?.consecutive ? <Check /> : <Dot />} Don’t use consecutive
                              characters
                            </li>
                          </ul> */}
                        </div>
                      </PasswordRequirement>

                      <div
                        style={{ margin: "35px 0", marginBottom: "0px" }}
                        className="btn-wrapper">
                        <PublicButton isLoading={loading} textcard={"Set Password"} />
                      </div>
                    </Form>
                  )}></Formik>
              </div>
            </div>
          </section>
        </ForgetWrapper>
      </div>
      <div className="background-img-box">
        <img src={PublicAppImg} alt="" />
      </div>
    </PublicLayoutWrapper>
  );
}

const PasswordEyeWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 59px;
  cursor: pointer;
  height: 20px;

  img {
    width: 20px !important;
    height: 16px !important;
    top: 0px;
    margin-bottom: 0px !important;
  }
  svg {
    margin-top: 0px !important;
    top: 0px;
    margin-bottom: 0px !important;
  }
`;

export const ForgetWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme?.secondaryColor};
  padding-top: 64px 0;

  @media only screen and (min-width: 480px) and (max-width: 834px) {
    align-items: unset;
    height: 100%;
    min-height: 618px !important;
  }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    align-items: unset;
    height: fit-content;
    min-height: 513px;
  }
  @media only screen and (max-height: 860px) {
    height:100%;
    overflow-y: scroll;
    overflow-x: hidden;
    ::-webkit-scrollbar {
      width: 0;
      height: 2px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #fff;
    }
    ::-webkit-scrollbar-thumb {
      background: #101010;
      border-radius: 10px;
    }
  }
  @media only screen and (max-height: 850px) {
    padding: 20px 0;
  }

  section > div {
    padding-bottom: 30px;
  }
  section {
    max-width: 480px;
    width: 100%;
    padding: 64px 0;
    @media (max-width: 1440px) {
      width: 75%;
    }
    @media (max-height: 850px) {
      height: 100%;
    }
    @media (min-width: 480px) and (max-width: 834px) {
      padding: 0px !important;
      margin-bottom: 30px;
    }
    @media (min-width: 320px) and (max-width: 480px) {
      width: 87%;
      margin-top: 51px;
      padding: 0px !important;
      margin-bottom: 30px;
    }

    img {
      width: 112.69px;
      height: 48.1px;

      @media (min-width: 480px) and (max-width: 834px) {
        margin-top: 57px;
      }
      @media (min-width: 320px) and (max-width: 480px) {
        /* margin-bottom: 32px; */
      }
      @media (max-height: 850px) {
        /* margin-bottom: 32px; */
      }
    }

    header {
      font-family: Lato, sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 125%;
      color: ${({ theme }) => theme?.primaryColor};
      margin: 16px 0;

      @media (min-width: 320px) and (max-width: 480px) {
        margin-top: 0px;
      }
    }

    subheader {
      font-family: Lato, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
      letter-spacing: 0.2px;
      /* color: ${({ themeValue }) => (themeValue == "day" ? "#000" : "#fff")}; */
      color: rgb(184, 184, 184);
    }

    .inner-Section {
      display: flex;
      flex-direction: column;
      margin: 32px 0;

      @media (min-width: 480px) and (max-width: 834px) {
        margin-bottom: 0px;
      }
      @media (min-width: 320px) and (max-width: 480px) {
        margin-bottom: 0px;
      }

      label {
        font-family: Lato, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%;
        color: #000;
        padding: 5px 0;
        margin-bottom: 4px;
        padding-top: 13px;
      }

      input {
        max-width: 480px;
        width: 100%;
        height: 56px;
        filter: drop-shadow(0px 2px 12px rgba(16, 24, 40, 0.06));
        /* background: ${({ themeValue }) => (themeValue == "day" ? "#fff" : "transparent")}; */
        background: white;
        /* color: ${({ themeValue }) => (themeValue == "day" ? "#000" : "#fff")}; */
        border: 1px solid transparent;
        width: 100%;
        height: 56px;
        filter: drop-shadow(rgba(16, 24, 40, 0.06) 0px 2px 12px);
        padding: 16px 20px;
        border-radius: 10px;
        font-family: Lato, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;

        :focus {
          outline: none;
          /* border: ${({ themeValue }) =>
    themeValue == "day" ? "1px solid #00A1E6" : "1px solid #00A1E6"} !important; */
        }
        ::placeholder {
          /* color: ${({ themeValue }) =>
    themeValue == "day" ? "#424B57" : "#535E6D"} !important; */
        }
        /* :hover {
          border: ${({ themeValue }) =>
    themeValue == "day" ? "1px solid #000" : "1px solid #FFF"};
        } */
      }
    }
  }
`;
