import { Modal, message } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import InputField from "../../validation/inputField";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import PropTypes from "prop-types";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileStatus } from "../../services/Collections";
import { SignInStep } from "../Auth/signIn/SignInSlice";
import { useNavigate } from "react-router-dom";

export default function InviteCRM({ open, handleModal }) {
  const userDetails = useSelector((state) => state?.LoginSlice?.data);
  const workspaceDetails = useSelector((state) => state?.WorkSpaceSlice?.workSpaceData);
  const [loading, setLoading] = useState(false);
  // console.log(loading, workspaceDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // console.log(userDetails, "userDetails", workspaceDetails);

  const initialValues = {
    clientid: "",
    clientsecret: "",
    tenantid: "",
    appkey: ""
  };

  const validationSchema = yup.object().shape({
    clientid: yup.string().required("Client ID is required"),
    clientsecret: yup.string().required("Client Secret is required"),
    tenantid: yup.string().required("Tenant ID is required"),
    appkey: yup.string().required("App Key is required")
  });

  const FormikFieldValues = [
    {
      label: "Client ID",
      name: "clientid",
      type: "text",
      placeholder: "Enter client ID",
      component: InputField
    },
    {
      label: "Client Secret",
      name: "clientsecret",
      type: "text",
      placeholder: "Enter client secret",
      component: InputField
    },

    {
      label: "Tenant ID",
      name: "tenantid",
      type: "text",
      placeholder: "Enter tenant ID",
      component: InputField
    },

    {
      label: "App Key",
      name: "appkey",
      type: "text",
      placeholder: "Enter app Key",
      component: InputField
    }
  ];

  const handleCreateWorkspace = async (values) => {
    // console.log(values);

    setLoading(true);
    let reqPayload = {
      client_secret: values?.clientsecret,
      userid: userDetails?._id,
      tenantid: Number(values?.tenantid),
      app_key: values?.appkey,
      client_id: values?.clientid,
      username: workspaceDetails?.user_name,
      usertype: workspaceDetails?.user_type,
      workspacename: workspaceDetails?.workspace?.workspace_name,
      app_type: "yapi",
      workspaceid: workspaceDetails?.workspace_id
    };
    const username = "adlevapis";
    const password = "mw8OHBjzN856XrikRDeX19W028s7cvEo";
    const btoaToken = btoa(`${username}:${password}`);
    const token = "Basic " + btoaToken;
    let res = await axios.post(
      "https://gkp16r6ztc.execute-api.us-west-2.amazonaws.com/prod/newclient",

      reqPayload,
      { headers: { Authorization: token } }
    );
    // console.log(res, "res");
    if (res?.data?.statusCode === 200 || res?.message == "OK") {
      message.info(res?.data?.status || "Successfully connected");
      handleSubmit(Number(values?.tenantid));
      setLoading(false);

      // navigate("/");
    } else {
      message.error(res?.data?.status || "Something went wrong", 10);
      setLoading(false);
    }
  };

  const handleSubmit = async (tenantId) => {
    setLoading(true);
    let obj = {
      tenant_id: tenantId,
      workspace_id: workspaceDetails?.workspace_id
      // connectionStatus: true
    };
    let res = await updateProfileStatus(obj);
    if (res?.status === 200) {
      let obj = { ...userDetails, ...res?.data };
      // console.log(obj, "objkeccccccccccccccccc");
      dispatch(SignInStep(obj));
      navigate("/private-layout/workspace/regions");
      setLoading(false);
      handleModal();
      // navigate("/connect-slack");
    } else {
      setLoading(false);
      message.error(
        res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
      );
    }
  };

  return (
    <Modal open={open} centered width={474} footer={false} onCancel={handleModal}>
      <ConnectModalWrapper>
        <Formik
          isInitialValid={false}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleCreateWorkspace}>
          <Form>
            <div className="content">
              {FormikFieldValues &&
                FormikFieldValues?.map((field) => (
                  <InputWrapper key={field.name}>
                    <label>{field?.label}</label>
                    <Field
                      name={field?.name}
                      type={field?.type}
                      placeholder={field?.placeholder}
                      component={field?.component}
                    />
                  </InputWrapper>
                ))}
            </div>

            <div className="btn-wrapper">
              {loading ? <span>Loading....</span> : <button type="submit">INVITE</button>}
            </div>
          </Form>
        </Formik>
      </ConnectModalWrapper>
    </Modal>
  );
}

InviteCRM.propTypes = {
  open: PropTypes.bool,
  handleModal: PropTypes.func
};

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 8px 0;

  label {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #101010;
    margin-bottom: 8px;
  }

  input {
    height: 52px;
    background: #f5f6f7;
    border-radius: 500px;
    border-style: none;
    padding-left: 16px;
    margin: 8px 0 16px 0;
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #000000;

    :focus {
      outline: none;
    }
  }
`;

const ConnectModalWrapper = styled.div`
  width: 100%;
  padding-bottom: 10px;

  .title {
    width: 100%;

    h3 {
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: #101010;
    }
  }

  .content {
    width: 100%;
    margin: 24px 0;
  }

  .btn-wrapper {
    width: 100%;
    margin-top: 8px;

    span {
      width: 100%;
      height: 56px;
      background: #3d97f2;
      border-radius: 500px;
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      border-style: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }

    button {
      width: 100%;
      height: 56px;
      background: #3d97f2;
      border-radius: 500px;
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      border-style: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
  }
`;
