import { Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
  addMemberToGroup,
  addRemoveFromJobs,
  getAllUsersListing
} from "../../services/Collections";
import { useSelector } from "react-redux";
import { NoManager } from "../../utils/Images/Images";
import { ModalLoader } from "../../components/ModalLoader";
import { useLocation } from "react-router-dom";

// import { assignManager, getManagerListing } from "../../services/Collections";
// import { useSelector } from "react-redux";
// import { ModalLoader } from "../../components/ModalLoader";
// import { NoManager } from "../../utils/Images/Images";
// import { activeWorkSpace } from "../../utils/common/commonParameter";

export const AddMembers = ({ handleModal, open, data, jobInfoApi }) => {
  const [loading, setLoading] = useState(true);
  const userDetails = useSelector((state) => state?.LoginSlice?.data);
  const [membersList, setMembersList] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [searchWord, setSearchWord] = useState("");
  const [searchdata, setSeatchdata] = useState([]);
  const [addloading, setAddloading] = useState(false);
  const location = useLocation();
  const selectedGroupchat = useSelector((state) => state?.GroupChat?.selectedChat);

  const handleUserListing = async () => {
    setLoading(true);
    let params = new URLSearchParams();
    params.append("workspace_id", userDetails?.workspace_id);
    let res = await getAllUsersListing(params.toString());
    if (res?.status === 200) {
      let filteredArray = [];

      if (Array.isArray(data?.userData) && data?.userData?.length > 0) {
        res?.data?.length > 0 &&
          res?.data?.map((val) => {
            let updated = data?.userData?.some((el) => el?._id == val?._id);
            if (!updated) {
              filteredArray.push(val);
            }

            // console.log(updated, "updatedupdated");
          });
      }

      setMembersList(filteredArray);

      setLoading(false);
    } else {
      setLoading(false);
      message.error(
        res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
      );
    }
  };

  const searchManager = (word) => {
    setSearchWord(word);
    if (word) {
      let updated = membersList?.filter((el) =>
        el?.name?.toLowerCase()?.includes(word.toLowerCase())
      );

      setSeatchdata(updated);
    } else {
      setSeatchdata([]);
    }
  };

  const handleAddRem = (obj, type) => {
    // console.log(type, "type");
    if (type) {
      if (selectedRowsData?.length > 0) {
        if (Array.isArray(selectedRowsData)) {
          if (selectedRowsData.find((el) => el?._id === obj?._id)) {
            return selectedRowsData.map((el) => {
              if (el._id === obj._id) {
                setSelectedRowsData(obj);
              }
              setSelectedRowsData(el);
            });
          } else {
            setSelectedRowsData([...selectedRowsData, obj]);
          }
        } else {
          setSelectedRowsData([obj]);
        }
      } else {
        setSelectedRowsData([obj]);
      }
    } else {
      let arr = selectedRowsData.filter((el) => el?._id !== obj?._id);
      setSelectedRowsData(arr);
    }
  };

  const handleAddMemberInJob = async () => {
    if (selectedRowsData?.length > 0) {
      let arr = [];
      selectedRowsData?.map((el) => {
        arr.push(el?._id);
      });
      setAddloading(true);
      let requestPayload = {
        job_id: location?.pathname?.includes("group") ? selectedGroupchat?.id : data?._id,
        user_ids: arr.toString(),
        type: 1,
        workspace_id: userDetails?.workspace?._id,
      };
      let res;
      if (location?.pathname?.includes("group")) {
        res = await addMemberToGroup(requestPayload);
      } else {
        res = await addRemoveFromJobs(requestPayload);
      }
      if (res?.status == 200) {
        message.success(res?.message);
        handleModal();
        jobInfoApi();
        setAddloading(false);
      } else {
        setAddloading(false);
        message.error(
          res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
        );
      }
    } else {
      message.warning("Please select members first");
    }
  };

  const GenerateAvatarName = (payload) => {
    let name = payload?.toString();

    let first = name?.split(" ")?.[0]?.slice(0, 1);
    return first;
  };

  useEffect(() => {
    handleUserListing();
  }, []);

  return (
    <div>
      <Modal open={open} centered width={421} footer={false} onCancel={handleModal}>
        <AssignManagerWrapper>
          <>
            <div className="title">
              <h3>Add to {data?.name}</h3>
              <p>Add Member to this {location?.pathname?.includes("group") ? "Group" : "Job"}</p>
            </div>
            <div className="search-bar">
              <input
                type="text"
                placeholder="Type user name"
                onChange={(e) => searchManager(e.target.value)}
              />
            </div>
            <section>
              {!loading ? (
                <>
                  {searchdata.length > 0 || searchWord ? (
                    <>
                      {searchdata?.length > 0 ? (
                        searchdata?.map((el, idx) => (
                          <div key={idx} className="manager-listing">
                            <div className="img-flex">
                              {el?.profile_url ? (
                                <img src={el?.profile_url} alt="" />
                              ) : (
                                <div className="defaultAvatar">
                                  <h5>{GenerateAvatarName(el?.name)?.toUpperCase()}</h5>
                                </div>
                              )}

                              <h3>{el?.name}</h3>
                            </div>
                            <div className="radio-flex">
                              <input
                                value={el?._id}
                                type="checkbox"
                                name="manager"
                                checked={selectedRowsData.some((val) => val?._id == el?._id)}
                                onChange={(e) => handleAddRem(el, e.target.checked)}
                              />
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="no-manager">
                          <img src={NoManager} alt="" />
                          <p>No Search found</p>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {membersList?.length > 0 &&
                        membersList?.map((el, idx) => (
                          <div key={idx} className="manager-listing">
                            <div className="img-flex">
                              {el?.profile_url ? (
                                <img src={el?.profile_url} alt="" />
                              ) : (
                                <div className="defaultAvatar">
                                  <h5>{GenerateAvatarName(el?.name)?.toUpperCase()}</h5>
                                </div>
                              )}

                              <h3>{el?.name}</h3>
                            </div>
                            <div className="radio-flex">
                              <input
                                //   value={ele?.manager}
                                value={el?._id}
                                type="checkbox"
                                checked={selectedRowsData.some((val) => val?._id == el?._id)}
                                onChange={(e) => handleAddRem(el, e.target.checked)}
                              //   onChange={(e) => setvalues(e?.target?.value)}
                              />
                            </div>
                          </div>
                        ))}
                    </>
                  )}
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%"
                  }}>
                  <ModalLoader size={45} color={"#00adff"} />
                </div>
              )}
            </section>

            <div className="btn-wrapper">
              {addloading ? (
                <button>Loading...</button>
              ) : (
                <button onClick={handleAddMemberInJob}>Add Members</button>
              )}
            </div>
          </>

          {/* <div className="loader-box">
            <ModalLoader size={45} color={"#1a1b55"} />
          </div> */}
        </AssignManagerWrapper>
      </Modal>
    </div>
  );
};

AddMembers.propTypes = {
  handleModal: PropTypes.string,
  open: PropTypes.boolean,
  data: PropTypes.any,
  jobInfoApi: PropTypes.any
};

const AssignManagerWrapper = styled.div`
  width: 100%;

  .loader-box {
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title {
    width: 100%;

    h3 {
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: #101010;
    }
    p {
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #4b5563;
      margin-top: 8px;
    }
  }
  .search-bar {
    width: 100%;
    margin-top: 24px;

    input {
      width: 100%;
      height: 52px;
      background: #f2f4f5;
      border-radius: 500px;
      border-style: none;
      font-family: Lato, sans-serif;
      font-style: normal;
      padding-left: 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #8e959d;
      :focus {
        outline: none;
      }
    }
  }

  section {
    margin-top: 30px;
    overflow-y: scroll;
    height: 340px;
    padding: 0 10px 0 0;

    ::-webkit-scrollbar {
      width: 3px;
      height: 2px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #fff;
    }
    ::-webkit-scrollbar-thumb {
      background: #cecece;
      border-radius: 10px;
    }

    .manager-listing {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 14px 0;

      .img-flex {
        display: flex;
        align-items: center;
        gap: 12px;

        img {
          width: 40px;
          height: 40px;
          border-radius: 5px;
        }
        .defaultAvatar {
          width: 40px;
          height: 40px;
          border-radius: 5px;
          background: #09b3f324;
          color: #00adff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 19px;
        }
        h3 {
          font-family: Lato, sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          color: #101010;
        }
      }
      .radio-flex {
        display: flex;
        align-items: center;

        input {
          width: 15px;
          height: 15px;
          accent-color: #000000;
        }
      }
    }
  }

  .no-manager {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 53px;

    img {
      height: 188px;
      width: 188px;
    }

    p {
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #101010;
      margin-top: 23px;
    }
  }

  .btn-wrapper {
    width: 100%;
    margin-top: 38px;

    button {
      width: 100%;
      background: #000000;
      border-radius: 500px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      border-style: none;
    }
  }
`;
