import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Search } from "../utils/Icons/SvgIcons";
import ChatList from "../components/ChatList";
import { ModalLoader } from "../components/ModalLoader";
import { useSelector } from "react-redux";

const SearchModal = ({
  openSearch,
  handlesetOpenSearch,
  activeSearchBtn,
  setActiveSearchBtn,
  searchLoading,
  globalSearchData,
  optimizedFn,
  handleTabsSwitch,
  handleGlobalSearch,
  setSearchLoading
}) => {
  const [chatInitiate, setChatInitiate] = useState(false);
  const connectedWorkspace = useSelector((state) => state?.LoginSlice?.data);

  useEffect(() => {
    handleGlobalSearch("", activeSearchBtn, connectedWorkspace);
    setSearchLoading(true);
  }, []);

  return (
    <Modal
      open={openSearch}
      closable={false}
      onCancel={() => handlesetOpenSearch(false)}
      centered={true}
      footer={null}
      title={null}
      closeIcon={<span></span>}
      width={"450px"}
      prefixCls="search-modal">
      <SearchModalStyle>
        {chatInitiate && (
          <div className="splash-screen">
            <div style={{ display: "grid", placeItems: "center", height: "100%" }}>
              <ModalLoader color={"#000"} size={32} />
            </div>
          </div>
        )}

        <div className="search-input">
          <input
            id="Search"
            type="search"
            placeholder="Search"
            onChange={(e) => optimizedFn(e?.target?.value, activeSearchBtn, connectedWorkspace)}
          />
          <i className="searchIconWrap">
            <Search />
          </i>
        </div>
        <div className="search-nav">
          <button
            className={activeSearchBtn == 1 && "active"}
            onClick={() => {
              handleTabsSwitch(activeSearchBtn, 1);
              setActiveSearchBtn(1);
            }}>
            Jobs
          </button>
          <button
            className={activeSearchBtn == 3 && "active"}
            onClick={() => {
              handleTabsSwitch(activeSearchBtn, 3);
              setActiveSearchBtn(3);
            }}>
            Group Chat
          </button>
          <button
            className={activeSearchBtn == 2 && "active"}
            onClick={() => {
              handleTabsSwitch(activeSearchBtn, 2);
              setActiveSearchBtn(2);
            }}>
            Team Chat
          </button>
        </div>
        <div className="search-result">
          <div className="search-content">
            {searchLoading ? (
              <div style={{ display: "grid", placeItems: "center", height: "100%" }}>
                <ModalLoader color={"#000"} size={32} />
              </div>
            ) : (
              <div>
                {globalSearchData?.length > 0 ? (
                  globalSearchData?.map((val, index) => (
                    <ChatList
                      key={index}
                      jobId={val._id}
                      chatData={val}
                      activeJob={null}
                      type={val?.type ?? 2}
                      search={true}
                      globalSearch={true}
                      handleModal={handlesetOpenSearch}
                      setChatInitiate={setChatInitiate}
                    />
                  ))
                ) : (
                  <div className="empty-data">
                    <h3>No Data</h3>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </SearchModalStyle>
    </Modal>
  );
};
export default SearchModal;

SearchModal.propTypes = {
  openSearch: PropTypes.bool,
  handlesetOpenSearch: PropTypes.func,
  optimizedFn: PropTypes.func,
  setActiveSearchBtn: PropTypes.func,
  activeSearchBtn: PropTypes.number,
  searchLoading: PropTypes.bool,
  globalSearchData: PropTypes.array,
  handleTabsSwitch: PropTypes.func,
  handleGlobalSearch: PropTypes.func,
  setSearchLoading: PropTypes.func
};

const SearchModalStyle = styled.div`
  width: 100%;
  height: 80vh;
  overflow: hidden;
  position: relative;
  background: #5a1f5c;

  .splash-screen {
    position: absolute;
    z-index: 9;
    width: 100%;
    height: 100%;
    background: #cecece8c;
  }

  .empty-data {
    display: flex;
    height: 300px;
    justify-content: center;
    align-items: center;
  }

  .search-input {
    position: relative;
    display: flex;
    justify-content: center;
    height: fit-content;
    width: 100%;
    margin-top: 15px;

    input {
      background: #fff;
      border: 1px solid transparent;
      height: 40px;
      width: 95%;
      padding: 0px 35px 0 35px;
      color: #000;
      border: 1px solid transparent;
      border-bottom: 1px solid rgb(151 151 151 / 53%);
      outline: none;
      border-radius: 10px;
    }
    .searchIconWrap {
      position: absolute;
      left: 32px;
      top: 23px;
      transform: translate(-50%, -50%);

      svg {
        width: 15px;
        path {
          /* stroke: white; */
        }
      }
    }
  }
  .search-nav {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: 10px 0;
    margin-top: 10px;
    background: #5a1f5c;

    button {
      background: #5a1f5c;
      color: white;
      width: fit-content;
      padding: 5px 15px;
      border-radius: 10px;
      border: 1px solid transparent;
      cursor: pointer;
    }
    .active {
      background: white;
      color: #5a1f5c;
    }
  }
  .search-content {
    width: 100%;
    height: 100%;
    max-height: 61vh;
    overflow: auto;
    ::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }

    ::-webkit-scrollbar-track {
      ${"" /* box-shadow: inset 0 0 5px #fff; */}
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #cecece;
      border-radius: 10px;
    }
  }
  .search-result {
    background: white;
    width: 100%;
    height: 100%;
    border-radius: 7px;
    padding: 10px 20px;
  }
  #Search::-webkit-search-cancel-button {
    position: relative !important;
    right: -22px !important;
  }
`;
