import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  job_Id: null,
  recentChat: [],
  unReadCount: [],
  jobs_Data: [],
  splashLoader: false
};

const ChatListIdSlice = createSlice({
  name: "chatid",
  initialState: initialState,
  reducers: {
    ChatId: (state, action) => {
      if (action.payload === null) {
        state.job_Id = null;
      } else {
        state.job_Id = action.payload;
      }
    },
    recentChatHistory: (state, action) => {
      if (action.payload) {
        state.recentChat = action.payload;
      }
    },
    receviedNewMessage: (state, action) => {
      if (action.payload) {
        let newArr = [];
        // let updatedArr = [];
        for (let i = 0; i < state.recentChat.length; i++) {
          if (state.recentChat[i]?.local_id == action?.payload?.local_id) {
            newArr.push(action?.payload);
          } else {
            newArr?.push(state.recentChat[i]);
          }
        }
        state.recentChat = newArr;

        let checkingDulicateMessge = state.recentChat?.some(
          (el) => el?._id === action.payload?._id
        );
        if (!checkingDulicateMessge) state.recentChat.unshift(action.payload);
      }
    },
    unReadCountMessage: (state, action) => {
      state.unReadCount = action.payload;
    },
    JobsData: (state, action) => {
      // state.jobs_Data = action?.payload;
      if (Array?.isArray(action?.payload)) {
        state.jobs_Data = action?.payload;
      } else {
        let tempArray = [];
        for (let i = 0; i < state.jobs_Data?.length; i++) {
          if (state.jobs_Data?.[i]?._id !== action?.payload?._id) {
            tempArray.push(state.jobs_Data[i]);
          }
        }
        state.jobs_Data = [action?.payload, ...tempArray];
      }
    },
    splashLoading: (state, action) => {
      state.splashLoader = action.payload;
    },
    chatInitialStateReset: (state, action) => {
      if (action.payload) {
        state.job_Id = null;
        state.recentChat = [];
        state.unReadCount = [];
        state.jobs_Data = [];
        state.splashLoader = true;
      }
    }
  }
});

export const {
  ChatId,
  recentChatHistory,
  receviedNewMessage,
  unReadCountMessage,
  JobsData,
  splashLoading,
  chatInitialStateReset
} = ChatListIdSlice.actions;

export default ChatListIdSlice.reducer;
