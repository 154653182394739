import React, { useState } from "react";
import styled from "styled-components";
import {
  DeleteChatIcon,
  JobsProfileIcon,
  LeaveGroupIconSVG,
  ThreeDot,
  ViewJobInfoIcon,
  ViewProfileIcon
} from "../utils/Icons/SvgIcons";
import PropTypes from "prop-types";
import { Popover, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { JobInfoModal } from "../module/jobManagement/JobInfo";
import CxcHeader from "./CxcHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { ProfileInfo } from "../module/Messenger/ProfileInfo";
import { addMemberToGroup, deleteGroup } from "../services/Collections";
import { LeaveGroupModal } from "./LeaveGroupModal";
import { groupsData, groupSelectedChat } from "../module/group/GroupChatSlice";
// import TimeAgo from "timeago-react";

export default function ChatInboxHeader({ name, image }) {
  const [infoData, setInfoData] = useState(null);
  const [openInfo, setOpenInfo] = useState(false);
  const currentLoginSlice = useSelector((state) => state?.LoginSlice?.data);
  const _id = useSelector((state) => state?.ChatId?.job_Id?.id);

  const [viewInfo, setViewInfo] = useState(false);
  const location = useLocation();
  const [profileInfo, setProfileInfo] = useState(false);
  const selectedchat = useSelector((state) => state?.IndividualChat?.selectedChat);
  const selectedGroupChat = useSelector((state) => state?.GroupChat?.selectedChat);
  const [removeLoading, setRemoveloading] = useState(false);
  const navigate = useNavigate();
  const [leaveGroupModal, setLeaveGroupModal] = useState(false);
  const [deleteGroupModal, setDeleteGroupModal] = useState(false);
  const dispatch = useDispatch();
  const groupData = useSelector((state) => state?.GroupChat?.groups_Data);

  // eslint-disable-next-line no-unused-vars
  const handleAddMemberInGroup = async () => {
    setRemoveloading(true);
    let requestPayload = {
      job_id: selectedGroupChat?.id ?? selectedGroupChat?._id,
      user_ids: currentLoginSlice?._id,
      type: 2,
      workspace_id: currentLoginSlice?.workspace?._id,
    };
    let res = await addMemberToGroup(requestPayload);
    if (res?.status == 200) {
      // message.success(res?.message);
      let data = groupData.filter((ele) => ele?._id != selectedGroupChat?.id);
      location?.pathname?.includes("group") && navigate("/private-layout/group");
      dispatch(groupSelectedChat(null));
      dispatch(groupsData(data));
      setRemoveloading(false);
      setLeaveGroupModal(false);
    } else {
      setRemoveloading(false);
      message.error(
        res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
      );
    }
  };

  const handleDeleteGroup = async () => {
    setRemoveloading(true);
    let param = new URLSearchParams();
    param.append("workspace_id", currentLoginSlice?.workspace_id);
    param.append("job_id", selectedGroupChat?.id ?? selectedGroupChat?._id);
    let res = await deleteGroup(param?.toString());
    if (res?.status == 200) {
      // navigate("/private-layout");
      let data = groupData.filter((ele) => ele?._id != selectedGroupChat?.id);
      dispatch(groupSelectedChat(null));
      dispatch(groupsData(data));
      setRemoveloading(false);
      setDeleteGroupModal(false);
    } else {
      setRemoveloading(false);
      message.error(
        res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
      );
    }
  };

  const content = (
    <div>
      <PopoverContent
        onClick={() => {
          setOpenInfo(true);
          setInfoData({
            _id: location?.pathname?.includes("group")
              ? selectedGroupChat?.id ?? selectedGroupChat?._id
              : _id
          });
          setViewInfo(false);
        }}>
        <ViewJobInfoIcon />
        <div>View {location?.pathname?.includes("group") ? "Group" : "Job"} Info</div>
      </PopoverContent>
    </div>
  );

  const individualContent = (
    <div>
      <PopoverContent>
        <ViewProfileIcon />
        <div
          onClick={() => {
            setProfileInfo(true);
            setViewInfo(false);
          }}>
          View Profile
        </div>
      </PopoverContent>
      {/* <PopoverContent>
        <MuteNotificationIcon />
        <div>Mute Notification</div>
      </PopoverContent>
      <PopoverContent>
        <DeleteChatIcon />
        <div style={{ color: "#D91717" }}>Delete Chat</div>
      </PopoverContent> */}
    </div>
  );

  const groupContent = (
    <div>
      <PopoverContent
        onClick={() => {
          setOpenInfo(true);
          setInfoData({
            _id: location?.pathname?.includes("group")
              ? selectedGroupChat?.id ?? selectedGroupChat?._id
              : _id
          });
          setViewInfo(false);
        }}>
        <ViewProfileIcon />
        <div>View Group Info</div>
      </PopoverContent>
      <PopoverContent
        onClick={() => {
          // handleAddMemberInGroup();
          setLeaveGroupModal(true);
          setViewInfo(false);
        }}>
        <LeaveGroupIconSVG />
        <div style={{ color: "#D91717" }}>Leave Group</div>
      </PopoverContent>
      {(currentLoginSlice?.user_type?.toLowerCase() == "admin" ||
        currentLoginSlice?.user_type?.toLowerCase() == "owner") && (
          <PopoverContent
            onClick={() => {
              setDeleteGroupModal(true);
              setViewInfo(false);
            }}>
            <DeleteChatIcon />
            <div style={{ color: "#D91717" }}>Delete Group</div>
          </PopoverContent>
        )}
    </div>
  );

  const GenerateAvatarName = (payload) => {
    let name = payload?.toString();

    let first = name?.split(" ")?.[0]?.slice(0, 1);
    let second = name?.split(" ")?.[1]?.slice(0, 1);
    if (first && second) {
      return first;
    } else if (first) {
      return first;
    } else if (second) {
      return second;
    }
  };

  const getUserType = () => {
    let result =
      selectedchat?.userData?.length > 0 &&
      selectedchat?.userData?.find((el) => el?._id != currentLoginSlice?._id);

    return result?.user_type;
  };

  return (
    <ChatInboxHeaderWrapper>
      {leaveGroupModal && (
        <LeaveGroupModal
          open={leaveGroupModal}
          handleClose={() => setLeaveGroupModal(false)}
          isLoading={removeLoading}
          handleSubmit={handleAddMemberInGroup}
          type="leave"
        />
      )}
      {deleteGroupModal && (
        <LeaveGroupModal
          open={deleteGroupModal}
          handleClose={() => setDeleteGroupModal(false)}
          isLoading={removeLoading}
          handleSubmit={handleDeleteGroup}
          type="delete"
        />
      )}
      <HeaderInfo>
        <div className="profile-image">
          {image ? (
            <img src={image} alt="" />
          ) : (
            <p style={{ textTransform: "capitalize" }}>{GenerateAvatarName(name)}</p>
          )}
          {location?.pathname?.includes("chats") && (
            <i>
              <JobsProfileIcon />
            </i>
          )}
        </div>
        <div className="name-time">
          <h1>{name}</h1>
          {location?.pathname?.includes("individual") ? (
            <div className="individual-chat">
              <p style={{ textTransform: "capitalize" }}>{getUserType()}</p>
            </div>
          ) : (
            <h4>{/* <TimeAgo datetime={createdDate} /> */}</h4>
          )}
        </div>
      </HeaderInfo>
      {/* <div>members</div> */}
      <div className="action-box">
        <CxcHeader headerName={""} isFilter={false} />
        {/* {!location?.pathname?.includes("individual") && ( */}
        <HeaderAction>
          <Popover
            open={viewInfo}
            onOpenChange={() => {
              setViewInfo(false);
            }}
            content={
              location?.pathname?.includes("group")
                ? groupContent
                : location?.pathname?.includes("individual")
                  ? individualContent
                  : content
            }
            trigger="click"
            placement="bottomRight"
            onClick={() => {
              setViewInfo(true);
            }}>
            <i>
              <ThreeDot />
            </i>
          </Popover>
        </HeaderAction>
        {/* )} */}
        {openInfo && (
          <JobInfoModal
            open={openInfo}
            handleModal={() => setOpenInfo(false)}
            data={infoData}
            userID={currentLoginSlice?._id}
          />
        )}
        {profileInfo && (
          <ProfileInfo open={profileInfo} handleModal={() => setProfileInfo(false)} />
        )}
      </div>
    </ChatInboxHeaderWrapper>
  );
}

ChatInboxHeader.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  createdDate: PropTypes.string
};

const ChatInboxHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 16px 0;
  padding: 0 20px;

  .action-box {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

const HeaderAction = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  i {
    transform: rotate(90deg);
  }
`;

const HeaderInfo = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  .individual-chat {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    p {
      color: #8f8f8f;
      font-family: Lato, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .profile-image {
    width: 56px;
    height: 56px;
    // background: #e5e5e5;
    position: relative;

    p {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(9, 179, 243, 0.14);
      color: rgb(0, 173, 255);
      font-family: Lato, sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
    }

    i {
      position: absolute;
      left: 0;
      bottom: 0;
      background: #fff;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 16px;
        height: 16px;
        path {
          fill: #3d97f2;
        }
      }
    }
  }

  .name-time {
    display: flex;
    flex-direction: column;
    justify-content: end;
    h1 {
      color: #3d3d3d;
      font-family: Lato, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
      text-overflow: ellipsis;
      max-width: 35vw;
      overflow: hidden;
    }

    h4 {
      color: #8f8f8f;
      font-family: Lato, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
`;

const PopoverContent = styled.div`
  color: #3d3d3d;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 10px;
`;
