import React from "react";
import styled from "styled-components";
import { BackgroundLinesYapi, BackgroundYapi } from "../utils/Images/Images";
import CxcHeader from "./CxcHeader";
import PropTypes from "prop-types";
import Sidenav from "./Sidenav";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

export default function NoActiveWorkspace({ isNotRouteMatched }) {
  const UserDetails = useSelector((state) => state?.LoginSlice?.data);
  const location = useLocation()?.pathname;

  return (
    <MainWrapperForNoActiveWorkspace>
      {isNotRouteMatched && UserDetails != null && (
        <section className="sidebar-section">
          <Sidenav notFound={true} />
        </section>
      )}
      <NoActiveWorkspaceWrapper isNotRouteMatched={isNotRouteMatched} UserDetails={UserDetails}>
        <img src={BackgroundYapi} alt="bg" className="bg-image" />
        <img src={BackgroundLinesYapi} alt="lines" className="bg-lines" />

        {(isNotRouteMatched && UserDetails != null) || UserDetails != null ? (
          <CxcHeader headerName={""} isFilter={false} />
        ) : null}

        <InnerWrapper>
          {isNotRouteMatched ? (
            <h1>404 Page Not Found</h1>
          ) : (
            <h1>

           { location?.includes("group")
            ? "NO GROUPS"
              : location?.includes("chats")
              ? "NO JOBS"
              : location?.includes("individual")
                ? "NO CHATS" : "404 Page Not Found"
          }
            </h1>
            // !location.includes("titan-job") && (
            //   <h1>
            //     Sorry, You {"don't"} have enough permission to view this workspace. Please contact
            //     the workspace Owner
            //   </h1>
            // )
          )}
        </InnerWrapper>
      </NoActiveWorkspaceWrapper>
    </MainWrapperForNoActiveWorkspace>
  );
}

NoActiveWorkspace.propTypes = {
  isNotRouteMatched: PropTypes.bool
};

const MainWrapperForNoActiveWorkspace = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  .sidebar-section {
    /* width: 260px;
    height: 98vh; */
    width: 320px;
    height: 100vh;
    background: #101010;
    position: fixed;
    z-index: 9;
    @media (max-height: 600px) {
      overflow-y: scroll;
    }

    ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #fff;
    }
    ::-webkit-scrollbar-thumb {
      background: #101010;
      border-radius: 10px;
    }

    /* @media (max-width: 1440px) {
      width: 200px;
    } */
    @media (max-width: 992px) {
      display: ${({ sidebar }) => !sidebar && "none"};
    }
  }
`;

const NoActiveWorkspaceWrapper = styled.div`
  height: 98vh;
  width: 100%;
  background: #edf7fe;
  padding: 20px;
  position: relative;
  margin-left: ${({ UserDetails, isNotRouteMatched }) =>
    isNotRouteMatched && UserDetails != null && "260px"};

  @media (max-width: 1440px) {
    margin-left: ${({ UserDetails, isNotRouteMatched }) =>
    isNotRouteMatched && UserDetails != null && "200px"};
  }

  .bg-image {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .bg-lines {
    position: absolute;
    left: 0;
    bottom: 0;
  }
`;

const InnerWrapper = styled.div`
  width: 80%;
  margin: auto;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 70px;
    color: rgb(75, 85, 99);
    text-align: center;
  }
`;
