import ForgetPassword from "../app/Auth/Forget/ForgetPassword";
import SignInWithEmail from "../app/Auth/signIn/SignInWithEmail";
import SignupWithEmail from "../app/Auth/signup/SignupWithEmail";
import Steps from "../app/Steps/Steps";
import AcceptInvitation from "../app/Workspace/AcceptInvitation";
import { Congratulations } from "../components/Congratulations";

export const Paths = [
  {
    path: "/",
    component: <SignInWithEmail />
  },
  {
    path: "/signup",
    component: <SignupWithEmail />
  },
  {
    path: "/congratulation",
    component: <Congratulations />
  },

  {
    path: "/accept-invitation",
    component: <AcceptInvitation />
  },
  {
    path: "/steps",
    component: <Steps />
  },
  {
    path: "/forgot-password",
    component: <ForgetPassword />
  },
];
