import React, { useState } from "react";
import PropTypes from "prop-types";
import { Answers, Heading, Stepsection } from "./Stepsstyle";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { WorkSpaceName } from "../Workspace/WorkSpaceSlice";

export default function Step1({ setstage }) {
  const dispatch = useDispatch();
  const [teamName, setTeamName] = useState("");

  const handleNext = () => {
    if (teamName === "") message.error("Please Enter Team/Company name");
    else {
      dispatch(WorkSpaceName(teamName));
      setstage(1);
    }
  };

  const handleTeamName = (e) => {
    setTeamName(e.target.value);
  };

  return (
    <Stepsection>
      <Heading>
        <p className="stepstage">STEP 1 OF 3</p>
        <p className="heading"> What’s the name of your team or company</p>
        <p className="lighttext">
          {" "}
          This will be the name of your yapi workspace- choose something that your team will
          recognise
        </p>
      </Heading>
      <Answers>
        <div className="field">
          <div className="team">
            <p className="company"> TEAM OR COMPANY NAME</p>
            <p className="number">{teamName?.length}/50</p>
          </div>
          <div>
            <input
              className="input-field"
              placeholder="Enter a name"
              name="team-name"
              onChange={handleTeamName}
              maxLength={50}
            />
          </div>
          <button className="nextbtn" onClick={handleNext}>
            NEXT
          </button>
        </div>
      </Answers>
    </Stepsection>
  );
}
Step1.propTypes = {
  setstage: PropTypes.func
};
