import React from "react";
import styled from "styled-components";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { useDispatch, useSelector } from "react-redux";
import { UpdateSteps } from "../Auth/signIn/SignInSlice";

export default function Steps() {
  const stage = useSelector((state) => state?.LoginSlice?.steps);
  const dispatch = useDispatch();

  const setstage = (step) => {
    dispatch(UpdateSteps(step));
  };
  return (
    <Stepswrapeer>
      {stage === 0 && <Step1 setstage={setstage} />}
      {stage === 1 && <Step2 setstage={setstage} />}
      {stage === 2 && <Step3 setstage={setstage} />}
    </Stepswrapeer>
  );
}
const Stepswrapeer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12% !important;
  @media (max-width: 834px) {
    margin-top: 0px !important;
  }
`;
