import { message, Modal } from "antd";
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CreateGroupBg } from "../utils/Images/Images";
import { getAllUsersListing, oneToOneChatInititate } from "../services/Collections";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalLoader } from "../components/ModalLoader";
import { GenerateAvatarName } from "../utils/common/commonParamter";
import { selectedChat } from "../module/Messenger/IndividualChatSlice";
import { useNavigate } from "react-router-dom";

const AddTeamMember = ({ open, handleClose }) => {
  const userDetails = useSelector((state) => state?.LoginSlice?.data);
  const [membersList, setMembersList] = useState([]);
  const [loading, setLoading] = useState(false);
  // const jobListData = useSelector((state) => state?.IndividualChat?.chats_Data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const chatData = useSelector((state) => state?.IndividualChat?.chats_Data);

  const handleUserListing = async () => {
    setLoading(true);
    let params = new URLSearchParams();
    params.append("workspace_id", userDetails?.workspace_id);

    let res = await getAllUsersListing(params.toString());
    if (res?.status === 200) {
      let chatPeoples = [];
      for (let i = 0; i < chatData.length; i++) {
        for (let j = 0; j < chatData?.[i]?.members.length; j++) {
          if (chatData?.[i]?.members[j]?.user_id !== userDetails?._id) {
            chatPeoples?.push(chatData?.[i]?.members[j]?.user_id);
          }
        }
      }

      let filteredArray = [];
      for (let i = 0; i < res?.data?.length; i++) {
        //
        if (res?.data[i]?._id != userDetails?._id) {
          if (chatPeoples?.some((el) => el == res?.data[i]?._id)) {
            filteredArray.push({ ...res?.data[i], alreadymate: true });
          } else {
            filteredArray.push({ ...res?.data[i], alreadymate: false });
          }
        }
      }

      setMembersList(filteredArray);
      setLoading(false);
    } else {
      setLoading(false);
      message.error(
        res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
      );
    }
  };

  const handleIndividualChat = async (id) => {
    let payload = {
      workspace_id: userDetails?.workspace?._id,
      user1_id: userDetails?._id,
      user2_id: id
    };
    let res = await oneToOneChatInititate(payload);
    if (res?.status === 200) {
      localStorage.setItem("dummy", res?.data?._id);
      navigate(`/private-layout/individual/${res?.data?._id}`);
      setTimeout(() => {
        dispatch(selectedChat(res?.data));
        handleClose();
      }, 500);
    } else {
      message.error(res?.response?.data?.message);
    }
  };

  useEffect(() => {
    handleUserListing();
  }, []);

  return (
    <Modal
      prefixCls="group-modal"
      open={open}
      onCancel={handleClose}
      width={400}
      footer={false}
      centered>
      <AddTeamMemberStyle>
        <div className="header-banner">
          <img src={CreateGroupBg} />
          <div className="text">
            <h3>Add Team Mate</h3>
          </div>
        </div>
        {loading ? (
          <div className="loader-box">
            <ModalLoader size={45} color={"#00adff"} />
          </div>
        ) : (
          <section>
            {membersList?.map((ele, i) => {
              return (
                <div
                  className="List"
                  key={i}
                  onClick={() =>
                    // !ele?.alreadymate &&
                    ele?._id != userDetails?._id && handleIndividualChat(ele?._id)
                  }>
                  <div className="name-avatar">
                    {ele?.profile_url ? (
                      <img src={ele?.profile_url} alt="" />
                    ) : (
                      <h1>{GenerateAvatarName(ele?.name)?.toUpperCase()}</h1>
                    )}
                  </div>
                  <div className="innnerdiv">
                    <p className="personname">{ele?.user || ele?.name}</p>
                    {ele?.alreadymate && <div className="already">Alreadymate</div>}
                  </div>
                </div>
              );
            })}
          </section>
        )}
      </AddTeamMemberStyle>
    </Modal>
  );
};

export default AddTeamMember;

AddTeamMember.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func
};

const AddTeamMemberStyle = styled.div`
  width: 100%;
  min-height: 350px;
  padding: 32px;
  max-height: 95vh;
  overflow-y: scroll;

  .loader-box {
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ::-webkit-scrollbar {
    width: 3px;
    height: 2px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
  }
  ::-webkit-scrollbar-thumb {
    background: #cecece;
    border-radius: 10px;
  }

  .header-banner {
    width: 100%;
    background: #3d97f2;
    border-radius: 4px;
    position: relative;
    padding: 10px;
    z-index: 1;
    min-height: 80px;

    img {
      width: 100%;
      height: 100%;
      z-index: 2;
    }
    .text {
      position: absolute;
      z-index: 3;
      top: 26px;
      width: 100%;
      text-align: center;
      left: 0;

      h3 {
        color: #fff;
        text-align: center;
        font-family: Lato, sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 132%; /* 42.24px */
        letter-spacing: 0.32px;
      }
      p {
        margin-top: 4px;
        color: #fff;
        text-align: center;
        font-family: Lato, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
      }
    }
  }

  section {
    padding: 20px 0;
    .List {
      display: flex;
      gap: 20px;
      -webkit-box-align: center;
      align-items: center;
      border: 1px solid #80808075;
      border-radius: 5px;
      padding: 10px;
      cursor: pointer;
      margin: 10px 0;
    }

    .innnerdiv {
      display: flex;
      gap: 5px 20px;
      justify-content: space-between;
      align-items: center;
      width: calc(100% - 50px);
      flex-wrap: wrap;
    }

    .already {
      text-transform: capitalize;
      font-family: Lato, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      color: green;
    }

    .personname {
      /* text-transform: capitalize; */
      font-family: Lato, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      border-radius: 0px 8px 8px;
    }

    .name-avatar {
      width: 50px;
      height: 50px;
      border-radius: 5px;
      background: #09b3f324;
      color: #00adff;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        object-fit: cover;
      }
    }
  }
`;
