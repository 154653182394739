/* eslint-disable no-unused-vars */
/* eslint-disable quotes */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BackArrowPng, Deactivate, DummyProfile } from "../utils/Images/Images";
import {
  CreateNewAccount,
  DeleteIcon,
  ProfileExit,
  SmallEditIcon,
  SwitchAccount
} from "../utils/Icons/SvgIcons";

import PropTypes from "prop-types";
import useWindowWidth from "../customHook/GetWindowWidth";
import { DeleteModal } from "./DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { SignInStep, UpdateSteps } from "../app/Auth/signIn/SignInSlice";
import { ConnectCrmModal } from "./ConnectCrmModal";
import { Popover, Tooltip, message } from "antd";
import {
  EditWorkspace,
  createWorkspace,
  deactiveWorkspace,
  deleteWorkspaceByOwner,
  getAllWorkspaceListing,
  requestForAdmin,
  switchWorkspace,
  updateInternalDbForDeactiveWorkspace
} from "../services/Collections";
import { CreateWorkSpaceModal } from "./CreateWorkSpaceModal";
import { useNavigate } from "react-router-dom";
import { ModalLoader } from "./ModalLoader";
import {
  CurrentWorkspace,
  WorkSpaceData,
  workSpaceInitialStateReset
} from "../app/Workspace/WorkSpaceSlice";
import { handleWorkspaceListing } from "../utils/common/WorkspaceListing";
import {
  ChatId,
  chatInitialStateReset,
  JobsData,
  receviedNewMessage,
  splashLoading
} from "../module/Messenger/ChatListIdSlice";
import {
  chatsData,
  individualInitialStateReset,
  selectedChat
} from "../module/Messenger/IndividualChatSlice";
import {
  groupSelectedChat,
  groupsData,
  groupInitialStateReset
} from "../module/group/GroupChatSlice";
import { PublicRoute } from "../routes/routes";
import { beamsClient } from "../Pusher/PusherConfig";

const ManageGoogleAccount = ({ backArrow, setProfileDrawer, handlePopup }) => {
  const [width] = useWindowWidth();
  const [logoutModal, setLogout] = useState(false);
  const [connectModal, setConnectModal] = useState(false);
  const [connectModalData, setConnectModalData] = useState(null);
  const [connectModalLoader, setConnectModalLoader] = useState(false);
  const [addNewCrnModal, setAddNewCrnModal] = useState(false);
  const dispatch = useDispatch();
  // const workspaceCRM = useSelector((state) => state?.LoginSlice?.data?.companies);
  const userDetails = useSelector((state) => state?.LoginSlice?.data);
  const isManagerLogin = useSelector((state) => state?.LoginSlice?.verifyOtp);
  const [addedNewCompany, setAddedNewCompany] = useState(false);
  const [addedNewCompanyName, setAddedNewCompanyName] = useState("");
  const [loading, setLoading] = useState(false);
  const workspaceCRM = useSelector((state) => state?.WorkSpaceSlice?.workSpaceData);
  const [ConnectCRM, setConnectCRM] = useState(false);
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [deleteModalLoader, setDeleteModalLoader] = useState(false);
  const [requestAdminModal, setRequestAdminModal] = useState(false);
  const connectedWorkspace = useSelector((state) => state?.LoginSlice?.data);
  const [deactiveLoader, setDeactiveLoader] = useState(false);
  const no_Of_Jobs = useSelector((state) => state?.ChatId?.jobs_Data);
  const selectedJob = useSelector((state) => state?.ChatId?.job_Id);

  const handleLogout = () => {
    beamsClient
      .start()
      .then(() => beamsClient.removeDeviceInterest(`user_${userDetails?._id}`))
      .then(() => beamsClient.clearDeviceInterests())
      .then(() => console.log("Successfully registered and subscribed!"))
      .catch(console.error);

    dispatch(SignInStep(null));
    dispatch(UpdateSteps(0));
    navigate("/");
    dispatch(ChatId(null));
    dispatch(receviedNewMessage([]));
    dispatch(WorkSpaceData([]));
    dispatch(JobsData([]));
    dispatch(CurrentWorkspace(null));
    dispatch(groupInitialStateReset(true));
    dispatch(individualInitialStateReset(true));
    dispatch(chatInitialStateReset(true));
    dispatch(workSpaceInitialStateReset(true));
  };

  const handleRequestForAdmin = async (payload) => {
    setConnectModalLoader(true);
    let requestPayload = {
      workspace_id: payload?._id,
      user_id: payload?.workSpaceUsers?._id,
      name: userDetails?.name
    };
    let res = await requestForAdmin(requestPayload);
    if (res?.status === 200) {
      message.success(res?.message);
      setConnectModalLoader(false);
      setRequestAdminModal(false);
    } else {
      setConnectModalLoader(false);
      message.error(
        res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
      );
    }
  };

  // const userDetails = useSelector((state) => state?.LoginSlice?.data);
  // const selectedJob = useSelector((state) => state?.ChatId?.job_Id);
  // const no_Of_Jobs = useSelector((state) => state?.ChatId?.jobs_Data);

  const handleConnectCRM = async (payload) => {
    setConnectModalLoader(true);
    let requestPayload = {
      workspace_id: payload?._id
    };

    let res = await switchWorkspace(requestPayload);

    if (res?.status === 200) {
      beamsClient
        .start()
        .then(() => beamsClient.removeDeviceInterest(`user_${userDetails?._id}`))
        .then(() => beamsClient.clearDeviceInterests())
        .then(() => console.log("Successfully  unsubscribed!"))
        .catch(console.error);

      // setConnectModalLoader(false);
      message.success(res?.message);
      // let obj = { ...userDetails, ...res?.data };
      let obj = { ...res?.data, token: userDetails?.token };
      // setConnectModal(false);
      dispatch(SignInStep(obj));
      dispatch(WorkSpaceData([]));
      dispatch(chatInitialStateReset(true));
      dispatch(individualInitialStateReset(true));
      dispatch(groupInitialStateReset(true));

      // if (obj?.user_type?.toLowerCase() === "owner" || obj?.user_type?.toLowerCase() == "admin") {
      //   if (obj?.workspace?.tenant_id && obj?.workspace?.tenant_id != null) {
      //     navigate("/private-layout");
      //   } else {
      //     navigate("/private-layout");
      //   }
      // } else {
      //   navigate("/private-layout");
      // }
      dispatch(splashLoading(true));
      setConnectModal(false);
      setTimeout(() => {
        setConnectModalLoader(false);
        window.location.reload();
      }, 1000);
    } else if (res?.response?.data?.status === 401) {
      let listing = await handleWorkspaceListing();
      dispatch(WorkSpaceData(listing));
      setConnectModal(false);
      setConnectModalLoader(false);
      message.error(
        res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
      );
    } else {
      setConnectModal(false);
      setConnectModalLoader(false);
      message.error(
        res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
      );
    }
  };

  const GenerateAvatarName = (payload) => {
    let name = payload?.toString();

    let first = name?.split(" ")?.[0]?.slice(0, 1);
    let second = name?.split(" ")?.[1]?.slice(0, 1);
    if (first && second) {
      // return first + second;
      return first;
    } else if (first) {
      return first;
    } else if (second) {
      return second;
    }
  };

  const getDynamic = () => {
    let generateColor = (Math.random() * 1000000)?.toString()?.split(".");
    return `#${generateColor?.[0]}`;
  };

  const handleCreateWorkspace = async () => {
    if (addedNewCompanyName) {
      setLoading(true);
      let reqPayload = {
        workSpaceName: addedNewCompanyName
      };
      let res = await createWorkspace(reqPayload);
      if (res.status === 200) {
        let obj = { ...userDetails, companies: res?.data?.companies };

        dispatch(SignInStep(obj));
        setLoading(false);
        setAddedNewCompany(false);
      } else {
        message.error(res?.data?.status || "Something went wrong", 10);
        setLoading(false);
      }
    }
  };

  const filterDataAfterDelete = (deletedID) => {
    if (Array.isArray(workspaceCRM) && workspaceCRM?.length > 0) {
      let arr = workspaceCRM?.filter((el) => el?._id != deletedID);
      dispatch(WorkSpaceData(arr));
    }
  };

  const handleDeleteWorkspace = async (data) => {
    setDeleteModalLoader(true);

    let params = new URLSearchParams();
    params.append("workspace_id", data?._id);
    params.append("user_type", data?.user_type);
    let res = await deleteWorkspaceByOwner(params.toString());
    if (res?.status == 200) {
      setDeleteModalLoader(false);
      setDeleteModal(false);
      message.success(res?.message);

      filterDataAfterDelete(data?._id);

      let listing = await handleWorkspaceListing();
      dispatch(WorkSpaceData(listing));
      Array.isArray(listing) &&
        listing?.length > 0 &&
        listing?.map((ele) => ele?.default_workspace && dispatch(CurrentWorkspace(ele)));
    } else {
      setDeleteModalLoader(false);
      message.error(
        res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
      );
    }
  };

  const workspaceDetails = (data) => {
    return (
      <div>
        {/* <p>Workspace Name - {data?.workSpaceName}</p> */}
        <p>Workspace Name - {data?.workspace_name}</p>
        <p>Tenant ID - {data?.tenant_id}</p>
        <p>Status - {data?.default_workspace ? "Active" : "Deactive"}</p>
      </div>
    );
  };

  const [isOpenForChangeWorkspacename, setIsOpenForChangeWorkspacename] = useState(null);
  const [newWorkspaceName, setNewWorkspaceName] = useState("");
  const [isloading, setIsLoading] = useState(false);

  const changeWorkspacename = async (value) => {
    setIsLoading(true);
    let requestPayload = {
      workspace_id: value._id,
      workspace_name: newWorkspaceName
    };
    let res = await EditWorkspace(requestPayload);

    if (res?.status === 200) {
      setIsLoading(false);
      window.location.reload();
    } else {
      setIsLoading(false);
      message.error(
        res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
      );
    }
  };

  const handleUpdateInternalDbForDeactiveWorkspace = async (workspaceID, tenantID, userID) => {
    let requestPayload = {
      tenant_id: tenantID,
      workspace_id: workspaceID,
      user_id: userID
    };
    let res = await updateInternalDbForDeactiveWorkspace(requestPayload);
    if (res?.status === 200) {
      message.success(res?.message);
    } else {
      message.error(
        res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
      );
    }
  };

  const DeactiveCrmWorkspace = async (val) => {
    setDeactiveLoader(true);
    await handleUpdateInternalDbForDeactiveWorkspace(
      val?._id,
      val?.tenant_id,
      val?.workSpaceUsers?._id
    );
    setDeactiveLoader(false);
    setDeactivateModal(false);
    window.location.reload(false);

    // let dbname = "yapi_" + val?._id + "_" + val?.tenant_id;

    // let obj = {
    //   db: dbname
    // };
    // let res = await deactiveWorkspace(obj);
    // if (res?.status == 200) {
    //   console.log(res, "resssssss");
    //   await handleUpdateInternalDbForDeactiveWorkspace(val?._id, val?.tenant_id, val?.workSpaceUsers?._id);
    //   setDeactiveLoader(false);
    //   setDeactivateModal(false);
    //   window.location.reload();
    // } else {
    //   setDeactiveLoader(false);
    //   message.error(
    //     res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
    //   );
    // }
  };

  return (
    <>
      {ConnectCRM && (
        <ConnectCrmModal
          open={ConnectCRM}
          handleClose={() => setConnectCRM(false)}
          connectModalData={connectModalData}
          closeStatus={true}
          newConnection={false}
        />
      )}
      {addedNewCompany && (
        <CreateWorkSpaceModal
          open={addedNewCompany}
          handleClose={() => setAddedNewCompany(false)}
          setConnectCRM={setConnectCRM}
          setConnectModalData={setConnectModalData}
        />
      )}
      {logoutModal && (
        <DeleteModal
          handleClose={() => setLogout(false)}
          open={logoutModal}
          title="Are you sure you want to Log out ?"
          description="All your process and data will be saved."
          button="Log out"
          btnColor="#E93124"
          handleSubmit={() => handleLogout()}
        />
      )}
      {connectModal && (
        <DeleteModal
          handleClose={() => setConnectModal(false)}
          open={connectModal}
          title="Are you sure you want to Switch this Workspace ?"
          description="All your process and data will be saved."
          button="Switch"
          btnColor="green"
          handleSubmit={() => handleConnectCRM(connectModalData)}
          isLoading={connectModalLoader}
        />
      )}
      {requestAdminModal && (
        <DeleteModal
          handleClose={() => setRequestAdminModal(false)}
          open={requestAdminModal}
          title="Are you sure you want to Request for admin access for this Workspace ?"
          description=""
          button="Request"
          btnColor="green"
          handleSubmit={() => handleRequestForAdmin(connectModalData)}
          isLoading={connectModalLoader}
        />
      )}
      {deleteModal && (
        <DeleteModal
          handleClose={() => setDeleteModal(false)}
          open={deleteModal}
          title="Are you sure you want to Delete this workspace ?"
          button="Delete"
          btnColor="red"
          handleSubmit={() => handleDeleteWorkspace(connectModalData)}
          isLoading={deleteModalLoader}
        />
      )}
      {deactivateModal && (
        <DeleteModal
          handleClose={() => setDeactivateModal(false)}
          open={deactivateModal}
          title="Are you sure you want to Deactivate this workspace ?"
          button="Deactivate"
          btnColor="red"
          handleSubmit={() => DeactiveCrmWorkspace(connectModalData)}
          isLoading={deactiveLoader}
        />
      )}
      {addNewCrnModal && (
        <ConnectCrmModal
          open={addNewCrnModal}
          handleClose={() => setAddNewCrnModal(false)}
          connectModalData={connectModalData}
          closeStatus={false}
          newConnection={false}
        />
      )}

      <ManageGoogleAccountWrapper style={{ width: width > 500 ? "380px" : "100%" }}>
        {backArrow && (
          <div className="arrow-img-div" onClick={() => setProfileDrawer(false)}>
            <img className="backArrowImg" src={BackArrowPng} />
          </div>
        )}

        <div className="upper-section">
          <div className="profile-img">
            {userDetails?.profile_url ? (
              <img src={userDetails?.profile_url} />
            ) : (
              <div className="avatar" style={{ background: "rgb(155 173 158 / 86%)" }}>
                {GenerateAvatarName(isManagerLogin ? isManagerLogin?.user_name : userDetails?.name)}
              </div>
            )}
          </div>
          <h3>{userDetails?.name ?? isManagerLogin?.user_name}</h3>
          <h4>{userDetails?.email ?? isManagerLogin?.email}</h4>
          <h4>{userDetails?.user_type ?? isManagerLogin?.user_type}</h4>
          <div></div>
        </div>

        {/* {isManagerLogin?.role != "manager" && ( */}
        <div className="Crm-box">
          {workspaceCRM?.length > 0 &&
            workspaceCRM?.map((crm, idx) => (
              <div key={idx}>
                <div
                  className={`content ${
                    crm?._id == userDetails?.workspace?._id && "activeContent"
                  }`}>
                  <div
                    className="avatar-name"
                    onClick={() => {
                      crm?._id != userDetails?.workspace?._id && setConnectModal(true);
                      !backArrow && handlePopup(false);
                      setConnectModalData(crm);
                    }}>
                    <div className="avatar" style={{ background: getDynamic() }}>
                      {crm?.workSpace_image_url ? (
                        <img src={crm?.workSpace_image_url} alt="img" />
                      ) : (
                        GenerateAvatarName(crm?.workspace_name)
                      )}
                    </div>

                    <Popover
                      content={workspaceDetails(crm)}
                      // title={`Workspace - ${crm?.workspace_name}`}
                    >
                      <WorkspaceNameAndIcon>
                        <h4
                          style={{ width: "120px", textOverflow: "ellipsis", overflow: "hidden" }}>
                          {crm?.workspace_name}{" "}
                          {crm?.tenant_id && crm?.crm_connected && "(Connected)"}
                        </h4>
                      </WorkspaceNameAndIcon>
                      <h5 style={{ fontFamily: "Inter", fontWeight: "normal" }}>
                        {crm?.memberCount} {crm?.memberCount > 1 ? "Members" : "Member"}
                      </h5>
                    </Popover>
                  </div>

                  <div>
                    <div style={{ display: "flex", justifyContent: "end", gap: "5px" }}>
                      {crm?.owner_id === userDetails?.user_id && (
                        <span onClick={() => setIsOpenForChangeWorkspacename(crm)}>
                          <SmallEditIcon color={"white"} />
                        </span>
                      )}
                      {crm?.user_type?.toLowerCase() == "owner" &&
                        (crm?.tenant_id == null || !crm?.crm_connected) &&
                        workspaceCRM?.length > 1 &&
                        !crm?.default_workspace && (
                          <span
                            onClick={() => {
                              setDeleteModal(true);
                              !backArrow && handlePopup(false);
                              setConnectModalData(crm);
                            }}>
                            <DeleteIcon />
                          </span>
                        )}
                    </div>

                    <div style={{ display: "flex" }}>
                      {crm?.crm_connected &&
                        workspaceCRM?.length > 1 &&
                        !crm?.default_workspace &&
                        (crm?.user_type?.toLowerCase() == "owner" ||
                          crm?.user_type?.toLowerCase() == "admin") && (
                          <button
                            onClick={() => {
                              setDeactivateModal(true);
                              !backArrow && handlePopup(false);
                              setConnectModalData(crm);
                            }}
                            className="deactive">
                            <Tooltip title="To deactive the CRM">Deactive</Tooltip>
                          </button>
                        )}
                      {crm?.owner_id === userDetails?.user_id ||
                      crm?.user_type?.toLowerCase() == "admin" ? (
                        <>
                          {
                            // crm?.default_workspace ? (
                            //   <button className="connected">Selected</button>
                            // ) :
                            crm?.tenant_id && crm?.crm_connected ? (
                              <button
                                onClick={() => {
                                  setConnectModal(true);
                                  !backArrow && handlePopup(false);
                                  setConnectModalData(crm);
                                }}
                                className="connect">
                                Switch
                              </button>
                            ) : (
                              <button
                                onClick={() => {
                                  setAddNewCrnModal(true);
                                  !backArrow && handlePopup(false);
                                  setConnectModalData(crm);
                                }}
                                className="connect">
                                Connect
                              </button>
                            )
                          }
                        </>
                      ) : (
                        <button
                          onClick={() => {
                            setRequestAdminModal(true);
                            !backArrow && handlePopup(false);
                            setConnectModalData(crm);
                          }}
                          className="requestAdmin">
                          Request Admin access
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                {isOpenForChangeWorkspacename?._id === crm?._id && (
                  <ChangeWorkspaceNameWrapper>
                    <input
                      type="text"
                      placeholder="workspace name"
                      onChange={(e) => setNewWorkspaceName(e.target.value)}
                    />
                    <div>
                      <button
                        className="cancel"
                        onClick={() => setIsOpenForChangeWorkspacename(null)}>
                        Cancel
                      </button>
                      {isloading ? (
                        <button className="save">
                          <ModalLoader size={18} color={"#03d42d"} />
                        </button>
                      ) : (
                        <button className="save" onClick={() => changeWorkspacename(crm)}>
                          Save
                        </button>
                      )}
                    </div>
                  </ChangeWorkspaceNameWrapper>
                )}
              </div>
            ))}

          <div className="footer-section">
            <section
              onClick={() => {
                setAddedNewCompany(true);
                !backArrow && handlePopup(false);
              }}
              className="create-new-account">
              <CreateNewAccount />
              <span>Add new workspace</span>
            </section>
          </div>
        </div>
        {/* )} */}

        <div className="footer-section">
          <section className="switch-logout">
            <div
              onClick={() => {
                !backArrow && handlePopup(false);
                setLogout(true);
              }}>
              <ProfileExit />
              <span>Logout</span>
            </div>
          </section>
        </div>
      </ManageGoogleAccountWrapper>
    </>
  );
};

ManageGoogleAccount.propTypes = {
  backArrow: PropTypes.bool,
  setProfileDrawer: PropTypes.func,
  handlePopup: PropTypes.func
};

export default ManageGoogleAccount;

const ManageGoogleAccountWrapper = styled.div`
  min-height: 100%;
  max-height:480px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 1px;
    height: 2px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
  }
  ::-webkit-scrollbar-thumb {
    background: #cecece;
    border-radius: 10px;
  }

  @media(max-width: 500px){
    overflow-y: unset;
    min-height: unset;
    max-height:unset;
    height: 100%;
  }

  //   background: #ffffff;
  //   box-shadow: 0px 12px 24px -4px rgba(16, 24, 40, 0.06), 0px 4px 12px -2px rgba(16, 24, 40, 0.1);
  border-radius: 8px;

  .arrow-img-div {
    width: 85%;
    margin: auto;
    .backArrowImg {
      width: 28px;
      height: 20px;
      position: absolute;
    left: 21px;
    top: 20px;
    }
  }

  .back-arrow {
    position: absolute;
    top: 20px;
  }

  .footer-section {
    .switch-logout {
      width: 100%;
      display: flex;

      div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
        cursor: pointer;
        &:nth-child(1) {
          border-right: 1px solid #f2f4f7;

          span {
            font-family: Lato, sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #3671e6;
            padding: 0 10px;
          }
        }

        &:nth-child(2) {
          span {
            font-family: Lato, sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #3671e6;
            padding: 0 10px;
          }
        }
      }
    }

    .create-new-account {
      display: flex;
      align-items: center;
      background: #f6f9fc;
      gap: 8px;
      padding: 16px 30px;
      cursor: pointer;

      span {
        color: #3671e6;
        font-size: 14px;
        font-family: Lato, sans-serif;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }


  .create-new-company {
    display: flex;
    align-items: center;
    justify-content:space-between;
    background: #f6f9fc;
    gap: 8px;
    padding: 16px 30px;
    cursor: pointer;

    span {
      background: #000;
      color: #fff;
      font-size: 14px;
      font-family: Lato, sans-serif;
      font-weight: 700;
      line-height: 20px;
      padding:5px 12px;
      border-radius: 500px;
    }

    label {
      background: #000;
      color: #fff;
      font-size: 14px;
      font-family: Lato, sans-serif;
      font-weight: 700;
      line-height: 20px;
      padding:5px 12px;
      border-radius: 500px;
    }


    .added-new-company {
      color: #8f8f8f;
      font-family: Lato, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.14px;
      border-radius: 500px;
      background: #fff;
      border-style: none;
      padding: 8px;
    }
  }
}


  .lower-section {
    section {
      display: flex;
      align-items: center;
      padding: 16px 24px;
      img {
        width: 36px;
        height: 36px;
      }
      div {
        margin: 0 12px;
        h3 {
          font-family: Lato, sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          color: #101010;
        }
        h4 {
          font-family: Lato, sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #4b5563;
        }
      }
    }
  }

  .upper-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #f2f4f7;

    img {
      width: 80px;
      height: 80px;
      object-fit: contain;
      margin: 20px 0 12px 0;
    }

    .profile-img{
      width: 80px;
      height: 80px;
      margin: 20px 0 12px 0;
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        width: 80px;
        height: 80px;
        object-fit:cover;
        border-radius: 50%;
      }

    .avatar {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: #cecece;
      color: #fff;
      font-family: Lato, sans-serif;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    }

    h3 {
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #101010;
      padding: 4px 0;
    }

    h4 {
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #4b5563;
      padding: 4px 0;
    }
    div {
      display: flex;
      align-items: center;
      gap: 10px;
      margin:5px 0;
      .btn1 {
        padding: 10px 20px;
        border: 1px solid #dadce0;
        border-radius: 6px;
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #3671e6;
        background: #ffffff;
        margin: 20px 0;
        cursor: pointer;
      }
      .btn2 {
        border-radius: 6px;
        border: 1px solid #dadce0;
        padding: 9px 12px 8px 12px;
        background: transparent;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .Crm-box {
    width: 100%;
    border-bottom: 1px solid #f2f4f7;
    padding: 10px 0;

    .title {
      width: 100%;
      padding: 0px 24px;
      text-align: center;

      h3 {
        font-family: Lato, sans-serif;
        font-weight: 700;
      }
    }
    .activeContent{
      background-color: #4e1c51;
      border-radius: 5px;
      color: white;
      :hover{
        background-color: #7e3882;
      }
    }
    .content {
      padding: 10px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: auto;

      h4 {
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        /* color: #101010; */
      }
      .avatar-name {
        display: flex;
        align-items: center;
        gap: 10px;

        .avatar {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          background: #cecece;
          color: #fff;
          font-family: Lato, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
          text-transform: uppercase;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid white;
          overflow: hidden;
        }

        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .connected {
        width: 85px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ddf9e2;
        border-style: none;
        ${"" /* padding: 5px 10px; */}
        color: #03d42d;
        border-radius: 40px;
        cursor: pointer;
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
      }
      .connect {
        width: 85px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgb(226, 237, 249);
        border-style: none;
        ${"" /* padding: 5px 10px; */}
        color: rgb(54, 121, 236);
        cursor: pointer;
        border-radius: 40px;
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
      }

      .deactive {
        width: 80px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        border-style: none;
        ${"" /* padding: 5px 10px; */}
        color: red;
        cursor: pointer;
        border-radius: 40px;
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
      }


      .invited {
        width: 85px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        ${"" /* padding: 5px 10px; */}
        background: transparent;
        border-style: none;
        color: rgb(0 0 0 / 81%);
        cursor: pointer;
        border-radius: 40px;
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
      }

      .requestAdmin {
        width: 150px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        /* background: rgb(189 189 184 / 40%); */
        background: white;
        border-style: none;
        padding: 5px 0px;
        /* color: #817c7c; */
        color: #4e1c51;
        border: 1px solid;
        cursor: pointer;
        border-radius: 40px;
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
`;

const WorkspaceNameAndIcon = styled.div`
  display: flex;
  gap: 5px;

  svg {
    width: 12px;
    cursor: pointer;
  }
`;

const ChangeWorkspaceNameWrapper = styled.div`
  width: 100%;
  height: 78px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;

  input {
    width: 80%;
    height: 33px;
    filter: drop-shadow(rgba(16, 24, 40, 0.06) 0px 2px 12px);
    background: rgb(255, 255, 255);
    border-radius: 8px;
    padding: 14px 16px;
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgb(75, 85, 99);
    border: 1px solid rgb(206, 206, 206);
  }
  div {
    display: flex;
    gap: 10px;
    margin: 10px 0;
  }

  .cancel {
    width: 85px;
    height: 26px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background: rgb(249 226 226);
    border-style: none;
    color: rgb(229 25 25);
    cursor: pointer;
    border-radius: 40px;
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
  }

  .save {
    width: 85px;
    height: 26px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background: rgb(221, 249, 226);
    border-style: none;
    color: rgb(3, 212, 45);
    cursor: pointer;
    border-radius: 40px;
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
  }
`;
