/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useMemo, useState } from "react";
import { ForgetWrapper, PasswordEyeWrapper } from "../Forget/ForgetStyle";
import { PublicButton } from "../../../components/PublicButton";
import { useNavigate } from "react-router";
import { Field, Form, Formik } from "formik";
import InputField from "../../../validation/inputField";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
// import { checkEmail } from "../../../services/Collections";
import { message } from "antd";
import { PasswordEye, PasswordEyeOpen } from "../../../utils/Icons/SvgIcons";
import { LogInUser } from "../../../services/Collections";
import { SignInStep } from "./SignInSlice";
import { splashLoading } from "../../../module/Messenger/ChatListIdSlice";

export default function SignInWithEmail() {
  const [loading, setLoading] = useState(false);
  const RememberMeCredentials = useSelector((state) => state?.LoginSlice?.rememberMe);
  const navigate = useNavigate();
  const [password, setPassword] = useState(true);
  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    if (RememberMeCredentials) {
      return {
        email: RememberMeCredentials ? RememberMeCredentials?.email : "",
        password: RememberMeCredentials ? RememberMeCredentials?.password : ""
      };
    } else {
      return {
        email: "",
        password: ""
      };
    }
  }, [RememberMeCredentials]);

  const validationSchema = yup.object().shape({
    email: yup.string().email("Email must be a valid email").required("Email is required"),
    password: yup.string().min(6).required("Password is required")
  });

  const handleForm = async (payload) => {
    setLoading(true);
    let res = await LogInUser(payload);
    if (res?.status === 200) {
      const result = { ...res?.data, token: res?.token };
      dispatch(SignInStep(result));
      if (res?.profile_created) {
        navigate("/private-layout/workspace/regions");
        message.success(res?.message);
      } else {
        navigate("/accept-invitation");
      }
    } else {
      message.error(
        res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
      );
    }
    setLoading(false);
  };

  const FormikFieldValues = [
    {
      label: "Email",
      name: "email",
      type: "email",
      placeholder: "Enter your email address",
      component: InputField,
      eyeComponent: null
    },
    {
      label: "Password",
      name: "password",
      type: password ? "password" : "text",
      placeholder: "Enter your password",
      component: InputField,
      eyeComponent: (
        <PasswordEyeWrapper onClick={() => setPassword(!password)}>
          {password ? <PasswordEye /> : <PasswordEyeOpen />}
        </PasswordEyeWrapper>
      )
    }
  ];

  useEffect(() => {
    dispatch(splashLoading(true));
  }, []);

  return (
    <ForgetWrapper>
      <section style={{ padding: "30px 0px 0px 0px", display: "grid", alignItems: "center" }}>
        {/* <img src={AppLogo} /> */}
        <div>
          <header>Sign in</header>
          <subheader>Sign in to start the communications with your mate</subheader>
          <div className="inner-Section">
            <Formik
              isInitialValid={false}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleForm}
              render={() => (
                <Form>
                  {FormikFieldValues &&
                    FormikFieldValues?.map((field) => (
                      <div
                        key={field?.name}
                        style={{ position: "relative", display: "flex", flexDirection: "column" }}>
                        <label>{field?.label}</label>
                        <Field
                          name={field?.name}
                          type={field?.type}
                          placeholder={field?.placeholder}
                          component={field?.component}
                        />
                        {field?.eyeComponent}
                      </div>
                    ))}
                  <div className="forgot">
                    <span onClick={() => navigate("/forgot-password")}>Forgot Password</span>
                  </div>

                  <div className="btn-wrapper" style={{ marginBottom: "15px" }}>
                    <PublicButton textcard={"Continue"} isLoading={loading} />
                  </div>
                </Form>
              )}
            />
          </div>
          <footer>
            Not signed up yet? <span onClick={() => navigate("/signup")}>Create an account</span>
          </footer>
        </div>
      </section>
    </ForgetWrapper>
  );
}
