// Auth Apis

import { deleteApi, getApi, postApi, putApi } from "./ApiMethod";
import {
  getApiExternal,
  postApiExternal,
  putApiExternal
} from "./ExternalCxcTeam/ApiMethodForExternal";

const LOGIN_USER = "user/login";
const GOOGLE_SIGNUP = "user/loginWithGoogleId";
const FORGOT_PASSWORD = "user/forgotPassword";
const SETNEW_PASSWORD = "user/reset-Password";

const GET_WORKSPACE_LISTING = "user/getWorkSpaceList";

const CREATE_WORKSPACE = "/user/createWorkSpace";
const CREATE_PAYER = "/payment/addPayerDetails";
const UPDATE_PAYER = "/payment/updatePayerDetails";

// Payment apis
const SAVED_CARD_DETAILS = "payment/createCustomerAndAttachPaymentMethod";
const PAYMENT_INTENT = "payment/createPaymentIntent";

const invitetoworks = "/workspace/invite-to-workspace";
const UPSDATE_SEEN_STATUS = "pusher/update-seen-status";

// Auth Apis

export const loginUser = (payload) => {
  return postApi(LOGIN_USER, payload);
};
export const SignupWithGoogle = (payload) => {
  return postApi(GOOGLE_SIGNUP, payload);
};
export const forgotPassword = (payload) => {
  return postApi(FORGOT_PASSWORD, payload);
};
export const resetPassword = (payload) => {
  return postApi(SETNEW_PASSWORD, payload);
};

export const getWorkspaceListing = () => {
  return getApi(GET_WORKSPACE_LISTING);
};

// Payment apis

export const savedCardPaymentMethod = (payload) => {
  return postApi(SAVED_CARD_DETAILS, payload);
};

export const createPaymentIntent = (payload) => {
  return postApi(PAYMENT_INTENT, payload);
};

export const createWorkspace = (payload) => {
  return postApi(CREATE_WORKSPACE, payload);
};

export const createPayer = (payload) => {
  return putApi(CREATE_PAYER, payload);
};

export const editPayer = (payload) => {
  return putApi(UPDATE_PAYER, payload);
};
// New API collection start here
const CHECK_EMAIL = "/user/sign-up";
const VERIFY_OTP = "/user/verify-otp";
const LOG_IN_USER = "/user/log-in";
const SIGN_UP_USER = "/user/sign-up";
const Forget_Password = "user/forgot-password";
const RESEND_OTP = "user/resend-otp";

// workSpace api
const WORKSPACE_CREATE = "/workspace/create-workspace";
const WORKSPACE_INVITAITON = "/workspace/view-workspace-invitations";
const GET_USERS_LISTING = "/workspace/view-workspace-members";
const ADD_REGIONS = "yapi/region-mapping";
const GET_REGIONS_LIST = "yapi/region-mapping";
const BUSINESS_LIST = "yapi/business-units";
const JOBS_LIST = "yapi/jobclass";
const GET_WORKSPACE_LIST = "workspace/view-workspaces";
const CREATE_NEW_WORKSPACE = "workspace/create-workspace";
const UPDATE_PROFILE_STATUS = "workspace/update-connection-status";
const SWITCH_WORKSPACE = "workspace/switch-workspace";
const EDIT_WORKSPACE = "workspace/edit-workspace-name";

const ADD_WORKSPACE_MEMBER = "workspace/invite-to-workspace";
const EDIT_WORKSPACE_MEMBER = "workspace/edit-workspace-member";
const CHECK_MEMBER_EXIST = "workspace/check-workspace-user";
const DELETE_WORKSPACE = "workspace/delete-workspace-from-admin";
const REQUEST_FOR_ADMIN = "workspace/request-role";
const deactive_workspace = "yapi/workspaces/inactivity";
const UPDATE_INTERNAL_DB_FOR_DEACTIVE = "workspace/update-crm-connection-status";
const VIEW_JOBS = "workspaceJob/view-user-all-jobs";
const VIEW_JOB_INFO = "workspaceJob/view-job";
const ADD_REMOVE_FROM_JOBS = "workspaceJob/add-job-member";
const SEARCH_JOB_LISTING = "workspaceJob/search-job-on-admin-side";
const SEARCH_CHAT_LISTING = "workspaceJob/search-view-individual-channels-admin-side";
const MESSAGE_HISTORY = "pusher/message-history";
const SEND_MESSAGE = "pusher/send-message";
const DELETE_MESSAGE = "workspaceJob/delete-message";
let PUSHER_READ_MESSAGE = "pusher/read-messages";
const VIEW_JOB_MEMBER = "workspaceJob/view-job-members";
const JOBS_INFO = "yapi/jobs/info";
const VIEW_WORKSPACE_USERS = "workspace/view-workspace-users-on-admin";
const BEFORE_MESSAGE_HISTORY = "pusher/message-history-before-message";

export const checkEmail = (payload) => {
  return postApi(CHECK_EMAIL, payload);
};

export const verifyOtp = (payload) => {
  return postApi(VERIFY_OTP, payload);
};

export const createWorkSpace = (payload) => {
  return postApi(WORKSPACE_CREATE, payload);
};
export const InviteWorkSpace = (payload) => {
  return postApi(invitetoworks, payload);
};

export const getWorkSpaceInvitation = () => {
  return getApi(WORKSPACE_INVITAITON);
};

export const getAllUsersListing = (payload) => {
  return getApi(`${GET_USERS_LISTING}?${payload}`);
};

export const addRegions = (db, payload) => {
  return postApiExternal(`${ADD_REGIONS}?${db}`, payload);
};
export const getRegionListing = (payload) => {
  return getApiExternal(`${GET_REGIONS_LIST}?${payload}`);
};
export const getBusinessListing = (db) => {
  return getApiExternal(`${BUSINESS_LIST}?${db}`);
};
export const getJobsListing = (db) => {
  return getApiExternal(`${JOBS_LIST}?${db}`);
};
export const updateBusinessRegion = (db, payload) => {
  return postApiExternal(`${BUSINESS_LIST}?${db}`, payload);
};
export const updateJobTypeWebhook = (db, payload) => {
  return postApiExternal(`${JOBS_LIST}?${db}`, payload);
};
export const getAllWorkspaceListing = () => {
  return getApi(GET_WORKSPACE_LIST);
};
export const createNewWorkspace = (payload) => {
  return postApi(CREATE_NEW_WORKSPACE, payload);
};
export const updateProfileStatus = (payload) => {
  return postApi(UPDATE_PROFILE_STATUS, payload);
};
export const switchWorkspace = (payload) => {
  return postApi(SWITCH_WORKSPACE, payload);
};

export const EditWorkspace = (payload) => {
  return postApi(EDIT_WORKSPACE, payload);
};
export const addWorkspaceMembers = (payload) => {
  return postApi(ADD_WORKSPACE_MEMBER, payload);
};
export const editWorkspaceMembers = (payload) => {
  return postApi(EDIT_WORKSPACE_MEMBER, payload);
};
export const checkMemberExisting = (payload) => {
  return postApi(CHECK_MEMBER_EXIST, payload);
};
export const deleteWorkspaceByOwner = (payload) => {
  return deleteApi(`${DELETE_WORKSPACE}?${payload}`);
};
export const requestForAdmin = (payload) => {
  return postApi(REQUEST_FOR_ADMIN, payload);
};

const ACTIVE_WORKSPACE = "yapi/workspaces";
export const getImportST = (payload) => {
  return getApiExternal(`${ACTIVE_WORKSPACE}/${payload}`);
};

const UPDATE_IMPORTSERVICETITAN = "yapi/workspaces/import-to-servicetian";
export const updateImportServicetian = (payload) => {
  return putApiExternal(UPDATE_IMPORTSERVICETITAN, payload);
};

export const deactiveWorkspace = (payload) => {
  return putApiExternal(deactive_workspace, payload);
};
export const updateInternalDbForDeactiveWorkspace = (payload) => {
  return postApi(UPDATE_INTERNAL_DB_FOR_DEACTIVE, payload);
};

export const getAllJobsListing = (payload) => {
  return getApi(`${VIEW_JOBS}?${payload}`);
};

export const getJobInfo = (payload) => {
  return getApi(`${VIEW_JOB_INFO}?${payload}`);
};

export const addRemoveFromJobs = (payload) => {
  return postApi(ADD_REMOVE_FROM_JOBS, payload);
};

export const searchJobListing = (payload) => {
  return getApi(`${SEARCH_JOB_LISTING}?${payload}`);
};
export const searchChatListing = (payload) => {
  return getApi(`${SEARCH_CHAT_LISTING}?${payload}`);
};

export const fetchingMessageHistory = (payload,obj) => {
  return getApi(`${MESSAGE_HISTORY}?${payload}`,obj);
};

export const sendMessage = (payload) => {
  return postApi(SEND_MESSAGE, payload);
};

export const DeleteMessage = (payload) => {
  return deleteApi(DELETE_MESSAGE, payload);
};

export const updateSeenStatusMessage = (payload) => {
  return postApi(UPSDATE_SEEN_STATUS, payload);
};

export const readMessage = (payload) => {
  return postApi(PUSHER_READ_MESSAGE, payload);
};

export const viewJobMember = (payload) => {
  return getApi(`${VIEW_JOB_MEMBER}?${payload}`);
};

export const getJobInformation = (payload) => {
  return getApiExternal(`${JOBS_INFO}?${payload}`);
};

export const ViewWorkspaceUsers = (payload) => {
  return getApi(`${VIEW_WORKSPACE_USERS}?${payload}`);
};

const UPDATE_ACTIVE_USER = "/workspace/update-workspace-job-active-users";
export const updateActiveUser = (payload) => {
  return postApi(UPDATE_ACTIVE_USER, payload);
};

const CREATE_ONE_TO_ONE_CHAT = "/workspaceJob/create-one-to-one-chat";
export const oneToOneChatInititate = (payload) => {
  return postApi(CREATE_ONE_TO_ONE_CHAT, payload);
};

const AWS_DETAILS = "user/get-bucket-detatils";

export const getAwsDetails = () => {
  return getApi(AWS_DETAILS);
};

const CREATE_GROUP = "workspaceJob/create-group-chat";
export const createGroupChat = (payload) => {
  return postApi(CREATE_GROUP, payload);
};

const ADD_MEMBERS_TO_GROUP = "workspaceJob/add-member-to-group";
export const addMemberToGroup = (payload) => {
  return postApi(ADD_MEMBERS_TO_GROUP, payload);
};

const GET_GROUPS_LISTING = "workspaceJob/search-view-group-channels-admin-side";
export const getGroupListing = (payload) => {
  return getApi(`${GET_GROUPS_LISTING}?${payload}`);
};

const GET_WORKSPACE_INFO = "workspace/view-user-workspace-profile";

export const getWorkspaceDetails = (id) => {
  return getApi(`${GET_WORKSPACE_INFO}?workspace_id=${id}`);
};

const DELETE_GROUP = "workspaceJob/delete-group";
export const deleteGroup = (payload) => {
  return deleteApi(`${DELETE_GROUP}?${payload}`);
};

const GLOBAL_SEARCH = "workspaceJob/global-search-channels";
export const searchGlobal = (payload) => {
  return getApi(`${GLOBAL_SEARCH}?${payload}`);
};

export const LogInUser = (payload) => {
  return postApi(LOG_IN_USER, payload);
};

export const SignUpUser = (payload) => {
  return postApi(SIGN_UP_USER, payload);
};

export const ForgetPasswordApi = (payload) => {
  return postApi(Forget_Password, payload);
};

export const ResendOtp = (payload) => {
  return postApi(RESEND_OTP, payload);
};

export const getBeforeMessageHistory = (payload) => {
  return getApi(`${BEFORE_MESSAGE_HISTORY}?${payload}`);
};