import styled from "styled-components";

export const Stepsection = styled.div`
  width: 75%;
  background-color: white;
  text-align: center;
  padding: 56px 0px !important;
  max-height: 550px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 5px;
    height: 2px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
  }
  ::-webkit-scrollbar-thumb {
    background: #cecece;
    border-radius: 10px;
  }
  @media (max-width: 834px) {
    padding: 32px 24px !important;
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 32px 24px !important;
  }
`;

export const Heading = styled.div`
font-family: Lato, sans-serif;
  font-style: normal;

  .stepstage {
    font-size: 14px;
    font-weight: 700;
  }

  .heading {
    color: #3d3d3d;
    font-size: 36px;
    margin-top: 32px;
    text-align: center;
    font-weight: 700;
    @media (max-width: 550px) {
      font-size: 24px;
      line-height: 136%;
      margin-top: 16px;
    }
  }

  .lighttext {
    padding: 10px 0px;
    color: #b8b8b8;
    text-align: center;
    font-size: 20px;
    font-weight: 400;

    @media (max-width: 550px) {
      font-size: 16px;
      line-height: 160%;
    }
  }
`;
export const Answers = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${(props) => props.paddingTop || "56px !important"};
  margin-bottom: 60px !important;
  @media (max-width: 550px) {
    padding-top: 40px !important;
  }

  .field {
    /* width: 450px; */
    width: ${({ FieldWidth }) => FieldWidth || "450px"};

    .removeEle {
      position: absolute;
      width: 20px;
      height: 20px;
      right: -32px;
      top: 18px;
      cursor: pointer;
      @media (max-width: 600px) {
        right: 18px;
        top: 18px;
      }
      svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .nextbtn {
      width: 100%;
      padding: 14px 0;
      border-radius: 4px;
      color: white;
      background-color: #3d97f2;
      border: none;
      color: #fff;
      text-align: center;
      font-family: Lato, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;

      letter-spacing: 0.16px;
      text-transform: uppercase;
    }
  }
  .team {
    display: flex;
    justify-content: space-between;
  }
  .company {
    font-family: Lato, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 136%;
    letter-spacing: 0.12px;
    text-transform: uppercase;
    font-size: 12px;
    color: #3d3d3d;
  }
  .number {
    font-size: 14px;
    color: #b8b8b8;
  }
  .input-field {
    width: 100%;
    padding: 17px 16px;
    font-size: 16px;
    border: 1px solid #f5f5f5;
    color: #000;
    margin: 8px 0 32px 0;
    height: 56px;
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 136%;

    &:focus {
      outline: none;
    }
    &::placeholder{
      color: #ababab;
    }
  }
  .check {
    display: flex;
    gap: 10px;
    margin: 20px 0px;
    p {
      color: #8f8f8f;
    }
  }
  .or {
    display: flex;
    align-items: center;
    margin: 20px 0px;
    gap: 10px;

    p {
      color: #000;
      text-align: center;
      font-family: Lato, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }
    .lines {
      width: 100%;
      height: 1px;
      background-color: #e3e3e3;
    }
  }
  .btns {
    display: flex;
    gap: 10px;
  }
  .add {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #3d97f2;
    margin: 16px 0px 8px 0px;
    cursor: pointer;
    width: fit-content;

    span {
      font-size: 24px;
    }
    p {
      color: #3d97f2;
      font-family: Lato, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 136%;
    }
  }
  .skip {
    div {
      margin: 20px 0px;
      span {
        color: #3d97f2;
        font-family: Lato, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 136%;
        cursor: pointer;
      }
    }
  }
`;

export const Backbtn = styled.button`
  width: 100%;
  border-radius: 10px;
  color: black;
  background-color: #f5f5f5;
  padding: 14px 0px !important;
  border: none;
`;
export const Invitebtn = styled.button`
  width: 100%;
  border-radius: 10px;
  color: #3d97f2;
  background-color: #ecf5fe;
  padding: 20px !important;
  border: none;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  ${"" /* margin-bottom: 60px; */}
`;
