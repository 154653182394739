import { Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  AboutInfoIcon,
  PhoneNumber,
  ProfileEmailIcon,
  RegionIcon,
  StatusSvgIcon
} from "../../utils/Icons/SvgIcons";
import { getJobInfo } from "../../services/Collections";
import { useSelector } from "react-redux";
import { ModalLoader } from "../../components/ModalLoader";

export const ProfileInfo = ({ open, handleModal }) => {
  const [loading, setLoading] = useState(true);
  const [jobInfo, setJobInfo] = useState(null);
  const selectedChat = useSelector((state) => state?.IndividualChat?.selectedChat);
  const loginDetails = useSelector((state) => state?.LoginSlice?.data);

  const handleJobInfoData = async () => {
    setLoading(true);
    let params = new URLSearchParams();
    params.append("job_id", selectedChat?._id);
    params.append("user_id", loginDetails?._id);
    params.append("workspace_id", loginDetails?.workspace?._id);
    params.append("type", 2);
    let res = await getJobInfo(params?.toString());
    if (res?.status === 200) {
      let result = res?.data?.userData?.find((el) => el?._id != loginDetails?._id);
      let obj = {
        ...result,
        email: res?.data?.email
      };
      setJobInfo(obj);
      setLoading(false);
    } else {
      setLoading(false);
      message.error(
        res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
      );
    }
  };

  const GenerateAvatarName = (payload) => {
    let name = payload?.toString();

    let first = name?.split(" ")?.[0]?.slice(0, 1);
    let second = name?.split(" ")?.[1]?.slice(0, 1);
    if (first && second) {
      // return first + second;
      return first;
    } else if (first) {
      return first;
    } else if (second) {
      return second;
    }
  };

  useEffect(() => {
    handleJobInfoData();
  }, []);

  return (
    <div>
      <Modal
        centered
        open={open}
        onCancel={handleModal}
        width={976}
        footer={false}
        prefixCls="profileInfo-modal">
        <>
          {loading ? (
            <div
              style={{
                height: "90vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}>
              <ModalLoader size={45} color={"#00adff"} />
            </div>
          ) : (
            <ProfileInfoWrapper>
              <div className="profile">
                <div className="name-box">
                  <div className="img-box">
                    {jobInfo?.profile_url ? (
                      <img src={jobInfo?.profile_url} alt="profile-img" />
                    ) : (
                      <p style={{ textTransform: "capitalize" }}>
                        {GenerateAvatarName(jobInfo?.name)}
                      </p>
                    )}
                  </div>
                  <div className="name-inner">
                    <h2>{jobInfo?.name}</h2>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <p style={{ textTransform: "capitalize" }}>{jobInfo?.user_type}</p>
                    </div>
                  </div>
                </div>
                {/* <div className="btn-box">
              <div className="btn">
                <button>Edit Profile</button>
              </div>
            </div> */}
              </div>
              <div className="info">
                <div>
                  <h6 className="label">Information</h6>
                  <div className="about">
                    <div className="icon-box">
                      <AboutInfoIcon />
                    </div>
                    <div className="about-inner">
                      <h3>About</h3>
                      <h4>{jobInfo?.about}</h4>
                    </div>
                  </div>
                  <div className="about">
                    <div className="icon-box">
                      <StatusSvgIcon />
                    </div>
                    <div className="about-inner">
                      <div style={{ width: "100%" }}>
                        <h3>Status</h3>
                        {jobInfo?.user_active ? (
                          <h4 style={{ marginTop: "7px", color: "#16c60c" }}>🟢 Active</h4>
                        ) : (
                          <h4 style={{ marginTop: "7px", color: "#e81224" }}>🔴 Inactive</h4>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info" style={{ marginTop: "30px" }}>
                <div>
                  <h6 className="label">Contact Info</h6>
                  <div className="about">
                    <div className="icon-box">
                      <ProfileEmailIcon />
                    </div>
                    <div className="about-inner">
                      <h3>Email</h3>
                      <h4>{jobInfo?.email?.[0]}</h4>
                    </div>
                  </div>
                  <div className="about">
                    <div className="icon-box">
                      <PhoneNumber />
                    </div>
                    <div className="about-inner">
                      <h3>Phone</h3>
                      <h4>{jobInfo?.phone ?? "N/A"}</h4>
                    </div>
                  </div>
                  <div className="about">
                    <div className="icon-box">
                      <RegionIcon />
                    </div>
                    <div className="about-inner">
                      <h3>Region</h3>
                      <h4>{jobInfo?.region ?? "N/A"}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </ProfileInfoWrapper>
          )}
        </>
      </Modal>
    </div>
  );
};

ProfileInfo.propTypes = {
  open: PropTypes.bool,
  handleModal: PropTypes.func
};

const ProfileInfoWrapper = styled.div`
  width: 100%;
  padding: 20px 40px;

  .profile {
    display: flex;
    justify-content: space-between;
    align-items: end;

    .name-box {
      display: flex;
      align-items: end;
      gap: 20px;

      h2 {
        color: #3d3d3d;
        font-family: Lato, sans-serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%;
      }

      p {
        margin: 0;
        color: #8f8f8f;
        font-family: Lato, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 160%;
      }
    }

    .img-box {
      width: 120px;
      height: 120px;
      background: #d8f0f9;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 7px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 7px;
      }
      p {
        font-family: Lato, sans-serif;
        font-size: 40px;
        color: #00adff;
      }
    }
  }

  .btn-box {
    .btn {
      button {
        padding: 12px 32px;
        border-radius: 4px;
        background: #3d97f2;
        border-style: none;
        color: #fff;
        text-align: center;
        font-family: Lato, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 19.6px */
        letter-spacing: 0.14px;
        text-transform: uppercase;
      }
    }
  }

  .info {
    width: 65%;
    margin: auto;
    margin-top: 50px;

    .label {
      color: #000;
      font-family: Lato, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
    }
    .about {
      display: flex;
      gap: 16px;
      margin-top: 10px;
      .about-inner {
        margin-top: 4px;
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 7px;
        h3 {
          color: #8f8f8f;
          font-family: Lato, sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 14.4px */
          letter-spacing: 0.12px;
          text-transform: uppercase;
        }

        h4 {
          color: #3d3d3d;
          font-family: Lato, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
        }
      }
    }

    .icon-box {
      ${"" /* padding: 10px; */}
      width: 60px;
      height: 50px;
      ${"" /* height: max-content; */}
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background: #f5f5f5;
    }
  }
`;
