/* eslint no-use-before-define: 0 */ // --> OFF
/* eslint-disable */

import React, { useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { PauseIcon, PlayIcon, VolumeUp, VolumeMute } from "../utils/Icons/SvgIcons";
import { UserContext } from "./ChatInbox";

const AudioPlayer = ({ tracks, id }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [mute, setMute] = useState(false);
  const { handleRef } = useContext(UserContext);

  useEffect(() => {
    const audio = audioRef.current;
    audio.addEventListener("loadeddata", () => {
      setDuration(audio.duration);
    });
    audio.addEventListener("timeupdate", () => {
      setCurrentTime(audio.currentTime);
    });
  }, []);

  const togglePlayPause = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleSeek = (event) => {
    const audio = audioRef.current;
    audio.currentTime = (event.target.value / 100) * audio.duration;
  };

  const handleMute = () => {
    audioRef.current.muted = !mute;
    setMute(!mute);
  };

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);

    let newtime = [String(minutes).padStart(2, "0"), String(seconds).padStart(2, "0")];
    if (hours > 0) {
      newtime.unshift(String(hours).padStart(2, "0"));
    }

    return newtime.join(":");
  };

  return (
    <>
      <AudioTrackWrapper>
        <audio id={id} ref={audioRef} onPlay={() => handleRef(audioRef)}>
          <source src={tracks} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>

        <button onClick={togglePlayPause}>{isPlaying ? <PauseIcon /> : <PlayIcon />}</button>
        <input
          type="range"
          min="0"
          max="100"
          value={(currentTime / duration) * 100 || 0}
          onChange={handleSeek}
        />
        <span>{formatTime(currentTime)}</span>
        <i onClick={handleMute}>{mute ? <VolumeMute /> : <VolumeUp />}</i>
      </AudioTrackWrapper>
    </>
  );
};

export default AudioPlayer;

AudioPlayer.propTypes = {
  tracks: PropTypes.string,
  id: PropTypes.string
};

const AudioTrackWrapper = styled.div`
  width: 280px;
  height: 70px;
  border: 1px solid rgb(191, 189, 189);
  border-radius: 8px !important;
  padding: 0px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  /* @media (max-width: 561px) {
    width: 100%;
  } */

  button {
    width: 35px;
    height: 35px;
    border: 1px solid transparent;
    border-radius: 50%;
    background: #4575ff;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  i {
    height: 25px;
    cursor: pointer;
  }

  input[type="range"] {
    width: 130px;
  }
`;
