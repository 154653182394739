import { Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
  addRemoveFromJobs,
  getJobInfo,
  getJobInformation,
  oneToOneChatInititate
} from "../../services/Collections";

import { ModalLoader } from "../../components/ModalLoader";
import { DeleteModal } from "../../components/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { AddMembers } from "./AddMembers";
import { CrossRedIcon, GreenTickIcon } from "../../utils/Icons/SvgIcons";
import { selectedChat } from "../Messenger/IndividualChatSlice";
import { useLocation, useNavigate } from "react-router-dom";
// import { NoManager } from "../../utils/Images/Images";

export const JobInfoModal = ({ open, handleModal, data, userID }) => {
  const [loading, setLoading] = useState(true);
  const [jobInfo, setJobInfo] = useState(null);
  const [removeModal, setRemoveModal] = useState(false);
  const [removeIds, setRemoveIds] = useState(null);
  const [removeLoading, setRemoveLoading] = useState(false);
  const userDetails = useSelector((state) => state?.LoginSlice?.data);
  const [addMemberModal, setAddMemberModal] = useState(false);
  const connectedWorkspace = useSelector((state) => state?.LoginSlice?.data);
  const [jobInfoAdd, setJobInfoAdd] = useState(null);
  const jobName = useSelector((state) => state?.ChatId?.job_Id?.jobname);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // eslint-disable-next-line no-unused-vars
  const handleIndividualChat = async (id) => {
    let payload = {
      workspace_id: userDetails?.workspace?._id,
      user1_id: userDetails?._id,
      user2_id: id
    };
    let res = await oneToOneChatInititate(payload);
    if (res?.status === 200) {
      dispatch(selectedChat(res?.data));
      localStorage.setItem("dummy", res?.data?._id);
      navigate(`/private-layout/individual/${id}`);
    } else {
      message.error(res?.response?.data?.message);
    }
  };

  const handleJobInfoData = async () => {
    setLoading(true);
    let params = new URLSearchParams();
    params.append("job_id", data?._id);
    params.append("user_id", userID);
    params.append("workspace_id", connectedWorkspace?.workspace?._id);
    let res = await getJobInfo(params?.toString());
    if (res?.status === 200) {
      setJobInfo(res?.data);
      setLoading(false);
    } else {
      setLoading(false);
      message.error(
        res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
      );
    }
  };

  const handleAddRemoveFromJobs = async () => {
    setRemoveLoading(true);
    let requestPayload = {
      job_id: data?._id,
      user_ids: removeIds,
      type: 2,
      workspace_id: connectedWorkspace?.workspace?._id,
    };
    let res = await addRemoveFromJobs(requestPayload);
    if (res?.status === 200) {
      message.success(res?.message);
      handleJobInfoData();
      setRemoveLoading(false);
      setRemoveModal(false);
    } else {
      setRemoveLoading(false);
      message.error(
        res?.response?.data?.message || res?.message || res?.error || "Something went wrong"
      );
    }
  };

  const handleJobInfo = async () => {
    let param = new URLSearchParams();
    let dbname =
      "yapi_" + connectedWorkspace?.workspace?._id + "_" + connectedWorkspace?.workspace?.tenant_id;

    param.append("db", dbname);
    param.append("job", jobName);

    // have info data
    // dbName="yapi_64a256ef4e71af824b163227_372955144"
    // jobname="tampa_10_roads_express_210051691"

    let res = await getJobInformation(param.toString());
    if (res?.status === 200) {
      setJobInfoAdd(res?.data);
    }
  };

  const GenerateAvatarName = (payload) => {
    let name = payload?.toString();

    let first = name?.split(" ")?.[0]?.slice(0, 1);
    return first;
  };

  const handleRemoveModalClose = () => {
    setRemoveModal(false);
  };

  useEffect(() => {
    if (connectedWorkspace?.workspace?.tenant_id != null) {
      handleJobInfo();
    }
    handleJobInfoData();
  }, []);

  return (
    <div>
      {addMemberModal && (
        <AddMembers
          open={addMemberModal}
          handleModal={() => setAddMemberModal(false)}
          data={jobInfo}
          jobInfoApi={handleJobInfoData}
        />
      )}
      {removeModal && (
        <DeleteModal
          handleClose={handleRemoveModalClose}
          open={removeModal}
          title="Are you sure you want to Remove this user from your job ?"
          description=""
          button="Remove"
          btnColor="#E93124"
          handleSubmit={handleAddRemoveFromJobs}
          isLoading={removeLoading}
        />
      )}
      <Modal
        open={open}
        centered
        width={421}
        footer={false}
        onCancel={handleModal}
        prefixCls="modalStyle">
        <AssignManagerWrapper>
          {!loading ? (
            <>
              <div className="title">
                <div className="name-avatar">
                  {jobInfo?.image_url ? (
                    <img src={jobInfo?.image_url} alt="" />
                  ) : (
                    <h1>{GenerateAvatarName(jobInfo?.name)?.toUpperCase()}</h1>
                  )}
                </div>

                <div style={{ textAlign: "center" }}>
                  <p style={{ maxWidth: "300px" }}>
                    <b>{jobInfo?.name}</b>
                  </p>
                  <p style={{ textAlign: "center" }}>
                    {location?.pathname?.includes("group") ? "Group" : "Job"}
                  </p>
                </div>
              </div>

              {!location?.pathname?.includes("group") && (
                <div style={{ borderTop: "1px solid #ffffff", marginBottom: "30px" }}>
                  <div className="infoHeading">Information</div>
                  <div className="infoData">
                    <span className="spanHeading">JOB NUMBER</span>
                    <span className="spanData">{jobInfoAdd?.jobNumber ?? "--"}</span>
                    <span className="spanHeading">JOB STATUS</span>
                    <span className="spanData">{jobInfoAdd?.jobStatus ?? "--"}</span>
                    <span className="spanHeading">JOB ESTIMATE</span>
                    <span className="spanData">
                      {jobInfoAdd?.job_estimates ? `$${jobInfoAdd?.job_estimates}` : "--"}
                    </span>
                    <span className="spanHeading">INVOICE TOTAL</span>
                    <span className="spanData">
                      {jobInfoAdd?.invoice_total ? `$${jobInfoAdd?.invoice_total}` : "--"}
                    </span>
                    <span className="spanHeading">CAMPAIGN</span>
                    <span className="spanData">{jobInfoAdd?.campaign ?? "--"}</span>
                    <span className="spanHeading">OPPORTUNITY</span>
                    <span className="spanData">
                      {jobInfoAdd ? (
                        <>
                          {jobInfoAdd?.opportunity === 0 ? (
                            <div className="icons">
                              <CrossRedIcon />
                              <span style={{ color: "red" }}>No</span>
                            </div>
                          ) : (
                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                              <GreenTickIcon />
                              <span style={{ color: "green" }}>Yes</span>
                            </div>
                          )}
                        </>
                      ) : (
                        "--"
                      )}
                    </span>
                    <span className="spanHeading">PRIORITY</span>
                    <span className="spanData">{jobInfoAdd?.priority ?? "--"}</span>
                    <span className="spanHeading">JOB TYPE</span>
                    <span className="spanData">{jobInfoAdd?.jobtype ?? "--"}</span>
                  </div>
                  <div className="spanHeading" style={{ margin: "10px 0 5px 0" }}>
                    JOBS TAGS
                  </div>
                  {jobInfoAdd?.jobtags && (
                    <div className="tags">
                      {jobInfoAdd?.jobtags.split(",").map((val, index) => {
                        return <span key={index}>{val}</span>;
                      })}
                    </div>
                  )}
                </div>
              )}

              {(userDetails?.user_type?.toLowerCase() == "owner" || userDetails?.user_type?.toLowerCase() == "admin") && (
                <div className="add-section">
                  <button onClick={() => setAddMemberModal(true)}>
                    <h2>+</h2> Add Member
                  </button>
                </div>
              )}

              <section>
                <div className="members-title">
                  <h3>Members({jobInfo?.userData?.length})</h3>
                </div>
                <div className="members-listing">
                  {jobInfo?.userData?.length > 0 &&
                    jobInfo?.userData?.map((el, idx) => (
                      <div key={idx} className="manager-listing">
                        {/* {console.log(el?._id, "listtttttttttttttttttttttttttttttttttttttttttttttt")} */}
                        <div
                          style={{ cursor: "pointer" }}
                          className="img-flex"
                          onClick={() =>
                            el?._id != userDetails?._id && handleIndividualChat(el?._id)
                          }>
                          {el?.profile_url ? (
                            <img src={el?.profile_url} alt="" />
                          ) : (
                            <div className="defaultAvatar">
                              <h5>{GenerateAvatarName(el?.name)?.toUpperCase()}</h5>
                            </div>
                          )}
                          <h3>{el?.name}</h3>
                        </div>

                        {(userDetails?.user_type?.toLowerCase() == "owner" || userDetails?.user_type?.toLowerCase() == "admin") &&
                          userDetails?._id != el?._id &&
                          el?.user_type != "owner" && (
                            <button
                              className="removeButton"
                              onClick={() => {
                                setRemoveModal(true);
                                setRemoveIds(el?._id);
                              }}>
                              Remove
                            </button>
                          )}
                      </div>
                    ))}
                </div>
              </section>

              <div className="btn-wrapper">
                <button onClick={handleModal}>Close</button>
              </div>
            </>
          ) : (
            <div className="loader-box">
              <ModalLoader size={45} color={"#00adff"} />
            </div>
          )}
        </AssignManagerWrapper>
      </Modal>
    </div>
  );
};

JobInfoModal.propTypes = {
  handleModal: PropTypes.any,
  open: PropTypes.boolean,
  data: PropTypes.any,
  userID: PropTypes.any
};

const AssignManagerWrapper = styled.div`
  width: 100%;
  height: 600px;
  overflow-y: scroll;
  padding: 24px;

  .members-listing {
    min-height: 240px;
  }

  @media (max-height: 630px) {
    height: 500px;
  }
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background: #fafafa;
  }
  ::-webkit-scrollbar-thumb {
    background: #e0e0e0;
    border-radius: 4px;
  }

  .members-title {
    font-family: Lato, sans-serif;
  }

  .add-section {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 15px;

    button {
      display: flex;
      background: transparent;
      width: 80%;
      justify-content: center;
      align-items: center;
      border-style: none;
      border: 1px solid rgb(0, 173, 255);
      color: rgb(0, 173, 255);
      cursor: pointer;
      height: 40px;
      gap: 10px;
      border-radius: 5px;
      font-family: Lato, sans-serif;
    }
  }

  .loader-box {
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .removeButton {
    background: rgb(255, 222, 220);
    color: rgb(232, 43, 31);
    border-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 22px;
    padding: 8px;
    border-radius: 100px;
    gap: 6px;
    font-size: 9px;
    font-weight: 700;
    line-height: 9px;
    letter-spacing: 0em;
    text-align: left;
    font-family: Lato, sans-serif;
    cursor: pointer;
    text-transform: uppercase;
    white-space: nowrap;
  }

  .title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .name-avatar {
      width: 75px;
      height: 60px;
      border-radius: 5px;
      background: #09b3f324;
      color: #00adff;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        object-fit: cover;
      }
    }

    h3 {
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: #101010;
    }
    p {
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #4b5563;
      margin-top: 8px;
    }
  }
  .search-bar {
    width: 100%;
    margin-top: 24px;

    input {
      width: 100%;
      height: 52px;
      background: #f2f4f5;
      border-radius: 500px;
      border-style: none;
      font-family: Lato, sans-serif;
      font-style: normal;
      padding-left: 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #8e959d;
      :focus {
        outline: none;
      }
    }
  }

  section {
    margin-top: 30px;
    overflow-y: scroll;
    /* height: 300px; */
    height: auto;
    padding: 0 10px 0 0;

    ::-webkit-scrollbar {
      width: 3px;
      height: 2px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #fff;
    }
    ::-webkit-scrollbar-thumb {
      background: #cecece;
      border-radius: 10px;
    }

    .manager-listing {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 14px 0;
      align-items: center;

      .img-flex {
        display: flex;
        align-items: center;
        gap: 12px;

        img {
          width: 40px;
          height: 40px;
          border-radius: 5px;
        }
        .defaultAvatar {
          width: 40px;
          height: 40px;
          border-radius: 5px;
          background: #09b3f324;
          color: #00adff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 19px;
        }

        h3 {
          font-family: Lato, sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          color: #101010;
        }
      }
      .radio-flex {
        display: flex;
        align-items: center;

        input {
          width: 24px;
          height: 24px;
          accent-color: #000000;
        }
      }
    }
  }

  .no-manager {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 53px;

    img {
      height: 188px;
      width: 188px;
    }

    p {
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #101010;
      margin-top: 23px;
    }
  }

  .btn-wrapper {
    width: 100%;
    margin-top: 38px;

    button {
      width: 100%;
      background: #000000;
      border-radius: 500px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Lato, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      border-style: none;
    }
  }
  .infoHeading {
    font-family: Lato, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    padding: 10px 0px;
    border-top: 1px solid #d8d4d4;
  }
  .infoData {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px 0;
  }
  .spanHeading {
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #a2a0a0;
  }
  .spanData {
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
  .tags {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    span {
      background: #e3f5fd;
      padding: 4px 10px;
      border-radius: 5px;
    }
  }
  .icons {
    display: flex;
    alignitems: center;
    gap: 5px;

    svg {
      width: 20px;
      height: auto;
      fill: #f16267;
    }
  }
`;
