import React, { useState } from "react";
import styled from "styled-components";
import { SettingNavIcon, SingleDownArrow, SingleUpArrow } from "../utils/Icons/SvgIcons";
import PropTypes from "prop-types";
import { GroupChat } from "./TeamSideNav";
import { useSelector } from "react-redux";
import { ConnectCrmModal } from "./ConnectCrmModal";

function SettingSideNav({ extractGroup, setSetting, calcHeight }) {
  const [addNewCrnModal, setAddNewCrnModal] = useState(false);
  const workspaceCRM = useSelector((state) => state?.WorkSpaceSlice?.workSpaceData);
  const connectCrm = Array.isArray(workspaceCRM)
    ? workspaceCRM?.find((ele) => ele?.default_workspace)
    : workspaceCRM;

  const handleExtractionofGroup = () => {
    setSetting(!extractGroup);
  };

  return (
    <MessengerSidenavWrapper calcHeight={calcHeight}>
      <GroupChat isOpen={extractGroup}>
        <div className="headerwrapper">
          <div style={{ margin: 0 }} className="all-tabs">
            <div style={{ margin: "0px 0" }} onClick={handleExtractionofGroup}>
              <span className="spanJobWrap">
                <span style={{ display: "flex" }}>
                  <i className="jobsIcon">
                    <SettingNavIcon />
                  </i>
                  <p>Settings</p>
                </span>
              </span>
              <i className="arrows-icons">
                {extractGroup ? <SingleUpArrow /> : <SingleDownArrow />}
              </i>
            </div>
          </div>
          {extractGroup && (
            <div className="extractSetting">
              <div
                className="settingOptions"
                onClick={() => {
                  !connectCrm?.crm_connected && setAddNewCrnModal(!addNewCrnModal);
                }}>
                <div
                  className="dot"
                  style={{ backgroundColor: `${connectCrm?.crm_connected ? "green" : "red"}` }}
                />
                {connectCrm?.crm_connected ? "CRM Connected" : "Connect CRM"}
              </div>
            </div>
          )}
        </div>
      </GroupChat>
      {addNewCrnModal && (
        <ConnectCrmModal
          open={addNewCrnModal}
          handleClose={() => setAddNewCrnModal(false)}
          connectModalData={connectCrm}
          closeStatus={false}
        />
      )}
    </MessengerSidenavWrapper>
  );
}

export default SettingSideNav;

SettingSideNav.propTypes = {
  setSetting: PropTypes.func,
  extractGroup: PropTypes.bool,
  calcHeight: PropTypes.number
};

const MessengerSidenavWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  opacity: 1;

  .headerwrapper {
    display: flex;
    gap: 5px;
    padding: 2px 0;
    justify-content: center;
    flex-direction: column;

    .arrows-icons {
      svg {
        font-size: 18px;
        color: #3d97f2;
        cursor: pointer;
      }
      cursor: pointer;
    }
    header {
      color: #3d3d3d;
      font-family: Lato, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      letter-spacing: 0.12px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
    }
    .header {
      display: flex;
      justify-content: space-between;
      padding: 12px;
    }
  }

  .jobsIcon {
    svg path {
      fill: rgb(255, 255, 255);
    }
  }
  .spanJobWrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .extractSetting {
    margin-left: 50px;
  }
  .settingOptions {
    font-family: Lato, sans-serif;
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-overflow: ellipsis;
    width: 220px;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    padding: 5px 0;
    margin-bottom: 5px;
    display: flex;
    gap: 5px;
    align-items: center;

    .dot {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      border: 1px solid;
    }
  }
`;
