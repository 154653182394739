/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// import styled from "styled-components";
import { Table, Tooltip, message } from "antd";

import "./style.css";
import { edit } from "../../utils/Images/Images";
import styled from "styled-components";
import AddRegions from "./AddRegions";
import { getAllUsersListing, getRegionListing } from "../../services/Collections";
import { useSelector } from "react-redux";
import { ModalLoader } from "../../components/ModalLoader";
import EditRegions from "./EditRegions";
import CxcHeader from "../../components/CxcHeader";
import ResponsiveHeader from "../../components/ResponsiveHeader";

export default function RegionsIndex() {
  const [addModal, setAddModal] = useState(false);
  const userDetails = useSelector((state) => state?.LoginSlice?.data);
  const [usersData, setUsersData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const connectedWorkspace = useSelector((state) => state?.WorkSpaceSlice?.currentWorkspace);
  const connectedWorkspace = useSelector((state) => state?.LoginSlice?.data);

  const [editModal, setEditModal] = useState(false);
  const [editDetails, setEditDetails] = useState(null);
  const [searchData, setSearchData] = useState([]);
  const [searchWord, setSearchWord] = useState("");

  const columns = [
    {
      title: "REGION",
      dataIndex: "region",
      render: (val) => (
        <div>
          <Tooltip placement="topLeft" title={val}>
            <p
              style={{
                cursor: "context-menu",
                width: "100px",
                maxWidth: "100px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap"
              }}>
              {val ? val : "----"}
            </p>
          </Tooltip>
        </div>
      )
    },
    {
      title: "MANAGER",
      dataIndex: "manager"
    },
    {
      title: "MANAGER 2",
      dataIndex: "manager2"
    },
    {
      title: "MANAGER 3",
      dataIndex: "manager3"
    },
    {
      title: "MANAGER 4",
      dataIndex: "manager4"
    },
    {
      title: "DISPATCHER",
      dataIndex: "dispatcher"
    },
    {
      title: "DISPATCHER 2",
      dataIndex: "dispatcher2"
    },
    {
      title: "DISPATCHER 3",
      dataIndex: "dispatcher3"
    },
    {
      title: "DISPATCHER 4",
      dataIndex: "dispatcher4"
    },
    {
      title: "ACTION",
      dataIndex: "",
      render: (_, ele) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            onClick={() => {
              setEditModal(true);
              setEditDetails(ele);
            }}
            style={{ width: "22px", height: "22px", cursor: "pointer" }}
            src={edit}
          />
        </div>
      )
    }
  ];

  const handleRegionListing = async () => {
    setLoading(true);
    let params = new URLSearchParams();
    let dbname =
      "yapi_" + connectedWorkspace?.workspace?._id + "_" + connectedWorkspace?.workspace?.tenant_id;
    params.append("db", dbname);
    let res = await getRegionListing(params.toString());
    if (res?.status == 200 || res?.message == "OK") {
      let arr = [];

      res?.data?.length > 0 &&
        res?.data?.map((ele) => {
          let obj = {
            id: ele?.id,
            region: ele?.region,
            manager: ele?.manager_user_name ? ele?.manager_user_name : "---",
            manager2: ele?.manager_user_name_2 ? ele?.manager_user_name_2 : "---",
            manager3: ele?.manager_user_name_3 ? ele?.manager_user_name_3 : "---",
            manager4: ele?.manager_user_name_4 ? ele?.manager_user_name_4 : "---",
            dispatcher: ele?.dispatcher_user_name ? ele?.dispatcher_user_name : "---",
            dispatcher2: ele?.dispatcher_user_name_2 ? ele?.dispatcher_user_name_2 : "---",
            dispatcher3: ele?.dispatcher_user_name_3 ? ele?.dispatcher_user_name_3 : "---",
            dispatcher4: ele?.dispatcher_user_name_4 ? ele?.dispatcher_user_name_4 : "---",
            ...ele
          };
          arr.push(obj);
        });

      setLoading(false);

      setRegionData(arr);
    } else {
      setLoading(false);

      message.error(res?.message || "Something went wrong");
    }
  };

  const handleUserListing = async () => {
    let params = new URLSearchParams();
    params.append("workspace_id", userDetails?.workspace_id);
    let res = await getAllUsersListing(params.toString());
    if (res?.status === 200) {
      // message.success(res?.message);
      let arr = [];
      Array.isArray(res?.data) &&
        res?.data?.length > 0 &&
        res?.data?.map((ele) => {
          let obj = {
            label: ele?.user_name,
            value: ele?.user_id,
            user_id: ele?.user_id,
            workspace_id: ele?.workspace_id,
            _id: ele?._id
          };
          arr.push(obj);
        });
      setUsersData(arr);
    } else {
      // message.error(res?.response?.data?.message || res?.message || "Something went wrong");
    }
  };

  const handleSearch = (word) => {
    setSearchWord(word);
    if (word) {
      let updated = regionData?.filter((el) =>
        el?.region?.toLowerCase()?.includes(word.toLowerCase())
      );

      setSearchData(updated);
    } else {
      setSearchData([]);
    }
  };

  const afterAddAction = () => {
    handleUserListing();
    handleRegionListing();
  };

  // For Close Action Popover

  useEffect(() => {
    handleUserListing();
    handleRegionListing();
  }, []);

  return (
    <>
      {addModal && (
        <AddRegions
          open={addModal}
          handleModal={() => setAddModal(false)}
          options={usersData}
          afterAddAction={afterAddAction}
        />
      )}
      {editModal && (
        <EditRegions
          open={editModal}
          handleModal={() => setEditModal(false)}
          options={usersData}
          afterAddAction={afterAddAction}
          editDetails={editDetails}
        />
      )}

      <CxcHeader headerName={"Regions"} isFilter={false} />
      <ResponsiveHeader />
      <div>
        <TitleWrapper>
          <h4></h4>
          <SearchWrapper>
            <label>Search:</label>{" "}
            <input
              placeholder="Search..."
              disabled={loading}
              type="text"
              onChange={(e) => handleSearch(e.target.value)}
            />
            <button onClick={() => setAddModal(true)}>Add Region</button>
          </SearchWrapper>
        </TitleWrapper>
      </div>
      <TableWrapper>
        <div className="table-wrap">
          <div className="table-box">
            {loading ? (
              <ModalWrapper>
                <ModalLoader size={40} color={"#101010"} />
              </ModalWrapper>
            ) : (
              <>
                {searchData?.length > 0 || searchWord ? (
                  <Table
                    className="gap-table"
                    columns={columns}
                    dataSource={searchData}
                    pagination={false}
                  />
                ) : (
                  <Table
                    className="gap-table"
                    columns={columns}
                    dataSource={regionData}
                    pagination={false}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </TableWrapper>
    </>
  );
}

const TitleWrapper = styled.div`
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    border-style: none;
    background: #71bbef30;
    color: #00adff;
    padding: 10px 10px;
    border-radius: 7px;
    font-family: Lato, sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    cursor: pointer;
  }

  h2 {
    font-family: Lato, sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 30px;
    line-height: 20px !important;
    color: #101010 !important;
    margin: 10px 0 !important;
  }
`;

export const ModalWrapper = styled.div`
  width: 100%;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow: scroll;

  ::-webkit-scrollbar {
    width: 5px;
    height: 2px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
  }
  ::-webkit-scrollbar-thumb {
    background: #cecece;
    border-radius: 10px;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  input {
    height: 33px;
    padding: 0 5px;
    filter: drop-shadow(rgba(16, 24, 40, 0.06) 0px 2px 12px);
    background: rgb(255, 255, 255);
    border-radius: 8px;
    border-style: none;
    padding: 14px 16px;
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgb(75, 85, 99);
    border: 1px solid #cecece;

    @media (max-width: 500px) {
      width: 60%;
    }

    :focus {
      outline: none;
    }
  }
  label {
    font-family: Lato, sans-serif;
    font-size: 15px;

    @media (max-width: 500px) {
      display: none;
    }
  }
`;
