import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  Download,
  // ThreeDot,
  VideoCollapseIcon,
  VideoExpendIcon,
  VideoPauseIcon,
  VideoPlayIcon,
  VolumeMute,
  VolumeUp
} from "../utils/Icons/SvgIcons";
import PropTypes from "prop-types";
import { UserContext } from "./ChatInbox";
import { Tooltip } from "antd";

const VideoPlayer = ({ videoMessage, id, handleDownload }) => {
  const videoRef = useRef(null);
  const videoWrapperRef = useRef(null);
  const videoRefCurrent = videoRef.current;
  const { handleRef } = useContext(UserContext);
  const [hover, setHover] = useState(false);
  const [mute, setMute] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [actions, setActions] = useState({
    playPause: false,
    mute: false,
    time: false,
    volumn: 10
  });
  const handleMouseover = () => {
    if (videoRef) {
      setActions((pre) => ({ ...pre, time: true }));
      setHover(true);
    }
    setHover(true);
  };

  const handleMouseout = () => {
    if (videoRef) {
      if (videoRef.current.paused) {
        setActions((pre) => ({ ...pre, time: false }));
      }
    }
    setHover(false);
  };

  const handleMute = () => {
    videoRefCurrent.muted = !mute;
    console.log("videoRefCurrent.volume ", mute);
    if (mute) {
      let data =
        videoRefCurrent.volume == 1 ? 10 : Number(videoRefCurrent.volume.toString().slice(2));
      setActions((pre) => ({ ...pre, volumn: data }));
    } else {
      setActions((pre) => ({ ...pre, volumn: 0 }));
    }
    setMute(!mute);
  };

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);

    let newtime = [String(minutes).padStart(2, "0"), String(seconds).padStart(2, "0")];
    if (hours > 0) {
      newtime.unshift(String(hours).padStart(2, "0"));
    }

    return newtime.join(":");
  };

  const handlePlayPause = () => {
    let video = videoRef.current;
    if (video.paused) {
      setActions((pre) => ({ ...pre, playPause: true }));
      video.play();
    } else {
      setActions((pre) => ({ ...pre, playPause: false }));
      video.pause();
    }
  };

  const handleVolumeRange = (e) => {
    let vol = e?.target?.value;
    actions.volumn = e?.target?.value;
    setActions((pre) => ({ ...pre, volumn: vol }));
    if (e?.target?.value == 0) {
      setMute(true);
    } else {
      setMute(false);
    }
    videoRef.current.volume = vol == 10 ? 1 : `0.${vol}`;
  };

  const handleSeek = (event) => {
    const video = videoRef.current;
    video.currentTime = (event.target.value / 100) * video.duration;
  };

  const showCondition = () => {
    if (!hover && !videoRef.current?.paused) {
      return false;
    } else if (!hover && videoRef.current?.paused) {
      return true;
    } else {
      return true;
    }
  };

  function toggleFullscreen() {
    let elem = videoWrapperRef.current;
    setActions((pre) => ({ ...pre, fullScreen: true }));
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem = window.top.document.body;
      elem.msRequestFullscreen();
    }
  }

  function closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }

  console.log("outerHeight", videoWrapperRef.current?.clientHeight == window.outerHeight);
  useEffect(() => {
    const video = videoRef.current;
    video.addEventListener("loadeddata", () => {
      setDuration(video.duration);
    });
    video.addEventListener("timeupdate", () => {
      setCurrentTime(video.currentTime);
      if (video.currentTime == video.duration) {
        setActions((pre) => ({ ...pre, playPause: false }));
      }
    });
  }, []);

  return (
    <VideoPlayerStyle
      hover={hover ? "calc(100% - 24px)" : hover}
      fullScreen={videoWrapperRef.current?.clientHeight == window.outerHeight}
      ref={videoWrapperRef}>
      <video
        height="100%"
        id={id}
        ref={videoRef}
        onPlay={() => handleRef(videoRef)}
        onMouseOver={() => handleMouseover()}
        onMouseOut={() => handleMouseout()}>
        <source src={videoMessage} type="video/mp4" />
        <source src={videoMessage} type="video/ogg" />
        Your browser does not support the video tag.
      </video>

      {showCondition() && (
        <div
          className="controls"
          style={{ width: hover ? videoRef.current.clientWidth - 20 : hover }}
          onMouseOver={handleMouseover}
          onMouseOut={handleMouseout}>
          {hover && (
            <input
              style={{ height: "5px", marginTop: "10px" }}
              type="range"
              min="0"
              max="100"
              value={(currentTime / duration) * 100 || 0}
              onChange={handleSeek}
            />
          )}
          <div className="main">
            <div className="hoverout">
              <i className="play" onClick={handlePlayPause}>
                {actions.playPause ? <VideoPauseIcon /> : <VideoPlayIcon />}
              </i>
              <span className="time">
                {videoRef.current?.duration
                  ? formatTime(actions.time ? currentTime : duration)
                  : ""}
              </span>
              {hover && (
                <div className="volSection">
                  <Tooltip placement="top" title={mute ? "Unmute" : "mute"}>
                    <i className="volIcon" onClick={handleMute}>
                      {mute ? <VolumeMute color="#fff" /> : <VolumeUp color="#fff" />}
                    </i>
                  </Tooltip>
                  <input
                    style={{ width: "100px", height: "5px" }}
                    type="range"
                    min="0"
                    max="10"
                    value={actions.volumn}
                    className="volrange"
                    onChange={handleVolumeRange}
                  />
                </div>
              )}
            </div>
            {/*<div>
            </div> */}
          </div>
        </div>
      )}

      <div
        className="uppertab"
        style={{ width: hover ? videoRef.current.clientWidth : hover }}
        onMouseOver={handleMouseover}
        onMouseOut={handleMouseout}>
        {hover && (
          <i
            className="expend"
            onClick={() =>
              videoWrapperRef.current?.clientHeight == window.outerHeight
                ? closeFullscreen()
                : toggleFullscreen()
            }>
            {videoWrapperRef.current?.clientHeight == window.outerHeight ? (
              <VideoCollapseIcon />
            ) : (
              <VideoExpendIcon />
            )}
          </i>
        )}

        {hover && (
          <i className="expend threedot" onClick={() => handleDownload(videoMessage)}>
            <Download color="#fff" />
          </i>
        )}
      </div>
    </VideoPlayerStyle>
  );
};

export default VideoPlayer;

VideoPlayer.propTypes = {
  videoMessage: PropTypes.string,
  id: PropTypes.string,
  handleDownload: PropTypes.func
};

const VideoPlayerStyle = styled.div`
  position: relative;
  display: flex;
  justify-content: ${({ fullScreen }) => (fullScreen ? "center" : "start")};
  width: ${({ fullScreen }) => (fullScreen ? "100%" : "fit-content")};
  height: ${({ fullScreen }) => (fullScreen ? "100%" : "420px")};

  video {
    border-radius: ${({ fullScreen }) => (fullScreen ? "0" : "8px")};
    box-shadow: 0px 4px 64px 8px rgb(0 0 0 / 10%);
    margin: auto;
  }
  .main {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .expend {
    background: #101010ba;
    padding: 4px;
    border-radius: 6px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .threedot {
    width: 32px;
    height: 32px;
  }
  .controls {
    position: absolute;
    left: ${({ fullScreen }) => (fullScreen ? "none" : "10px")};
    bottom: 10px;
    background: #101010ba;
    padding: 6px 8px;
    border-radius: 6px !important;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .time {
      color: white;
    }
    .play {
      width: 16px;
      height: 16px;
    }
  }
  .uppertab {
    position: absolute;
    right: ${({ fullScreen }) => (fullScreen ? "none" : 0)};
    padding: 10px;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 8px;
  }
  .hoverout {
    display: flex;
    gap: 6px;
    align-items: center;
    height: 25px;
  }
  .volSection {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    align-items: center;
    :hover {
      .volrange {
        display: block;
      }
    }
  }
  .volIcon {
    height: 24px;
    :hover {
      background: #00000080;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  .volrange {
    display: none;
  }
`;
