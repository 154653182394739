/* eslint-disable no-unused-vars */
import React, { createContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ChatInboxHeader from "./ChatInboxHeader";
import ChatInboxEmpty from "./ChatInboxEmpty";
import MessageSender from "./MessageSender";
import {
  fetchingMessageHistory,
  getBeforeMessageHistory,
  readMessage,
  updateSeenStatusMessage
} from "../services/Collections";
import { useDispatch, useSelector } from "react-redux";
import Inbox from "./Inbox";
import { pusher } from "../Pusher/PusherConfig";
import {
  JobsData,
  recentChatHistory,
  receviedNewMessage
} from "../module/Messenger/ChatListIdSlice";
import { ModalLoader } from "./ModalLoader";
import { checkPropTypes } from "prop-types";
import { useLocation } from "react-router-dom";
import {
  chatsData,
  individualRecentChatHistory,
  individualReceviedNewMessage
} from "../module/Messenger/IndividualChatSlice";
import {
  groupRecentChatHistory,
  groupReceviedNewMessage,
  groupsData as groupsListing
} from "../module/group/GroupChatSlice";
import moment from "moment";
import { useCallback } from "react";

const UserContext = createContext();

export default function ChatInbox() {
  const currentLoginSlice = useSelector((state) => state?.LoginSlice?.data);
  const selectedJob = useSelector((state) => state?.ChatId?.job_Id);
  const selectedchat = useSelector((state) => state?.IndividualChat?.selectedChat);
  const selectedGroupchat = useSelector((state) => state?.GroupChat?.selectedChat);
  const scrollRef = useRef(null);
  const dispatch = useDispatch();
  const chat = useSelector((state) => state?.ChatId?.recentChat);
  const individualChat = useSelector((state) => state?.IndividualChat?.recentChat);
  const groupChat = useSelector((state) => state?.GroupChat?.recentChat);
  const location = useLocation();
  let pathname = location?.pathname;
  const [page, setPage] = useState(1);
  const [hasMore, setHasMoreData] = useState(true);
  const [messageLoader, setMessageLoader] = useState(true);
  const groupData = useSelector((state) => state?.GroupChat?.groups_Data);
  const jobData = useSelector((state) => state?.ChatId?.jobs_Data);
  const teamData = useSelector((state) => state?.IndividualChat?.chats_Data);
  const activeSelectedChat = pathname?.includes("group")
    ? selectedGroupchat?.id
    : pathname?.includes("individual")
    ? selectedchat?._id
    : selectedJob?.id;
  const activeSelectedRecentChat = pathname?.includes("group")
    ? groupChat
    : pathname?.includes("individual")
    ? individualChat
    : chat;
  const [scrollPosition, setScrollPosition] = useState(0);

  const [mediaRef, setMediaRef] = useState(null);

  const handleRef = (ref) => {
    if (mediaRef && mediaRef.current?.id !== ref.current?.id) {
      mediaRef.current?.pause();
      setMediaRef(ref);
    }

    if (!mediaRef) {
      setMediaRef(ref);
    }
  };

  const value = { handleRef };

  const manupilateChatData = (chats, allusers) => {
    let arr = [];
    let typesOfObj = ["text", "image", "file", "audio", "video"];

    for (let i = 0; i < chats?.length; i++) {
      // if (chats?.[i]?.type === "text" || chats?.[i]?.type === "image") {
      if (typesOfObj?.includes(chats?.[i]?.type)) {
        for (let j = 0; j < allusers?.length; j++) {
          if (chats[i]?.user_id === allusers[j]?._id) {
            let temp = {
              ...chats[i],
              profile_url: allusers[j]?.profile_url,
              name: allusers[j]?.name,
              timestamp: new Date(chats[i]?.sentAt).getTime()
            };
            arr.push(temp);
            break;
          }
        }
      }
    }

    let res = arr?.sort((a, b) => {
      if (a?.timestamp > b?.timestamp) {
        return -1;
      } else {
        return 1;
      }
    });

    let newchat = [];
    let imgarr = [];

    for (let i = 0; i < res?.length; i++) {
      if (res[i]?.type == "image" || res[i]?.type == "file") {
        let type = res?.[i]?.type;
        let nexttype = res?.[i + 1]?.type;
        let sentMsg = moment(new Date(res?.[i]?.sentAt)).format("YYYY-MM-DD");
        let nextSentMsg = moment(new Date(res?.[i + 1]?.sentAt)).format("YYYY-MM-DD");
        if (res[i]?.user_id == res[i + 1]?.user_id && sentMsg == nextSentMsg && type == nexttype) {
          imgarr.push(res[i]);
        } else {
          if (imgarr?.length > 0) {
            imgarr.push(res[i]);
            newchat.push(imgarr);
            imgarr = [];
          } else {
            newchat.push(res[i]);
          }
        }
      } else {
        if (imgarr?.length > 0) {
          newchat.push(imgarr);
          newchat.push(res[i]);
          imgarr = [];
        } else {
          newchat.push(res[i]);
        }
      }
    }
    return newchat;
  };

  let createAbortController = () => new AbortController();
  let currentAbortController;

  const MessageHistory = async (pageNumber) => {
    setMessageLoader(true);
    currentAbortController = createAbortController();

    let payload = new URLSearchParams();
    payload.append("workspace_id", currentLoginSlice?.workspace?._id);
    !pathname?.includes("group")
      ? pathname?.includes("individual")
        ? payload.append("channelName", `job_${selectedchat?._id}`)
        : payload.append("channelName", `job_${selectedJob?.id}`)
      : payload.append("channelName", `job_${selectedGroupchat?.id}`);
    payload.append("user_id", currentLoginSlice?._id);
    payload.append("page", pageNumber);

    let res;
    if (pageNumber > 1) {
      res = await handleBeforeMessageHistory();
    } else {
      res = await fetchingMessageHistory(payload.toString(), {
        signal: currentAbortController.signal
      });
    }

    if (res?.status === 200) {
      if (res?.data?.length <= 0) {
        setHasMoreData(false);
        setMessageLoader(false);
      }
      let result = manupilateChatData(res?.data, currentLoginSlice?.workspace?.users);
      if (pathname?.includes("group")) {
        pageNumber === 1
          ? dispatch(groupRecentChatHistory(result))
          : dispatch(groupRecentChatHistory([...groupChat, ...result]));
      } else {
        if (pathname?.includes("individual")) {
          pageNumber === 1
            ? dispatch(individualRecentChatHistory(result))
            : dispatch(individualRecentChatHistory([...individualChat, ...result]));
        } else {
          pageNumber === 1
            ? dispatch(recentChatHistory(result))
            : dispatch(recentChatHistory([...chat, ...result]));
        }
      }
      setMessageLoader(false);
      setPage(pageNumber + 1);
    } else {
      dispatch(recentChatHistory([]));
      setMessageLoader(false);
    }
  };

  const handleBeforeMessageHistory = async () => {
    let beforeThisMessage = activeSelectedRecentChat[activeSelectedRecentChat?.length - 1];
    let message;
    if (Array.isArray(beforeThisMessage)) {
      message = beforeThisMessage[beforeThisMessage?.length - 1];
    } else {
      message = beforeThisMessage;
    }

    let param = new URLSearchParams();
    param.append("workspace_id", currentLoginSlice?.workspace?._id);
    param.append("message_id", message?._id);
    param.append("channelName", message?.channelName);

    const response = await getBeforeMessageHistory(param.toString());

    return response;
  };

  const scrollToBottom = () => {
    if (scrollRef?.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth", inline: "end" });
    }
  };

  const handleReadMessages = async (data) => {
    let individual;
    if (pathname?.includes("individual")) {
      individual = 2;
    } else {
      if (pathname?.includes("group")) {
        individual = 3;
      } else {
        individual = 1;
      }
    }
    let requestPayload = {
      message_id: data?._id,
      user_id: data?.user_id,
      channelName: data?.channelName,
      type: individual,
      workspace_id: currentLoginSlice?.workspace?._id
    };

    await readMessage(requestPayload);
  };

  // eslint-disable-next-line no-unused-vars
  const lastestMessageCallback = useCallback(
    (data) => {
      let idid = localStorage.getItem("dummy");

      if (data.channelName == `job_${idid}`) {
        let result = manupilateChatData([data], currentLoginSlice?.workspace?.users);
        if (pathname?.includes("group")) {
          dispatch(groupReceviedNewMessage(result[0]));
        } else {
          if (pathname?.includes("individual")) {
            dispatch(individualReceviedNewMessage(result[0]));
          } else {
            dispatch(receviedNewMessage(result[0]));
          }
        }
        if (data?.user_id !== currentLoginSlice?._id) {
          handleReadMessages(result[0]);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedJob?.id, selectedchat?._id, selectedGroupchat?._id]
  );

  const fetchingLastestMessage = async () => {
    const channel = pusher.subscribe(`workspace_${currentLoginSlice?.workspace?._id}`);
    if (channel) {
      channel.bind("send-message", lastestMessageCallback);
    }
    return () => {
      if (channel) {
        pusher.unsubscribe(`workspace_${currentLoginSlice?.workspace?._id}`);
        channel.unbind("send-message");
      }
    };
  };

  const removeDeleteMessageFromStore = (data, chat) => {
    let idid = localStorage.getItem("dummy");
    if (data?.[0]?.channelName != `job_${idid}`) return;
    if (chat?.length === 0) return;

    let solutionArray = [];
    chat?.forEach((x) => {
      if (Array?.isArray(x)) {
        let newArr = [];
        x?.forEach((item) => {
          if (!data?.find((obj) => obj?._id === item?._id)) {
            newArr?.push(item);
          }
        });
        if (newArr?.length > 0) {
          solutionArray?.push(newArr);
        }
      } else {
        if (!data?.find((obj) => obj?._id === x?._id)) {
          solutionArray?.push(x);
        }
      }
    });

    return solutionArray;
  };

  const deletedGroupMessage = (chat) => {
    let idid = localStorage.getItem("dummy");
    const channel = pusher.subscribe(`workspace_${currentLoginSlice?.workspace?._id}`);
    if (channel) {
      channel.bind("deleted-message", function (data) {
        if (data?.[0]?.channelName == `job_${idid}`) {
          if (pathname?.includes("group")) {
            let result = removeDeleteMessageFromStore(data, groupChat);
            dispatch(groupRecentChatHistory(result));
          } else {
            if (pathname?.includes("individual")) {
              let result = removeDeleteMessageFromStore(data, individualChat);
              dispatch(individualRecentChatHistory(result));
            } else {
              let result = removeDeleteMessageFromStore(data, chat);
              dispatch(recentChatHistory(result));
            }
          }
        }
      });
    }
    return () => {
      if (channel) {
        pusher.unsubscribe(`workspace_${currentLoginSlice?.workspace?._id}`);
        channel.unbind("deleted-message");
      }
    };
  };

  const markedAsRead = (recent, allChat) => {
    let flag = 0;
    let tempChat = [...allChat];

    outerLoop: for (let i = 0; i < tempChat?.length; i++) {
      if (Array.isArray(tempChat[i])) {
        let arrTemp = [];
        for (let j = 0; j < tempChat?.[i]?.length; j++) {
          if (recent?.[0]?._id === tempChat[i]?.[j]?._id) {
            flag++;
          }
          if (flag === 1) {
            if (tempChat[i]?.[j]?.isRead) {
              let arr = tempChat?.[i].slice(j);
              tempChat[i] = [...arrTemp, ...arr];
              arrTemp = [];
              break outerLoop;
            }
            arrTemp.push({ ...tempChat?.[i]?.[j], isRead: true });
          }
        }
        if (arrTemp?.length > 0) {
          tempChat[i] = arrTemp;
        }
      } else {
        if (recent?.[0]?._id === tempChat[i]?._id) {
          flag++;
        }
        if (flag === 1) {
          if (tempChat[i]?.isRead) {
            break;
          }
          tempChat[i] = { ...tempChat[i], isRead: true };
        }
      }
    }
    if (pathname?.includes("group")) {
      dispatch(groupRecentChatHistory(tempChat));
    } else {
      if (pathname?.includes("individual")) {
        dispatch(individualRecentChatHistory(tempChat));
      } else {
        dispatch(recentChatHistory(tempChat));
      }
    }
  };

  const readEventMessage = (chat) => {
    const channel = pusher.subscribe(`workspace_${currentLoginSlice?.workspace?._id}`);
    if (channel) {
      channel.bind("latest-message", function (data) {
        markedAsRead(data, chat);
      });
    }
    return () => {
      if (channel) {
        pusher.unsubscribe(`workspace_${currentLoginSlice?.workspace?._id}`);
        channel.unbind("latest-message");
      }
    };
  };

  const getName = () => {
    if (pathname?.includes("group")) {
      return selectedGroupchat?.jobname;
    } else {
      if (pathname?.includes("individual")) {
        let getName =
          selectedchat?.userData?.length > 0 &&
          selectedchat?.userData?.find((el) => el?._id != currentLoginSlice?._id);

        return getName?.name;
      } else {
        return selectedJob?.jobname;
      }
    }
  };
  const getImage = () => {
    if (pathname?.includes("group")) {
      return selectedGroupchat?.image_url;
    } else {
      if (pathname?.includes("individual")) {
        let getName =
          selectedchat?.userData?.length > 0 &&
          selectedchat?.userData?.find((el) => el?._id != currentLoginSlice?._id);
        return getName?.profile_url;
      } else {
        return selectedJob?.image_url;
      }
    }
  };
  const getCreatedAt = () => {
    if (pathname?.includes("group")) {
      return selectedGroupchat?.createdAt;
    } else {
      if (pathname?.includes("individual")) {
        let getName =
          selectedchat?.userData?.length > 0 &&
          selectedchat?.userData?.find((el) => el?._id != currentLoginSlice?._id);
        return getName?.createdAt;
      } else {
        return selectedJob?.createdAt;
      }
    }
  };

  const getCountData = () => {
    let selectedChatByUser;
    let selectedData;
    let selectedGroup;
    if (pathname?.includes("group")) {
      selectedChatByUser = selectedGroupchat;
      selectedData = groupData;
    } else if (pathname?.includes("individual")) {
      selectedChatByUser = selectedchat;
      selectedData = teamData;
    } else {
      selectedChatByUser = selectedJob;
      selectedData = jobData;
    }
    if (selectedChatByUser?.type == 2) {
      selectedGroup = selectedData?.find((ele) => ele?._id == selectedChatByUser?._id);
    } else {
      selectedGroup = selectedData?.find((ele) => ele?._id == selectedChatByUser?.id);
    }
    if (selectedGroup?.members?.length > 0) {
      let countData = selectedGroup?.members?.find((el) => el?.user_id == currentLoginSlice?._id);
      return countData;
    }
  };

  const handleNavigate = () => {
    let selectedChatByUser;
    let selectedData;
    if (pathname?.includes("group")) {
      selectedChatByUser = selectedGroupchat;
      selectedData = groupData;
    } else if (pathname?.includes("individual")) {
      selectedChatByUser = selectedchat;
      selectedData = teamData;
    } else {
      selectedChatByUser = selectedJob;
      selectedData = jobData;
    }
    if (selectedData && selectedData?.length > 0) {
      let object = null;
      for (let i = 0; i < selectedData?.length; i++) {
        if (pathname?.includes("individual")) {
          if (selectedData[i]?._id === selectedChatByUser?._id) {
            let tempArray = [{ ...selectedData[i]?.members?.[0], unreadMessageCount: 0 }];
            object = { ...selectedData?.[i], members: tempArray };
          }
        } else {
          if (selectedData[i]?._id === selectedChatByUser?.id) {
            let tempArray = [{ ...selectedData?.[i]?.members?.[0], unreadMessageCount: 0 }];
            object = { ...selectedData?.[i], members: tempArray };
          }
        }
      }
      const NewData = selectedData.map((ele) => {
        if (ele?._id === object?._id) {
          return object;
        } else {
          return ele;
        }
      });
      if (pathname?.includes("group")) {
        dispatch(groupsListing(NewData));
      } else if (pathname?.includes("individual")) {
        dispatch(chatsData(NewData));
      } else {
        dispatch(JobsData(NewData));
      }
    }
  };

  const handleSeenMessage = async () => {
    const req = {
      user_id: currentLoginSlice?._id,
      channelName: `job_${activeSelectedChat}`,
      type: pathname?.includes("group") ? 3 : pathname?.includes("individual") ? 2 : 1,
      workspace_id: currentLoginSlice?.workspace?._id
    };

    await updateSeenStatusMessage(req);
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const position = Math.ceil((scrollTop / (scrollHeight - clientHeight)) * 100);
    setScrollPosition(position);
  };

  useEffect(() => {
    deletedGroupMessage(chat);

    if (pathname?.includes("group")) {
      readEventMessage(groupChat);
    } else {
      if (pathname?.includes("individual")) {
        readEventMessage(individualChat);
      } else {
        readEventMessage(chat);
      }
    }
  }, [chat, individualChat, groupChat]);

  useEffect(() => {
    setMessageLoader(true);
    setPage(1);
    setHasMoreData(true);
    if (pathname?.includes("group") && selectedGroupchat?.id) {
      MessageHistory(1);
    }
    if (pathname?.includes("individual") && selectedchat?._id) {
      MessageHistory(1);
    }
    if (pathname?.includes("chats") && selectedJob?.id) {
      MessageHistory(1);
    }
    fetchingLastestMessage();
    return () => {
      if (currentAbortController) {
        currentAbortController.abort();
      }
    };
  }, [selectedJob, selectedchat, selectedGroupchat]);

  useEffect(() => {
    if (scrollPosition >= -3 && getCountData()?.unreadMessageCount > 0) {
      handleNavigate();
      handleSeenMessage();
    }
  }, [scrollPosition, groupChat, individualChat, chat, getCountData()?.unreadMessageCount]);

  return (
    <UserContext.Provider value={value}>
      <ChatInboxWrapper>
        <ChatInboxHeader name={getName()} image={getImage()} createdDate={getCreatedAt()} />
        {page == 1 && messageLoader ? (
          <div className="modalWrapper">
            <ModalLoader size={28} />
          </div>
        ) : activeSelectedRecentChat && activeSelectedRecentChat?.length > 0 ? (
          <>
            <Inbox
              chat={activeSelectedRecentChat}
              MessageHistory={MessageHistory}
              page={page}
              hasMore={hasMore}
              scrollRef={scrollRef}
              messageLoader={messageLoader}
              parentScrollingBox={handleScroll}
            />
          </>
        ) : (
          <ChatInboxEmpty />
        )}

        <MessageSender
          type={!pathname?.includes("group") ? (pathname?.includes("individual") ? 2 : 1) : 3}
          scrollToBottom={scrollToBottom}
        />
        {getCountData()?.unreadMessageCount > 0 && scrollPosition <= -3 && (
          <div
            className="unreadMsgCount"
            onClick={() => {
              scrollToBottom();
              handleNavigate();
              handleSeenMessage();
            }}>
            {getCountData()?.unreadMessageCount > 10 ? "10+" : getCountData()?.unreadMessageCount}{" "}
            new message
          </div>
        )}
      </ChatInboxWrapper>
    </UserContext.Provider>
  );
}

export { UserContext };

ChatInbox.propTypes = {
  type: checkPropTypes.any
};

const ChatInboxWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 48px);
  /* padding: 0 20px; */
  padding: 0 10px 0 0;
  position: relative;

  @media (max-width: 992px) {
    height: calc(100vh - 86px);
  }

  .modalWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70%;
  }

  .unreadMsgCount {
    width: fit-content;
    height: 30px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 80px;
    right: 46%;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 0 10px;
    color: white;
    background: #3273b5;
    font-size: 12px;
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 400;

    @media (max-width: 992px) {
      top: 55px;
    }
  }
`;
