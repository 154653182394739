/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ChatList from "./ChatList";
import PropTypes from "prop-types";
import { InfoIcon, NavPlus, SingleDownArrow, TeamIcon } from "../utils/Icons/SvgIcons";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUsersListing,
  searchChatListing,
  viewJobMember
} from "../services/Collections";
import { message, Popover } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useNavigate } from "react-router-dom";
import { pusher } from "../Pusher/PusherConfig";
import { JobsData } from "../module/Messenger/ChatListIdSlice";
import { chatsData, selectedChat } from "../module/Messenger/IndividualChatSlice";
import AddTeamMember from "../modals/AddTeamMember";
import { unReadMessageEvent } from "../Pusher/PusherSlice";

export default function TeamSideNav({
  setSubMenu,
  extractGroup,
  setExtractGroup,
  navigateRoutes,
  calcHeight,
  setJobExtractGroup,
  setGroupExtractGroup
}) {
  const location = useLocation()?.pathname;
  const activeJobId = useSelector(
    (state) => state?.IndividualChat?.selectedChat?.id ?? state?.IndividualChat?.selectedChat?._id
  );
  const [activeJob, setActiveJob] = useState(null);
  const connectedWorkspace = useSelector((state) => state?.LoginSlice?.data);
  const [page, setPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const jobListData = useSelector((state) => state?.IndividualChat?.chats_Data);
  let userid = useSelector((state) => state?.LoginSlice?.data?._id);
  let AllJobIds = useSelector((state) => state?.LoginSlice?.data?.jobIds);
  const [searchJobData, setSearchJobData] = useState([]);
  const currentLoginSlice = useSelector((state) => state?.LoginSlice?.data);
  const navigate = useNavigate();
  const [openTeamModal, setOpenTeamModal] = useState(false);
  const [open, setOpen] = useState(false);
  const messageUnRead = useSelector((state) => state?.PusherSlice?.unReadMessageEvent);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleExtractionofGroup = () => {
    setExtractGroup(!extractGroup);
  };

  const searchJobsListing = async () => {
    setPage(1);
    let params = new URLSearchParams();
    params.append("workspace_id", connectedWorkspace?.workspace?._id);
    search && params.append("search", search);

    let res = await getAllUsersListing(params.toString());
    if (res?.status == 200 || res?.message == "OK") {
      let data = res?.data;
      let filteredArray = [];

      data?.length &&
        data.map((el) => {
          if (el?._id != connectedWorkspace?._id) {
            let obj = {
              userData: [el]
            };
            filteredArray.push(obj);
          }
        });

      setHasMoreData(filteredArray.length !== 0);

      if (search) {
        setSearchJobData(filteredArray);
      } else {
        dispatch(JobsData(filteredArray));
      }
    } else {
      message.error(res?.response?.data?.message || res?.message || "Something went wrong");
    }
  };

  const handleJobsListing = async (pagenumber) => {
    let currentpage;
    if (
      jobListData?.length > 0 &&
      jobListData?.[0]?.workspace_id != connectedWorkspace?.workspace?._id
    ) {
      currentpage = 1;
    } else {
      currentpage = pagenumber ?? page;
    }
    let params = new URLSearchParams();
    params.append("workspace_id", connectedWorkspace?.workspace?._id);
    params.append("user_id", connectedWorkspace?._id);
    params.append("page", currentpage);

    let res = await searchChatListing(params.toString());
    if (res?.status == 200 || res?.message == "OK") {
      let data = res?.data;
      let datalength = currentpage == 1 ? 0 : jobListData?.length;
      setHasMoreData(data?.workspaceusersCount > datalength + data?.length);
      if (currentpage === 1) {
        dispatch(chatsData(data));
      } else {
        dispatch(chatsData([...jobListData, ...data]));
      }
      if (data?.workspaceusersCount > datalength + data?.length) {
        setPage(currentpage + 1);
      }
    } 
  };

  const handleRoutes = () => {
    if (activeJobId) {
      navigateRoutes("individual");
    }
  };

  const matchJobsId = async (data) => {
    const idFind = jobListData?.some((val) => val?._id === data?.job_id);
    if (idFind) {
      const param = new URLSearchParams();
      param.append("job_id", data?.job_id);
      param.append("user_id", userid);
      param.append("type", 2);
      param.append("workspace_id", currentLoginSlice?.workspace?._id);

      const res = await viewJobMember(param.toString());

       dispatch(unReadMessageEvent({}));

      if (res?.status === 200) {
        let countData =
          res?.data?.members?.length > 0 &&
          res?.data?.members?.find((el) => el?.user_id == currentLoginSlice?._id);

        if (countData?.unreadMessageCount == 0) {
          return;
        } else {
          let tempArray = [];
          for (let i = 0; i < jobListData?.length; i++) {
            if (jobListData?.[i]?._id !== res?.data?._id) {
              tempArray.push(jobListData[i]);
            }
          }
          dispatch(chatsData(res?.data));
        }
      }
    } else {
      handleJobsListing();
    }
  };

  const handleAddMember = () => {
    const channel = pusher.subscribe(`workspace_${connectedWorkspace?.workspace?._id}`);
    if (channel) {
      channel.bind("one-to-one", function (data) {
        setPage(1);
        handleJobsListing(1);
      });
    }
    return () => {
      if (channel) {
        pusher.unsubscribe(`workspace_${connectedWorkspace?.workspace?._id}`);
        channel.unbind("one-to-one");
      }
    };
  };

  useEffect(() => {
    handleAddMember();
  }, [connectedWorkspace?.workspace]);

  useEffect(() => {
    if (messageUnRead?.type == 2) {
      let findUser = messageUnRead?.memberIds?.some((el) => el == connectedWorkspace?._id);
      if (messageUnRead?.memberIds?.length > 0 && findUser) {
        if (messageUnRead?.job_id) {
          matchJobsId(messageUnRead);
          setPage(1);
        }
      }
    }
  }, [messageUnRead]);

  useEffect(() => {
    if (search) {
      searchJobsListing();
    } else {
      handleJobsListing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, connectedWorkspace?.workspace_id]);

  useEffect(() => {
    let locationPathId = location.slice(location.lastIndexOf("/") + 1);
    if (location?.includes("individual")) {
      let data = jobListData.find((ele) => ele?._id == locationPathId);
      if (data) {
        localStorage.setItem("dummy", data?._id);
        dispatch(selectedChat(data));
      } else if (activeJobId) {
        navigate(`/private-layout/individual/${activeJobId}`);
      } else {
        navigate("/private-layout/individual");
      }
    }
    if (activeJobId) {
      setActiveJob(activeJobId);
    }
  }, [activeJobId]);

  return (
    <MessengerSidenavWrapper calcHeight={calcHeight}>
      <GroupChat isOpen={extractGroup}>
        <div className="headerwrapper">
          {openTeamModal && (
            <AddTeamMember
              open={openTeamModal}
              handleClose={() => setOpenTeamModal(!openTeamModal)}
            />
          )}
          <div style={{ margin: 0 }} className="all-tabs">
            <div
              style={{ margin: "0px 0" }}
              className={
                (location.includes("individual") || location?.includes("no-chat")) && "active"
              }>
              <span
                className="spanJobWrap"
                onClick={() => {
                  setSearch("");
                  if (jobListData?.length > 0) {
                    handleRoutes();
                    setSubMenu(false);
                    setJobExtractGroup(false);
                    setGroupExtractGroup(false);
                    handleExtractionofGroup();
                    setExtractGroup(!extractGroup);
                  } else {
                    navigateRoutes("no-chat");
                    setExtractGroup(!extractGroup);
                  }
                }}>
                <span style={{ display: "flex", width: "100%" }}>
                  <i className="jobsIcon">
                    <TeamIcon />
                  </i>
                  <p>Team Chat</p>
                </span>
                <i className="arrows-icons">
                  {extractGroup ? (
                    <Popover
                      prefixCls="addPopOverClass"
                      content={
                        <CreateStyled onClick={hide}>
                          <div>
                            <h4>
                              <InfoIcon width="15px" height="15px" /> Click + to add team mate
                            </h4>
                          </div>
                        </CreateStyled>
                      }
                      trigger="hover"
                      open={open}
                      onOpenChange={handleOpenChange}>
                      <p
                        className="plusIcon"
                        onClick={(event) => {
                          event.stopPropagation();
                          setOpenTeamModal(true);
                        }}>
                        <NavPlus />
                      </p>
                    </Popover>
                  ) : (
                    <SingleDownArrow />
                  )}
                </i>
              </span>
            </div>
          </div>

          {extractGroup && (
            <>
              <div id="scrollableDiv" className="infinityScroll">
                <InfiniteScroll
                  dataLength={jobListData?.length}
                  next={handleJobsListing}
                  hasMore={hasMoreData}
                  scrollableTarget="scrollableDiv"
                  className="infinity">
                  {search
                    ? searchJobData?.length > 0 &&
                      searchJobData?.map((val, index) => {
                        return (
                          <ChatList
                            key={index}
                            jobId={val._id}
                            chatData={val}
                            activeJob={activeJob}
                            setActiveJob={setActiveJob}
                            type={2}
                            searchChat={true}
                          />
                        );
                      })
                    : jobListData?.map((val, index) => {
                        return (
                          <ChatList
                            key={index}
                            jobId={val._id}
                            chatData={val}
                            activeJob={activeJob}
                            setActiveJob={setActiveJob}
                            type={2}
                            search={false}
                          />
                        );
                      })}
                </InfiniteScroll>
              </div>
            </>
          )}
        </div>
      </GroupChat>
    </MessengerSidenavWrapper>
  );
}

TeamSideNav.propTypes = {
  setSubMenu: PropTypes.func,
  navigateRoutes: PropTypes.func,
  setExtractGroup: PropTypes.func,
  extractGroup: PropTypes.bool,
  calcHeight: PropTypes.number,
  setJobExtractGroup: PropTypes.func,
  setGroupExtractGroup: PropTypes.func
};

export const GroupChat = styled.div`
  width: 100%;
  /* height: ${({ isOpen }) => (isOpen ? "95%" : "48px")}; */
  overflow: hidden;

  .groupchat-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: #3d97f2;
      font-size: 19px;
    }
  }
`;

const MessengerSidenavWrapper = styled.div`
  width: 100%;
  height: auto;
  /* padding: 20px 0 0 0; */
  position: relative;
  overflow: hidden;
  opacity: 1;

  .create-group {
    padding: 10px 20px;
    margin-left: 30px;
    width: 80%;
    color: #fff;
    font-family: Lato, sans-serif;
    cursor: pointer;
    /* background: rgb(251 242 242 / 17%); */
    background: red;
    border-radius: 7px;
    ${"" /* border: 1px solid red; */}
  }

  .headerwrapper {
    display: flex;
    gap: 5px;
    padding: 2px 0;
    justify-content: center;
    flex-direction: column;

    .arrows-icons {
      svg {
        font-size: 18px;
        color: #3d97f2;
        cursor: pointer;
      }
      cursor: pointer;
    }
    header {
      color: #3d3d3d;
      font-family: Lato, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      letter-spacing: 0.12px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
    }
    .header {
      display: flex;
      justify-content: space-between;
      padding: 12px;
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
  }

  .seenAll {
    font-family: Lato, sans-serif;
    color: #8f8f8f;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin: 10px 0;
    padding: 0 44px;
  }

  .infinityScroll {
    max-height: ${({ calcHeight }) => `${calcHeight}px`};
    overflow-y: auto;
    padding: 0 0 0 40px;
    ::-webkit-scrollbar {
      width: 2px;
    }
    ::-webkit-scrollbar-thumb {
      background: rgb(232 237 243 / 38%);
      border-radius: 10px;
    }
  }
  /* .infinity{
    height: auto;
    overflow: auto;
    ::-webkit-scrollbar {
      height: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background: #f5f5f5;
      border-radius: 10px;
    }
  } */
  .jobsIcon {
    svg path {
      fill: rgb(255, 255, 255);
    }
  }
  .spanJobWrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .inputDiv {
    position: relative;
    display: flex;
    justify-content: center;
  }
  .searchInput {
    width: 90%;
    /* background: #343434; */
    background: transparent;
    border: 1px solid transparent;
    padding: 10px 10px 10px 22px;
    border-radius: 500px;
    outline: none;
    color: white;
    font-family: Lato, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  .searchIconWrap {
    position: absolute;
    left: 22px;
    top: 54%;
    transform: translate(-50%, -50%);

    svg {
      width: 15px;
      path {
        stroke: white;
      }
    }
  }
  .plusIcon {
    width: fit-content;
    height: 31px;
    font-size: 20px;
    color: inherit;
    padding: 0 6px;
    /* margin-right: 12px; */
    svg {
      width: 17px !important;
      height: 17px !important;
    }
  }
`;

export const CreateStyled = styled.div`
  color: black;
  cursor: pointer;
  border-radius: 7px;

  h4 {
    font-weight: 700;
    font-family: Lato, sans-serif;
    font-size: 15px;
    font-style: normal;
    display: flex;
    align-items: center;
    gap: 5px;
  }
`;
